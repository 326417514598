import { Redirect } from 'react-router';
import React, { useState, FunctionComponent, useContext, useEffect } from 'react';

import { Grid, Toolbar, useMediaQuery, ClickAwayListener } from '@material-ui/core';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import Header from './Header';
import IdleTimeout from './IdleTimeout';
import PrimaryDrawer from './PrimaryDrawer/PrimaryDrawer';
import SecondaryDrawer from './SecondaryDrawer';

import Paths from 'constants/Paths';
import MakeAPayment from 'constants/Paths/MakeAPayment';
import { LoanContext } from 'context';
import { ErrorBoundaryFallback } from 'components';
import AuthenticationContext from 'context/Authentication';
import MakeAPaymentContext, { MakeAPaymentActionTypes } from 'context/MakeAPayment';

import { ErrorBoundary } from 'sfc-kit';
import CopyrightInfo from '../Private/CopyrightInfo';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
			padding: theme.spacing(3),

			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(2),
			},
		},
		contentNoPadding: {
			minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
			padding: theme.spacing(3),

			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(0),
			},
		},
	})
);

const PrivateLayout: FunctionComponent = ({ children }) => {
	const theme = useTheme();
	const classes = useStyles({} as any);
	const location = useLocation();
	const isSmall = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg + 1));
	const isTiny = useMediaQuery(theme.breakpoints.down('sm'));
	const isLaptop = useMediaQuery(theme.breakpoints.down(1601));
	const [isMobileView, setIsMobileView] = useState<boolean>(false);

	const { selectLoan, selectedLoan } = useContext(LoanContext);

	const {
		authenticationState: { isAuthenticated },
	} = useContext(AuthenticationContext);

	const { dispatchForMakeAPayment } = useContext(MakeAPaymentContext);

	const [primaryDrawerOpen, setPrimaryDrawerOpen] = useState(false);
	const [secondaryDrawerOpen, setSecondaryDrawerOpen] = useState(false);

	const isMakeAPayment = location.pathname.includes(MakeAPayment.Root);

	const onClickAway = (): void => {
		if (isSmall && (primaryDrawerOpen || secondaryDrawerOpen)) {
			setPrimaryDrawerOpen(false);
			setSecondaryDrawerOpen(false);
		}
	};

	useEffect(() => {
		if (isTiny) {
			setPrimaryDrawerOpen(false);
			setSecondaryDrawerOpen(false);
		} else {
			setPrimaryDrawerOpen(true);
			setSecondaryDrawerOpen(false);
		}
	}, [isTiny]);

	const onPrimaryDrawerClick = (loanNumber?: string): void => {
		// Close the drawer if loanNumber is undefined and isSmall

		if (loanNumber === undefined) {
			// clear loanToken
			if (sessionStorage.getItem('LoanToken')) {
				sessionStorage.removeItem('LoanToken');
			}
			// clear selected loan
			selectLoan();
			setPrimaryDrawerOpen(!isSmall && !isTiny);
		} else {
			setSecondaryDrawerOpen(true);
			setPrimaryDrawerOpen(false);

			// New loan selected
			if (selectedLoan?.loanNumber !== loanNumber) {
				dispatchForMakeAPayment({ type: MakeAPaymentActionTypes.ClearState });
			}

			// LoanToken logic if a loan number is selected
			const sessionItem = sessionStorage.getItem('LoanToken');
			const isNotSelectedLoanNumber = loanNumber && sessionItem !== loanNumber;

			if (isNotSelectedLoanNumber) {
				sessionStorage.setItem('LoanToken', loanNumber !== undefined ? loanNumber : '');
			}
			selectLoan(loanNumber);
		}
	};

	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);

	const onSecondaryDrawerClose = (closePrimary: boolean): void => {
		setSecondaryDrawerOpen(false);

		if (closePrimary) {
			setPrimaryDrawerOpen(false);
		} else {
			// ensure tab order by setting primary drawer to open (if the secondary drawer is open on load, primary drawer will be "closed")
			setPrimaryDrawerOpen(true);
		}
	};

	const onMenuClick = (): void => {
		// when a user clicks the "Customer portal" logo, the tab order messes up until a second click
		if (isSmall) {
			setPrimaryDrawerOpen(open => !open);
		}

		// Also this should route to the Dsa
		setSecondaryDrawerOpen(open => !open && !primaryDrawerOpen && selectedLoan !== undefined);
	};

	if (!isAuthenticated) {
		return <Redirect to={Paths.Home} />;
	}

	return (
		<Grid container wrap="nowrap">
			<IdleTimeout />

			{!isMobileView && (
				<ClickAwayListener onClickAway={onClickAway}>
					<div>
						<Header onMenuClick={onMenuClick} />
						<PrimaryDrawer
							desktop={!isSmall}
							open={primaryDrawerOpen}
							onClick={onPrimaryDrawerClick}
							aria-hidden={!primaryDrawerOpen ? 'true' : null}
						/>
						<SecondaryDrawer open={secondaryDrawerOpen} onClose={onSecondaryDrawerClose} />
					</div>
				</ClickAwayListener>
			)}

			<Grid
				container
				component="main"
				direction="column"
				wrap="nowrap"
				className={isMakeAPayment && isTiny ? classes.contentNoPadding : classes.content}>
				{!isMobileView && <Toolbar />}
				<ErrorBoundary Fallback={ErrorBoundaryFallback}>{children}</ErrorBoundary>
				{isLaptop && !isMobileView && (
					<div>
						<CopyrightInfo isSidebarOpen laptopFooter />
					</div>
				)}
			</Grid>
		</Grid>
	);
};

export default PrivateLayout;
