import React, { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { alpha } from '@material-ui/core/styles/colorManipulator';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import SelectableListItem from './SelectableListItem';
import EditToggleTextField from 'sfc-kit/src/components/EditToggleTextField';

import Routes from 'constants/Routes';
import Paths from 'constants/Paths';
import LoanService from 'services/LoanService';
import NumberUtility from 'sfc-kit/src/utils/NumberUtility';

import { Grid, Divider, ListItem, ListItemIcon, ListItemText, useMediaQuery, LinearProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { LoanContext } from 'context';
import GlobalContext from 'context/Global';
import { MakeAPaymentButton } from 'components';
import { Button, FlexBox, useLoanDetailsQuery, usePayoffProgressQuery, Text } from 'sfc-kit';
import { useCustomerPortalNotificationStack, useLoanNickname } from 'hooks';
import { useQueryCache } from 'react-query';
import classnames from 'classnames';
import colors from '../../../../src/sfc-kit/src/constants/Colors';

export interface ISecondaryDrawerProps {
	onClose: (closePrimary: boolean) => void;
	open?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			margin: theme.spacing(2, 0),
		},
		listItemButton: {
			border: `2px solid ${colors.misc.white}`,
		},
		nickname: {
			color: theme.palette.common.white,
		},
		nicknameDisabled: {
			color: alpha(theme.palette.common.white, 0.87),
		},
		nicknameUnderline: {
			'&::before': {
				borderBottomStyle: 'solid !important',
				borderColor: alpha(theme.palette.common.white, 0.42),
			},

			'&:hover::before': {
				borderColor: `${alpha(theme.palette.common.white, 0.87)} !important`,
			},

			'&::after': {
				borderColor: theme.palette.primary.light,
			},
		},
		nicknameIcon: {
			color: theme.palette.primary.light,
		},
		progressBar: {
			margin: theme.spacing(0, 1),
			backgroundColor: '#eeeeee',
			height: '7px',
		},
		progressBarColorPrimary: {
			backgroundColor: theme.palette.common.white,
		},
		progressBarBarColorPrimary: {
			backgroundColor: '#0ce386',
		},
		icon: {
			color: 'inherit',
			minWidth: 40,
		},
		backIcon: { transform: 'rotate(180deg)' },
		backButton: { position: 'absolute', top: '-10px', right: 0 },
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const SecondaryDrawerContent: React.FC<ISecondaryDrawerProps> = ({ onClose, open }) => {
	const theme = useTheme();
	const classes = useStyles({} as any);
	const location = useLocation();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const cache = useQueryCache();
	const { selectedLoan, selectedLoanNumber, hasLoanSelected } = useContext(LoanContext);

	const { dispatchForGlobal } = useContext(GlobalContext);

	const { createDangerNotification } = useCustomerPortalNotificationStack();

	const { nickname: currentNickname, defaultNickname } = useLoanNickname();

	const [editingNickname, setEditingNickname] = React.useState(false);
	const [nickname, setNickname] = React.useState(currentNickname);

	const { isLoading, data: payoffProgress } = usePayoffProgressQuery(
		{ loanNumber: selectedLoanNumber },
		{ queryConfig: { enabled: hasLoanSelected } }
	);

	React.useEffect(() => {
		setNickname(currentNickname);
	}, [currentNickname]);

	const saveNickname = async (): Promise<void> => {
		try {
			const updatedNickname = nickname.trim().length > 0 ? nickname : defaultNickname;

			await LoanService.UpdateLoanNickname(selectedLoanNumber, updatedNickname, dispatchForGlobal);
		} catch (error) {
			createDangerNotification({ title: 'notifications.oops', body: 'notifications.updateLoanNickname.failure' });
		} finally {
			setEditingNickname(false);
		}

		cache.invalidateQueries(useLoanDetailsQuery.key);
	};

	const onRouteLinkClick = (): void => {
		if (isMobile) {
			onClose(true);
		}
	};

	return (
		<>
			<ListItem key="Loan Section">
				<FlexBox justifyContent="space-between">
					<ListItemText primary="LOAN SECTION" />
					<Button
						size="small"
						className={classnames(classes.focus, classes.backButton)}
						startIcon={<DoubleArrowIcon className={classes.backIcon} />}
						message="buttons.back"
						casing="capitalizeEachWord"
						variant="text"
						textColor="misc.white"
						onClick={(): void => onClose(false)}
						tabIndex={open ? 2 : -1}
					/>
				</FlexBox>
			</ListItem>
			<Divider aria-hidden="true" />
			<ListItem className={classes.listItem} tabIndex={2}>
				<EditToggleTextField
					id="nickname-input"
					fullWidth
					saveOnEnterPress
					value={nickname}
					onSave={saveNickname}
					onChange={setNickname}
					editing={editingNickname}
					onEdit={(): void => setEditingNickname(true)}
					placeholder={defaultNickname}
					classes={{ icon: classes.nicknameIcon, iconButton: classes.focus }}
					InputProps={{
						disableUnderline: false,
						tabIndex: 2,
						classes: {
							root: classes.nickname,
							disabled: classes.nicknameDisabled,
							underline: classes.nicknameUnderline,
						},
					}}
					inputProps={{ tabIndex: 2 }}
				/>
			</ListItem>

			<ListItem className={classes.listItem}>
				<Grid container justifyContent="space-around" alignItems="center">
					<Grid item xs={6}>
						<Text variant="caption" color="misc.white">
							Initial Balance
						</Text>
						<Text color="misc.white">
							{isLoading ? <Skeleton animation="wave" /> : `$${NumberUtility.formatCurrency(payoffProgress?.loanAmount)}`}
						</Text>
					</Grid>
					<Grid item xs={6}>
						<Text align="center" variant="caption" color="misc.white">
							{isLoading ? <Skeleton animation="wave" /> : `${payoffProgress?.percentagePaid ?? 0}%`}
						</Text>
						<LinearProgress
							className={classes.progressBar}
							classes={{ colorPrimary: classes.progressBarColorPrimary, barColorPrimary: classes.progressBarBarColorPrimary }}
							variant="determinate"
							value={payoffProgress?.percentagePaid ?? 0}
						/>
					</Grid>
				</Grid>
			</ListItem>

			<ListItem className={classes.listItem}>
				<MakeAPaymentButton
					fullWidth
					openLoan={selectedLoan}
					tabIndex={open ? 2 : -1}
					className={classnames(classes.focus, classes.listItemButton)}
				/>
			</ListItem>

			{Routes.PrivateApp.SecondaryDrawer.map(route => {
				const selected = location.pathname.includes(Paths[route.link]);
				return (
					<SelectableListItem
						className={classes.focus}
						button
						key={route.heading}
						selected={selected}
						component={RouterLink}
						to={{ pathname: Paths[route.link], state: { selectedLoanNumber } }}
						onClick={onRouteLinkClick}
						tabIndex={open ? 2 : -1}>
						<ListItemIcon className={classes.icon}>
							<route.icon fontSize="small" color={selected ? 'primary' : 'inherit'} />
						</ListItemIcon>
						<ListItemText>{route.heading}</ListItemText>
					</SelectableListItem>
				);
			})}
		</>
	);
};

export default SecondaryDrawerContent;
