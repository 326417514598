import React, { useContext } from 'react';
import classnames from 'classnames';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { List, Drawer, Divider, Toolbar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import CopyrightInfo from '../CopyrightInfo';
import SelectableListItem from '../SelectableListItem';
import PrimaryDrawerLoanListItem from './PrimaryDrawerLoanListItem';

import { FlexBox, FlexItem, Text } from 'sfc-kit';
import Paths from 'constants/Paths';
import { LoanContext } from 'context';
import useLoans from 'hooks/useLoans';

interface PrimaryDrawerProps {
	open?: boolean;
	desktop?: boolean;

	onClick: (loanNumber?: Loan['loanNumber']) => void;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		drawerPaper: {
			backgroundColor: 'inherit',
			color: 'inherit',

			borderRight: 0,

			width: theme.appDrawer.width,
			transition: 'all 125ms !important',
			'&:hover': { width: theme.appDrawer.width },
		},
		drawer: {
			backgroundColor: theme.palette.background.menu,
			color: theme.palette.common.white,

			[theme.breakpoints.up(theme.breakpoints.values.lg + 1)]: {
				width: theme.appDrawer.width,
				flexShrink: 0,
			},
		},
		listItem: {
			padding: theme.spacing(2),
		},
		menuListItem: {
			padding: theme.spacing(0.5, 2),
		},
		icon: {
			color: 'inherit',
			minWidth: 40,
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	});
});

const PrimaryDrawer: React.FC<PrimaryDrawerProps> = ({ open = false, desktop = false, onClick }) => {
	// const theme = useTheme();
	// const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
	const location = useLocation();
	const classes = useStyles({} as any);

	const { isLoading, isError, isSuccess, data: loans } = useLoans();
	const { hasLoanSelected } = useContext(LoanContext);

	const homePageSelected = !hasLoanSelected && location.pathname === Paths.Dashboard;

	return (
		<Drawer
			open={open}
			anchor="left"
			className={classes.drawer}
			variant={desktop ? 'permanent' : 'persistent'}
			classes={{ paper: classes.drawerPaper }}>
			<Toolbar />
			<FlexBox direction="column" wrap="nowrap" fullHeight>
				<FlexItem xs={12}>
					<List>
						<>
							<ListItem key="Menu" className={classes.menuListItem}>
								<Text variant="h6" color="misc.white">
									MENU
								</Text>
							</ListItem>
							<Divider role="presentation" aria-hidden="true" />
							<SelectableListItem
								button
								key="Home"
								to={Paths.Dashboard}
								onClick={(): void => onClick()}
								component={RouterLink}
								selected={homePageSelected}
								className={classnames(classes.listItem, classes.focus)}
								tabIndex={open ? 2 : -1}>
								<ListItemIcon className={classes.icon}>
									<HomeIcon />
								</ListItemIcon>
								<ListItemText primary="Home" />
							</SelectableListItem>
							<Divider aria-hidden="true" />
							<ListItem key="Accounts" className={classes.listItem}>
								<ListItemIcon className={classes.icon}>
									<DescriptionIcon />
								</ListItemIcon>
								<ListItemText primary="Accounts" />
							</ListItem>
							<Divider aria-hidden="true" />

							{isLoading && (
								<ListItem>
									<Skeleton animation="wave" />
								</ListItem>
							)}

							{isError && (
								<ListItem>
									<Text>No loans available.</Text>
								</ListItem>
							)}

							{isSuccess &&
								loans?.map((loan: Loan) => (
									<PrimaryDrawerLoanListItem
										key={loan.loanNumber}
										isSelectable={open}
										loan={loan}
										onClick={(loanNumber): void => onClick(loanNumber)}
									/>
								))}
						</>
					</List>
				</FlexItem>
				<CopyrightInfo isSidebarOpen={open} />
			</FlexBox>
		</Drawer>
	);
};

export default PrimaryDrawer;
