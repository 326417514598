import React from 'react';
import { Link } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles, Drawer, List, ListItem, ListItemText, Toolbar, IconButton } from '@material-ui/core';

import { Spacing, Text, useSfcKitMessages } from 'sfc-kit';

import Paths from 'constants/Paths';
import { TRoute } from 'constants/Routes';
import GetSvcFinUrlForEnvironment from 'sfc-kit/src/utils/GetSvcFinUrlForEnvironment';

interface SidebarProps {
	open: boolean;
	onClose: () => void;
	mainRoutes: TRoute[];
}

const useStyles = makeStyles(theme =>
	createStyles({
		drawerPaper: {
			backgroundColor: theme.palette.background.menu,
			color: theme.palette.common.white,
		},
	})
);

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, mainRoutes }) => {
	const classes = useStyles({} as any);
	const messages = useSfcKitMessages();
	const svcFinBaseUrl = GetSvcFinUrlForEnvironment();

	return (
		<Drawer open={open} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
			<Toolbar>
				<IconButton edge="start" onClick={onClose} aria-label="close-menu">
					<CloseIcon color="primary" fontSize="large" />
				</IconButton>
			</Toolbar>
			<List>
				{mainRoutes.map(({ link, heading, headingCasing, external }: TRoute, index) => (
					<ListItem
						button
						component={Link}
						to={external ? { pathname: `${svcFinBaseUrl}/${Paths[link]}` } : Paths[link]}
						target={external ? '_blank' : ''}
						onClick={onClose}
						key={`public-sidebar-link-${index}`}>
						<Spacing pr="xLarge" mr="xLarge">
							<ListItemText primary={<Text messages={messages} message={heading} casing={headingCasing} color="misc.white" />} />
						</Spacing>
					</ListItem>
				))}
			</List>
		</Drawer>
	);
};

export default Sidebar;
