import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type UserResult = QueryResult<User, AxiosError<User>>;
export type UserConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<User, AxiosError<User>>;
};

interface UserQuery {
	(request: UserRequestParameters, config?: UserConfig): UserResult;
	key: 'User';
}

const useUserQuery: UserQuery = (request, config = { axiosConfig: {} }) => {
	async function User(): Promise<User> {
		const client = ApiCSGService();

		const path = '/api/Users';

		const response = await client.get<User>(path, {
			customerNumber: request?.customerNumber,
			username: request?.username,
		});

		return response.data;
	}

	return useQuery(['User', ...Object.values(request)], User, config.queryConfig);
};

useUserQuery.key = 'User';

export default useUserQuery;
