export const base64ToBlob = (dataURI: string): Blob => {
	const splitURI = dataURI.split(',');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [data, type] = splitURI[0].split(':');
	const byteString = atob(splitURI[1]);
	const arraybuffer = new ArrayBuffer(byteString.length);
	const uint8 = new Uint8Array(arraybuffer);

	for (let i = 0; i < byteString.length; i++) {
		uint8[i] = byteString.charCodeAt(i);
	}

	return new Blob([arraybuffer], { type });
};

export default base64ToBlob;
