import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetActiveFinanceProgramsResult = QueryResult<Array<ActiveFinanceProgram>, AxiosError<Array<ActiveFinanceProgram>>>;
export type GetActiveFinanceProgramsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<ActiveFinanceProgram>, AxiosError<Array<ActiveFinanceProgram>>>;
};

interface GetActiveFinanceProgramsQuery {
	(request: GetActiveFinanceProgramsRequestParameters, config?: GetActiveFinanceProgramsConfig): GetActiveFinanceProgramsResult;
	key: 'GetActiveFinancePrograms';
}

const useGetActiveFinanceProgramsQuery: GetActiveFinanceProgramsQuery = (request, config = { axiosConfig: {} }) => {
	async function GetActiveFinancePrograms(): Promise<Array<ActiveFinanceProgram>> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetActiveFinancePrograms';

		const response = await client.get<Array<ActiveFinanceProgram>>(path, {
			dealerNumber: request?.dealerNumber,
		});

		return response.data;
	}

	return useQuery(['GetActiveFinancePrograms', ...Object.values(request)], GetActiveFinancePrograms, config.queryConfig);
};

useGetActiveFinanceProgramsQuery.key = 'GetActiveFinancePrograms';

export default useGetActiveFinanceProgramsQuery;
