import React, { useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';

import SfcTextField from 'sfc-kit/src/components/SfcForm/SfcTextField';
import SquareSwitch from 'sfc-kit/src/components/SquareSwitch';
import SfcMaskedField from 'sfc-kit/src/components/SfcForm/SfcMaskedField';
import SfcAlert from 'sfc-kit/src/components/SfcAlert';
import SquareButton from 'sfc-kit/src/components/SquareButton';

import { FormControlLabel, makeStyles, Theme, createStyles, Link, Card, CardContent, Hidden } from '@material-ui/core';

import Paths from 'constants/Paths';
import AlertStrings from 'constants/AlertStrings';
import RegistrationPaths from 'constants/Paths/Registration';
import PasswordRecoveryPaths from 'constants/Paths/PasswordRecovery';
import ForgotUsernamePaths from 'constants/Paths/ForgotUsername';

import LoginContext, { LoginActionTypes } from 'context/Login';
import AuthenticationContext from 'context/Authentication';
import History from 'utils/History';
import useAuthenticate from 'hooks/auth/useAuthenticate';
import FormFieldStatus from 'sfc-kit/src/types/FormFieldStatus';
import { Text } from 'sfc-kit';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		usernameField: ({ usernameStatus }: { usernameStatus: FormFieldStatus; passwordStatus: FormFieldStatus }) => {
			return { '& #username-helper-text': { display: usernameStatus.errorMessage === undefined ? 'none' : 'inline' } };
		},
		passwordField: ({ passwordStatus }) => {
			return { '& #password-helper-text': { display: passwordStatus.errorMessage === undefined ? 'none' : 'inline' } };
		},
		root: {
			borderRadius: 0,
			maxWidth: '400px',
			width: '95%',
			[theme.breakpoints.down('md')]: {
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '100%',
			},
		},
		textField: {
			'& input': {
				padding: theme.spacing(1.5),
			},
			'& div > input::placeholder': {
				opacity: 1,
			},
		},
		forgotTypography: {
			padding: theme.spacing(0, 0.5),
		},
		rememberMe: {
			marginLeft: 0,
		},
		signInButton: {
			fontSize: '1.2rem',
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(2),
			'&:focus-visible': {
				outline: '2px solid blue',
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		linkText: {
			color: theme.palette.action.main,
		},
	})
);

const LoginCard: React.FunctionComponent = () => {
	const authenticate = useAuthenticate();

	const {
		loginState: { username, usernameStatus, password, passwordStatus, lockedOutUntil, loginStatus, rememberMe },
		dispatchForLogin,
	} = useContext(LoginContext);

	const classes = useStyles({ usernameStatus, passwordStatus });

	const {
		authenticationState: { isAuthenticated, username: authUsername },
	} = useContext(AuthenticationContext);

	useEffect(() => {
		if (isAuthenticated && authUsername) {
			History.push(Paths.Dashboard);
		}
	}, [isAuthenticated, authUsername]);

	useEffect(() => {
		if (loginStatus.wasSuccessful !== true) {
			document.getElementById('login-card-alert')?.scrollIntoView();
		}
	});

	const onUsernameChange = (value: string): void => {
		dispatchForLogin({ type: LoginActionTypes.UpdateLoginUsername, payload: value });
	};

	const onPasswordChange = (value: string): void => {
		dispatchForLogin({ type: LoginActionTypes.UpdateLoginPassword, payload: value });
	};

	const onRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		dispatchForLogin({ type: LoginActionTypes.UpdateRememberMe, payload: event.target.checked });
	};

	const onSubmit = async (event: React.FormEvent): Promise<void> => {
		event.preventDefault();

		authenticate(username, password);
	};

	const isLockedOut = lockedOutUntil !== undefined;
	const isValid = usernameStatus.isValid && passwordStatus.isValid;

	return (
		<Card className={classes.root}>
			<CardContent>
				<SfcAlert
					positioned
					variant="standard"
					id="login-card-alert"
					type="error"
					title={loginStatus.errorTitle}
					open={loginStatus.wasSuccessful === false}
					alertDescribedBy={'log-in-error'}>
					<Text variant="body2" id={'log-in-error'}>
						{loginStatus.errorMessage ?? AlertStrings.UnexpectedLoginFailureAlertMessage}
					</Text>
				</SfcAlert>
				<Text gutterBottom variant="h4" component="h2">
					Sign-In
				</Text>
				<form onSubmit={onSubmit}>
					<SfcTextField
						required
						id="username"
						margin="dense"
						value={username}
						placeholder="Username"
						StartIcon={PersonIcon}
						onChange={onUsernameChange}
						className={classnames(classes.textField, classes.usernameField)}
						error={usernameStatus.isValid === false}
						helperText={usernameStatus.errorMessage}
						autoComplete="off"
						inputProps={{ 'aria-describedby': `${usernameStatus.errorMessage}` }}
					/>
					<Text gutterBottom variant="body2" align="right">
						<Link className={classes.linkText} component={RouterLink} to={ForgotUsernamePaths.Entry}>
							Forgot Username?
						</Link>
					</Text>
					<SfcMaskedField
						required
						id="password"
						margin="dense"
						value={password}
						StartIcon={LockIcon}
						placeholder="Password"
						onChange={onPasswordChange}
						className={classnames(classes.textField, classes.passwordField)}
						error={passwordStatus.isValid === false}
						helperText={passwordStatus.errorMessage}
						autoComplete="off"
						inputProps={{ 'aria-describedby': `${passwordStatus.errorMessage}` }}
					/>
					<Text gutterBottom variant="body2" align="right">
						<Link className={classes.linkText} component={RouterLink} to={PasswordRecoveryPaths.Username}>
							Forgot Password?
						</Link>
					</Text>

					<FormControlLabel
						label="Remember My Credentials"
						className={classes.rememberMe}
						control={<SquareSwitch checked={rememberMe} onChange={onRememberMeChange} aria-label="Remember my credentials" />}
						data-testid="remember-me-switch"
					/>

					<SquareButton
						fullWidth
						variant="contained"
						color="primary"
						disabled={isLockedOut || !isValid}
						className={classes.signInButton}
						data-testid="signInButton"
						type="submit">
						Sign In
					</SquareButton>

					<Hidden lgUp>
						<Text align="center">
							Not registered yet?{' '}
							<Link className={classes.linkText} component={RouterLink} to={RegistrationPaths.BorrowerInfo}>
								Register now!
							</Link>
						</Text>
					</Hidden>
				</form>
			</CardContent>
		</Card>
	);
};

export default LoginCard;
