import React, { useContext } from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';

import { StepIconProps } from '@material-ui/core/StepIcon';

import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles, Hidden, Step, Stepper, StepLabel, StepConnector } from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import MakeAPaymentPaths from 'constants/Paths/MakeAPayment';
import MakeAPaymentContext from 'context/MakeAPayment';

const useStepIconStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			alignItems: 'center',
			backgroundColor: '#ccc',
			borderRadius: '50%',
			color: theme.palette.primary.contrastText,
			display: 'flex',
			height: 50,
			justifyContent: 'center',
			width: 50,
			zIndex: 1,

			[theme.breakpoints.down('sm')]: {
				height: 40,
				width: 40,
			},
		},
		active: {
			backgroundColor: theme.palette.primary.main,
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		},
		completed: { backgroundColor: theme.palette.primary.main },
		icon: {
			fontSize: '1.25rem',

			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem',
			},
		},
	})
);

const StepIcon: React.FC<StepIconProps> = ({ active, completed, icon }) => {
	const classes = useStepIconStyles();

	const icons: { [index: string]: React.ReactElement } = {
		1: <DescriptionIcon className={classes.icon} />,
		2: <LocalAtmIcon className={classes.icon} />,
		3: <InsertInvitationIcon className={classes.icon} />,
		4: <DoneIcon className={classes.icon} />,
	};

	return (
		<div
			className={classnames(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}>
			{icons[String(icon)]}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		stepperRoot: {
			[theme.breakpoints.down('sm')]: {
				padding: 0,
			},
		},
		alternativeLabel: {
			top: 22,

			[theme.breakpoints.down('sm')]: {
				top: 20,
			},
		},
		active: {
			color: `${theme.palette.primary.main} !important`,
			'& $line': { backgroundColor: theme.palette.primary.main },
		},
		completed: {
			color: `${theme.palette.primary.main} !important`,
			'& $line': { backgroundColor: theme.palette.primary.main },
		},
		line: {
			height: 3,
			border: 0,
			backgroundColor: '#eaeaf0',
			borderRadius: 1,
		},
	})
);

export function getStepByPath(path: string): number {
	switch (path) {
		case MakeAPaymentPaths.BankAccountEntry:
		case MakeAPaymentPaths.BankAccountSelection:
			return 0;

		case MakeAPaymentPaths.PaymentAmount:
			return 1;

		case MakeAPaymentPaths.PaymentDate:
			return 2;

		case MakeAPaymentPaths.PrincipalReductionDisclaimer:
		case MakeAPaymentPaths.Confirmation:
		case MakeAPaymentPaths.PaymentConfirmed:
		case MakeAPaymentPaths.PaymentConfirmedError:
			return 3;

		default:
			return 0;
	}
}

const MakeAPaymentStepper: React.FC<RouteComponentProps> = ({ location }) => {
	const classes = useStyles({} as any);

	const stepLabelClasses = { active: classes.active, completed: classes.completed };
	const {
		makeAPaymentState: { isPayoffPayment },
	} = useContext(MakeAPaymentContext);

	return (
		<Stepper
			className={classes.stepperRoot}
			alternativeLabel
			activeStep={getStepByPath(location.pathname)}
			connector={
				<StepConnector
					classes={{
						alternativeLabel: classes.alternativeLabel,
						active: classes.active,
						completed: classes.completed,
						line: classes.line,
					}}
				/>
			}>
			<Step>
				<StepLabel StepIconComponent={StepIcon} classes={stepLabelClasses}>
					<Hidden xsDown>Bank Account</Hidden>
				</StepLabel>
			</Step>
			<Step>
				<StepLabel StepIconComponent={StepIcon} classes={stepLabelClasses}>
					<Hidden xsDown>Payment Amount</Hidden>
				</StepLabel>
			</Step>
			{!isPayoffPayment && (
				<Step>
					<StepLabel StepIconComponent={StepIcon} classes={stepLabelClasses}>
						<Hidden xsDown>Payment Date</Hidden>
					</StepLabel>
				</Step>
			)}
			<Step>
				<StepLabel StepIconComponent={StepIcon} classes={stepLabelClasses}>
					<Hidden xsDown>Confirmation</Hidden>
				</StepLabel>
			</Step>
		</Stepper>
	);
};
export default withRouter(MakeAPaymentStepper);
