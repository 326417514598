import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetInitialDocumentsResult = QueryResult<Array<InitialDocument>, AxiosError<Array<InitialDocument>>>;
export type GetInitialDocumentsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<InitialDocument>, AxiosError<Array<InitialDocument>>>;
};

interface GetInitialDocumentsQuery {
	(request: GetInitialDocumentsRequestParameters, config?: GetInitialDocumentsConfig): GetInitialDocumentsResult;
	key: 'GetInitialDocuments';
}

const useGetInitialDocumentsQuery: GetInitialDocumentsQuery = (request, config = { axiosConfig: {} }) => {
	async function GetInitialDocuments(): Promise<Array<InitialDocument>> {
		const client = ApiCSGService();

		const path = '/api/InitialDocuments';

		const response = await client.get<Array<InitialDocument>>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetInitialDocuments', ...Object.values(request)], GetInitialDocuments, config.queryConfig);
};

useGetInitialDocumentsQuery.key = 'GetInitialDocuments';

export default useGetInitialDocumentsQuery;
