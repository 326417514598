import React from 'react';

import { LangText } from 'sfc-kit';
import ArrayUtility from 'sfc-kit/src/utils/ArrayUtility';
import { IRadioListGroupItem } from 'sfc-kit/src/components/RadioListGroup';

import { MakeAPaymentStateType } from 'context/MakeAPayment';

class BankAccountSelectionService {
	public GetInitialAccountNumberValue(
		bankAccountInfo: MakeAPaymentStateType['bankAccountInfo'],
		existingAccountSelected: boolean,
		items: IRadioListGroupItem[]
	): string {
		if (bankAccountInfo !== undefined && existingAccountSelected) {
			return bankAccountInfo.accountNumber;
		}
		return items.length > 1 ? items[0].value : '';
	}

	public GetRadioListGroupItemsFromPriorPayments(payments: ScheduledAchPayment[]): IRadioListGroupItem[] {
		if (payments !== undefined && payments.length > 0) {
			const items: IRadioListGroupItem[] = ArrayUtility.Unique<ScheduledAchPayment>(payments, 'bankAccountNumber')
				.filter(paymentList => paymentList.bankAccountNumber != null)
				.map(payment => {
					return {
						label: (
							<>
								Account ending in{' '}
								<strong>
									{payment.bankAccountNumber.length > 4
										? payment.bankAccountNumber.slice(payment.bankAccountNumber.length - 4)
										: payment.bankAccountNumber}
								</strong>
							</>
						),
						value: payment.bankAccountNumber as LangText,
						labelString: `Account ending in ${
							payment.bankAccountNumber.length > 4
								? payment.bankAccountNumber.slice(payment.bankAccountNumber.length - 4)
								: payment.bankAccountNumber
						}`,
					};
				});
			return items;
		}
		return [] as IRadioListGroupItem[];
	}
}

const bankAccountSelectionService = new BankAccountSelectionService();

export default bankAccountSelectionService;
