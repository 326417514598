import MiscConstants from '../constants/Misc';
import StringCrypto from 'string-crypto';
import { jwtDecode } from 'jwt-decode';
import getObjectPath from 'lodash/get';

const password = process.env.REACT_APP_STORAGE_PASSWORD ?? 'customerportal@12';
const options = {
	salt: process.env.REACT_APP_STORAGE_SALT ?? '2cb9201b3c6a17e5ddeba9b5ae667d76b872e553250761c4a882ba5861301790938fcd',
	iterations: 10,
	digest: 'sha512' as const,
};

const isTest = process.env.IS_TEST === 'true';

const { encryptString: saferEncrypt, decryptString: saferDecrypt } = new StringCrypto(options);

export const GetItem = (key: string): string | null => {
	if (localStorage != null) {
		const storage = localStorage.getItem(key);

		if (storage === null) {
			return null;
		}
		try {
			return saferDecrypt(storage, password);
		} catch (err) {
			if (!isTest) {
				localStorage.removeItem(key);
			}
		}
	}

	return null;
};

export const SetItem = (key: string, value: string, skipEncrypt = false): void => {
	if (localStorage != null) {
		localStorage.setItem(key, skipEncrypt ? value : saferEncrypt(value, password));
	}
};

export const RemoveItem = (key: string): void => {
	if (localStorage != null) {
		localStorage.removeItem(key);
	}
};

export function LoadState<TState = unknown>(key: keyof typeof MiscConstants.LocalStorage): TState | undefined {
	try {
		const lsKey = MiscConstants.LocalStorage[key];
		const LsItem = localStorage.getItem(lsKey);

		if (!LsItem) {
			return undefined;
		}

		let decryptedData = null;

		try {
			decryptedData = saferDecrypt(LsItem, password);
		} catch (err) {
			if (!isTest) {
				localStorage.removeItem(lsKey);
			}
			return undefined;
		}

		const parsedItem = JSON.parse(decryptedData as string);

		return parsedItem;
	} catch (err) {
		// TODO: Log the error
		return undefined; // eslint-disable-line  no-undefined
	}
}

export function SaveState<TState>(key: keyof typeof MiscConstants.LocalStorage, newState: Partial<TState>, skipEncrypt = false): void {
	try {
		localStorage.setItem(
			MiscConstants.LocalStorage[key],
			skipEncrypt ? JSON.stringify(newState) : saferEncrypt(JSON.stringify(newState), password)
		);
	} catch (err) {
		// Ignore errors here because the app can run without them.
		// TODO: Log the error
	}
}

export function isValidJson(input: string | null): boolean {
	try {
		//check if the string exists
		if (input === null) {
			return false;
		}

		if (input) {
			var o = JSON.parse(input);

			//validate the result too
			if (o && o.constructor === Object) {
				return true;
			}
		}
	} catch (e) {
		return false;
	}

	return false;
}

export const AuthenticationSessionGet = (): AuthenticationAuthenticateResponse => {
	const authentication = sessionStorage.getItem('Authentication');
	if (authentication) {
		let auth: AuthenticationAuthenticateResponse = JSON.parse(authentication);

		if (auth.authToken && !auth.otherToken) {
			const decoded = jwtDecode(auth.authToken);
			auth.otherToken = getObjectPath(decoded, 'AuthenticationToken', '');
		}
		
		return auth;
	}

	return { username: '' };
};
