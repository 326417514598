import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Text } from '../../sfc-kit';
import FindDealerPaths from '../../constants/Paths/FindDealer';
import { createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			background: theme.palette.background.default,
			borderRadius: 0,
			padding: theme.spacing(2),
			marginTop: theme.spacing(1),
			marginLeft: 'auto',
			marginRight: 'auto',
			[theme.breakpoints.down('md')]: {
				maxWidth: '400px',
			},
		},
		dealerText: {
			maxWidth: 440,
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%',
			},
		},
		button: {
			boxSizing: 'border-box',
			padding: theme.spacing(1, 3),
			'&:focus-visible': {
				outline: '2px solid blue',
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const LoginFindDealerCard: React.FC = () => {
	const classes = useStyles({} as any);

	return (
		<Paper className={classes.card}>
			<Grid container justifyContent="space-between">
				<Grid item xs={12} lg={6} container justifyContent="flex-start">
					<Text className={classes.dealerText} component="h3">
						<strong>Let us help you find</strong> a contractor to provide flexible financing for your next home improvement project.
					</Text>
				</Grid>

				<Grid item container xs={12} lg={6} justifyContent="flex-end" alignContent="center">
					<Grid item>
						<Link to={FindDealerPaths.Information} tabIndex={-1}>
							<Button
								id="findDealer"
								casing="capitalizeEachWord"
								message="misc.findDealer"
								variant="contained"
								color="findDealer.main"
								className={classes.button}
							/>
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default LoginFindDealerCard;
