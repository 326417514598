import { DateTime } from 'luxon';
import classnames from 'classnames';
import React, { useState, FunctionComponent } from 'react';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Paper, createStyles, makeStyles, Hidden, IconButton, Menu, MenuItem, Fade, Theme, Grid, Chip } from '@material-ui/core';

import { NumberUtility, Text } from 'sfc-kit';
import SquareButton from 'sfc-kit/src/components/SquareButton';

import { GetBorderLeftStyles } from 'utils/GetBorderLeftStyles';
import { StopPaymentDialog } from 'components/Dialogs';

export interface IScheduledPaymentListItemProps {
	payment: ScheduledAchPayment;
	onEdit: () => void | Promise<void>;
	onStopConfirm: (payment: ScheduledAchPayment) => void | Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles<string, Record<string, unknown>>({
		paper: {
			backgroundColor: theme.palette.grey[100],
			color: 'black',
			padding: theme.spacing(1),
			marginBottom: theme.spacing(2),
		},
		accountNumber: {
			alignSelf: 'flex-start',
			paddingLeft: theme.spacing(0.5),

			[theme.breakpoints.only('lg')]: {
				...theme.typography.body2,
			},

			[theme.breakpoints.down('md')]: {
				...theme.typography.caption,
			},

			...GetBorderLeftStyles(theme),
		},
		paymentFrequency: {
			...theme.typography.caption,

			backgroundColor: theme.palette.info.dark,
			borderRadius: theme.shape.borderRadius,
			color: 'white',
			minWidth: '100%',
		},
		gridDivider: {
			borderLeftColor: theme.palette.divider,
			borderLeftStyle: 'solid',
			borderLeftWidth: 3,

			padding: theme.spacing(2),
		},
		desktopPaymentFrequencyGridItem: {
			width: '60%',
		},
		button: {
			padding: theme.spacing(0.75, 0),
			marginLeft: theme.spacing(1),
			[theme.breakpoints.down('lg')]: {
				fontSize: theme.typography.caption.fontSize,
				padding: theme.spacing(0.5, 0),
			},
		},
		stopButton: {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.danger.main,

			'&:hover': {
				backgroundColor: theme.palette.danger.dark,
			},
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const ScheduledPaymentListItem: FunctionComponent<IScheduledPaymentListItemProps> = ({ payment, onEdit, onStopConfirm }) => {
	const classes = useStyles({} as any);

	const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
	const [openStopPaymentDialog, setOpenStopPaymentDialog] = useState<boolean>(false);

	const bankAccountNumber = NumberUtility.maskPartialString(payment.bankAccountNumber ?? '');
	const paymentDate = DateTime.fromISO(payment.paymentDate ?? '')
		.toUTC()
		.toFormat('MM/dd/yyyy');

	return (
		<Paper className={classes.paper}>
			<Hidden smUp>
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs>
						<Text className={classes.accountNumber}>{bankAccountNumber}</Text>
						<Text variant="caption">{paymentDate}</Text>
					</Grid>
					<Grid item xs>
						<Chip
							size="small"
							className={classes.paymentFrequency}
							label={payment.paymentType === 'One Time' ? 'Once' : payment.paymentPeriod}
							aria-label={`Payment Type ${payment.paymentType === 'One Time' ? 'Once' : payment.paymentPeriod}`}
						/>
					</Grid>
					<Grid item xs>
						<Text color="primary" align="center">
							${payment.paymentAmount}
						</Text>
					</Grid>
					<Grid item>
						<IconButton size="small" onClick={(event): void => setMenuAnchorEl(event.currentTarget)}>
							<MoreVertIcon />
						</IconButton>
						<Menu
							keepMounted
							anchorEl={menuAnchorEl}
							TransitionComponent={Fade}
							open={menuAnchorEl !== undefined}
							onClose={(): void => setMenuAnchorEl(undefined)}>
							<MenuItem tabIndex={1} className={classes.focus} onClick={(): void | Promise<void> => onEdit()}>
								Edit
							</MenuItem>
							<MenuItem tabIndex={1} className={classes.focus} onClick={(): void => setOpenStopPaymentDialog(true)}>
								Cancel
							</MenuItem>
						</Menu>
					</Grid>
				</Grid>
			</Hidden>
			<StopPaymentDialog
				payment={payment}
				isOpen={openStopPaymentDialog}
				setShowDialog={setOpenStopPaymentDialog}
				stopPayment={onStopConfirm}
			/>
			<Hidden xsDown>
				<Grid container>
					<Grid item xs={4} container direction="column" justifyContent="space-around" alignItems="center">
						<Text className={classes.accountNumber}>{bankAccountNumber}</Text>
						<Text variant="body2">{paymentDate}</Text>
						<Grid item className={classes.desktopPaymentFrequencyGridItem}>
							<Chip
								size="small"
								className={classes.paymentFrequency}
								label={payment.paymentType === 'One Time' ? 'Once' : payment.paymentPeriod}
							/>
						</Grid>
					</Grid>
					<Grid item xs={8} container direction="column" justifyContent="space-around" className={classes.gridDivider}>
						<Grid item>
							<Text variant="h6" color="primary">
								${payment.paymentAmount}
							</Text>
						</Grid>
						<Grid item container justifyContent={'center'} spacing={1}>
							<Grid item xs={6}>
								<SquareButton
									fullWidth
									aria-label="Edit Payment"
									color="primary"
									tabIndex={1}
									variant="contained"
									className={classnames(classes.focus, classes.button)}
									onClick={(): void | Promise<void> => onEdit()}>
									Edit
								</SquareButton>
							</Grid>
							<Grid item xs={6}>
								<SquareButton
									fullWidth
									tabIndex={1}
									aria-label="Cancel Payment"
									variant="contained"
									className={classnames(classes.focus, classes.button, classes.stopButton)}
									onClick={(): void => setOpenStopPaymentDialog(true)}>
									Cancel
								</SquareButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Hidden>
		</Paper>
	);
};

export default ScheduledPaymentListItem;
