import React, { ReactNode } from 'react';
import { GetBorderLeftStyles } from 'utils/GetBorderLeftStyles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Text } from 'sfc-kit';

import MediumBold from 'sfc-kit/src/components/MediumBold';

interface SummaryItemProps {
	title: string | ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			borderRadius: 0,
			margin: theme.spacing(1, 0),
			paddingLeft: theme.spacing(2),

			...GetBorderLeftStyles(theme),

			[theme.breakpoints.down('md')]: {
				margin: 0,
			},
		},
	})
);

const SummaryItem: React.FC<SummaryItemProps> = ({ title, children }) => {
	const classes = useStyles({} as any);

	return (
		<div className={classes.paper}>
			{typeof title === 'string' ? (
				<Text variant="caption" noWrap>
					{title}
				</Text>
			) : (
				title
			)}

			<Text variant="subtitle1">
				<MediumBold>{children}</MediumBold>
			</Text>
		</div>
	);
};

export default SummaryItem;
