import React from 'react';
import { Modal, Text } from 'sfc-kit';
import { useMessages } from 'hooks';
import { makeStyles, createStyles } from '@material-ui/core';

interface PaperlessNotVerifiedEmailDialogProps {
	isOpen: boolean;
	onCloseClick: () => void;
	onContinueClick: () => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		text: {
			textAlign: 'center',
		},
	})
);

const PaperlessNotVerifiedEmailDialog: React.FC<PaperlessNotVerifiedEmailDialogProps> = ({ isOpen, onCloseClick, onContinueClick }) => {
	const classes = useStyles({} as any);
	const messages = useMessages();

	return (
		<Modal
			open={isOpen}
			messages={messages}
			variant="InformationAlternative"
			id="Not-Verified-Email-Dialog"
			title="paperless.emailVerificationRequired"
			buttonCasing="capitalizeEachWord"
			confirmDenyProps={{
				onConfirm: onContinueClick,
				onDeny: onCloseClick,
				confirmText: 'buttons.continue',
				denyText: 'buttons.cancel',
			}}>
			<Text messages={messages} message="paperless.requiresVerifiedEmail" className={classes.text} />
		</Modal>
	);
};

export default PaperlessNotVerifiedEmailDialog;
