import classnames from 'classnames';
import React, { useState, useEffect, useContext } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';

import useField from 'sfc-kit/src/hooks/useField';
import Validator from 'sfc-kit/src/types/Validator';
import SfcAlert from 'sfc-kit/src/components/SfcAlert';
import MediumBold from 'sfc-kit/src/components/MediumBold';
import SquareButton from 'sfc-kit/src/components/SquareButton';
import SfcTextField from 'sfc-kit/src/components/SfcForm/SfcTextField';
import SfcMaskedField from 'sfc-kit/src/components/SfcForm/SfcMaskedField';

import CommonServicesGatewayAPI from 'utils/CommonServicesGatewayAPI';

import { useMediaQuery } from '@material-ui/core';
import LocalStorageKeys from 'constants/LocalStorageKeys';
import * as BrowserStorage from 'sfc-kit/src/utils/BrowserStorage';
import AuthenticationContext from 'context/Authentication';

import { usernameValidator, passwordValidator } from 'validators/User';
import { useSfcKitMessages, LangText, FlexItem, FlexBox, Text, Spacing } from 'sfc-kit';
import useLoans from 'hooks/useLoans';

export interface IChangeUsernameFormProps {
	onValidSubmit: (username: string) => void | Promise<void>;
	onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: { height: '100%' },
		button: {
			padding: theme.spacing(1, 8),
		},
		submitButton: {
			[theme.breakpoints.down('md')]: { marginBottom: theme.spacing(1) },
		},
		btnCancelChanges: {
			color: '#007D46',
			backgroundColor: '#eeeeee',
			textTransform: 'none',
			whiteSpace: 'nowrap',

			'&:hover': {
				color: '#007D46',
			},
		},
		alert: {
			margin: theme.spacing(2, 0),
			paddingRight: theme.spacing(1),
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const Password_Validator = Validator.FromYupSchema(passwordValidator);
const User_Validator = Validator.FromYupSchema(usernameValidator);

const ChangeUsernameForm: React.FC<IChangeUsernameFormProps> = ({ onValidSubmit, onCancel }) => {
	const classes = useStyles({} as any);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const { data: loans } = useLoans();

	const {
		authenticationState: { username: existingUsername },
	} = useContext(AuthenticationContext);
	const messages = useSfcKitMessages();

	const [alertTitle, setAlertTitle] = useState<LangText>();
	const [alertMessage, setAlertMessage] = useState<LangText>();

	const [password, setPassword, passwordValidation] = useField('', Password_Validator);
	const [newUsername, setNewUsername, newUsernameValidation] = useField('', User_Validator);

	useEffect(() => {
		if (newUsernameValidation.Invalid && newUsername.length) {
			setAlertTitle(newUsernameValidation.ReasonForInvalidity as LangText);
			setAlertMessage('user.username.invalidUsername');
		} else {
			setAlertMessage(undefined);
			setAlertTitle(undefined);
		}
	}, [newUsernameValidation.ReasonForInvalidity]); // eslint-disable-line

	useEffect(() => {
		if (alertMessage === 'user.password.currentPasswordIncorrect') {
			setAlertMessage(undefined);
			setAlertTitle(undefined);
		}
	}, [password]); // eslint-disable-line

	const saveChanges = async (): Promise<void> => {
		setAlertMessage(undefined);
		try {
			const emailExists = await CommonServicesGatewayAPI.IsUserTaken({
				username: newUsername.toLowerCase(),
				existingUsername,
				loanNumber: loans != null && loans.length > 0 ? loans[0].loanNumber : '',
			});

			if (!emailExists.data.usernameIsTaken) {
				const { data: response } = await CommonServicesGatewayAPI.AuthenticationAuthenticate({
					body: { username: existingUsername, password },
				});

				if (response.bearerToken !== undefined) {
					await onValidSubmit(newUsername.toLowerCase());
					BrowserStorage.RemoveItem(LocalStorageKeys.LastVerifyEmailPromptDate);
				} else {
					setAlertTitle('user.password.incorrectPassword');
					setAlertMessage('user.password.currentPasswordIncorrect');
				}
			} else {
				setPassword('');
				setAlertTitle('misc.emptyString');
				setAlertMessage('user.username.usernameTaken');
			}
		} catch (error) {
			setPassword('');
			setAlertTitle('user.password.incorrectPassword');
			setAlertMessage('user.password.currentPasswordIncorrect');
		}
	};

	const displayAlert = alertMessage && alertTitle;
	const formIsValid = !newUsernameValidation.Invalid && existingUsername !== newUsername && password.length;

	return (
		<FlexBox justifyContent="space-between" className={classes.root} direction="column" wrap="nowrap">
			<FlexItem>
				<Text gutterBottom variant="body2">
					Enter your current password to change your username. The new username must be a valid email address.
				</Text>
				<Text gutterBottom variant="body2">
					You will be required to verify your email address to complete the process.
				</Text>
				<Text gutterBottom variant="body2" inline>
					Click <MediumBold inline>Submit</MediumBold> when you are finished.
				</Text>
			</FlexItem>

			<form onSubmit={saveChanges} className={classes.form}>
				<FlexItem xs>
					<FlexBox direction="column">
						{displayAlert && (
							<FlexItem xs={12} lg={6} className={classes.alert}>
								<SfcAlert
									positioned
									open
									title={alertTitle}
									messages={messages}
									aria-hidden="false"
									aria-live="polite"
									type="error"
									variant="standard">
									{messages[alertMessage as LangText]}
								</SfcAlert>
							</FlexItem>
						)}
						<FlexBox direction="row">
							<FlexItem xs={12} lg={6}>
								<Spacing pr={mdDown ? undefined : 'small'}>
									<SfcTextField
										required
										id="username"
										label="Username"
										placeholder="Username"
										type="text"
										autoComplete="username"
										value={newUsername}
										onChange={setNewUsername}
										error={newUsernameValidation.Invalid}
										helperText={
											newUsernameValidation.ReasonForInvalidity === 'user.username.usernameIsRequired' &&
											messages[newUsernameValidation.ReasonForInvalidity as LangText]
										}
										onBlur={(): boolean => newUsernameValidation.ValidateSync()}
										InputProps={{ name: 'username' }}
										inputProps={{ tabIndex: 1, 'aria-label': 'Enter New Username' }}
									/>
								</Spacing>
							</FlexItem>

							<FlexItem xs={12} lg={6}>
								<Spacing pl={mdDown ? undefined : 'small'}>
									<SfcMaskedField
										required
										id="current-password"
										placeholder="Password"
										label="Current Password"
										autoComplete="current-password"
										value={password}
										error={passwordValidation.Invalid}
										helperText={messages[passwordValidation.ReasonForInvalidity as LangText]}
										onBlur={(): boolean => passwordValidation.ValidateSync()}
										onChange={setPassword}
										answerTabIndex={1}
										inputProps={{ 'aria-label': 'Enter Current Password', tabIndex: 1 }}
									/>
								</Spacing>
							</FlexItem>
						</FlexBox>
					</FlexBox>
				</FlexItem>

				<FlexItem>
					<FlexBox>
						<FlexItem xs={12} md={6} lg={3}>
							<Spacing pr={mdDown ? undefined : 'small'}>
								<SquareButton
									fullWidth
									color="primary"
									tabIndex={!formIsValid ? -1 : 1}
									variant="contained"
									onClick={saveChanges}
									className={classnames(classes.button, classes.focus, classes.submitButton)}
									disabled={!formIsValid}>
									Submit
								</SquareButton>
							</Spacing>
						</FlexItem>
						<FlexItem xs={12} md={6} lg={3}>
							<Spacing pl={mdDown ? undefined : 'small'} pr={mdDown ? undefined : 'small'}>
								<SquareButton
									variant="contained"
									onClick={onCancel}
									tabIndex={1}
									fullWidth
									className={classnames(classes.button, classes.focus, classes.btnCancelChanges)}>
									Cancel Changes
								</SquareButton>
							</Spacing>
						</FlexItem>
					</FlexBox>
				</FlexItem>
			</form>
		</FlexBox>
	);
};

export default ChangeUsernameForm;
