import React from 'react';

import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import { Text, FlexBox } from 'sfc-kit';
import { Link } from 'react-router-dom';
import Paths from 'constants/Paths';
import { useMessages } from 'hooks';
import copyright from 'sfc-kit/src/constants/Copyright';

interface CopyRightInfoProps {
	open?: boolean;
	laptopFooter?: boolean;
	isSidebarOpen?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			paddingTop: theme.spacing(0.4),
			'&>:not(:first-child)': {
				paddingLeft: theme.spacing(1),
				borderLeft: `1px solid ${theme.palette.grey[500]}`,
			},
			'&>:last-child': {
				paddingLeft: theme.spacing(5),
				borderLeft: `none`,
			},
			alignItems: 'baseline',
			'& a': {
				marginTop: theme.spacing(1),
			},
		},
		textLinks: {
			lineHeight: '1',
			paddingRight: theme.spacing(1),
			fontWeight: 'bold',
		},
		copyrightWrapper: {
			whiteSpace: 'pre',
		},
	})
);

const CopyrightInfo: React.FC<CopyRightInfoProps> = ({ open, laptopFooter, isSidebarOpen }) => {
	const classes = useStyles({} as any);
	const messages = useMessages();

	return (
		<Box padding={2} display="flex" justifyContent="flex-end" alignItems={laptopFooter ? 'center' : undefined} flexDirection="column">
			<Text variant="caption" color="inherit" messages={messages} message="sfc.companyNameLLC" />
			{!laptopFooter && <Text variant="caption" color="inherit" messages={messages} message="sfc.allRightsReserved" />}
			<FlexBox direction={open ? 'column' : 'row'} justifyContent={laptopFooter ? 'center' : undefined} className={classes.copyrightWrapper}>
				{laptopFooter && (
					<>
						<Text variant="caption" color="inherit" messages={messages} message="sfc.allRightsReserved" inline />
						<Text variant="caption" inline>
							{' '}
						</Text>
					</>
				)}
				<Text variant="caption" color="inherit" inline={laptopFooter}>
					{copyright}
				</Text>
				{!open && (
					<Text inline variant="caption" aria-hidden="true" color="inherit">
						{' '}
						|{' '}
					</Text>
				)}
				<Text variant="caption" color="inherit" messages={messages} message="sfc.nmls" inline={laptopFooter} />
			</FlexBox>
			<Text variant="caption" color="inherit" messages={messages} message="misc.customerPortalVersion" />
			{open ? null : (
				<FlexBox direction="row" className={classes.wrapper} justifyContent={laptopFooter ? 'center' : undefined}>
					<Link to={Paths.PrivateFAQs} tabIndex={isSidebarOpen ? 500 : -1}>
						<Text
							messages={messages}
							message="misc.faqs"
							variant="caption"
							color={laptopFooter ? 'action.main' : 'misc.white'}
							className={classes.textLinks}
						/>
					</Link>
					<Link to={Paths.PrivatePrivacyPolicy} tabIndex={isSidebarOpen ? 500 : -1}>
						<Text
							messages={messages}
							casing="capitalizeEachWord"
							message="misc.privacyPolicy"
							variant="caption"
							color={laptopFooter ? 'action.main' : 'misc.white'}
							className={classes.textLinks}
						/>
					</Link>
					<Link to={Paths.PrivateAccessibility} tabIndex={isSidebarOpen ? 500 : -1}>
						<Text
							messages={messages}
							casing="capitalizeEachWord"
							message="misc.accessibility"
							variant="caption"
							color={laptopFooter ? 'action.main' : 'misc.white'}
							className={classes.textLinks}
						/>
					</Link>
					<Link to={Paths.PrivateInstructionalDocuments} tabIndex={isSidebarOpen ? 500 : -1}>
						<Text
							messages={messages}
							casing="capitalizeEachWord"
							message="misc.instructionalDocuments"
							variant="caption"
							color={laptopFooter ? 'action.main' : 'misc.white'}
							className={classes.textLinks}
						/>
					</Link>
				</FlexBox>
			)}
		</Box>
	);
};

export default CopyrightInfo;
