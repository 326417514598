import React, { ReactElement } from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import { Messages, SfcKitMessages, MessagesProps } from '../../../';
import SfcAlert from '../../SfcAlert/SfcAlert';

interface ErrorMessage {
	message: {
		message: string;
		title: string;
	};
}
export interface FormAlertErrors {
	[key: string]: Pick<FieldError, 'type'> & ErrorMessage;
}

export interface UseFormAlertProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	errors?: UseFormMethods['errors'];
	id?: string;
}

export default function FormAlert<TMessages extends Messages = SfcKitMessages>({
	errors,
	messages,
	id,
}: UseFormAlertProps<TMessages>): ReactElement | null {
	const formErrors = errors as FormAlertErrors;

	if (!errors) {
		return null;
	}

	const requiredErrors = Object.values(formErrors).filter((error): object | null => {
		const { type } = error;

		if (type !== 'required' && type !== 'typeError') {
			return error;
		}

		return null;
	});

	if (!requiredErrors.length) {
		return null;
	}

	return (
		<SfcAlert
			positioned
			messages={messages}
			message={requiredErrors[0].message.message}
			title={requiredErrors[0].message.title}
			type="error"
			variant="standard"
			open
			id={id}
		/>
	);
}
