import React from 'react';
import classnames from 'classnames';

import SvgIcon from '@material-ui/core/SvgIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { createStyles, makeStyles } from '@material-ui/core';

export interface ISfcTextFieldProps extends Omit<TextFieldProps, 'fullWidth' | 'variant' | 'onChange'> {
	StartIcon?: typeof SvgIcon;
	onChange?: (value: string) => void;
	id?: string;
	answerTabIndex?: number;
}

const useStyles = makeStyles(() =>
	createStyles({
		textField: {
			'& fieldset': {
				borderRadius: 0,
			},
			'& .MuiInputBase-root': {
				backgroundColor: '#FAFAFA',
			},
			'& label': {
				color: '#707070',
				// this is the placeholder font color
			},
		},
	})
);

const SfcTextField: React.FC<ISfcTextFieldProps> = ({ onChange, StartIcon, id, InputProps, className, ...textFieldProps }) => {
	const classes = useStyles({} as any);

	return (
		<TextField
			fullWidth
			variant="outlined"
			margin="none"
			className={classnames(classes.textField, className)}
			onChange={(event): void => onChange && onChange(event.target.value)}
			FormHelperTextProps={{ id: `${id}-helper-text`, 'aria-hidden': 'false', 'aria-live': 'polite' }}
			InputProps={{
				...InputProps,
				startAdornment: StartIcon && (
					<InputAdornment position="start">
						<StartIcon color="primary" />
					</InputAdornment>
				),
				'aria-describedby': `${id}-helper-text`,
			}}
			{...textFieldProps}
		/>
	);
};

SfcTextField.defaultProps = {
	helperText: ' ',
	margin: 'normal',
};

export default SfcTextField;
