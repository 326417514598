import { AxiosResponse } from 'axios';

import { GlobalDispatchType } from 'context/Global';
import CommonServicesGatewayAPI from './CommonServicesGatewayAPI';
import { AwaitBlockingPromise } from './AwaitBlockingPromise';
import { DateTime } from 'luxon';
import { AuthenticationSessionGet } from 'sfc-kit';

export async function GetUsername(
	params: BorrowerUsernameRequestParameters,
	dispatchForGlobal: GlobalDispatchType
): Promise<GetBorrowerUsernameResponse> {
	const response = await AwaitBlockingPromise(CommonServicesGatewayAPI.BorrowerUsername(params), dispatchForGlobal);

	return response.data;
}

export async function UnlockAccount(username: string, dispatchForGlobal: GlobalDispatchType): Promise<boolean> {
	const { data } = await AwaitBlockingPromise(CommonServicesGatewayAPI.UnlockAccount({ body: { username } }), dispatchForGlobal);

	return data;
}

export async function IsEmailVerified(params: IsEmailVerifiedRequestParameters): Promise<AxiosResponse<boolean>> {
	return (await CommonServicesGatewayAPI.IsEmailVerified(params)) as AxiosResponse<boolean>;
}

export async function getSecurityQuestionsByToken(
	command: GetSecurityQuestionsByTokenRequestParameters,
	dispatchForGlobal: GlobalDispatchType
): Promise<AxiosResponse<SecurityQuestionsDto>> {
	return await AwaitBlockingPromise(
		CommonServicesGatewayAPI.GetSecurityQuestionsByToken(command) as Promise<AxiosResponse<SecurityQuestionsDto>>,
		dispatchForGlobal
	);
}

export async function validateSecurityQuestionsAnswersWithToken(
	command: ValidateAnswersWithTokenRequestParameters,
	dispatchForGlobal: GlobalDispatchType
): Promise<AxiosResponse<string>> {
	return await AwaitBlockingPromise(
		CommonServicesGatewayAPI.ValidateAnswersWithToken(command) as Promise<AxiosResponse<string>>,
		dispatchForGlobal
	);
}

export const getTokenNls = () => {
	const { otherToken } = AuthenticationSessionGet();

	return otherToken ?? '';
};

export const getTokenExpirationDate = (): string => {
	return DateTime.local()
		.plus({ minutes: 12 })
		.toISO();
};
