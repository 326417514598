import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

export interface ISquareButtonProps extends ButtonProps {
	normalCase?: boolean;
	disabled?: boolean;
}

const SquareButton: React.FC<ISquareButtonProps> = ({ disabled = false, normalCase = true, ...props }) => (
	<Button
		style={{
			borderRadius: 0,
			textTransform: normalCase ? 'none' : 'uppercase',
			backgroundColor: `${disabled ? '#efefef' : ''}`,
			color: `${disabled ? '#6a6a6a' : ''}`,
		}}
		disabled={disabled}
		{...props}
	/>
);

export default SquareButton;
