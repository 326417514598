import { ResourceStrings } from './ResourceStrings';

const AlertStrings = {
	UnexpectedLoginFailureAlertMessage: 'Something when wrong when trying to log you in.',

	ChangeUsernameInvalidCredentialsTitle: 'The current password you have entered is not correct.',
	ChangeUsernameInvalidCredentialsMessage: 'Please enter the correct current password for this account and try again.',

	StatementUnavailableAlertTitle: 'Statement Unavailable',
	StatementUnavailableAlertMessage:
		'The statement you have selected is current unavailable. Please select a different statement from the list to view. ' +
		`If this problem persists please contact customer support at ${ResourceStrings.FormattedCustomerSupportPhone} option ${ResourceStrings.CustomerSupportOption}`,
	UnableValidateInfoAltertMessage: 'We are unable to validate the provided information, please try again. If the error persists, please contact the Consumer Credit Center: (866) 254-0497. Thank you.',
};

export default AlertStrings;
