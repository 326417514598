import React, { ReactNode } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Card, CardHeader, CardContent } from '@material-ui/core';
import { LangText } from 'sfc-kit';
import { useMessages } from 'hooks';
import { CustomerPortalMessages } from 'types';

interface DashboardCardProps {
	title: LangText<CustomerPortalMessages>;
	action?: ReactNode;
}

const useStyles = makeStyles(theme =>
	createStyles({
		dashboardCard: {
			height: '100%',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				padding: '0px !important',
			},
		},
		dashboardCardHeader: {
			paddingBottom: 0,
		},
	})
);

const DashboardCard: React.FC<DashboardCardProps> = ({ title, children, action }) => {
	const classes = useStyles({} as any);
	const customerMessages = useMessages();

	return (
		<Card className={classes.dashboardCard} style={{ padding: '0px' }}>
			<CardHeader
				component="h2"
				className={classes.dashboardCardHeader}
				title={customerMessages[title as keyof typeof customerMessages]}
				titleTypographyProps={{ color: 'primary', variant: 'button' }}
				action={action}
			/>
			<CardContent>{children}</CardContent>
		</Card>
	);
};

export default DashboardCard;
