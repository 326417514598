import { FunctionComponent } from 'react';
import { StatusVariant, Color } from '../types';

import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckOutlinedCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import PanIcon from '@material-ui/icons/PanTool';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export interface StatusValues {
	color: Color;
	textColor: Color;
	icons: {
		standard: FunctionComponent;
		outlined: FunctionComponent;
	};
}

export default function ConvertStatusVariantToValues(variant: StatusVariant): StatusValues {
	switch (variant) {
		case 'Success':
			return {
				color: 'primary.main',
				textColor: 'misc.white',
				icons: {
					standard: CheckCircleIcon,
					outlined: CheckOutlinedCircleIcon,
				},
			};

		case 'Warning':
			return {
				color: 'warning',
				textColor: 'text.primary',
				icons: {
					standard: ErrorIcon,
					outlined: ErrorOutlinedIcon,
				},
			};

		case 'Danger':
			return {
				color: 'error',
				textColor: 'misc.white',
				icons: {
					standard: CancelIcon,
					outlined: CancelOutlinedIcon,
				},
			};

		case 'Information':
			return {
				color: 'info.darkAlt',
				textColor: 'misc.white',
				icons: {
					standard: InfoIcon,
					outlined: InfoOutlinedIcon,
				},
			};

		case 'InformationAlternative':
			return {
				color: 'infoAlt',
				textColor: 'misc.white',
				icons: {
					standard: InfoIcon,
					outlined: InfoOutlinedIcon,
				},
			};
		case 'InformationWait':
			return {
				color: 'info',
				textColor: 'misc.white',
				icons: {
					standard: PanIcon,
					outlined: PanIcon,
				},
			};

		case 'ClipboardCopy':
			return {
				color: 'success',
				textColor: 'misc.white',
				icons: {
					standard: FileCopyOutlinedIcon,
					outlined: FileCopyOutlinedIcon,
				},
			};

		default:
			throw new Error(`Invalid Status Variant Specified. (Value: ${variant})`);
	}
}
