import React, { Dispatch, ReactElement, ReactInstance, SetStateAction, useState } from 'react';
import { type FontWeightProperty } from 'csstype';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { createStyles, makeStyles, Theme, Checkbox, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/GetApp';

import { FlexBox, LangText, Modal, ModalProps, Text } from 'sfc-kit';

import { useMessages } from 'hooks';
import ReactToPrint from 'react-to-print';
import { CustomerPortalMessages } from 'types';

export interface TermsOfServiceCheckboxProps extends Omit<ModalProps, 'messages' | 'title' | 'id' | 'children' | 'onClose' | 'open' | 'size'> {
	required: boolean;
	checked: boolean;
	modalSize?: ModalProps['size'];
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	modalOpen: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
	currentPrintRef?: React.RefObject<HTMLDivElement>;
	openOnChecked?: boolean;
	checkboxText?: LangText<CustomerPortalMessages>;
	children: React.ReactNode;
}

interface ModalActionProps {
	currentPrintRef: React.RefObject<HTMLDivElement>;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		orangeText: { cursor: 'pointer' },
		gridSpacing: {
			marginLeft: theme.spacing(6),
			[theme.breakpoints.only('xs')]: {
				marginLeft: 0,
			},
		},
		textIndent: {
			marginLeft: theme.spacing(5.5),
			color: theme.palette.accent.main,
			fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
			cursor: 'pointer',
		},
		resizeFont: {
			[theme.breakpoints.only('xs')]: {
				fontSize: '0.9rem',
			},
		},
		noPadding: {
			padding: theme.spacing(0, 0, 0, 0),
			display: 'inline',
			[theme.breakpoints.only('xs')]: {
				padding: theme.spacing(1),
			},
		},
		focus: {
			color: 'blue',
		},
		actionButtons: { color: theme.palette.action.main },
		padding: {
			padding: theme.spacing(0, 0, 0, 1),
		},
		modal: { height: '100vh' },
	});
});

const ModalActions: React.FC<ModalActionProps> = ({ currentPrintRef }) => {
	const classes = useStyles({} as any);

	return (
		<>
			<IconButton>
				<a
					tabIndex={1}
					aria-label="select to download the Terms of Service"
					href="/paperlessTOS.pdf"
					download="PaperlessTOS.pdf"
					className={classes.actionButtons}>
					<DownloadIcon />
				</a>
			</IconButton>

			<ReactToPrint
				pageStyle="@page { size: auto; }"
				trigger={(): ReactElement => (
					<IconButton>
						<div className={classes.actionButtons}>
							<PrintIcon tabIndex={1} aria-label="select to print the Terms of Service" />
						</div>
					</IconButton>
				)}
				content={(): ReactInstance => currentPrintRef.current!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
			/>
		</>
	);
};

const TermsOfServiceCheckbox: React.FC<TermsOfServiceCheckboxProps> = ({
	required,
	checked,
	onChange,
	children,
	modalOpen,
	currentPrintRef,
	setModalOpen,
	checkboxText,
	modalSize = 'large',
	openOnChecked = false,
}) => {
	const classes = useStyles({} as any);
	const messages = useMessages();
	const [focused, setFocused] = useState<boolean>(false);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (openOnChecked) {
			setModalOpen(true);
		}

		onChange && onChange(event);
	};

	const handleKeyDownModal = (key: React.KeyboardEvent<HTMLSpanElement>): void => {
		if (key.key === 'Enter' && modalOpen === false) {
			setModalOpen(true);
			window.scrollTo(0, 0);
		}
	};

	return (
		<FlexBox direction="row" wrap="nowrap" alignItems="center">
			<Checkbox
				id="terms-of-service"
				icon={
					<CheckBoxOutlineBlankIcon
						aria-label="select to agree to the Terms of Service"
						color="primary"
						style={{ fontSize: 30 }}
						className={focused ? classes.focus : ''}
					/>
				}
				checkedIcon={<CheckBoxIcon color="primary" style={{ fontSize: 30 }} className={focused ? classes.focus : ''} />}
				data-testid="termsCheckbox"
				required={required}
				inputProps={{ tabIndex: 1, 'aria-label': 'select to agree to the Terms of Service' }}
				checked={checked}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				color="primary"
				disableRipple
				onChange={handleOnChange}
				className={classes.noPadding}
			/>
			<Text variant="caption" className={classes.padding} messages={messages} message={checkboxText ?? 'paperless.readAndAgree'} inline />
			<Text variant="caption" inline>
				{'\u00A0'}
			</Text>
			<Text
				onClick={(): void => setModalOpen(true)}
				onKeyDown={(event): void => handleKeyDownModal(event)}
				tabIndex={1}
				color="action.main"
				variant="caption"
				className={classes.orangeText}
				messages={messages}
				message="paperless.termsOfService"
				inline
			/>
			<Modal
				id="terms-of-service-dialog"
				size={modalSize}
				aria-live="polite"
				aria-hidden="false"
				title="paperless.termsOfService"
				variant="Information"
				messages={messages}
				modalActions={
					currentPrintRef ? <ModalActions aria-label="select to print the Terms of Service" currentPrintRef={currentPrintRef} /> : undefined
				}
				open={modalOpen}
				zIndex={1300}
				onClose={(): void => setModalOpen(false)}>
				{children}
			</Modal>
		</FlexBox>
	);
};

export default TermsOfServiceCheckbox;
