import React, { FunctionComponent } from 'react';

import useMessages from 'hooks/useMessages';
import { Dialog, Text, Spacing, FlexBox, FlexItem, Button, Alert } from 'sfc-kit';

export interface PrincipalReductionDisclaimerDialogProps {
	isOpen: boolean;

	onAgree: () => void;
	onDisagree: () => void;
}

const PrincipalReductionDisclaimerDialog: FunctionComponent<PrincipalReductionDisclaimerDialogProps> = ({ isOpen, onAgree, onDisagree }) => {
	const messages = useMessages();

	return (
		<Dialog open={isOpen} id="principal-reduction-disclaimer-dialog" onClose={onDisagree}>
			<Spacing p="medium">
				<Text weight="medium">Please confirm that you agree with the payment disclaimer:</Text>
				<Spacing mt="medium" mb="xLarge">
					<Alert variant="InformationAlternative" messages={messages} body="alerts.principalReductionDisclaimer" />
				</Spacing>
				<FlexBox>
					<FlexItem xs={6}>
						<Spacing mr="small">
							<Button fullWidth onClick={onAgree}>
								I agree
							</Button>
						</Spacing>
					</FlexItem>
					<FlexItem xs={6}>
						<Spacing ml="small">
							<Button fullWidth color="misc.lightGray" onClick={onDisagree}>
								I do not agree
							</Button>
						</Spacing>
					</FlexItem>
				</FlexBox>
			</Spacing>
		</Dialog>
	);
};

export default PrincipalReductionDisclaimerDialog;
