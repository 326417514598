import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PaperlessStatusResult = QueryResult<Envelope<boolean>, AxiosError<Envelope<boolean>>>;
export type PaperlessStatusConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<boolean>, AxiosError<Envelope<boolean>>>;
};

interface PaperlessStatusQuery {
	(request: PaperlessStatusRequestParameters, config?: PaperlessStatusConfig): PaperlessStatusResult;
	key: 'PaperlessStatus';
}

const usePaperlessStatusQuery: PaperlessStatusQuery = (request, config = { axiosConfig: {} }) => {
	async function PaperlessStatus(): Promise<Envelope<boolean>> {
		const client = ApiCSGService();

		const path = '/api/LoanCommunication/PaperlessStatus';

		const response = await client.get<Envelope<boolean>>(path, {
			username: request.username,
			loanNumber: request.loanNumber,
		});

		return response.data;
	}

	return useQuery(['PaperlessStatus', ...Object.values(request)], PaperlessStatus, config.queryConfig);
};

usePaperlessStatusQuery.key = 'PaperlessStatus';

export default usePaperlessStatusQuery;
