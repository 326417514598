import { useMediaQuery } from '@material-ui/core';

type ScreenSizeType = {
	isDesktop: boolean;
	isTable: boolean;
	isMobile: boolean;
};

const useScreenSize = (): ScreenSizeType => {
	const isMobile = useMediaQuery('(max-width: 900px)');
	// const isTable = useMediaQuery('(max-width: 1280px)');
	const isDesktop = useMediaQuery('(min-width: 1281px)');

	return {
		isDesktop,
		isTable: !isMobile && !isDesktop,
		isMobile,
	};
};

export default useScreenSize;
