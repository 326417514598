import React, { FunctionComponent } from 'react';

import { TableHead as MuiTableHead } from '@material-ui/core';

export interface TableHeadProps {
	children: React.ReactNode;
}

const TableHead: FunctionComponent<TableHeadProps> = ({ children }) => {
	return <MuiTableHead>{children}</MuiTableHead>;
};

export default TableHead;
