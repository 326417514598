import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import { AppBar, Toolbar, makeStyles, createStyles, Hidden, IconButton } from '@material-ui/core';

import { FlexBox, FlexItem } from 'sfc-kit';
import Image from 'sfc-kit/src/components/Image';
import SquareButtonLink from 'sfc-kit/src/components/SquareButtonLink';

import Paths from '../../../constants/Paths';
import colors from 'sfc-kit/src/constants/Colors';
import { GetLogoForEnvironment } from 'sfc-kit/src/utils';

interface HomeHeaderProps {
	onMenuClick: () => void;
}

const useStyles = makeStyles(theme =>
	createStyles({
		appBar: { zIndex: 3, backgroundColor: colors.background.paper },
		logo: { height: 40, marginTop: '8px' },
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const HomeHeader: React.FC<HomeHeaderProps> = ({ onMenuClick }) => {
	const classes = useStyles({} as any);
	const location = useLocation();
	const isInformationPage =
		location.pathname === Paths.Accessibility ||
		location.pathname === Paths.ConsumerPrivacyPolicy ||
		location.pathname === Paths.RightsNotice ||
		location.pathname === Paths.FAQs ||
		location.pathname === Paths.Privacy;
	const logo = GetLogoForEnvironment();

	/**
		* Tells the browser to scroll elements into view that are hidden by the sticky header. Adds some extra buffer to account for focused element outlines and takes that into the total height of the element's box, so that the outline is also visible when a hidden element comes into view. Theoretically, this should also work in Safari since it uses scroll-padding-top, which is supported in that browser.

		Just to note, I used the example provided here, with a few minor tweaks: https://www.tpgi.com/prevent-focused-elements-from-being-obscured-by-sticky-headers/#an-alternative-approach
		*/
	const handleHiddenFocusScrolling = () => {
		const EXTRA_PADDING = 6; // a "buffer" to account for focus outlines

		const header = document.querySelector('header');

		const style = document.head.appendChild(document.createElement('style'));
		const styles = style.sheet;

		const applyScrollPadding = () => {
			while (styles?.cssRules.length) {
				styles.deleteRule(0);
			}

			const offsetHeight = header?.offsetHeight as number;
			let height = offsetHeight + EXTRA_PADDING;

			/**
			 I had to add this check since the offset height is sometimes 0 (have no clue why), and that causes the total height to be less than 70, which does not bring the focused element into view. Probably not the best solution but seems to work ok.
			 */
			if (height < 70) {
				height = 70;
			}

			styles?.insertRule(`html { scroll-padding-top: ${height}px }`);

			const focused = document.activeElement || document.body;

			if (focused !== document.body && !header?.contains(focused)) {
				if (focused.getBoundingClientRect().top < height) {
					focused.scrollIntoView();
				}
			}
		};

		applyScrollPadding();
	};

	useEffect(() => {
		handleHiddenFocusScrolling();
	}, []);

	return (
		<AppBar position={isInformationPage ? 'static' : 'fixed'} className={classes.appBar}>
			<Toolbar>
				<FlexBox alignItems="center" justifyContent="space-between">
					<FlexItem>
						{location.pathname !== Paths.Home && (
							<IconButton edge="start" onClick={onMenuClick} className={classes.focus} aria-label="open-menu">
								<MenuIcon color="primary" fontSize="large" />
							</IconButton>
						)}
					</FlexItem>
					<FlexItem>
						<Hidden mdDown>
							<Image src={logo} alt="Service Finance Company, LLC" className={classes.logo} />
						</Hidden>
						<Hidden mdUp>
							<Image src="/svcfin_no_text.png" alt="Service Finance Company, LLC" className={classes.logo} />
						</Hidden>
					</FlexItem>
					<FlexItem>
						{location.pathname !== Paths.Home && (
							<SquareButtonLink
								to={Paths.Home}
								className={classes.focus}
								startIcon={<HomeIcon />}
								color="primary"
								variant="contained"
								aria-label="Home page link">
								Home
							</SquareButtonLink>
						)}
					</FlexItem>
				</FlexBox>
			</Toolbar>
		</AppBar>
	);
};

export default HomeHeader;
