import { Redirect } from 'react-router-dom';
import React, { FunctionComponent, useState, useContext } from 'react';

import { IconTooltip, Text, Spacing, LoadingBoundary, useScheduledAchPaymentsQuery } from 'sfc-kit';

import { DashboardCard, ScheduledPaymentListItem } from 'components';

import { LoanContext } from 'context';
import PaymentService from 'services/PaymentService';
import LoggingService from 'services/LoggingService';
import EditPaymentPaths from 'constants/Paths/EditPayment';
import GlobalContext, { GlobalActionTypes } from 'context/Global';
import EditPaymentContext, { EditPaymentActionTypes } from 'context/EditPayment';
import { useCustomerPortalNotificationStack, useMessages, useScheduledAchPayments } from 'hooks';
import { useQueryCache } from 'react-query';
import { useTheme, Theme, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const ScheduledPaymentList: FunctionComponent = () => {
	const classes = useStyles({} as any);

	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
	const { createSuccessNotification, createDangerNotification } = useCustomerPortalNotificationStack();

	const { selectedLoanNumber } = useContext(LoanContext);

	const cache = useQueryCache();

	const { isLoading: isScheduledAchPaymentsLoading, data: scheduledAchPayments } = useScheduledAchPayments();

	const { dispatchForGlobal } = useContext(GlobalContext);
	const { dispatchForEditPayment } = useContext(EditPaymentContext);

	const messages = useMessages();
	const [redirectTo, setRedirectTo] = useState<string>();

	if (redirectTo !== undefined) {
		return <Redirect to={redirectTo} />;
	}

	const editClick = (payment: ScheduledAchPayment): void => {
		dispatchForEditPayment({
			type: EditPaymentActionTypes.InitializeEditPayment,
			payload: payment,
		});

		setRedirectTo(EditPaymentPaths.Edit);
	};

	const onStopConfirm = async (payment: ScheduledAchPayment): Promise<void> => {
		try {
			dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: true });

			const emailSent = await PaymentService.DeleteScheduledAchPayment(payment.rowId, selectedLoanNumber);

			if (!emailSent) {
				LoggingService.LogError(
					"A payment was successfully stopped for loan #{loanNumber} but the API indicates an email wasn't sent.",
					selectedLoanNumber
				);
			}

			createSuccessNotification({ title: 'notifications.stopPayment.success.title', body: 'notifications.stopPayment.success.body' });
		} catch (error) {
			createDangerNotification({ title: 'notifications.oops', body: 'notifications.stopPayment.failure' });
		} finally {
			cache.invalidateQueries(useScheduledAchPaymentsQuery.key);
			dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: false });
		}
	};

	const tooltip = (
		<IconTooltip
			variant="InformationAlternative"
			className={classes.focus}
			tooltipProps={{ arrowDirection: isSmall ? 'left' : 'right', maxWidth: 350 }}
			hideCloseButton
			ariaId="scheduled-payments-tooltip"
			ariaLabel="Select to view the scheduled payments disclaimer"
			ariaDescribedBy="scheduled-payments-tooltip"
			hideIconInTooltip>
			<Spacing p="small">
				<Text variant="caption" message="alerts.paymentDateAndTimeDisclaimer.title" messages={messages} weight="medium" />
				<Text variant="caption" message="alerts.paymentDateAndTimeDisclaimer.body" messages={messages} />
			</Spacing>
		</IconTooltip>
	);

	return (
		<>
			<DashboardCard title="dashboard.scheduledPayments" action={tooltip}>
				<LoadingBoundary loading={isScheduledAchPaymentsLoading}>
					{scheduledAchPayments?.length
						? scheduledAchPayments.map(payment => (
								<ScheduledPaymentListItem
									key={payment.rowId}
									payment={payment}
									onEdit={(): void => editClick(payment)}
									onStopConfirm={onStopConfirm}
								/>
						  ))
						: 'There are currently no scheduled payments.'}
				</LoadingBoundary>
			</DashboardCard>
		</>
	);
};

export default ScheduledPaymentList;
