import Lang from '../translations/messages.json';

export * from './PaymentInfo';
export * from './PaymentAmounts';
export * from './SecurityQuestions';
export * from './LoanAccountAchSort';
export * from './PaymentFrequencies';
export * from './Paperless';

export type CustomerPortalMessages = typeof Lang['en'];

export enum HistoricalPaymentsSortOption {
	DateOldestToNewest = 0,
	DateNewestToOldest = 1,
}
