import { useState, useRef, useEffect, RefObject } from 'react';

export default function useRefOffsetWidth<TRef extends HTMLElement>(): [number, RefObject<TRef>] {
	const [offsetWidth, setOffsetWidth] = useState(0);
	const ref = useRef<TRef>(null);

	useEffect(() => {
		setOffsetWidth(ref.current?.offsetWidth ?? 0);
	}, []);

	return [offsetWidth, ref];
}
