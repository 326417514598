import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Radio, makeStyles, createStyles } from '@material-ui/core';

import { FlexBox, FlexItem, Spacing, Label } from '../../';

import { Color } from '../../../types';
import extractColorFromKeyString from '../../../utils/ExtractColorFromKeyString';

export interface RadioListItem {
	label?: string;
	inlineLabel?: string;
	element?: JSX.Element;
	disabled?: boolean;
}

export interface RadioListItemsObject {
	[key: string]: RadioListItem;
}

export interface RadioListProps<TItems extends RadioListItemsObject> {
	items: TItems;
	selectedItemKey: keyof TItems | undefined;
	tabIndex?: number;

	onChange: (newSelectedItemKey: keyof TItems | undefined) => void;

	showLabels?: boolean;
	color?: Color;
}

const useStyles = makeStyles(theme =>
	createStyles({
		radioBox: {
			border: `3px solid ${theme.palette.divider}`,
		},

		checkedRadioBox: ({ color }: { color: Color }) => {
			const extractedColor = extractColorFromKeyString(color);

			return { borderColor: extractedColor };
		},

		radio: ({ color }: { color: Color }) => {
			const extractedColor = extractColorFromKeyString(color);

			return {
				'&[aria-disabled="false"] svg': { color: extractedColor },
			};
		},
	})
);

export default function RadioList<TItems extends RadioListItemsObject>({
	items,
	selectedItemKey,
	tabIndex,

	onChange,

	showLabels = false,
	color = 'primary.main',
}: RadioListProps<TItems>): ReturnType<FunctionComponent> {
	const styles = useStyles({ color });

	return (
		<FlexBox direction="column">
			{Object.keys(items).map(key => {
				const checked = key === selectedItemKey;
				const { label, inlineLabel, element, disabled = false } = items[key];

				if (checked && disabled) {
					onChange(undefined);
				}

				return (
					<FlexItem key={key}>
						<Spacing py="small">
							{showLabels && label && <Label for={key}>{label}</Label>}
							<FlexBox
								spacing="xSmall"
								alignItems="center"
								className={classnames(styles.radioBox, { [styles.checkedRadioBox]: checked })}>
								<FlexItem>
									<Radio
										id={key}
										checked={checked && !disabled}
										className={styles.radio}
										onChange={(): void => onChange(key)}
										disabled={disabled}
										tabIndex={tabIndex}
										aria-label={`${label}`}
										aria-labelledby={`${label}-id`}
									/>
								</FlexItem>
								<FlexItem xs>
									<Spacing px="small">
										{element ?? (
											<Label for={key} id={`${label}-id`} color={disabled ? 'text.disabled' : checked ? color : 'text.primary'}>
												{inlineLabel ?? label}
											</Label>
										)}
									</Spacing>
								</FlexItem>
							</FlexBox>
						</Spacing>
					</FlexItem>
				);
			})}
		</FlexBox>
	);
}
