import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { FlexItem, Button, FlexBox, Text, Spacing, Modal } from 'sfc-kit';
import { useMessages } from 'hooks';

interface PaperlessAccountDifDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onSignUpClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			padding: theme.spacing(2, 3),
			textAlign: 'center',
		},
		buttonWrapper: {
			paddingBottom: theme.spacing(1),
		},
		actionButton: {
			marginRight: theme.spacing(1),
		},
		alertWrapper: {
			maxWidth: '390px',
		},
	})
);

const PaperlessAccountDifDialog: React.FC<PaperlessAccountDifDialogProps> = ({ isOpen, onClose, onSignUpClick }) => {
	const messages = useMessages();
	const classes = useStyles({} as any);

	const handleOnSignUp = (): void => {
		onSignUpClick();
		onClose();
	};

	return (
		<Modal
			onClose={onClose}
			messages={messages}
			id="paperless-prompt"
			open={isOpen}
			title="paperless.titleTurnedOff"
			titleCasing="capitalizeEachWord"
			variant="Warning">
			<FlexBox className={classes.contentWrapper} direction="column">
				<FlexItem>
					<Spacing pb="large">
						<Text weight="light" messages={messages} message="paperless.turnedOff" />
					</Spacing>
				</FlexItem>
				<FlexItem>
					<Spacing pb="medium">
						<Button
							onClick={handleOnSignUp}
							casing="capitalizeEachWord"
							messages={messages}
							message="misc.signUp"
							fullWidth
							aria-label="submit button"
						/>
					</Spacing>
				</FlexItem>
			</FlexBox>
		</Modal>
	);
};

export default PaperlessAccountDifDialog;
