import React, { ReactElement, ReactNode } from 'react';

import { makeStyles, createStyles, Chip as MaterialChip, ChipProps as MaterialChipProps } from '@material-ui/core';

import useSfcKitMessages from '../../hooks/useSfcKitMessages';
import extractColorFromKeyString from '../../utils/ExtractColorFromKeyString';
import { Color, Casing, LangText, Messages, SfcKitMessages, MessagesProps } from '../../types';
import generateCasing from '../../utils/GenerateCasing';

export interface ChipProps<TMessages extends Messages = SfcKitMessages>
	extends Omit<MaterialChipProps, 'color' | 'label' | 'children'>,
		MessagesProps<TMessages> {
	color?: Color;
	textColor?: Color;
	message?: LangText<TMessages>;
	casing?: Casing;
	children?: ReactNode;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: ({ color = 'primary.main', textColor = 'misc.white', casing }: Pick<ChipProps, 'color' | 'textColor' | 'casing'>) => {
			const extractedBackgroundColor = extractColorFromKeyString(color);
			const extractedTextColor = extractColorFromKeyString(textColor);

			return { backgroundColor: extractedBackgroundColor, color: extractedTextColor, ...generateCasing(casing) };
		},
	})
);

export default function Text<TMessages extends Messages = SfcKitMessages>({
	message,
	messages,
	color,
	textColor,
	casing,
	children,
	...restProps
}: ChipProps<TMessages>): ReactElement {
	const styles = useStyles({ color, textColor, casing });

	const sfcKitMessages = useSfcKitMessages();

	return (
		<MaterialChip
			className={styles.root}
			label={message ? messages?.[message] ?? sfcKitMessages[message as keyof SfcKitMessages] : children}
			{...restProps}
		/>
	);
}
