import { useLocation } from 'react-router';

export class QueryParameters {
	constructor(private parameters: { [key: string]: string | null } = {}) {}

	public get(key: string): string | null {
		return this.parameters[key] ?? null;
	}

	public getAll(): { [key: string]: string | null } {
		return this.parameters;
	}
}

export default function useQueryParameters(): QueryParameters {
	const { search } = useLocation();

	if (search.indexOf('?') !== 0) {
		return new QueryParameters();
	}

	return new QueryParameters(
		Object.fromEntries(
			search
				.substring(1)
				.split('&')
				.map(param => {
					const [key, query] = param.split(/=(.+)/);
					return [key, decodeURIComponent(query)];
				})
		)
	);
}
