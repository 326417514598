import AuthenticationContext, { AuthenticationActionTypes } from 'context/Authentication';
import useContactQuery from './useContactQuery';
import { GetFirstNonEmptyString, ToTitleCase } from 'utils';
import { useContext } from 'react';

export default function useProfileName(): string {
	const { isSuccess, data: contact } = useContactQuery();
	const { dispatchForAuthentication } = useContext(AuthenticationContext);
	if (!isSuccess || contact === undefined) {
		return '';
	}

	const authentication = JSON.parse(sessionStorage.getItem('Authentication') || '{}');

	const { shortName, firstNameOne, firstNameTwo, nameOne, nameTwo, email } = contact;

	if (email && !authentication.username) {
		dispatchForAuthentication({ type: AuthenticationActionTypes.UpdateUsername, payload: email });
	}

	const profileName = GetFirstNonEmptyString(shortName, firstNameOne, firstNameTwo, nameOne, nameTwo);

	return ToTitleCase(profileName);
}
