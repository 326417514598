import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetProgressPaymentInfoResult = QueryResult<ProgressPaymentForDealer, AxiosError<ProgressPaymentForDealer>>;
export type GetProgressPaymentInfoConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<ProgressPaymentForDealer, AxiosError<ProgressPaymentForDealer>>;
};

interface GetProgressPaymentInfoQuery {
	(request: GetProgressPaymentInfoRequestParameters, config?: GetProgressPaymentInfoConfig): GetProgressPaymentInfoResult;
	key: 'GetProgressPaymentInfo';
}

const useGetProgressPaymentInfoQuery: GetProgressPaymentInfoQuery = (request, config = { axiosConfig: {} }) => {
	async function GetProgressPaymentInfo(): Promise<ProgressPaymentForDealer> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetProgressPaymentInfo';

		const response = await client.get<ProgressPaymentForDealer>(path, {
			taskId: request?.taskId,
		});

		return response.data;
	}

	return useQuery(['GetProgressPaymentInfo', ...Object.values(request)], GetProgressPaymentInfo, config.queryConfig);
};

useGetProgressPaymentInfoQuery.key = 'GetProgressPaymentInfo';

export default useGetProgressPaymentInfoQuery;
