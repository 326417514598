import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetCertificateOfCompletionDocumentPdfResult = QueryResult<FileDto, AxiosError<FileDto>>;
export type GetCertificateOfCompletionDocumentPdfConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<FileDto, AxiosError<FileDto>>;
};

interface GetCertificateOfCompletionDocumentPdfQuery {
	(
		request: GetCertificateOfCompletionDocumentPdfRequestParameters,
		config?: GetCertificateOfCompletionDocumentPdfConfig
	): GetCertificateOfCompletionDocumentPdfResult;
	key: 'GetCertificateOfCompletionDocumentPdf';
}

const useGetCertificateOfCompletionDocumentPdfQuery: GetCertificateOfCompletionDocumentPdfQuery = (request, config = { axiosConfig: {} }) => {
	async function GetCertificateOfCompletionDocumentPdf(): Promise<FileDto> {
		const client = ApiCSGService();

		let path = '/api/CertificateOfCompletionDocuments/{documentId}/Pdf';

		path = path.replace('{documentId}', request.documentId.toString());

		const response = await client.get<FileDto>(path);

		return response.data;
	}

	return useQuery(['GetCertificateOfCompletionDocumentPdf', ...Object.values(request)], GetCertificateOfCompletionDocumentPdf, config.queryConfig);
};

useGetCertificateOfCompletionDocumentPdfQuery.key = 'GetCertificateOfCompletionDocumentPdf';

export default useGetCertificateOfCompletionDocumentPdfQuery;
