import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { type FontWeightProperty } from 'csstype';

import Hidden from '@material-ui/core/Hidden';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Text } from '../../Typography';
import IsString from '../../../guards/IsString';
import { FlexBox, FlexItem, Spacing } from '../..';
import NumericStepper, { INumericStepperProps } from '../../NumericStepper';
import WizardCardContent, { WizardCardContentProps } from './WizardCardContent';

export interface WizardCardProps {
	/** A Material-UI Icon https://material-ui.com/components/material-icons/ */
	icon?: typeof SvgIcon;

	/** Text to be displayed as a title in the Sidebar */
	title?: string;

	/**
	 * Either a string or ReactNode to be displayed below the title in the Sidebar
	 *
	 * If the value is a string it will be rendered inside of a typography element,
	 * otherwise it will be rendered as is
	 */
	sidebarContent?: string | ReactNode;

	/**
	 * Either a string or ReactNode to be displayed at the bottom of the Sidebar
	 *
	 * If the value is a string it will be rendered inside of a typography element,
	 * otherwise it will be rendered as is
	 */
	sidebarFooter?: string | ReactNode;

	children: ReactNode;

	/**
	 * Props to be passed to a `NumericStepper`. If left undefined a numeric stepper will not be rendered.
	 */
	stepperProps?: INumericStepperProps;

	/**
	 * Props to be passed to a `WizardCardContent`. If left undefined the WizardCardContent will not be rendered.
	 */
	contentProps?: WizardCardContentProps;

	/** Classes used to override styles of the underlying components */
	classes?: {
		root?: string;
		sidebar?: string;
		icon?: string;
		title?: string;
		subTitle?: string;
		content?: string;
	};

	isCaptcha?: boolean;
}

const useStyles = makeStyles(theme =>
	createStyles({
		wizardCard: {
			minHeight: 450,
			width: '75vw',
			maxWidth: 950,
		},
		sidebar: {
			boxShadow: theme.shadows[1],
			backgroundColor: theme.palette.background.default,
		},
		icon: {
			color: theme.palette.primary.main,
			fontSize: theme.typography.h1.fontSize,
		},
		title: {
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
		},
		content: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[1],
			minHeight: 450,
			overflow: 'hidden',
		},
		stepper: {
			padding: theme.spacing(2, 0),
			width: '100%',
		},
	})
);

const WizardCard: FunctionComponent<WizardCardProps> = ({
	icon: Icon,
	title,
	sidebarContent,
	sidebarFooter,

	children,

	stepperProps,
	contentProps,

	isCaptcha,

	classes = {},
}) => {
	const styles = useStyles({} as any);

	const hasSidebarContent = Icon !== undefined || title !== undefined || sidebarContent !== undefined || sidebarFooter !== undefined;

	return (
		<FlexBox className={classnames(styles.wizardCard, classes.root)}>
			{/* Begin Sidebar */}
			{hasSidebarContent && (
				<FlexItem xs={12} lg={4} className={classnames(styles.sidebar, classes.sidebar)}>
					<FlexBox fullHeight direction="column" justifyContent="space-between">
						<FlexItem xs>
							<FlexBox fullHeight direction="column" justifyContent="center" alignItems="center" wrap="nowrap">
								{/* Sidebar Icon */}
								{Icon !== undefined && (
									<FlexItem>
										<Spacing px="small" mt="small">
											<Icon className={classnames(styles.icon, classes.icon)} role="lockIcon" />
										</Spacing>
									</FlexItem>
								)}

								{/* Sidebar Title */}
								{title !== undefined && (
									<FlexItem>
										<Spacing px="small" mb="small">
											<Text
												role="heading"
												align="center"
												variant="h6"
												component="h1"
												className={classnames(styles.title, classes.title)}>
												{title}
											</Text>
										</Spacing>
									</FlexItem>
								)}

								{/* Sidebar Content */}
								<Hidden xsDown>
									<FlexItem>
										{sidebarContent !== undefined &&
											(IsString(sidebarContent) ? (
												<Spacing px="medium">
													<Text align="center" variant="subtitle1" className={classes.subTitle} aria-label="mainSidebar">
														{sidebarContent}
													</Text>
												</Spacing>
											) : (
												sidebarContent
											))}
									</FlexItem>
								</Hidden>
							</FlexBox>
						</FlexItem>

						{/* Sidebar Footer */}
						<Hidden xsDown>
							{sidebarFooter !== undefined && (
								<FlexItem>
									<Spacing p="xSmall">
										{IsString(sidebarFooter) ? (
											<Text align="center" aria-label="footerSidebar">
												{sidebarFooter}
											</Text>
										) : (
											sidebarFooter
										)}
									</Spacing>
								</FlexItem>
							)}
						</Hidden>
					</FlexBox>
				</FlexItem>
			)}
			{/* End Sidebar */}

			{/* Start Main Content */}
			<FlexItem xs={12} lg className={classnames(styles.content, classes.content)}>
				<FlexBox fullHeight direction="column">
					<FlexItem xs>
						{contentProps !== undefined ? (
							<WizardCardContent isCaptcha={isCaptcha} {...contentProps}>
								{children}
							</WizardCardContent>
						) : (
							<Spacing p="medium">{children}</Spacing>
						)}
					</FlexItem>
					{stepperProps !== undefined && (
						<FlexItem aria-hidden="true">
							<NumericStepper {...stepperProps} className={styles.stepper} />
						</FlexItem>
					)}
				</FlexBox>
			</FlexItem>
			{/* End Main Content */}
		</FlexBox>
	);
};

export { WizardCardContent };
export default WizardCard;
