import { FindDealerActionTypes, FindDealerActions, FindDealerInitialStateType } from './FindDealerContext';

export default function FindDealerReducer(state: FindDealerInitialStateType, action: FindDealerActions): FindDealerInitialStateType {
	switch (action.type) {
		case FindDealerActionTypes.VerifyInfo:
			return {
				...state,
				...action.payload,
			};

		case FindDealerActionTypes.UpdateAppointment: {
			return {
				...state,
				appointment: action.payload,
			};
		}

		default:
			return state;
	}
}
