import { IsPromise } from 'guards/IsPromise';
import { GlobalDispatchType, GlobalActionTypes } from 'context/Global';

export async function AwaitBlockingPromise<T>(promiseOrFunc: Promise<T> | (() => Promise<T>), dispatchForGlobal: GlobalDispatchType): Promise<T> {
	try {
		dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: true });

		const promise = IsPromise<T>(promiseOrFunc) ? promiseOrFunc : promiseOrFunc();

		return await promise;
	} finally {
		dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: false });
	}
}
