import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetBankHistoryResult = QueryResult<Envelope<Array<LoanAccountAchDto>>, AxiosError<Envelope<Array<LoanAccountAchDto>>>>;
export type GetBankHistoryConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<Array<LoanAccountAchDto>>, AxiosError<Envelope<Array<LoanAccountAchDto>>>>;
};

interface GetBankHistoryQuery {
	(request: GetBankHistoryRequestParameters, config?: GetBankHistoryConfig): GetBankHistoryResult;
	key: 'GetBankHistory';
}

const useGetBankHistoryQuery: GetBankHistoryQuery = (request, config = { axiosConfig: {} }) => {
	async function GetBankHistory(): Promise<Envelope<Array<LoanAccountAchDto>>> {
		const client = ApiCSGService();

		const path = '/api/Payment/GetBankHistory';

		const response = await client.get<Envelope<Array<LoanAccountAchDto>>>(path, {
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetBankHistory', ...Object.values(request)], GetBankHistory, config.queryConfig);
};

useGetBankHistoryQuery.key = 'GetBankHistory';

export default useGetBankHistoryQuery;
