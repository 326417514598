import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetBorrowerContactResult = QueryResult<ContactDto, AxiosError<ContactDto>>;
export type GetBorrowerContactConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<ContactDto, AxiosError<ContactDto>>;
};

interface GetBorrowerContactQuery {
	(request: GetBorrowerContactRequestParameters, config?: GetBorrowerContactConfig): GetBorrowerContactResult;
	key: 'GetBorrowerContact';
}

const useGetBorrowerContactQuery: GetBorrowerContactQuery = (request, config = { axiosConfig: {} }) => {
	async function GetBorrowerContact(): Promise<ContactDto> {
		const client = ApiCSGService();

		const path = '/api/Borrower/GetBorrowerContact';

		const response = await client.get<ContactDto>(path);

		return response.data;
	}

	return useQuery(['GetBorrowerContact', ...Object.values(request)], GetBorrowerContact, config.queryConfig);
};

useGetBorrowerContactQuery.key = 'GetBorrowerContact';

export default useGetBorrowerContactQuery;
