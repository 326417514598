import * as yup from 'yup';
import RegEx from 'sfc-kit/src/constants/Regex';

export default yup.object().shape({
	loanNumber: yup
		.string()
		.matches(RegEx.numeric)
		.required('Loan number is required.'),
	socialSecurityNumber: yup
		.string()
		.test('len', 'SSN is not long enough.', val => val.length >= 11)
		.test('len', 'SSN is too long.', value => value.length <= 11)
		.required('SSN is required.'),
	email: yup
		.string()
		.email('Email must be a valid email address')
		.required('Email is required.'),
});
