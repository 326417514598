import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoansResult = QueryResult<Array<Loan>, AxiosError<Array<Loan>>>;
export type LoansConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<Loan>, AxiosError<Array<Loan>>>;
};

interface LoansQuery {
	(request: LoansRequestParameters, config?: LoansConfig): LoansResult;
	key: 'Loans';
}

const useLoansQuery: LoansQuery = (request, config = { axiosConfig: {} }) => {
	async function Loans(): Promise<Array<Loan>> {
		const client = ApiCSGService();

		const path = '/api/Loans';

		const response = await client.get<Array<Loan>>(path, {
			includeRelatedLoans: request?.includeRelatedLoans,
		});

		return response.data;
	}

	return useQuery(['Loans', ...Object.values(request)], Loans, config.queryConfig);
};

useLoansQuery.key = 'Loans';

export default useLoansQuery;
