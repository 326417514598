import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetStipulationsResult = QueryResult<Array<Stipulation>, AxiosError<Array<Stipulation>>>;
export type GetStipulationsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<Stipulation>, AxiosError<Array<Stipulation>>>;
};

interface GetStipulationsQuery {
	(request: GetStipulationsRequestParameters, config?: GetStipulationsConfig): GetStipulationsResult;
	key: 'GetStipulations';
}

const useGetStipulationsQuery: GetStipulationsQuery = (request, config = { axiosConfig: {} }) => {
	async function GetStipulations(): Promise<Array<Stipulation>> {
		const client = ApiCSGService();

		const path = '/api/Stipulations/GetStipulations';

		const response = await client.get<Array<Stipulation>>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetStipulations', ...Object.values(request)], GetStipulations, config.queryConfig);
};

useGetStipulationsQuery.key = 'GetStipulations';

export default useGetStipulationsQuery;
