import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type ForceChangePasswordWithTokenResult = MutationResultPair<
	string,
	AxiosError<string>,
	ForceChangePasswordWithTokenRequestParameters,
	unknown
>;

export type ForceChangePasswordWithTokenConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<string, AxiosError<string>, ForceChangePasswordWithTokenRequestParameters>;
};

export default function useForceChangePasswordWithTokenMutation(
	config: ForceChangePasswordWithTokenConfig = { axiosConfig: {} }
): ForceChangePasswordWithTokenResult {
	async function ForceChangePasswordWithToken(request: ForceChangePasswordWithTokenRequestParameters): Promise<string> {
		const client = ApiCSGService();

		const path = '/api/PasswordRecovery/ForceChangePasswordWithToken';

		const response = await client.patch<string>(path, request.body);

		return response.data;
	}

	return useMutation(ForceChangePasswordWithToken, config.mutationConfig);
}
