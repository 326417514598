import NotNull from 'sfc-kit/src/guards/NotNull';

export class ContactService {
	GetPrimaryPhone(phones: ContactPhoneDto[]): ContactPhoneDto {
		return phones?.filter(NotNull).filter(phone => Boolean(+(phone?.primaryFlag ?? '0')))[0];
	}
}

const contactService = new ContactService();

export default contactService;
