import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type VerifyBorrowerInfoResult = MutationResultPair<boolean, AxiosError<boolean>, VerifyBorrowerInfoRequestParameters, unknown>;

export type VerifyBorrowerInfoConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<boolean, AxiosError<boolean>, VerifyBorrowerInfoRequestParameters>;
};

export default function useVerifyBorrowerInfoMutation(config: VerifyBorrowerInfoConfig = { axiosConfig: {} }): VerifyBorrowerInfoResult {
	async function VerifyBorrowerInfo(request: VerifyBorrowerInfoRequestParameters): Promise<boolean> {
		const client = ApiCSGService();

		const path = '/api/Borrower/VerifyBorrowerInfo';

		const response = await client.post<boolean>(path, request.body);

		return response.data;
	}

	return useMutation(VerifyBorrowerInfo, config.mutationConfig);
}
