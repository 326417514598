import CommonServicesGatewayAPI from 'utils/CommonServicesGatewayAPI';
import LocalStorageKeys from 'constants/LocalStorageKeys';
import { DateTime } from 'luxon';
import * as BrowserStorage from 'sfc-kit/src/utils/BrowserStorage';

import { LoanWithStatement } from 'types';
import { OnboardingActionTypes, OnboardingDispatchType } from 'context/Onboarding';
import { AxiosError } from 'axios';

export const retrieveLoanStatementOptions = async (loans: Loan[], username: string): Promise<Array<LoanWithStatement>> => {
	const arrayOfLoansPaperless = await Promise.all(
		loans.map(async loan => {
			const { loanNumber } = loan;

			const { data: borrowersEnvelope } = await CommonServicesGatewayAPI.Borrowers({ loanNumber });

			const hasCoBorrower = borrowersEnvelope.success && borrowersEnvelope.response.hasCoBorrower;

			try {
				const { data: paperlessStatusEnvelope } = await CommonServicesGatewayAPI.PaperlessStatus({
					loanNumber,
					username,
				});

				const enrolledInPaperless = paperlessStatusEnvelope.success && paperlessStatusEnvelope.response;

				return { ...loan, enrolledInPaperless, hasCoBorrower };
			} catch (error) {
				const axiosError: AxiosError<string> = error as AxiosError<string>;

				if (axiosError?.response?.status === 400) {
					return { ...loan, enrolledInPaperless: false, hasCoBorrower };
				}
				throw error;
			}
		})
	);

	return arrayOfLoansPaperless;
};

export const patchAllLoans = async (loans: Array<LoanWithStatement>, someLoansPaperless: boolean, loggedInUser: string): Promise<void> => {
	if (!someLoansPaperless) {
		BrowserStorage.SetItem(
			LocalStorageKeys.PaperlessPromptDate,
			DateTime.local()
				.plus({ months: 6 })
				.toISO()
		);
	}

	if (someLoansPaperless) {
		BrowserStorage.RemoveItem(LocalStorageKeys.PaperlessPromptDate);
	}

	const paperlessOptInLoans: Array<string> = [];
	const paperlessOptOutLoans: Array<string> = [];
	loans.forEach(loan => (loan.enrolledInPaperless ? paperlessOptInLoans.push(loan.loanNumber) : paperlessOptOutLoans.push(loan.loanNumber)));

	if (paperlessOptInLoans.length) {
		paperlessOptInLoans.forEach(loanNumber => CommonServicesGatewayAPI.PaperlessOptIn({ body: { loanNumber, username: loggedInUser } }));
	}
	if (paperlessOptOutLoans.length) {
		paperlessOptOutLoans.forEach(loanNumber => CommonServicesGatewayAPI.PaperlessOptOut({ body: { loanNumber, username: loggedInUser } }));
	}
};

export const handleOptInPaperless = (
	loanNumber: string,
	value: boolean,
	loansWithStatementOptions: LoanWithStatement[],
	setCurrentLoans: (loans: LoanWithStatement[]) => void,
	setPaperlessPrompt: (value: boolean) => void,
	setNotValidEmailPrompt: (value: boolean) => void,
	emailIsVerified: boolean
): void => {
	if (loansWithStatementOptions) {
		setCurrentLoans(
			loansWithStatementOptions.map((loan: LoanWithStatement) => {
				if (loanNumber === 'Toggle All') {
					return { ...loan, enrolledInPaperless: value };
				}

				return loan.loanNumber === loanNumber ? { ...loan, enrolledInPaperless: value } : loan;
			})
		);
	}

	if (emailIsVerified) {
		setPaperlessPrompt(true);
	} else {
		setNotValidEmailPrompt(true);
	}
};

export const handlePaperlessDialogContinue = async (
	loans: Array<LoanWithStatement>,
	loggedInUser: string,
	setPaperlessPrompt: (value: boolean) => void,
	displayEnrolledNotif: () => void
): Promise<void> => {
	const someLoansPaperless = loans.some(loan => loan.enrolledInPaperless);

	try {
		await patchAllLoans(loans, someLoansPaperless, loggedInUser);
	} finally {
		setPaperlessPrompt(false);
		someLoansPaperless && displayEnrolledNotif();
	}
};

export const handleNonVerifiedEmailDialogContinue = (
	currentLoans: LoanWithStatement[],
	dispatchForOnboarding: OnboardingDispatchType,
	setPaperlessPrompt: (value: boolean) => void,
	setNotValidEmailPrompt: (value: boolean) => void
): void => {
	const paperlessOptInLoans: Array<string> = [];
	const paperlessOptOutLoans: Array<string> = [];
	currentLoans.forEach(loan => (loan.enrolledInPaperless ? paperlessOptInLoans.push(loan.loanNumber) : paperlessOptOutLoans.push(loan.loanNumber)));

	dispatchForOnboarding({ type: OnboardingActionTypes.PaperlessOptInLoanNumbers, payload: paperlessOptInLoans });
	dispatchForOnboarding({ type: OnboardingActionTypes.PaperlessOptOutLoanNumbers, payload: paperlessOptOutLoans });
	setPaperlessPrompt(true);
	setNotValidEmailPrompt(false);
};
