import React, { useCallback } from 'react';
import { useSnackbar, OptionsObject, WithSnackbarProps, SnackbarContent } from 'notistack';

import { Messages, SfcKitMessages } from '../types';
import { Notification, NotificationProps } from '../components';
import { useClickAwayListener } from '.';

export type CreateNotificationOptions<TMessages extends Messages = SfcKitMessages> = Pick<
	NotificationProps<TMessages>,
	'title' | 'body' | 'children' | 'classes' | 'variant' | 'messages'
> &
	Omit<OptionsObject, 'variant'> & { dismissible?: boolean; disableTimeout?: boolean };

export type CreateNotification<TMessages extends Messages = SfcKitMessages> = (
	options: CreateNotificationOptions<TMessages>
) => OptionsObject['key'] | null;

export interface UseNotificationStackResult<TMessages extends Messages = SfcKitMessages> {
	createNotification: CreateNotification<TMessages>;
	closeNotification: WithSnackbarProps['closeSnackbar'];
}

export default function useNotificationStack<TMessages extends Messages = SfcKitMessages>(): UseNotificationStackResult<TMessages> {
	const { enqueueSnackbar, closeSnackbar: closeNotification } = useSnackbar();

	const ref = useClickAwayListener<HTMLDivElement>(true, () => closeNotification());

	const createNotification: CreateNotification<TMessages> = useCallback(
		({ title, body, children, classes, variant, dismissible, messages, disableTimeout, ...options }) => {
			const content: SnackbarContent = key => (
				<div ref={ref}>
					<Notification
						key={key}
						title={title}
						body={body}
						classes={classes}
						variant={variant}
						onClose={dismissible ? (): void => closeNotification(key) : undefined}
						messages={messages}>
						{children}
					</Notification>
				</div>
			);

			return enqueueSnackbar('', {
				autoHideDuration: disableTimeout ? null : 4_000,
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },

				...options,

				content,
			});
		},
		[enqueueSnackbar, closeNotification, ref]
	);

	return { createNotification, closeNotification };
}
