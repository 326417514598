import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PipelineDetailsResult = QueryResult<PipelineDetails, AxiosError<PipelineDetails>>;
export type PipelineDetailsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<PipelineDetails, AxiosError<PipelineDetails>>;
};

interface PipelineDetailsQuery {
	(request: PipelineDetailsRequestParameters, config?: PipelineDetailsConfig): PipelineDetailsResult;
	key: 'PipelineDetails';
}

const usePipelineDetailsQuery: PipelineDetailsQuery = (request, config = { axiosConfig: {} }) => {
	async function PipelineDetails(): Promise<PipelineDetails> {
		const client = ApiCSGService();

		const path = '/api/Dealer/DealerPipeline/Details';

		const response = await client.get<PipelineDetails>(path, {
			taskId: request.taskId,
		});

		return response.data;
	}

	return useQuery(['PipelineDetails', ...Object.values(request)], PipelineDetails, config.queryConfig);
};

usePipelineDetailsQuery.key = 'PipelineDetails';

export default usePipelineDetailsQuery;
