const Paths = {
	Home: '/',
	FAQs: '/FAQS',
	Privacy: 'pages/privacy.notice/',
	Contact: '/ContactUs',
	InstructionalDocuments: '/InstructionalDocuments',
	Subordination: '/Subordination',
	ConsumerPrivacyPolicy: '/ConsumerPrivacyPolicy',
	RightsNotice: 'pages/privacy.notice.collection/',
	Information: '/Information/*',

	Login: '/Login',
	ForgotUsername: '/ForgotUsername/*',
	ForgotUsernameRoot: '/ForgotUsername/',
	EmulateMobileLogin:'/EmulateMobileApp',
	
	Dashboard: '/Dashboard',
	PaymentCenter: '/PaymentCenter',
	PaymentActivity: '/PaymentActivity',
	EditPayment: '/Payment',
	Statements: '/Statements',
	StatementDocument: '/Statement',
	LoanOverview: '/LoanOverview',
	PayoffLetter: '/PayoffLetter',
	PublicDocument: '/InstructionalDocument/View',
	Document: '/Document',

	PrivateFAQs: '/Dashboard/Faqs',
	PrivatePrivacyPolicy: '/Dashboard/PrivacyPolicy',
	PrivateAccessibility: '/Dashboard/Accessibility',
	PrivateInstructionalDocuments: '/Dashboard/InstructionalDocument',
	PrivateInstructionalDocument: '/Dashboard/InstructionalDocument/View',

	EmailVerification: '/EmailVerification',
	EmailVerificationSuccessful: '/EmailVerification/Successful',
	Accessibility: '/Accessibility',
	Any: '/*',
	NotFound: '/404',
	OnlinePrivacyPractices: '#privacy-practices',
};

export default Paths;
