import './global.css';
import 'reflect-metadata';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';

import { Route, Switch } from 'react-router-dom';
import React, { FunctionComponent, lazy, useContext, Suspense } from 'react';

import Paths from 'constants/Paths';
import StatementPaths from 'constants/Paths/Statements';
import EditPaymentPaths from 'constants/Paths/EditPayment';
import ForgotUsernamePaths from 'constants/Paths/ForgotUsername';
import UASRoutes from 'constants/Paths/UserAccountSettings';
import MakeAPaymentPaths from 'constants/Paths/MakeAPayment';
import RegistrationPaths from 'constants/Paths/Registration';
import PasswordRecoveryPaths from 'constants/Paths/PasswordRecovery';

import { SfcSpinner, SfcLoadingSnackbar } from 'components';

import { GlobalProvider } from 'context/Global';
import { useCustomerPortalNotificationStack } from 'hooks';
import AuthenticationContext from 'context/Authentication';
import { LoadingBoundary, FlexBox, SFCHelmet } from 'sfc-kit';

const PublicIndex = lazy(() => import('pages/public/index'));
const PrivateIndex = lazy(() => import('pages/private/index'));

const PUBLIC_PATHS = [
	Paths.Home,
	Paths.FAQs,
	Paths.Login,
	Paths.Privacy,
	Paths.ConsumerPrivacyPolicy,
	Paths.RightsNotice,
	Paths.Contact,
	`${ForgotUsernamePaths.Root}/*`,
	Paths.EmailVerificationSuccessful,
	Paths.EmailVerification,
	`${RegistrationPaths.Root}/*`,
	`${PasswordRecoveryPaths.Root}/*`,
];

const PRIVATE_PATHS = [
	Paths.Document,
	Paths.Dashboard,
	Paths.PayoffLetter,
	Paths.PaymentCenter,
	Paths.PaymentActivity,
	UASRoutes.Root,
	Paths.LoanOverview,
	StatementPaths.Root,
	EditPaymentPaths.Root,
	MakeAPaymentPaths.Root,
];

const App: FunctionComponent = () => {
	const {
		authenticationState: { isAuthenticated },
	} = useContext(AuthenticationContext);

	const { createDangerNotification } = useCustomerPortalNotificationStack();

	function onError(): void {
		createDangerNotification({ title: 'notifications.oops', body: 'notifications.somethingWentWrong' });
	}

	const queryCache = new QueryCache({
		defaultConfig: {
			queries: { retry: false, onError },
			mutations: { onError },
		},
	});

	return (
		<ReactQueryCacheProvider queryCache={queryCache}>
			<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
			<GlobalProvider>
				<Suspense
					fallback={
						<FlexBox style={{ height: '100vh' }}>
							<LoadingBoundary loading>
								<div />
							</LoadingBoundary>
						</FlexBox>
					}>
					<SFCHelmet />
					<Switch>
						<Route exact path={PUBLIC_PATHS} component={PublicIndex} />
						<Route path={PRIVATE_PATHS} component={PrivateIndex} />
						<Route exact path={[Paths.Any, Paths.NotFound]} component={isAuthenticated ? PrivateIndex : PublicIndex} />
					</Switch>
					<SfcSpinner />
					<SfcLoadingSnackbar />
				</Suspense>
			</GlobalProvider>
		</ReactQueryCacheProvider>
	);
};

export default App;
