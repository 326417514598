import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type StatementPdfResult = QueryResult<FileDto, AxiosError<FileDto>>;
export type StatementPdfConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<FileDto, AxiosError<FileDto>>;
};

interface StatementPdfQuery {
	(request: StatementPdfRequestParameters, config?: StatementPdfConfig): StatementPdfResult;
	key: 'StatementPdf';
}

const useStatementPdfQuery: StatementPdfQuery = (request, config = { axiosConfig: {} }) => {
	async function StatementPdf(): Promise<FileDto> {
		const client = ApiCSGService();

		const {loanNumber, documentId} = request;

		const path = `/api/Loans/${loanNumber}/StatementPdf/${documentId}`;

		const response = await client.get<FileDto>(path);

		return response.data;
	}

	return useQuery(['StatementPdf', ...Object.values(request)], StatementPdf, config.queryConfig);
};

useStatementPdfQuery.key = 'StatementPdf';

export default useStatementPdfQuery;
