import React, { useEffect } from 'react';
import { Duration } from 'luxon';
import { createStyles, makeStyles } from '@material-ui/core';

interface CountdownProps {
	active: boolean;
	timeout: number;
	handleTimeout: () => void;
	format?: string;
}

const useStyles = makeStyles(() => createStyles({ timerText: { display: 'inherit' } }));

const Countdown: React.FC<CountdownProps> = ({ active, handleTimeout, timeout, format = 'mm:ss' }) => {
	const classes = useStyles({} as any);
	const [counter, setCounter] = React.useState(timeout / 1000);

	useEffect(() => {
		let timer: NodeJS.Timer;

		if (counter <= 0) {
			handleTimeout();
		} else {
			if (active) {
				timer = setInterval(() => setCounter(counter - 1), 1000);

				return (): void => clearInterval(timer);
			}
		}
	}, [counter, active, timeout, handleTimeout]);

	return <span className={classes.timerText}>{Duration.fromObject({ second: counter }).toFormat(format)} minutes.</span>;
};

export default Countdown;
