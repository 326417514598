import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanNicknameResult = QueryResult<string, AxiosError<string>>;
export type LoanNicknameConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<string, AxiosError<string>>;
};

interface LoanNicknameQuery {
	(request: LoanNicknameRequestParameters, config?: LoanNicknameConfig): LoanNicknameResult;
	key: 'LoanNickname';
}

const useLoanNicknameQuery: LoanNicknameQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanNickname(): Promise<string> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/Nickname`;

		const response = await client.get<string>(path);

		return response.data;
	}

	return useQuery(['LoanNickname', ...Object.values(request)], LoanNickname, config.queryConfig);
};

useLoanNicknameQuery.key = 'LoanNickname';

export default useLoanNicknameQuery;
