import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type ScheduledAchPaymentsResult = QueryResult<Array<ScheduledAchPayment>, AxiosError<Array<ScheduledAchPayment>>>;
export type ScheduledAchPaymentsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<ScheduledAchPayment>, AxiosError<Array<ScheduledAchPayment>>>;
};

interface ScheduledAchPaymentsQuery {
	(request: ScheduledAchPaymentsRequestParameters, config?: ScheduledAchPaymentsConfig): ScheduledAchPaymentsResult;
	key: 'ScheduledAchPayments';
}

const useScheduledAchPaymentsQuery: ScheduledAchPaymentsQuery = (request, config = { axiosConfig: {} }) => {
	async function ScheduledAchPayments(): Promise<Array<ScheduledAchPayment>> {
		const client = ApiCSGService();

		const path = '/api/Payment/ScheduledAchPayments';

		const response = await client.get<Array<ScheduledAchPayment>>(path, {
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['ScheduledAchPayments', ...Object.values(request)], ScheduledAchPayments, config.queryConfig);
};

useScheduledAchPaymentsQuery.key = 'ScheduledAchPayments';

export default useScheduledAchPaymentsQuery;
