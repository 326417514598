import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetBorrowerForDealerResult = QueryResult<BorrowerForDealer, AxiosError<BorrowerForDealer>>;
export type GetBorrowerForDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<BorrowerForDealer, AxiosError<BorrowerForDealer>>;
};

interface GetBorrowerForDealerQuery {
	(request: GetBorrowerForDealerRequestParameters, config?: GetBorrowerForDealerConfig): GetBorrowerForDealerResult;
	key: 'GetBorrowerForDealer';
}

const useGetBorrowerForDealerQuery: GetBorrowerForDealerQuery = (request, config = { axiosConfig: {} }) => {
	async function GetBorrowerForDealer(): Promise<BorrowerForDealer> {
		const client = ApiCSGService();

		let path = '/api/Borrower/{borrowerId}/ForDealer';

		path = path.replace('{borrowerId}', request.borrowerId.toString());

		const response = await client.get<BorrowerForDealer>(path);

		return response.data;
	}

	return useQuery(['GetBorrowerForDealer', ...Object.values(request)], GetBorrowerForDealer, config.queryConfig);
};

useGetBorrowerForDealerQuery.key = 'GetBorrowerForDealer';

export default useGetBorrowerForDealerQuery;
