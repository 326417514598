import { AxiosError, AxiosRequestConfig } from 'axios';
import { usePaginatedQuery, QueryConfig, PaginatedQueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PaginatedDealerPipelineResult = PaginatedQueryResult<DealerPipeline, AxiosError<DealerPipeline>>;
export type PaginatedDealerPipelineConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<DealerPipeline, AxiosError<DealerPipeline>>;
};

interface PaginatedDealerPipelineQuery {
	(request: PaginatedDealerPipelineRequestParameters, config?: PaginatedDealerPipelineConfig): PaginatedDealerPipelineResult;
	key: 'PaginatedDealerPipeline';
}

const usePaginatedDealerPipelineQuery: PaginatedDealerPipelineQuery = (request, config = { axiosConfig: {} }) => {
	async function PaginatedDealerPipeline(): Promise<DealerPipeline> {
		const client = ApiCSGService();

		const path = '/api/Dealer/PaginatedDealerPipeline';

		const response = await client.get<DealerPipeline>(path, {
			page: request?.page,
			take: request?.take,
			sort: request?.sort,
			loanNumber: request?.loanNumber,
			loanStatus: request?.loanStatus,
			borrowerName: request?.borrowerName,
			salesName: request?.salesName,
			pipelineStatus: request?.pipelineStatus,
			stipulationType: request?.stipulationType,
			borrowerSigned: request?.borrowerSigned,
			dealerSigned: request?.dealerSigned,
			stipulationRejected: request?.stipulationRejected,
			stipulationPending: request?.stipulationPending,
			stipulationCompleted: request?.stipulationCompleted,
			minimumLoanAmount: request?.minimumLoanAmount,
			maximumLoanAmount: request?.maximumLoanAmount,
			createdDateFrom: request?.createdDateFrom,
			createdDateTo: request?.createdDateTo,
		});

		return response.data;
	}

	return usePaginatedQuery(['PaginatedDealerPipeline', ...Object.values(request)], PaginatedDealerPipeline, config.queryConfig);
};

usePaginatedDealerPipelineQuery.key = 'PaginatedDealerPipeline';

export default usePaginatedDealerPipelineQuery;
