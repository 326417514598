import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import FindInPage from '@material-ui/icons/FindInPage';
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';

import { FlexBox, FlexItem, Text, WizardCard, WizardCardProps } from 'sfc-kit';
import Paths from 'constants/Paths';
import { useMessages } from 'hooks';

export interface FindDealerCardProps extends Omit<WizardCardProps, 'icon' | 'title' | 'sidebarContent' | 'sidebarFooter' | 'stepperProps'> {
	activeStep: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wizardCardRoot: {
			[theme.breakpoints.up('xl')]: {
				height: 600,
			},
			[theme.breakpoints.down('md')]: {
				width: '100%',
			},
			maxWidth: 1200,
		},
		icon: {
			fontSize: '8rem',
			color: '#007F45',
		},
		linkText: {
			color: theme.palette.action.main,
			fontWeight: 'bold',
		},
	})
);

const FindDealerCard: FunctionComponent<FindDealerCardProps> = ({ classes = {}, activeStep, ...props }) => {
	const styles = useStyles({} as any);
	const messages = useMessages();

	const SubTitle = () => {
		return (
			<FlexBox justifyContent="center">
				<FlexItem xs={6}>
					<Text align="center" messages={messages} message={'findDealer.subtitle'} />
				</FlexItem>
			</FlexBox>
		);
	};
	return (
		<WizardCard
			stepperProps={{ id: 'find-dealer-stepper', numSteps: 2, activeStep }}
			icon={FindInPage}
			title="Find a Dealer/Contractor"
			sidebarContent={<SubTitle />}
			sidebarFooter={
				<Box>
					<Text align="center">
						Already have an account?{' '}
						<Link className={styles.linkText} to={Paths.Home}>
							Sign-In
						</Link>
					</Text>
				</Box>
			}
			classes={{
				...classes,
				root: classnames(styles.wizardCardRoot, classes.root),
				icon: classnames(styles.icon, classes.icon),
			}}
			{...props}
		/>
	);
};

export default FindDealerCard;
