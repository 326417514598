import React from 'react';
import { Helmet } from 'react-helmet';
import { useSfcKitMessages } from '../../hooks';

/**
 * HTML Head elements can be further leveraged if needed.
 * Limited initial changes to Title
 */
interface SFCHelmetProps {
	/**
	 * Title. String
	 */
	title?: string;
}

/**
 * https://github.com/nfl/react-helmet
 * React Helmet provides the ability to change head elements on the fly, title, meta, scripts, styles, ...
 */
export const SFCHelmet: React.FC<SFCHelmetProps> = ({ title }) => {
	const sfcKitMessages = useSfcKitMessages();
	const defaultTitle = sfcKitMessages['sfc.companyNameLLC'];

	return <Helmet defaultTitle={defaultTitle} title={title} />;
};

export default SFCHelmet;
