import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useCallback, useContext } from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import { Snackbar, CircularProgress, Theme } from '@material-ui/core';
import GlobalContext from 'context/Global';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		snackbar: {
			position: 'relative',
		},
		progress: {
			marginRight: theme.spacing(2),
		},
	})
);

const SfcLoadingSnackbar: React.FC = () => {
	const classes = useStyles({} as any);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const alertRef = useRef<string | number | null | undefined>();
	const {
		globalState: {
			loadingSnackbar: { open, message },
		},
	} = useContext(GlobalContext);

	const closeSnackbarIfOpen = useCallback((): void => {
		if (alertRef.current != null) {
			closeSnackbar(alertRef.current);
		}
	}, [closeSnackbar]);

	useEffect(() => {
		closeSnackbarIfOpen();

		if (open) {
			alertRef.current = enqueueSnackbar(message, {
				persist: true,
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				// eslint-disable-next-line react/display-name
				content: (key, message) => (
					<Snackbar
						open
						key={key}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						className={classes.snackbar}
						message={
							<span id="message-id" style={{ display: 'flex', alignItems: 'center' }}>
								<CircularProgress className={classes.progress} /> {message}...
							</span>
						}
					/>
				),
			});
		}
	}, [open, message, classes, closeSnackbarIfOpen, enqueueSnackbar]);

	return null;
};

export default SfcLoadingSnackbar;
