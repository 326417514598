import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type DealerPipelineReportResult = QueryResult<PipelineReport, AxiosError<PipelineReport>>;
export type DealerPipelineReportConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<PipelineReport, AxiosError<PipelineReport>>;
};

interface DealerPipelineReportQuery {
	(request: DealerPipelineReportRequestParameters, config?: DealerPipelineReportConfig): DealerPipelineReportResult;
	key: 'DealerPipelineReport';
}

const useDealerPipelineReportQuery: DealerPipelineReportQuery = (request, config = { axiosConfig: {} }) => {
	async function DealerPipelineReport(): Promise<PipelineReport> {
		const client = ApiCSGService();

		const path = '/api/Dealer/DealerPipelineReport';

		const response = await client.get<PipelineReport>(path, {
			currentPage: request?.currentPage,
			pageSize: request?.pageSize,
			search: request?.search,
			orderOn: request?.orderOn,
			orderBy: request?.orderBy,
		});

		return response.data;
	}

	return useQuery(['DealerPipelineReport', ...Object.values(request)], DealerPipelineReport, config.queryConfig);
};

useDealerPipelineReportQuery.key = 'DealerPipelineReport';

export default useDealerPipelineReportQuery;
