import React, { ReactNode } from 'react';

import { SnackbarProvider } from 'notistack';

export interface NotificationStackProps {
	/**
	 * A dense notification stack takes up less space by having smaller margins around the notifications.
	 */
	dense?: boolean;

	/**
	 * Specifies the maximum number of notifications to show at any given time.
	 */
	maxNotifications?: number;

	children: ReactNode;
}

/**
 * The NotificationStack component is used to display multiple notifications in an efficient and user friendly manner.
 *
 * To use it, simply wrap your application in one and utilize the `useNotificationStack` Hook to create notifications
 */
const NotificationStack: React.FC<NotificationStackProps> = ({ dense = false, maxNotifications = 2, children }) => {
	return (
		<SnackbarProvider dense={dense} maxSnack={maxNotifications}>
			<>{children}</>
		</SnackbarProvider>
	);
};

export default NotificationStack;
