import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type DealerResult = QueryResult<Dealer, AxiosError<Dealer>>;
export type DealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Dealer, AxiosError<Dealer>>;
};

interface DealerQuery {
	(request: DealerRequestParameters, config?: DealerConfig): DealerResult;
	key: 'Dealer';
}

const useDealerQuery: DealerQuery = (request, config = { axiosConfig: {} }) => {
	async function Dealer(): Promise<Dealer> {
		const client = ApiCSGService();

		const path = '/api/Dealer';

		const response = await client.get<Dealer>(path);

		return response.data;
	}

	return useQuery(['Dealer', ...Object.values(request)], Dealer, config.queryConfig);
};

useDealerQuery.key = 'Dealer';

export default useDealerQuery;
