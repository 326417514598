export default class ArrayUtility {
	public static Unique<T>(values: T[], field: keyof T): T[] {
		return values.reduce((uniqueValues: T[], current: T) => {
			const foundInUniqueValues = uniqueValues.find(x => x[field] === current[field]) != null;

			if (foundInUniqueValues) {
				return uniqueValues;
			}

			return [...uniqueValues, current];
		}, []);
	}
}
