import React, { useState, useRef } from 'react';

import HelpIcon from '@material-ui/icons/Help';
import { makeStyles, createStyles, Theme, ClickAwayListener, IconButton } from '@material-ui/core';

import SfcPopover from 'sfc-kit/src/components/SfcPopover';
import SquareSwitch from 'sfc-kit/src/components/SquareSwitch';
import { FlexBox, FlexItem, Text, LoadingBoundary } from 'sfc-kit/src/components';

import { useMessages } from 'hooks';
import { LoanWithStatement } from 'types';
import { LoanNickname } from 'components';
import colors from 'sfc-kit/src/constants/Colors';

export interface PaperlessOptinOutSwitchProps {
	onChange: (loanNumber: string, value: boolean) => void;
	checked: boolean | undefined;
	loans: Array<LoanWithStatement>;
	dialog?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formWrapper: {
			backgroundColor: '#F8F9FA',
			padding: theme.spacing(1),
		},
		multipleLoansWrapper: {
			borderTop: `1px solid ${theme.palette.grey[200]}`,
			backgroundColor: '#F8F9FA',
			padding: theme.spacing(0),
		},
		helpIcon: ({ multipleLoans, dialog }: { multipleLoans: boolean | undefined; dialog: boolean }) => ({
			'&:hover': {
				cursor: 'pointer',
			},
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(!multipleLoans && dialog ? 1 : 1),
			marginBottom: '5px',
		}),
		titlesWrapper: ({ multipleLoans }: { multipleLoans: boolean | undefined; dialog: boolean }) => ({
			paddingLeft: theme.spacing(multipleLoans ? 1 : 0),
		}),
		labelWrapper: {
			paddingLeft: theme.spacing(1),
		},
		loanWrapper: {
			'&:first-of-type': {
				borderTop: `2px solid ${theme.palette.grey[300]}`,
			},
			'&:nth-of-type(even)': {
				backgroundColor: colors.misc.white,
			},
			backgroundColor: colors.background.form,
			paddingLeft: theme.spacing(1),
		},
		allAccounts: ({ dialog }: { dialog: boolean }) => ({
			marginRight: theme.spacing(dialog ? 20 : 1),
		}),
		popper: {
			boxShadow: theme.shadows[10],
			maxWidth: 400,
			left: '50px !important',
			zIndex: 1300,
		},
		tooltip: {
			zIndex: 1300,
			padding: 0,
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const PaperlessOptinOutSwitch: React.FC<PaperlessOptinOutSwitchProps> = ({ onChange, checked, loans, dialog = false }) => {
	const multipleLoans = loans.length > 1;
	const classes = useStyles({ multipleLoans, dialog });
	const messages = useMessages();
	const helpIconRef = useRef<HTMLDivElement | null>(null);
	const [helpIconPopover, setHelpIconPopover] = useState<boolean>(false);

	return (
		<LoadingBoundary loading={checked === undefined}>
			{checked === undefined ? null : (
				<FlexBox className={classes.formWrapper} direction="column">
					<FlexBox className={classes.titlesWrapper} direction="row" alignItems="center" justifyContent="space-between">
						<FlexItem>
							<FlexBox alignItems="center">
								<Text weight="medium" color="text.primary" messages={messages} message="paperless.goPaperless" variant="body2" />
								<SfcPopover
									anchorOrigin={{ vertical: -15, horizontal: 'center' }}
									transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
									open={helpIconPopover}
									anchorEl={helpIconRef.current}
									arrowDirection="down"
									onClose={(): void => setHelpIconPopover(false)}
									width={400}
									margin={15}>
									<Text variant="body1" messages={messages} message="paperless.goingPaperless" />
								</SfcPopover>
								<IconButton
									tabIndex={1}
									disableRipple
									className={classes.focus}
									aria-label="Select for more information on going paperless"
									onClick={(): void => setHelpIconPopover(!helpIconPopover)}>
									<HelpIcon className={classes.helpIcon} innerRef={helpIconRef} color="primary" />
								</IconButton>
								{multipleLoans && (
									<ClickAwayListener
										onClickAway={(): void => {
											setHelpIconPopover(false);
										}}>
										<div>
											<Text
												messages={messages}
												message="paperless.allAccounts"
												className={classes.allAccounts}
												variant="caption"
											/>
										</div>
									</ClickAwayListener>
								)}
							</FlexBox>
						</FlexItem>
						<FlexItem>
							<SquareSwitch
								tabIndex={1}
								aria-label={`Select to turn${checked ? 'off' : 'on'} paperless for all loans `}
								checked={checked}
								onChange={(): void => onChange('Toggle All', !checked)}
							/>
						</FlexItem>
					</FlexBox>
					{multipleLoans && (
						<FlexBox className={classes.multipleLoansWrapper} direction="column">
							<FlexItem>
								<FlexBox className={classes.labelWrapper} direction="row" justifyContent="space-between">
									<Text weight="medium" variant="caption" messages={messages} message="paperless.loanName" />
									<Text weight="medium" variant="caption" messages={messages} message="paperless.paperlessActive" />
								</FlexBox>
							</FlexItem>
							<FlexItem>
								{loans?.map(({ loanNumber, enrolledInPaperless }) => {
									return (
										<FlexItem className={classes.loanWrapper} key={`Loan-${loanNumber}`}>
											<FlexBox alignItems="center" direction="row" justifyContent="space-between">
												<Text variant="subtitle2">
													<LoanNickname loanNumber={loanNumber} />
												</Text>
												<SquareSwitch
													tabIndex={1}
													aria-label={`Select to turn paperless ${
														enrolledInPaperless ? 'off' : 'on'
													} for loan ${loanNumber}`}
													checked={enrolledInPaperless}
													onChange={(): void => onChange(loanNumber, !enrolledInPaperless)}
												/>
											</FlexBox>
										</FlexItem>
									);
								})}
							</FlexItem>
						</FlexBox>
					)}
				</FlexBox>
			)}
		</LoadingBoundary>
	);
};

export default PaperlessOptinOutSwitch;
