import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { getLoans } from './thunks'

interface LoansState extends IBaseStateModel<Array<Loan>> {}

const initialState: LoansState = {
  data: [],
	error: '',
	loading: false,
	hasChanged: false,
}

export const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
	setLoansHasChanged: (state, { payload }: PayloadAction<boolean>) => {
		return {
			...state,
			hasChanged: payload,
		};
	},
  },
  extraReducers: builder => {
		builder.addCase(PURGE, () => initialState);
		builder.addCase(getLoans.pending, () => {
			return {
				...initialState,
				loading: true,
			};
		});
    builder.addCase(getLoans.fulfilled, (state, { payload }: PayloadAction<Array<Loan>>) => {
			return {
				...initialState,
				data: payload,
			};
		});
		builder.addCase(getLoans.rejected, (state, action) => {
			return {
				...initialState,
				error: action.error?.message || 'An error has occurred. (api/Loans)',
			};
		});
  }
})

//actions
export const { setLoansHasChanged } = loansSlice.actions;

//reducers
export default loansSlice.reducer;