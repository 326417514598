import { DateTime } from 'luxon';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { PaginatedQueryConfig } from 'react-query';

import { LoanContext } from 'context';
import { usePaginatedStatementsQuery } from 'sfc-kit';
import { getTokenNls } from 'utils/Auth';

export default function usePaginatedStatements(
	page = 0,
	statementsPerPage = 10,
	startDate?: DateTime,
	endDate?: DateTime,
	config?: PaginatedQueryConfig<PaginatedStatements, AxiosError<PaginatedStatements>>
): ReturnType<typeof usePaginatedStatementsQuery> {
	const nlsToken = getTokenNls();

	const { selectedLoanNumber, hasLoanSelected } = useContext(LoanContext);

	return usePaginatedStatementsQuery(
		{
			authorization: nlsToken,
			loanNumber: selectedLoanNumber,
			page,
			statementsPerPage,
			startDate: startDate?.toISO(),
			endDate: endDate?.toISO(),
		},
		{ queryConfig: { ...config, enabled: nlsToken && (config?.enabled ?? true) && hasLoanSelected } }
	);
}
