import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type StatementsResult = QueryResult<Array<Statement>, AxiosError<Array<Statement>>>;
export type StatementsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<Statement>, AxiosError<Array<Statement>>>;
};

interface StatementsQuery {
	(request: StatementsRequestParameters, config?: StatementsConfig): StatementsResult;
	key: 'Statements';
}

const useStatementsQuery: StatementsQuery = (request, config = { axiosConfig: {} }) => {
	async function Statements(): Promise<Array<Statement>> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/Statements`;

		const response = await client.get<Array<Statement>>(path);

		return response.data;
	}

	return useQuery(['Statements', ...Object.values(request)], Statements, config.queryConfig);
};

useStatementsQuery.key = 'Statements';

export default useStatementsQuery;
