import { Strengths } from '../components/StrengthMeter';

export default function GetPasswordStrength(password: string): Strengths {
	const hasDigit = /\d/.test(password) ? 1 : 0;
	const hasSpecialCharacter = /[!@#$%^*/&]/.test(password) ? 1 : 0;
	const hasLowerCase = /[a-z]/.test(password) ? 1 : 0;
	const hasUpperCase = /[A-Z]/.test(password) ? 1 : 0;

	const passedRequirements = hasDigit + hasSpecialCharacter + hasLowerCase + hasUpperCase;

	if (password.length >= 10 && passedRequirements >= 4) {
		return Strengths.Strong;
	}

	if (password.length >= 8 && passedRequirements >= 3) {
		return Strengths.Good;
	}

	if (password.length > 0) {
		return Strengths.Weak;
	}

	return Strengths.Unknown;
}
