import React from 'react';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, createStyles, InputAdornment, SvgIcon, Theme } from '@material-ui/core';

import useRefOffsetWidth from '../hooks/useRefOffsetWidth';
import { Text } from '../components';

interface FullWidthSelectProps {
	label?: string;
	value?: unknown;
	title?: string;
	onChange?: (value: unknown) => void;
	onBlur?: () => void;
	StartIcon?: typeof SvgIcon;
	tabIndex?: number;
	ariaLabel?: string;

	errorMessage?: string;
	error?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		select: {
			'& fieldset': { borderRadius: 0 },
			'& .MuiSelect-selectMenu': {
				[theme.breakpoints.down('md')]: {
					whiteSpace: 'normal !important',
				},
			},
		},
		error: {
			padding: '3px 14px 0px',
		},
	})
);

const FullWidthSelect: React.FC<FullWidthSelectProps> = ({
	label,
	value,
	onChange = (): void => undefined,
	children,
	onBlur,
	StartIcon,
	tabIndex = 0,
	title,
	ariaLabel,
	error = false,
	errorMessage,
}) => {
	const classes = useStyles({} as any);

	const [labelWidth, inputLabel] = useRefOffsetWidth<HTMLLabelElement>();

	return (
		<FormControl variant="outlined" fullWidth error={error}>
			<InputLabel ref={inputLabel} id={`${label}-label`}>
				{label}
			</InputLabel>
			<Select
				label={label}
				fullWidth
				id={label}
				title={title}
				name={label}
				labelWidth={labelWidth}
				inputProps={{ tabIndex: tabIndex ?? null, role: 'combobox', 'aria-label': `${ariaLabel ?? undefined}` }}
				labelId={`${label}-id`}
				value={value}
				startAdornment={
					StartIcon && (
						<InputAdornment position="start">
							<StartIcon color="primary" />
						</InputAdornment>
					)
				}
				onChange={(event): void => onChange(event.target.value)}
				onBlur={onBlur}
				className={classes.select}>
				{children}
			</Select>
			<Text variant="caption" color="error.main" className={classes.error}>
				{error && errorMessage ? errorMessage : '\u00A0'}
			</Text>
		</FormControl>
	);
};

export default FullWidthSelect;
