import React from 'react';
import classnames from 'classnames';

import { Dialog, DialogProps } from '..';
import { DialogTitle, Zoom, ZoomProps } from '@material-ui/core';

export interface ZoomDialogProps {
	id: string;
	open: boolean;
	title: string;
	onClose: DialogProps['onClose'];

	isIdleTimeout?: boolean;
	className?: string;
	classes?: {
		root?: string;
		title?: string;
	};
}

const ZoomTransition = React.forwardRef<unknown, ZoomProps>(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

const ZoomDialog: React.FC<ZoomDialogProps> = ({ id, open, onClose, title, className, isIdleTimeout, classes = {}, children }) => {
	const titleId = `${id}-title`;
	const descriptionId = `${id}-description`;

	return (
		<Dialog
			keepMounted
			open={open}
			onClose={onClose}
			aria-labelledby={titleId}
			aria-describedby={descriptionId}
			role={isIdleTimeout ? 'alert' : undefined}
			aria-label={isIdleTimeout ? 'Your login session is about to expire' : title}
			TransitionComponent={ZoomTransition}
			className={classnames(className, classes.root)}>
			<DialogTitle id={titleId} className={classes.title}>
				{title}
			</DialogTitle>
			{children}
		</Dialog>
	);
};

export default ZoomDialog;
