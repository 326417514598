import React, { FunctionComponent, ReactChildren, useEffect, useState } from 'react';

import { createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

import Routes from 'constants/Routes';
import Paths from 'constants/Paths';
import { ErrorBoundaryFallback } from 'components';
import { FlexBox, ErrorBoundary } from 'sfc-kit';
import generateRandomBackground from 'utils/generateRandomBackground';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			...generateRandomBackground(),
			backgroundColor: theme.palette.background.default,
			backgroundAttachment: 'fixed',
			minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		},
		shadowBox: {
			backgroundColor: 'rgba(0, 0, 0, 0.65)',
			minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		},
		mobileRoot: {
			...generateRandomBackground(),
			backgroundColor: theme.palette.background.default,
			backgroundAttachment: 'fixed',
			minHeight: '100vh',
		},
		mobileShadowBox: {
			backgroundColor: 'rgba(0, 0, 0, 0.65)',
			minHeight: '100vh',
			position: 'relative',
		},
	})
);

const PublicLayout: FunctionComponent = ({ children }) => {
	const classes = useStyles({} as any);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isMobileView, setIsMobileView] = useState<boolean>(false);
	const location = useLocation();
	const isInformationPage =
		location.pathname === Paths.Accessibility ||
		location.pathname === Paths.ConsumerPrivacyPolicy ||
		location.pathname === Paths.RightsNotice ||
		location.pathname === Paths.FAQs ||
		location.pathname === Paths.Privacy;

	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);

	const closeMenu = (): void => setIsMenuOpen(false);
	const toggleMenu = (): void => setIsMenuOpen(!isMenuOpen);

	return (
		<>
			<Sidebar open={isMenuOpen} onClose={closeMenu} mainRoutes={Routes.PublicSidebar.Main} />
			{!isMobileView && <Header onMenuClick={toggleMenu} />}
			<main className={!isMobileView ? classes.root : classes.mobileRoot}>
				{!isMobileView && !isInformationPage && <Toolbar />}
				<ErrorBoundary Fallback={ErrorBoundaryFallback}>
					<FlexBox
						alignItems="center"
						direction="column"
						justifyContent="center"
						className={!isMobileView && !isInformationPage ? classes.shadowBox : classes.mobileShadowBox}>
						{children}
					</FlexBox>
				</ErrorBoundary>
			</main>
			{!isMobileView && <Footer />}
		</>
	);
};

export interface PublicLayoutProps {
	children: ReactChildren;
}

export default PublicLayout;
