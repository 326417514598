import { DateTime } from 'luxon';
import PaymentService from 'services/PaymentService';
import { EditPaymentActionTypes, EditPaymentActions, EditPaymentStateType, editPaymentInitialState } from './EditPaymentContext';

export const editPaymentReducer = (state: EditPaymentStateType = editPaymentInitialState, action: EditPaymentActions): EditPaymentStateType => {
	switch (action.type) {
		case EditPaymentActionTypes.SelectBankAccount:
			return {
				...state,

				bankAccountInfo: action.payload,
			};

		case EditPaymentActionTypes.ClearState:
			return editPaymentInitialState;

		case EditPaymentActionTypes.ConfirmMultiplePayments:
			return {
				...state,

				confirmedMultiplePayments: true,
			};

		case EditPaymentActionTypes.SetPaymentDate:
			return {
				...state,

				paymentDate: action.payload,
			};

		case EditPaymentActionTypes.SetPaymentFrequency:
			return {
				...state,

				paymentFrequency: action.payload,
			};

		case EditPaymentActionTypes.SetPaymentAmount:
			return {
				...state,

				paymentAmount: action.payload,
			};

		case EditPaymentActionTypes.SetExtraAchPayment:
			return {
				...state,

				extraAchPayment: action.payload,
			};

		case EditPaymentActionTypes.SetIsPayoffPayment:
			return {
				...state,
				isPayoffPayment: action.payload,
			};

		case EditPaymentActionTypes.InitializeEditPayment:
			return {
				...editPaymentInitialState,
				paymentBeingEdited: action.payload,
				paymentAmount: action.payload.paymentAmount.toFixed(2),
				paymentDate: DateTime.fromISO(action.payload.paymentDate),
				paymentFrequency: PaymentService.GetPaymentFrequencyFromPayment(action.payload),
				bankAccountInfo: {
					accountHolder: '',
					routingNumber: action.payload.bankRoutingNumber,
					accountNumber: action.payload.bankAccountNumber,
					accountType: action.payload.accountType === 'Checking' ? 0 : 1,
				},
			};

		default:
			return state;
	}
};

export default editPaymentReducer;
