import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import { makeStyles, createStyles, IconProps as MaterialIconProps } from '@material-ui/core';

import { Constants, Color, ExtractColorFromKeyString } from '../..';

export interface IconProps {
	size?: MaterialIconProps['fontSize'];
	color?: Color;
	icon: FunctionComponent<{ className?: string }>;
	className?: string;
}

const fontSizeReturn = (size: MaterialIconProps['fontSize']): number | string | undefined => {
	switch (size) {
		case 'inherit':
			return size;
		case 'small':
			return Constants.IconSizes.small;
		case 'default':
			return Constants.IconSizes.default;
		case 'large':
			return Constants.IconSizes.large;
	}
};

const useStyles = makeStyles(() =>
	createStyles({
		icon: ({ size, color }: { size: MaterialIconProps['fontSize']; color: Color | undefined }) => ({
			fontSize: fontSizeReturn(size),
			color: `${color && ExtractColorFromKeyString(color)}`,
		}),
	})
);

const Icon: FunctionComponent<IconProps> = ({ size = 'default', color, icon: IconComponent, className }) => {
	const styles = useStyles({ size, color });

	return <IconComponent className={classnames(styles.icon, className)} />;
};

export default Icon;
