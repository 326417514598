import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type ACHValidateResult = QueryResult<ACHValidate, AxiosError<ACHValidate>>;
export type ACHValidateConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<ACHValidate, AxiosError<ACHValidate>>;
};

interface ACHValidateQuery {
	(request: ACHValidateRequestParameters, config?: ACHValidateConfig): ACHValidateResult;
	key: 'ACHValidate';
}

const useACHValidateQuery: ACHValidateQuery = (request, config = { axiosConfig: {} }) => {
	async function ACHValidate(): Promise<ACHValidate> {
		const api = ApiCSGService();

		const path = '/api/Payment/ACHValidate';

		const { data } = await api.get<ACHValidate>(path, {
			routingNumber: request?.routingNumber,
			accountNumber: request?.accountNumber,
			accountType: request?.accountType,
		});

		return data;
	}

	return useQuery(['ACHValidate', ...Object.values(request)], ACHValidate, config.queryConfig);
};

useACHValidateQuery.key = 'ACHValidate';

export default useACHValidateQuery;
