import { useCallback } from 'react';
import useMessages from './useMessages';
import { CustomerPortalMessages } from 'types';
import { useNotificationStack, UseNotificationStackResult, CreateNotification, CreateNotificationOptions } from 'sfc-kit';

export type CreateSpecificNotificationOptions = Omit<CreateNotificationOptions<CustomerPortalMessages>, 'variant' | 'messages'>;
export type CreateSpecificNotification = (options: CreateSpecificNotificationOptions) => ReturnType<CreateNotification>;

export interface UseCustomerPortalNotificationStackResult {
	messages: CustomerPortalMessages;
	createNotification: CreateNotification<CustomerPortalMessages>;
	createDangerNotification: CreateSpecificNotification;
	createSuccessNotification: CreateSpecificNotification;
	createInformationNotification: CreateSpecificNotification;
	closeNotification: UseNotificationStackResult['closeNotification'];
}

export default function useCustomerPortalNotificationStack(): UseCustomerPortalNotificationStackResult {
	const messages = useMessages();
	const { createNotification, closeNotification } = useNotificationStack<CustomerPortalMessages>();

	const createDangerNotification: CreateSpecificNotification = useCallback(
		options => createNotification({ messages, variant: 'Danger', ...options }),
		[createNotification, messages]
	);

	const createSuccessNotification: CreateSpecificNotification = useCallback(
		options => createNotification({ messages, variant: 'Success', ...options }),
		[createNotification, messages]
	);

	const createInformationNotification: CreateSpecificNotification = useCallback(
		options => createNotification({ messages, variant: 'Information', ...options }),
		[createNotification, messages]
	);

	return { messages, createNotification, createDangerNotification, createSuccessNotification, closeNotification, createInformationNotification };
}
