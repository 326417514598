import useLoans from 'hooks/useLoans';
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ILocationState } from 'types/LoactionState';
import { getHistorySelectedLoanNumber } from 'utils';

export interface ILoanContext {
	hasLoanSelected: boolean;
	selectedLoan: Loan | null;
	selectedLoanNumber: string;

	selectLoan: (loanNumber?: string) => void;
}

const LoanContext = createContext<ILoanContext>({
	hasLoanSelected: false,
	selectedLoan: null,
	selectedLoanNumber: '',

	selectLoan: () => undefined,
});

export const LoanProvider: React.FunctionComponent<{ initialSelectedLoan?: Loan | null }> = ({ initialSelectedLoan = null, children }) => {
	const [selectedLoan, setSelectedLoan] = useState<Loan | null>(initialSelectedLoan);

	const { state } = useLocation<ILocationState>();
	const [loanNumber, setLoanNumber] = useState('');

	useEffect(() => {
		const getSelectedLoanNumber = (loanNumber: string) => {
			if (!loanNumber) {
				const historyLoanNumber = getHistorySelectedLoanNumber(state);
				return setLoanNumber(historyLoanNumber);
			}
			setLoanNumber(loanNumber);
		};

		return getSelectedLoanNumber(selectedLoan?.loanNumber as string);
	}, [selectedLoan, state]);

	const { data: loans } = useLoans();

	const selectLoan = (loanNumber?: string) => {
		if (loanNumber === undefined || loans === undefined) {
			setSelectedLoan(null);
			return;
		}

		setSelectedLoan(loans.find(loan => loan.loanNumber === loanNumber) ?? null);
	};

	return (
		<LoanContext.Provider
			value={{
				hasLoanSelected: (selectedLoan !== null && loanNumber !== '') || sessionStorage.getItem('LoanToken') !== null,
				selectedLoan,
				selectedLoanNumber: loanNumber || sessionStorage.getItem('LoanToken') || '',

				selectLoan,
			}}>
			{children}
		</LoanContext.Provider>
	);
};

export default LoanContext;
