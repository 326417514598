import React, { useState, useEffect, useCallback, useContext } from 'react';

import { LoanWithStatement } from 'types';
import OnboardingDialog from 'components/Dialogs/OnboardingDialog';
import PaperlessOptDialog from 'components/Dialogs/PaperlessOptDialog';
import PaperlessOptinOutSwitch from 'components/Controls/PaperlessOptInOutSwitch';
import PaperlessNotVerifiedEmailDialog from 'components/Dialogs/PaperlessNotVerifiedEmailDialog';
import UASPaths from 'constants/Paths/UserAccountSettings';

import { handlePaperlessDialogContinue, handleOptInPaperless, retrieveLoanStatementOptions } from 'utils/PaperlessBilling';

import { useIsEmailVerifiedQuery, useQueryParameters } from 'sfc-kit';
import { useCustomerPortalNotificationStack } from 'hooks';
import useLoans from 'hooks/useLoans';
import History from 'utils/History';
import AuthenticationContext from 'context/Authentication';

export const retrieveLoansPreference = async (
	loans: LoanWithStatement[],
	username: string,
	setCurrentLoans: (loans: LoanWithStatement[]) => void,
	setLoansWithStatementOptions: (loans: LoanWithStatement[]) => void,
	setAllLoansOptedIn: (value: boolean) => void
): Promise<boolean> => {
	const allLoansOpted = loans && (await retrieveLoanStatementOptions(loans, username));

	if (allLoansOpted) {
		setCurrentLoans(allLoansOpted);
		setLoansWithStatementOptions(allLoansOpted);
		const allLoansOptedIn = allLoansOpted.every(({ enrolledInPaperless }) => enrolledInPaperless);
		setAllLoansOptedIn(allLoansOptedIn);
		return allLoansOptedIn;
	}

	setAllLoansOptedIn(false);
	return false;
};

const PaperlessSidebar: React.FC = () => {
	const queryParams = useQueryParameters();

	const { data: loans } = useLoans();

	const {
		authenticationState: { username: loggedInUser },
	} = useContext(AuthenticationContext);

	const { createInformationNotification, createDangerNotification } = useCustomerPortalNotificationStack();

	const [allLoansOptedIn, setAllLoansOptedIn] = useState<boolean>();
	const [paperlessPrompt, setPaperlessPrompt] = useState<boolean>(false);
	const [emailIsVerified, setEmailIsVerified] = useState<boolean>(false);
	const [onboardingPrompt, setOnboardingPromp] = useState<boolean>(false);
	const [notValidEmailPrompt, setNotValidEmailPrompt] = useState<boolean>(false);
	const [currentLoans, setCurrentLoans] = useState<Array<LoanWithStatement>>([]);
	const [loansWithStatementOptions, setLoansWithStatementOptions] = useState<Array<LoanWithStatement>>([]);

	const openModalFromUrl = queryParams.get('openModal');
	const validateEmailFromUrl = queryParams.get('validateEmail');

	useIsEmailVerifiedQuery(
		{ username: loggedInUser },
		{
			queryConfig: {
				onSuccess: setEmailIsVerified,
				onError: (): void => {
					setEmailIsVerified(false);
				},
				enabled: loggedInUser.length,
			},
		}
	);

	const displayEnrolledNotif = useCallback(
		() =>
			createInformationNotification({
				title: 'notifications.info',
				dismissible: true,
				disableTimeout: true,
				body: 'notifications.paperless.enrolled',
			}),
		[createInformationNotification]
	);

	useEffect(() => {
		if (validateEmailFromUrl) {
			setNotValidEmailPrompt(true);
		}

		if (loans?.length && loggedInUser.length) {
			try {
				retrieveLoansPreference(loans, loggedInUser, setCurrentLoans, setLoansWithStatementOptions, setAllLoansOptedIn).then(
					(allOptedIn: boolean) => {
						if (openModalFromUrl) {
							if (!allOptedIn) {
								setPaperlessPrompt(true);
							}
						}
					}
				);
			} catch {
				setPaperlessPrompt(false);
				createDangerNotification({ title: 'notifications.oops', body: 'notifications.somethingWentWrong' });
			}
		}
	}, [loans?.length, loggedInUser.length]); // eslint-disable-line

	return (
		<>
			<PaperlessNotVerifiedEmailDialog
				isOpen={notValidEmailPrompt}
				onCloseClick={(): void => setNotValidEmailPrompt(false)}
				onContinueClick={(): void => {
					setOnboardingPromp(true);
					setNotValidEmailPrompt(false);
				}}
			/>
			{!emailIsVerified && <OnboardingDialog isOpen={onboardingPrompt} onClose={(): void => setOnboardingPromp(false)} />}
			{paperlessPrompt && emailIsVerified && (
				<PaperlessOptDialog
					isOpen
					onCloseClick={(): void => {
						setPaperlessPrompt(false);
						History.push(UASPaths.Root);
					}}
					onContinueClick={async (loans, enablePaperlessFromUrl): Promise<void> => {
						setAllLoansOptedIn(loans.every(loan => loan.enrolledInPaperless));
						setLoansWithStatementOptions(loans);
						await handlePaperlessDialogContinue(loans, loggedInUser, setPaperlessPrompt, displayEnrolledNotif);
						if (enablePaperlessFromUrl) {
							History.push(UASPaths.Root);
						}
					}}
					loans={currentLoans}
					enablePaperlessFromUrl={!!openModalFromUrl}
				/>
			)}
			<PaperlessOptinOutSwitch
				checked={allLoansOptedIn}
				onChange={(loanNumber, value): void => {
					handleOptInPaperless(
						loanNumber,
						value,
						loansWithStatementOptions,
						setCurrentLoans,
						setPaperlessPrompt,
						setNotValidEmailPrompt,
						emailIsVerified
					);
					window.scrollTo(0, 0);
				}}
				loans={loansWithStatementOptions ?? []}
			/>
		</>
	);
};

export default PaperlessSidebar;
