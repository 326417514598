import classnames from 'classnames';
import React, { useState, ReactNode } from 'react';
import { type FontWeightProperty } from 'csstype';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import SfcPopper from '../SfcPopper';
import SquareButton, { ISquareButtonProps } from '../SquareButton';

export interface ISfcDropdownPopperProps {
	/** Whether the popper is open */
	open?: boolean;

	/** An event to fire when the button is clicked */
	onChange?: (open: boolean) => void;

	/** The text to be displayed in the button. */
	buttonText: string;

	/** The children to be rendered inside of the popper. */
	children: ReactNode;

	/** Whether the menu should close when the user clicks away. */
	closeOnClickAway?: boolean;

	/**
	 * The `classes` prop allows consumers to specify classes to apply to the various elements of the SfcDropdownPopper.
	 *
	 * `button` applies to the button used to toggle the popper open and closed.
	 *
	 * `popper` applies to the wrapping popper element.
	 *
	 * `popperPaper` applies to the paper element directly wrapping the `children` content.
	 */
	classes?: {
		button?: string;
		popper?: string;
		popperPaper?: string;
	};

	/** Props to be forwarded to the Button component. */
	ButtonProps?: Omit<ISquareButtonProps, 'onClick' | 'className'>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popper: {
			marginTop: theme.spacing(1),
			zIndex: 1000,
		},
		popperPaper: {
			borderRadius: 0,
			boxShadow: theme.shadows[4],
			padding: 0,
		},

		formControlRoot: {
			margin: 0,
			padding: theme.spacing(1),
		},
		formControlLabel: {
			...theme.typography.body2,
		},
		checkBox: {
			padding: theme.spacing(0, 0.25, 0, 0),
		},

		selectAllButton: {
			boxShadow: 'none',
			fontWidth: theme.typography.fontWeightMedium as FontWeightProperty,
			color: theme.palette.primary.main,
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const SfcDropdownPopper: React.FC<ISfcDropdownPopperProps> = ({
	open,
	onChange,

	children,
	buttonText,

	classes = {},
	ButtonProps = {},
	closeOnClickAway = false,
}) => {
	const styles = useStyles({} as any);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const onButtonClick: ISquareButtonProps['onClick'] = event => {
		const newAnchorEl = anchorEl !== null ? null : event.currentTarget;

		setAnchorEl(newAnchorEl);

		if (onChange !== undefined) {
			onChange(newAnchorEl !== null);
		}
	};

	const onClickAway = (): void => {
		if (closeOnClickAway === true) {
			setAnchorEl(null);

			if (onChange !== undefined) {
				onChange(false);
			}
		}
	};

	return (
		<>
			<SquareButton
				normalCase
				color="primary"
				variant="contained"
				tabIndex={1}
				endIcon={<ArrowDropDownIcon fontSize="large" />}
				onClick={onButtonClick}
				className={classnames(styles.focus, classes.button)}
				{...ButtonProps}>
				{buttonText}
			</SquareButton>
			<SfcPopper
				open={open !== false && anchorEl !== null}
				anchorEl={anchorEl}
				disablePortal
				id="order-drop-down"
				aria-live="polite"
				aria-hidden="false"
				className={classnames(styles.popper, classes.popper)}
				aria-label="select to sort by newest or oldest date"
				aria-describedBy="order-drop-down"
				classes={{ paper: classnames(styles.popperPaper, classes.popperPaper) }}>
				<ClickAwayListener onClickAway={onClickAway}>
					<div>{children}</div>
				</ClickAwayListener>
			</SfcPopper>
		</>
	);
};

export default SfcDropdownPopper;
