import React from 'react';
import classnames from 'classnames';

import { Box, Paper, Popper, PopperProps, makeStyles, Theme, createStyles } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

export interface ISfcPopperProps extends Omit<PopperProps, 'classes'> {
	arrowDirection?: 'left' | 'right' | 'up' | 'down';
	classes?: {
		root?: string;
		box?: string;
		paper?: string;
	};
}

type ISfcPopperStyleProps = Pick<ISfcPopperProps, 'arrowDirection'>;

const getHelpPopoverBoxPositionProperties = (props: ISfcPopperStyleProps): Partial<CreateCSSProperties> => {
	switch (props.arrowDirection) {
		case 'right':
			return {
				right: -10,
				top: 'calc(50% - 10px)',
			};

		case 'down':
			return {
				left: 'calc(50% - 10px)',
				bottom: -10,
			};

		case 'left':
			return {
				left: -10,
				top: 'calc(50% - 10px)',
			};

		case 'up':
		default:
			return {
				left: 'calc(50% - 10px)',
				top: -10,
			};
	}
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		helpPopperBox: (props: ISfcPopperStyleProps) => ({
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[10],
			height: 20,
			position: 'absolute',
			transform: 'rotate(45deg)',
			width: 20,
			zIndex: 1,
			...getHelpPopoverBoxPositionProperties(props),
		}),

		helpPopperPaper: {
			padding: theme.spacing(2),
			position: 'relative',
			zIndex: 2,
		},
	})
);

const SfcPopper: React.FC<ISfcPopperProps> = ({ children, arrowDirection, className, classes: externalClasses = {}, ...popperProps }) => {
	const classes = useStyles({ arrowDirection });

	return (
		<Popper {...popperProps} className={classnames(className, externalClasses.root)}>
			{arrowDirection !== undefined && <Box className={classnames(classes.helpPopperBox, externalClasses.box)} />}
			<Paper className={classnames(classes.helpPopperPaper, externalClasses.paper)} elevation={0}>
				{children}
			</Paper>
		</Popper>
	);
};

export default SfcPopper;
