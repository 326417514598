import React, { useState, useEffect, FunctionComponent } from 'react';

import TablePaginationActions from './TablePaginationActions';
import { TablePagination as MuiTablePagination, TablePaginationProps as MuiTablePaginationProps, createStyles, makeStyles } from '@material-ui/core';

export interface TablePaginationProps {
	page: number;
	count: number;
	rowsPerPage: number;
	rowsPerPageOptions?: MuiTablePaginationProps['rowsPerPageOptions'];

	onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
	onRowsPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const useStyles = makeStyles(theme =>
	createStyles({
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);
const TablePagination: FunctionComponent<TablePaginationProps> = ({
	page,
	count,
	rowsPerPage,
	rowsPerPageOptions = [],
	onPageChange,
	onRowsPerPageChange,
}) => {
	const styles = useStyles({} as any);
	const [rowPageOptions, setRowPageOptions] = useState<MuiTablePaginationProps['rowsPerPageOptions']>(rowsPerPageOptions);

	useEffect(() => {
		if (count > 10 && !rowsPerPageOptions.length) {
			if (count <= 25) {
				setRowPageOptions([
					{ value: 10, label: '10 / Page' },
					{ value: count, label: 'All' },
				]);
			} else if (count <= 50) {
				setRowPageOptions([
					{ value: 10, label: '10 / Page' },
					{ value: 25, label: '25 / Page' },
					{ value: count, label: 'All' },
				]);
			} else {
				setRowPageOptions([
					{ value: 10, label: '10 / Page' },
					{ value: 25, label: '25 / Page' },
					{ value: count, label: 'All' },
				]);
			}
		}
	}, [count, rowsPerPageOptions.length]);

	return (
		<MuiTablePagination
			component={'div'}
			page={page}
			count={count}
			rowsPerPage={rowsPerPage}
			onPageChange={onPageChange}
			rowsPerPageOptions={rowPageOptions}
			onRowsPerPageChange={onRowsPerPageChange}
			ActionsComponent={TablePaginationActions}
			SelectProps={{ inputProps: { tabIndex: 1, className: styles.focus, 'aria-label': 'rows per page' } }}
			labelDisplayedRows={({ from, to, count }): JSX.Element => (
				<>
					{from} to {to} of {count}
				</>
			)}
		/>
	);
};

export default TablePagination;
