import React, { createContext, useReducer, Dispatch, PropsWithChildren, useEffect } from 'react';
import { ActionMap } from 'types/Context';
import makeAPaymentReducer from './MakeAPaymentReducer';
import { PaymentFrequencies, PaymentAmounts } from 'types';
import { DateTime } from 'luxon';
import MakeAPaymentPaths from 'constants/Paths/MakeAPayment';
import { SaveState } from 'sfc-kit/src/utils/BrowserStorage';

export interface MakeAPaymentStateType {
	loan: Loan | null;
	confirmedMultiplePayments: boolean;
	selectedExistingBankAccount: boolean;
	bankAccountInfo?: {
		accountHolder?: string;
		routingNumber: string;
		accountNumber: string;
		accountType: number;
	};
	paymentDate: DateTime | null;
	frequency: PaymentFrequencies;
	paymentAmountInfo?: {
		paymentAmount: string;
		type: PaymentAmounts;
	};
	scheduledAchPayments: ScheduledAchPayment[];
	isPayoffPayment: boolean;
	inProgress: boolean;
	page: string;
	confirmationNumber?: string | null;
}

export const makeAPaymentInitialState: MakeAPaymentStateType = {
	loan: null,
	confirmedMultiplePayments: false,
	selectedExistingBankAccount: false,
	paymentDate: null,
	frequency: PaymentFrequencies.OneTime,
	paymentAmountInfo: {
		paymentAmount: '',
		type: PaymentAmounts.None,
	},
	isPayoffPayment: false,
	inProgress: false,
	page: MakeAPaymentPaths.BankAccountSelection,
	scheduledAchPayments: [],
	confirmationNumber: null,
};

export enum MakeAPaymentActionTypes {
	ClearState = 'CLEAR_MAKE_A_PAYMENT_STATE',
	ConfirmMultiplePayments = 'CONFIRM_MULTIPLE_PAYMENTS',
	SelectBankAccount = 'SELECT_BANK_ACCOUNT',
	SetPaymentDate = 'SET_PAYMENT_DATE',
	SetPaymentFrequency = 'SET_PAYMENT_FREQUENCY',
	SetPaymentAmount = 'SET_PAYMENT_AMOUNT',
	InitiateWorkflow = 'INITIATE_MAKE_A_PAYMENT_WORKFLOW',
	UpdatePage = 'UPDATE_MAKE_A_PAYMENT_PAGE',
	SetIsPayoffPayment = 'SET_IS_PAYOFF_PAYMENT',
	LoadState = 'LOAD_STATE',
	SetScheduledAchPayments = 'SET_SCHEDULED_ACH_PAYMENT',
	SetConfirmationNumber = 'SET_CONFIRMATION_NUMBER',
}

interface SelectBankAccountPayload {
	bankAccountInfo: MakeAPaymentStateType['bankAccountInfo'];
	selectedExistingBankAccount?: MakeAPaymentStateType['selectedExistingBankAccount'];
}

type MakeAPaymentPayload = {
	[MakeAPaymentActionTypes.ClearState]: undefined;
	[MakeAPaymentActionTypes.ConfirmMultiplePayments]: undefined;
	[MakeAPaymentActionTypes.SelectBankAccount]: SelectBankAccountPayload | undefined;
	[MakeAPaymentActionTypes.SetPaymentDate]: MakeAPaymentStateType['paymentDate'];
	[MakeAPaymentActionTypes.SetPaymentFrequency]: MakeAPaymentStateType['frequency'];
	[MakeAPaymentActionTypes.SetPaymentAmount]: MakeAPaymentStateType['paymentAmountInfo'];
	[MakeAPaymentActionTypes.InitiateWorkflow]: MakeAPaymentStateType['loan'];
	[MakeAPaymentActionTypes.UpdatePage]: MakeAPaymentStateType['page'];
	[MakeAPaymentActionTypes.SetScheduledAchPayments]: MakeAPaymentStateType['scheduledAchPayments'];
	[MakeAPaymentActionTypes.SetIsPayoffPayment]: MakeAPaymentStateType['isPayoffPayment'];
	[MakeAPaymentActionTypes.LoadState]: undefined;
	[MakeAPaymentActionTypes.SetConfirmationNumber]: MakeAPaymentStateType['confirmationNumber'];
};

export type MakeAPaymentActions = ActionMap<MakeAPaymentPayload>[keyof ActionMap<MakeAPaymentPayload>];

export type MakeAPaymentDispatchType = Dispatch<MakeAPaymentActions>;

export const MakeAPaymentContext = createContext<{
	makeAPaymentState: MakeAPaymentStateType;
	dispatchForMakeAPayment: MakeAPaymentDispatchType;
}>({
	makeAPaymentState: makeAPaymentInitialState,
	dispatchForMakeAPayment: () => null,
});

export function MakeAPaymentProvider({
	initialState = makeAPaymentInitialState,
	children,
}: PropsWithChildren<{ initialState?: Partial<MakeAPaymentStateType> }>): React.ReactElement {
	const [state, dispatch] = useReducer(makeAPaymentReducer, { ...makeAPaymentInitialState, ...initialState });

	useEffect(() => {
		dispatch({ type: MakeAPaymentActionTypes.LoadState });
	}, []);

	useEffect(() => {
		const { paymentDate, ...restState } = state;

		SaveState('MakeAPayment', { paymentDate: paymentDate?.toISO(), ...restState });
	}, [state]);

	return (
		<MakeAPaymentContext.Provider value={{ makeAPaymentState: state, dispatchForMakeAPayment: dispatch }}>
			{children}
		</MakeAPaymentContext.Provider>
	);
}

export default MakeAPaymentContext;
