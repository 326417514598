import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { getBorrowerContact } from './thunks'

interface BorrowerContacState extends IBaseStateModel<ContactDto> {}

const initialState: BorrowerContacState = {
  	data: {},
	error: '',
	loading: false,
	hasChanged: false,
}

export const borrowerContactSlice = createSlice({
  name: 'borrowerContact',
  initialState,
  reducers: {
	setBorrowerContactHasChanged: (state, { payload }: PayloadAction<boolean>) => {
		return {
			...state,
			hasChanged: payload,
		};
	},
  },
  extraReducers: builder => {
	builder.addCase(PURGE, () => initialState);
	builder.addCase(getBorrowerContact.pending, () => {
		return {
			...initialState,
			loading: true,
		};
	});
    builder.addCase(getBorrowerContact.fulfilled, (state, { payload }: PayloadAction<ContactDto>) => {
			return {
				...initialState,
				data: payload,
			};
		});
	builder.addCase(getBorrowerContact.rejected, (state, action) => {
		return {
			...initialState,
			error: action.error?.message || 'An error has occurred. (Borrower/BorrowerContact)',
		};
	});
  }
})

//actions
export const { setBorrowerContactHasChanged } = borrowerContactSlice.actions;

//reducers
export default borrowerContactSlice.reducer;