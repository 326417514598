import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core';
import { Text, Validator, useField } from 'sfc-kit';

import SquareButton from 'sfc-kit/src/components/SquareButton';
import SfcTextField from 'sfc-kit/src/components/SfcForm/SfcTextField';
import SfcAlert from 'sfc-kit/src/components/SfcAlert';
import PdfViewer from 'sfc-kit/src/components/PdfViewer';

import { W9Passcode } from 'constants/FAQ';
import colors from 'sfc-kit/src/constants/Colors';
import W9PasscodeSchema from '../../schemas/W9Passcode.Schema';

const useStyles = makeStyles(() =>
	createStyles({
		pdf: {
			position: 'relative',
		},
		textInput: {
			display: 'block',
			width: '40%',
			minWidth: '250px',
			maxWidth: '300px',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid blue`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const W9: React.FC = () => {
	// const [passcode, setPasscode] = React.useState('');
	const [passcode, setPasscode, passcodeValidation] = useField('', Validator.FromYupSchema(W9PasscodeSchema.fields.passcode));

	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [submitted, setSubmitted] = React.useState(false);
	const [passcodeValid, setPasscodeValid] = React.useState(false);
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const styles = useStyles();
	const theme = useTheme();

	useEffect(() => {
		if (!passcode) {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [passcode]);

	useEffect(() => {
		if (passcodeValid) {
			setTimeout(() => {
				wrapperRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'center',
				});
			}, theme.transitions.duration.standard);
		}
	}, [passcodeValid, theme.transitions.duration.standard]);

	// Allow the user to try again if passcode is invalid
	const handleRetry = () => {
		if (!passcodeValid) {
			setPasscodeValid(true);
			setButtonDisabled(false);
			setSubmitted(false);
		}
	};

	const handleOnSubmit = () => {
		setSubmitted(true);

		if (passcode === W9Passcode) {
			passcodeValidation.ValidateSync();
			setPasscodeValid(true);
			setPasscode('');
			setButtonDisabled(true);
		} else {
			setPasscodeValid(false);
			setButtonDisabled(true);
		}
	};

	return (
		<div ref={wrapperRef}>
			<SfcAlert open={submitted && !passcodeValid} type={'error'} positioned variant={'standard'} alertDescribedBy={'invalid-passcode-error'}>
				<Text id={'invalid-passcode-error'}>The passcode you entered is invalid. Please try again.</Text>
			</SfcAlert>
			<form
				onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
					event.preventDefault();
					handleOnSubmit();
				}}>
				<SfcTextField
					id="passcode"
					label="Please enter the passcode:"
					value={passcode}
					onBlur={(): boolean => !submitted && passcodeValidation.ValidateSync()}
					onChange={(value): void => {
						setPasscode(value);
						handleRetry();
					}}
					placeholder="Passcode"
					className={styles.textInput}
					error={passcodeValidation.Invalid}
					helperText={passcodeValidation.ReasonForInvalidity}
					inputProps={{ 'aria-label': 'W9 Passcode' }}
				/>

				<SquareButton
					variant="contained"
					color="primary"
					onClick={handleOnSubmit}
					className={styles.focus}
					disabled={buttonDisabled}
					aria-label={'Click to bring up the W9 form'}>
					Submit
				</SquareButton>

				{submitted && passcodeValid && <Text>Thanks for submitting the passcode {passcode}</Text>}
			</form>

			{submitted && passcodeValid && (
				<PdfViewer
					document={{ source: '/Service Finance Company, LLC W9-2023.pdf', title: 'W9' }}
					iconColor={colors.action.main}
					classes={{ body: styles.pdf }}
					disableFullscreen
				/>
			)}
		</div>
	);
};

export default W9;
