import React, { FunctionComponent } from 'react';

import { TableBody as MuiTableBody } from '@material-ui/core';

export interface TableBodyProps {
	children: React.ReactNode;
}

const TableBody: FunctionComponent<TableBodyProps> = ({ children }) => {
	return <MuiTableBody>{children}</MuiTableBody>;
};

export default TableBody;
