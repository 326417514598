import loginLang from 'lang/Login';
import { DateTime } from 'luxon';
import { password as passwordLang, username as usernameLang } from 'sfc-kit/src/lang/User';
import { LoadState, SaveState } from 'sfc-kit/src/utils/BrowserStorage';
import * as Yup from 'yup';
import { LoginActionTypes, LoginActions, LoginInitialStateType, loginInitialState } from './LoginContext';

const MAX_LOGIN_ATTEMPTS = 3;

const getLockoutErrorTitle = (attempts: number): string => {
	switch (attempts) {
		case 0:
			return '';
		case 1:
			return '';
		default:
			return loginLang.invalidUsernameOrPassword;
	}
};

export const getLockoutErrorMessage = (attempts: number): string => {
	switch (attempts) {
		case 0:
			return '';
		case 1:
			return loginLang.invalidUsernameOrPassword;
		case 2:
			return loginLang.secondFailedLogin;
		default:
			return loginLang.lockedOut;
	}
};

const incrementLoginAttempts = (attempts: number, state: LoginInitialStateType): LoginInitialStateType => {
	return {
		...state,
		loginAttempts: attempts,
		lockedOutUntil: attempts >= MAX_LOGIN_ATTEMPTS ? DateTime.local().plus({ days: 1 }) : undefined,
		loginStatus: {
			wasSuccessful: false,
			errorTitle: getLockoutErrorTitle(attempts),
			errorMessage: getLockoutErrorMessage(attempts),
		},
	};
};

export const clearNonPersistantFields = (state: LoginInitialStateType = loginInitialState): LoginInitialStateType => ({
	...loginInitialState,
	lockedOutUntil: state.lockedOutUntil,
	loginAttempts: state.loginAttempts,
	loginStatus: {
		wasSuccessful: state.loginAttempts >= 2 ? false : undefined,
		errorTitle: state.loginAttempts >= 2 ? getLockoutErrorTitle(state.loginAttempts) : undefined,
		errorMessage: state.loginAttempts >= 2 ? getLockoutErrorMessage(state.loginAttempts) : undefined,
	},
	...(state.rememberMe && { rememberMe: state.rememberMe, username: state.username, usernameStatus: { isValid: true } }),
});

const resetLoginAttempts = (state: LoginInitialStateType): LoginInitialStateType => {
	const newLoginState = {
		...state,
		loginAttempts: loginInitialState.loginAttempts,
		lockedOutUntil: loginInitialState.lockedOutUntil,
		loginStatus: loginInitialState.loginStatus,
	};

	SaveState('Login', newLoginState);

	return newLoginState;
};

export const loginReducer = (state: LoginInitialStateType, action: LoginActions): LoginInitialStateType => {
	switch (action.type) {
		case LoginActionTypes.UpdateLoginUsername:
			try {
				Yup.string()
					.required(usernameLang.usernameIsRequired)
					.validateSync(action.payload);
				return {
					...state,
					username: action.payload,
					usernameStatus: { isValid: true },
				};
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					// Handle Yup validation errors specifically
					return {
						...state,
						username: action.payload,
						usernameStatus: { isValid: false, errorMessage: error.errors[0] },
					};
				}
				// Handle other types of errors
				return {
					...state,
					username: action.payload,
					usernameStatus: { isValid: false, errorMessage: 'Unexpected error:' },
				};
			}

		case LoginActionTypes.UpdateLoginPassword:
			try {
				Yup.string()
					.required(passwordLang.passwordRequired)
					.validateSync(action.payload);
				return {
					...state,
					password: action.payload,
					passwordStatus: { isValid: true },
				};
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					// Handle Yup validation errors specifically
					return {
						...state,
						password: action.payload,
						passwordStatus: { isValid: false, errorMessage: error.errors[0] },
					};
				}
				// Handle other types of errors
				return {
					...state,
					password: action.payload,
					passwordStatus: { isValid: false, errorMessage: 'Unexpected error:' },
				};
			}

		case LoginActionTypes.ResetLoginAttempts:
			return resetLoginAttempts(state);

		case LoginActionTypes.IncrementLoginAttempts:
			return incrementLoginAttempts(state.loginAttempts + 1, state);

		case LoginActionTypes.LockedOut:
			return incrementLoginAttempts(MAX_LOGIN_ATTEMPTS, state);

		case LoginActionTypes.UpdateRememberMe:
			return {
				...state,
				rememberMe: action.payload,
			};

		case LoginActionTypes.LoadState:
			return clearNonPersistantFields(LoadState('Login'));

		default:
			return state;
	}
};

export default loginReducer;
