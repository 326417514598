import React from 'react';
import { Text, NumberUtility } from 'sfc-kit';
import { useMessages } from 'hooks';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

interface FirstParagrahProps {
	pastDue: boolean;
	totalPastDue: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			marginRight: theme.spacing(0.5),
		},
	})
);

const PaymentInfoFirstParagraph: React.FC<FirstParagrahProps> = ({ pastDue, totalPastDue }) => {
	const messages = useMessages();
	const classes = useStyles({} as any);

	if (pastDue) {
		return (
			<>
				<Text inline messages={messages} message="payments.pastDue" className={classes.text}>
					<Text inline weight="medium">{`$${NumberUtility.formatCurrency(parseFloat(totalPastDue))}`}</Text>
				</Text>
			</>
		);
	}

	return <Text messages={messages} message="payments.completedPayment" />;
};
export default PaymentInfoFirstParagraph;
