import { Redirect } from 'react-router';
import React, { useContext } from 'react';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import { LangText, LoadingBoundary, useGetBankHistoryQuery } from 'sfc-kit';
import RadioListGroup from 'sfc-kit/src/components/RadioListGroup';

import { EditPaymentCard } from 'components';
import LoanService from 'services/LoanService';
import EditPaymentPaths from 'constants/Paths/EditPayment';
import BankAccountSelectionService from 'services/BankAccountSelectionService';
import EditPaymentContext, { EditPaymentActionTypes } from 'context/EditPayment';
import { LoanContext } from 'context';

interface IEditSelectedBankAccountForm {
	children?: React.ReactNode;
	disabledAccounts?: Array<LangText>;
}

const EditSelectedBankAccountForm: React.FC<IEditSelectedBankAccountForm> = ({ children, disabledAccounts = [] }) => {
	const {
		editPaymentState: { bankAccountInfo, extraAchPayment },
		dispatchForEditPayment,
	} = useContext(EditPaymentContext);

	const [redirectTo, setRedirectTo] = React.useState<string>();
	const [accountNumber, setAccountNumber] = React.useState<string>(bankAccountInfo.accountNumber);

	React.useEffect(() => {
		setAccountNumber(bankAccountInfo.accountNumber);
	}, [bankAccountInfo.accountNumber]);

	const { selectedLoanNumber } = useContext(LoanContext);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [bankHistory, setBankHistory] = React.useState<LoanAccountAchDto[] | any>([]);
	const [scheduledAchPayments, setScheduledAchPayments] = React.useState<ScheduledAchPayment[]>([]);
	const payments: ScheduledAchPayment[] =
		(extraAchPayment === undefined ? scheduledAchPayments : scheduledAchPayments?.concat(extraAchPayment)) ?? [];

	React.useEffect(() => {
		if (bankHistory.length) {
			const newArr: ScheduledAchPayment[] = [];
			bankHistory.forEach((bank: LoanAccountAchDto) => {
				newArr.push({
					accountType: bank.accountType,
					bankRoutingNumber: bank.abaNumber,
					bankAccountNumber: bank.accountNumber,
					billingType: bank.billingType,
					paymentType: bank.billingType,
				} as ScheduledAchPayment);
			});
			setScheduledAchPayments(newArr);
		}
	}, [bankHistory]);

	const { isFetching } = useGetBankHistoryQuery(
		{ loanNumber: selectedLoanNumber },
		{
			queryConfig: {
				enabled: selectedLoanNumber,
				refetchOnWindowFocus: false,
				onSuccess: setBankHistory,
			},
		}
	);

	if (redirectTo !== undefined) {
		return <Redirect push to={redirectTo} />;
	}

	const items = [
		...BankAccountSelectionService.GetRadioListGroupItemsFromPriorPayments(payments),
		{ label: 'Add New Account', value: '' as LangText },
	];

	const onBankAccountChange = (accountNumber: string): void => {
		if (accountNumber === '') {
			setRedirectTo(EditPaymentPaths.AddBankAccountEntry);
			return;
		}

		setAccountNumber(accountNumber);

		const selectedAchPayment = LoanService.GetScheduledAchPaymentByAccountNumber(payments, accountNumber);

		if (selectedAchPayment === undefined) {
			setRedirectTo(EditPaymentPaths.AddBankAccountEntry);
			return;
		}

		dispatchForEditPayment({
			type: EditPaymentActionTypes.SelectBankAccount,
			payload: {
				routingNumber: selectedAchPayment.bankRoutingNumber,
				accountNumber: selectedAchPayment.bankAccountNumber,
				accountType: selectedAchPayment.accountType === 'Checking' ? 0 : 1,
			},
		});
	};

	return (
		<EditPaymentCard title="Select a Bank Account" Avatar={AccountBalanceIcon}>
			{children}
			<LoadingBoundary loading={isFetching}>
				<RadioListGroup
					name="bank-account-select"
					disabledItems={disabledAccounts}
					value={accountNumber}
					onChange={onBankAccountChange}
					items={items}
				/>
			</LoadingBoundary>
		</EditPaymentCard>
	);
};

export default EditSelectedBankAccountForm;
