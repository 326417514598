const regex = {
	ssn: /\d{3}[-]\d{2}[-]\d{4}$/,
	numeric: /^[0-9]*$/,
	validPhone: /^(1\s?)?((\([2-9][0-9][0-9]\))|[2-9][0-9][0-9])[\s-]?([\0-9]{3})[\s-]?[0-9]{4}$/,
	validPhoneInputKey: /^[0-9\s-()]*$/,
	anyNumber: /[0-9]/g,
	whitespaceDashesParenthesis: /[-()\s]/g,
	//Check email regex later
	//Source: https://stackoverflow.com/a/719543
	validEmail: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,

	//Source: https://stackoverflow.com/questions/37956584/characters-and-symbols-allowed-in-shipping-and-billing-address
	city: /[a-zA-Z \-,.;'&/()]/g,
	address: /[a-zA-Z0-9#.,;:'° ]/g,
	postalCode: /[a-zA-Z 0-9]/g,
};

export default regex;
