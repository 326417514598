import React, { FunctionComponent, useEffect, useContext, useState } from 'react';
import Paths from 'constants/Paths';
import { Link } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import { Spacing, Text, Button, useQueryParameters } from 'sfc-kit';
import { useMessages, useCustomerPortalNotificationStack } from 'hooks';
import * as BrowserStorage from 'sfc-kit/src/utils/BrowserStorage';
import LocalStorageKeys from 'constants/LocalStorageKeys';
import AuthenticationContext, { AuthenticationActionTypes } from 'context/Authentication';
import { makeStyles, createStyles } from '@material-ui/core';

interface VerificationCompleteProps {
	onClose: () => void;
}

const useStyles = makeStyles(theme =>
	createStyles({
		contentWrapper: {
			overflowWrap: 'anywhere',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const VerificationComplete: FunctionComponent<VerificationCompleteProps> = ({ onClose }) => {
	const messages = useMessages();
	const classes = useStyles({} as any);
	const { createSuccessNotification } = useCustomerPortalNotificationStack();
	const queryParams = useQueryParameters();
	const newVerifiedEmail = queryParams.get('username');
	const { dispatchForAuthentication } = useContext(AuthenticationContext);
	const [isMobileView, setIsMobileView] = useState<boolean>(false);

	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);

	useEffect(() => {
		if (newVerifiedEmail) {
			BrowserStorage.RemoveItem(LocalStorageKeys.LastVerifyEmailPromptDate);
			BrowserStorage.RemoveItem(LocalStorageKeys.OnboardingNumberOfAttempts);
			createSuccessNotification({ title: 'notifications.emailVerified', body: 'notifications.changeUsername.verified' });
			dispatchForAuthentication({ type: AuthenticationActionTypes.UpdateUsername, payload: newVerifiedEmail });
		}
	}, [newVerifiedEmail, createSuccessNotification, dispatchForAuthentication]);

	return (
		<div className={classes.contentWrapper}>
			<Spacing pb="large">
				<MailIcon style={{ fontSize: '3.5em' }} color="primary" />
			</Spacing>
			<Spacing pb="large">
				<Text weight="medium" messages={messages} message="user.verification.accountVerified" />
			</Spacing>
			<Spacing pb="large">
				<Text messages={messages} message="onboarding.usernameWillBeEmail" inline />
				<Text inline weight="medium">{` ${newVerifiedEmail}`}</Text>
				{'. '}
				<Text messages={messages} message="onboarding.haveAnyQuestion" inline />{' '}
				{!isMobileView ? (
					<Link to={Paths.Contact} tabIndex={1}>
						<Text weight="medium" color="action" messages={messages} message="onboarding.supportTeam" inline />
					</Link>
				) : (
					<Text weight="medium" color="action" messages={messages} message="onboarding.supportTeam" inline />
				)}{' '}
				<Text messages={messages} message="onboarding.furtherAssistance" inline />
			</Spacing>
			<Button
				casing="capitalizeEachWord"
				messages={messages}
				className={classes.focus}
				message="misc.close"
				tabIndex={1}
				fullWidth
				onClick={onClose}
			/>
		</div>
	);
};

export default VerificationComplete;
