const MakeAPaymentRoot = '/MakeAPayment';

const MakeAPayment = {
	Root: MakeAPaymentRoot,
	BankAccountSelection: `${MakeAPaymentRoot}/SelectBankAccount`,
	BankAccountEntry: `${MakeAPaymentRoot}/BankAccount`,
	PaymentAmount: `${MakeAPaymentRoot}/PaymentAmount`,
	PaymentDate: `${MakeAPaymentRoot}/SchedulePayment`,
	Confirmation: `${MakeAPaymentRoot}/ConfirmPayment`,
	PaymentConfirmed: `${MakeAPaymentRoot}/PaymentConfirmed`,
	PaymentConfirmedError: `${MakeAPaymentRoot}/PaymentError`,
	PrincipalReductionDisclaimer: `${MakeAPaymentRoot}/PrincipalDisclaimer`,
};

export default MakeAPayment;
