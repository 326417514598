import * as Yup from 'yup';
import { LangText } from 'sfc-kit';
import { CustomerPortalMessages } from 'types';

export enum ContactUsSubject {
	AccountInquiry = 0,
	PasswordReset = 1,
	WebsiteAccess = 2,
	RequestingPaperless = 3,
	SfcCreditInquiry = 4,
	DisputeLatePayments = 5,
	FindDealerContractor = 6,
}

export const ContactUsLocationState = {
	FindDealerContractor: 'findDealerContractor',
};

export const Subjects = {
	SelectSubject: 'contactUs.selectASubject' as keyof CustomerPortalMessages,
	AccountInquiry: 'contactUs.accountInquiry' as keyof CustomerPortalMessages,
	ChangeDueDate: 'contactUs.changeDueDate' as keyof CustomerPortalMessages,
	CopyOfContract: 'contactUs.copyOfContract' as keyof CustomerPortalMessages,
	DisputeLatePayments: 'contactUs.disputeLatePayments' as keyof CustomerPortalMessages,
	FindDealerContractor: 'contactUs.findDealerContractor' as keyof CustomerPortalMessages,
	RequestingPaperless: 'contactUs.requestingPaperless' as keyof CustomerPortalMessages,
	PasswordResetOrWebsiteAccess: 'contactUs.passwordResetOrWebsiteAccess' as keyof CustomerPortalMessages,
	SfcCreditInquiry: 'contactUs.sfcCreditInquiry' as keyof CustomerPortalMessages,
};

export const FullNameSchema = Yup.string().required('user.name.fullNameRequired');

export const EmailSchema = Yup.string()
	.email('email.verification.mustBeValidEmail' as LangText)
	.required('email.verification.emailRequired' as LangText);

export const LoanNumberSchema = Yup.string();

export const PhoneSchema = Yup.string()
	.length(14, 'user.phone.phoneLength' as LangText)
	.required('user.phone.phoneRequired' as LangText);

export const SubjectSchema = Yup.string()
	.required('email.verification.subjectRequired' as LangText)
	.notOneOf(['none'], 'email.verification.subjectRequired' as LangText);

export const CommentsSchema = Yup.string().required('email.verification.commentsRequired' as LangText);
