export const securityQuestions = {
	changeSecurityQuestionsSuccessAlertTitle: 'Security Questions Updated',
	changeSecurityQuestionsSuccessAlertMessage: 'Your security questions have been updated successfully',
	updateSecurityQuestions: 'Update Your Security Questions',
	securityQuestionsTip:
		"You can change a security question, or the answer to a question you've already selected, by modifying any of the fields listed below.",
	firstSecurityQuestion: 'Security Question 1',
	secondSecurityQuestion: 'Security Question 2',
	invalidSecurityQuestion: 'Invalid Security Question',
	invalidSecurityQuestionAnswer: 'Invalid Security Question Answer',
	securityQuestionRequired: 'Security Question is Required',
	answerRequired: 'Answer is Required',
	firstSecurityAnswer: 'Answer One',
	secondSecurityAnswer: 'Answer Two',
	securityAnswerWrong: 'One or more of your security question answers are incorrect. Please check your response and try again.',
	answerSecurityQuestions: 'Answer Security Questions',
	securityQuestionsInvalid: 'The security questions must be valid and unique',
	securityQuestionAnswersInvalid: 'The security question answers must be valid and unique',
	answerMatchesPassword: 'The answer must not match your password',
};

export const profile = {
	profileSaveNameFailureAlertTitle: 'Saving Failed',
	profileSaveNameFailureAlertMessage: 'Failed to save your Username',
};

export const name = {
	firstName: 'first name',
	middleName: 'middle name',
	lastName: 'last name',
	fullName: 'full name',
	name: 'name',
	firstNameRequired: 'First name is required.',
	lastNameRequired: 'Last name is required.',
	fullNameRequired: 'Full Name is required',
};

export const phone = {
	phone: 'Phone',
	phoneNumber: 'Phone Number',
	changePhoneNumberSuccessAlertTitle: 'Phone Number Changed',
	changePhoneNumberSuccessAlertMessage: 'The changes to your phone number have been saved.',
	changePhoneNumberFailureAlertTitle: 'Phone Number Unchanged',
	changePhoneNumberFailureAlertMessage: 'The changes to your phone number could not be saved. Please try again.',
	phoneRequired: 'Phone Number is required',
	phoneInvalid: 'Phone Number is invalid',
	phoneLength: 'Phone must be 14 characters long',
	phoneValidation: 'Phone number must be of the form (555) 555 5555.',
};

export const address = {
	changeAddressSuccessAlertTitle: 'Address Changed',
	changeAddressSuccessAlertMessage: 'The changes to your address have been saved.',
	changeAddressFailureAlertTitle: 'Address Unchanged',
	changeAddressFailureAlertMessage: 'The changes to your address could not be saved. Please try again.',
	addressRequired: 'Street Address is required',
	cityRequired: 'City is required',
	postalCodeRequired: 'Postal Code is required',
	postalCodeMin: 'Postal Code must be 5 numbers',
	stateRequired: 'State is required',
	residentialAddress: 'residential address',
};

export const username = {
	username: 'username',
	changeUserIDSuccessAlertTitle: 'Username Changed',
	updateUsername: 'Update Username',
	updateYourUsername: 'Update Your Username',
	changeUserIDSuccessAlertMessage: 'The changes to your Username have been saved.',
	changeUsernameFailedAuthenticateTitle: 'Username Unchanged',
	changeUsernameFailedAuthenticateMessage: 'The changes to your Username could not be saved. Please try again.',
	changeUsernameInvalidCredentialsTitle: 'Incorrect Password',
	changeUsernameInvalidCredentialsMessage: 'The current password you have entered is incorrect',
	completeUpdateUsernameByVerifying: 'Complete the update username process by verifying your email',
	usernameIsRequired: 'Username is Required',
	invalidUsername: 'The username entered must be a valid email address',
	currentPasswordRequired: "Your account's current password is required to update your username",
	enterCurrentUsername: 'Enter your current password to change your username. The new username must be a valid email address.',
	emailVerificationRequired: 'You will be required to verify your email address to complete the process.',
	forgotUsernameTip: 'Enter the following information to retrieve your username.',
	enterUsername: 'Enter Username',
	enterUsernameStartReset: 'Enter your username to start the password reset process',
};

export const ssn = {
	SSN: 'SSN',
	socialSecurityNumber: 'social security number',
	lastFourSSN: 'Last 4 of SSN',
};

export const loan = {
	loanNumber: 'Loan Number',
	loanNumberRequired: 'Loan Number is required',
};

export const password = {
	password: 'password',
	passwordRequired: 'Password is required',
	changeUserPasswordSuccessAlertTitle: 'Password Updated',
	changeUserPasswordSuccessAlertMessage: 'Your password has been updated successfully',
	changeUserPasswordFailureAlertTitle: 'Oops!',
	changeUserPasswordFailureAlertMessage: 'Something went wrong when trying to change your password.',
	passwordRecoveryFailureAlertMessage: 'One or more of your security question answers are incorrect. Please check your response and try again.',
	passwordRecoveryLockedOutAlertMessage:
		'It appears you are having trouble recovering your account information. Please contact the support team for further assistance',
	passwordStrength: 'Your password strength must be Good or Strong to successfully save the changes.',
	passwordReset: 'Your password has been reset. You can now Sign-In using your new password.',
	passwordResetEmailVerified:
		'Your password has been reset and your email has been verified. You can now Sign-In using your new password and the email you verified as your new username.',
	invalidNewPassword: 'The new password cannot be the same as the current password',
	invalidNewPasswordTitle: 'Invalid New Password',
	passwordMismatch: 'The confirm new password must match the new password',
	passwordMismatchTitle: 'Password Mismatch',
	currentPasswordIncorrect: 'The current password you have entered is incorrect',
	incorrectPassword: 'Incorrect Password',
	tryCurrentPasswordAgain: 'Please enter the correct current password for this account and try again.',
	updateYourPassword: 'Update Your Password',
	newPassword: 'New Password',
	currentPassword: 'Current Password',
	confirmPassword: 'Confirm Password',
	confirmNewPassword: 'Confirm New Password',
	createNewPassword: 'Create New Password',
	verifyEmailAddress: 'Verify Email Address',
	resendEmail: 'Resend Email',
	passwordUpdatedTitle: 'Password Updated',
	passwordUpdatedDescription: 'Your password has been updated successfully',
	changePasswordError: 'Something went wrong when trying to change your password.',
	passwordRecoveryError: 'One or more of your security question answers are incorrect. Please check your response and try again.',
	confirmPasswordRequired: 'Confirm Password is Required',
	newPasswordRequired: 'New Password is Required',
	currentPasswordRequired: 'Current Password is Required',
};

export const settings = {
	userAccountSettings: 'User Account Settings',
};

export const verification = {
	accountVerified: 'Thank you for verifying your account!',
	unableToValidate:
		'We are unable to validate the provided information at this time, please try again later. If this error continues, contact support.',
};

export const account = {
	alreadyHaveAccount: 'Already have an account?',
	accountUnlocked: 'Your account has been unlocked, please Sign-In.',
	registerNow: 'Register now!',
	registrationComplete: 'Registration Complete',
	accountCreated: 'Your account has been successfully created.',
	signInWithEmail: 'Please Sign-In using the email you verified during registration.',
	setupDate: 'Setup Date',
};

export const errors = {
	issueCreatingCredentials: 'There was an issue creating your credentials. Please contact support for further assistance. (866) 254-0497 x4',
};

export const deactivate = {
	deactivateDate: 'Deactivate Date',
};
