import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type ViewStipulationDocumentResult = QueryResult<ViewStipulationDocument, AxiosError<ViewStipulationDocument>>;
export type ViewStipulationDocumentConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<ViewStipulationDocument, AxiosError<ViewStipulationDocument>>;
};

interface ViewStipulationDocumentQuery {
	(request: ViewStipulationDocumentRequestParameters, config?: ViewStipulationDocumentConfig): ViewStipulationDocumentResult;
	key: 'ViewStipulationDocument';
}

const useViewStipulationDocumentQuery: ViewStipulationDocumentQuery = (request, config = { axiosConfig: {} }) => {
	async function ViewStipulationDocument(): Promise<ViewStipulationDocument> {
		const client = ApiCSGService();

		const path = '/api/Stipulations/ViewStipulationDocument';

		const response = await client.get<ViewStipulationDocument>(
			path,
			{
				taskId: request?.taskId,
				loanNumber: request?.loanNumber,
				categoryId: request?.categoryId,
			}
		);

		return response.data;
	}

	return useQuery(['ViewStipulationDocument', ...Object.values(request)], ViewStipulationDocument, config.queryConfig);
};

useViewStipulationDocumentQuery.key = 'ViewStipulationDocument';

export default useViewStipulationDocumentQuery;
