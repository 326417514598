import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenNls } from 'utils/Auth';
import commonServicesGatewayApi from 'utils/CommonServicesGatewayAPI';

export const getBorrowerContact = createAsyncThunk('/api/Borrower/GetBorrowerContact/GET', async () => {
	const token = getTokenNls();

	const { data } = await commonServicesGatewayApi.GetBorrowerContact({ authorization: token });

	return data;
});
