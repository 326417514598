import React from 'react';
import { FlexBox, Spacing, Text } from 'sfc-kit';
import { createStyles, makeStyles, Link, Theme } from '@material-ui/core';

import { useMessages } from 'hooks';
import copyright from 'sfc-kit/src/constants/Copyright';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		footer: {
			backgroundColor: theme.palette.background.default,
			margin: '0 auto',
		},
		belowCopyright: {
			margin: '0 auto',
			[theme.breakpoints.up('lg')]: {
				width: '60%',
			},
		},
		spanishText: {
			textAlign: 'center',
			display: 'inline',
			justifyContent: 'center',
			paddingTop: '6px',
		},
		michiganText: {
			display: 'inline',
			justifyContent: 'center',
			textAlign: 'center',
		},
	})
);

const HomeFooter: React.FC = () => {
	const classes = useStyles({} as any);
	const messages = useMessages();

	return (
		<Spacing my="medium" p="medium" className={classes.footer}>
			<Text align="center" weight="medium">
				<Text weight="medium" inline component="strong" message="sfc.companyNameLLC" /> |{' '}
				<Text weight="medium" inline component="strong" message="sfc.allRightsReserved" /> |{' '}
				<Text weight="medium" inline component="strong">
					{copyright}
				</Text>
				| <Text weight="medium" inline component="strong" message="misc.version" messages={messages} /> |{' '}
				<Text weight="medium" inline component="strong" message="sfc.nmls" />
			</Text>
			<FlexBox justifyContent="center" direction="column" className={classes.belowCopyright}>
				<Text variant="body2" align="center" message="footer.newHampshire" messages={messages} gutterBottom />
				<Text variant="body2" align="center" message="footer.texas" messages={messages} gutterBottom />
				<Text variant="body2" align="center" message="footer.newYork" messages={messages} gutterBottom />
				<Text variant="body2" align="center" message="footer.arizona" messages={messages} gutterBottom />
				<Text variant="body2" align="center" message="footer.michigan" messages={messages} inline />
				<div className={classes.michiganText}>
					<Text variant="body2" align="center" message="footer.michigan2" messages={messages} style={{ marginRight: '5px' }} inline />
					<Link
						id={'difs-email-link'}
						href={`mailto:${messages['footer.michiganSite']}`}
						target="blank"
						aria-label="Send mail to Michigan Insurance financial services">
						<Text
							variant="body2"
							messages={messages}
							message="footer.michiganSite"
							color="action"
							weight="medium"
							align="center"
							inline
						/>
					</Link>
				</div>
				<div className={classes.spanishText}>
					<Text variant="body2" align="center" inline message="footer.spanishTranslation" messages={messages} />
					<Link
						underline="hover"
						href={`//${messages['footer.nycConsumerAffairsSite']}`}
						target="blank"
						aria-label="New york department of consumer affairs website">
						<Text inline variant="body2" weight="heavy" messages={messages} message="footer.nycConsumerAffairsSite" color="action" />
					</Link>
				</div>
			</FlexBox>
		</Spacing>
	);
};

export default HomeFooter;
