import React, { RefObject } from 'react';

/**
 *
 * @param shouldListen Whether the click event listener should be added to the document
 * @param onClickAway A callback function that is fired when a click is registered outside of the returned ref
 */
export default function useClickAwayListener<T extends HTMLElement>(shouldListen: boolean, onClickAway: () => void): RefObject<T> {
	const ref = React.useRef<T>(null);

	React.useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent): void => {
			if (!ref.current || ref.current.contains(event.target as Node)) {
				return;
			}

			onClickAway();
		};

		if (shouldListen) {
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);
		} else {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		}

		return (): void => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [shouldListen, onClickAway]);

	return ref;
}
