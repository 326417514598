const Root = '/FindDealer';

export const homeInstallExperts = 'http://www.homeinstallexperts.com/';

const FindDealer = {
	Root,
	Information: `${Root}/Information`,
	Appointment: `${Root}/Appointment`,
	ThankYou: `${Root}/ThankYou`,
};

export default FindDealer;
