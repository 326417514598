import Colors, { flattenedColors } from '../constants/Colors';
import { Color } from '../types';

/**
 * Gets the color value for a given TColors string
 * @param color A string representing a color in our theme
 */
export const extractColorFromKeyString = (color: Color): string => {
	if (color === 'inherit') {
		return 'inherit';
	}

	// The remaining possible values are in the format <category>.<shade> to specify the path to access their color in the theme.
	const [category, shade] = flattenedColors[color].split('.');

	/* eslint-disable-next-line */
	// @ts-ignore
	const extractedColor = Colors[category][shade];

	return extractedColor;
};

export default extractColorFromKeyString;
