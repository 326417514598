export function ToTitleCase(value: string): string {
	if (value.length === 0) {
		return '';
	}

	if (value.length === 1) {
		return value.toUpperCase();
	}

	if (value.includes(' ')) {
		return value
			.split(' ')
			.map(ToTitleCase)
			.join(' ');
	}

	return value[0].toUpperCase() + value.substring(1).toLowerCase();
}
