import React from 'react';
import * as yup from 'yup';

import FormFieldStatus from '../types/FormFieldStatus';

import IsYupArraySchema from '../guards/IsYupArraySchema';

export default function useFormField<T>(
	initialValue: T,
	validationSchema?: yup.Schema<T> | yup.ArraySchema<T>,
	deps: React.DependencyList = [],
	shouldValidateImmediately = false
): [T, (newValue: T) => void, FormFieldStatus] {
	const [value, setValue] = React.useState(initialValue);
	const [status, setStatus] = React.useState<FormFieldStatus>({});

	const hasChanged = React.useRef(shouldValidateImmediately);

	React.useEffect(() => {
		if (hasChanged.current && validationSchema != null) {
			if (IsYupArraySchema(validationSchema)) {
				(validationSchema as yup.ArraySchema<T>)
					.validate(value)
					.then(() => setStatus({ isValid: true }))
					.catch((error: yup.ValidationError) => setStatus({ isValid: false, errorMessage: error.errors[0] }));
			} else {
				(validationSchema as unknown as yup.ArraySchema<T>)
					.validate(value)
					.then(() => setStatus({ isValid: true }))
					.catch((error: yup.ValidationError) => setStatus({ isValid: false, errorMessage: error.errors[0] }));
			}
		}
	}, [value, setStatus, ...deps]); // eslint-disable-line

	const onChange = (newValue: T): void => {
		if (!hasChanged.current) {
			hasChanged.current = true;
		}

		setValue(newValue);
	};

	return [value, onChange, status];
}
