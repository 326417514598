import * as React from 'react';
import { useTheme } from '@material-ui/core';

export interface ProgressCircleProps {
	className?: string;
	progress: number;
	animated?: boolean;
	animationDuration?: string;
	text: string;
	subText?: string;
}

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0): number => Math.round(((100 - Math.min(val, 100)) / 100) * diameter);

const textOffset = 30;

const ProgressCircle: React.FC<ProgressCircleProps> = ({ progress, animated, animationDuration, text, subText, className }) => {
	const theme = useTheme();

	const strokeDashoffset = getOffset(progress);
	const transition = animated ? `stroke-dashoffset ${animationDuration} ease-out` : undefined;

	return (
		<svg width="100%" height="100%" viewBox="-25 -25 400 400" className={className}>
			<circle stroke={theme.palette.grey[200]} cx="175" cy="175" r="175" strokeWidth="25" fill="none" />
			<circle
				stroke={theme.palette.primary.main}
				transform="rotate(-90 175 175)"
				cx="175"
				cy="175"
				r="175"
				strokeDasharray="1100"
				strokeWidth="25"
				strokeDashoffset="1100"
				strokeLinecap="round"
				fill="none"
				style={{ strokeDashoffset, transition }}
			/>
			<text
				style={{ fontSize: theme.typography.h4.fontSize }}
				fill={theme.palette.text.primary}
				x={radius}
				y={radius - (subText != null ? textOffset : 0)}
				textAnchor="middle"
				dominantBaseline="central">
				{text}
			</text>
			{subText && (
				<text
					style={{ fontSize: theme.typography.h4.fontSize }}
					fill={theme.palette.text.primary}
					x={radius}
					y={radius + textOffset}
					textAnchor="middle"
					dominantBaseline="central">
					{subText}
				</text>
			)}
		</svg>
	);
};

export default ProgressCircle;
