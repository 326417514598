import React from 'react';
import { Text } from '../../sfc-kit';
import { useMessages } from '../../hooks';
// import { makeStyles, Theme, createStyles } from '@material-ui/core';

// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		group: {
// 			paddingTop: theme.spacing(2),
// 		},
// 	})
// );

const FindDealerToolTip: React.FunctionComponent = () => {
	const messages = useMessages();
	// const classes = useStyles({} as any);

	return (
		<>
			<Text variant="caption" weight="medium" messages={messages} message="findDealer.mustBeHomeowner" />
		</>
	);
};

export default FindDealerToolTip;
