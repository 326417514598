export default {
	Ok: 200,
	Created: 201,
	BadRequest: 400,
	Unauthorized: 401,
	NotFound: 404,
	Conflict: 409,
	UnprocessableEntity: 422,
	InternalServerError: 500,
};
