import React, { ReactElement, useRef, useState, PropsWithChildren, useEffect } from 'react';

import { IconButton, IconButtonProps, ClickAwayListener, createStyles, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ConvertStatusVariantToValues } from '../../utils';
import { Spacing, FlexBox, FlexItem, Text, Icon, Tooltip, TextProps, IconProps, TooltipProps } from '..';
import { LangText, SfcKitMessages, Messages, MessagesProps, StatusVariant, Color, IconSize } from '../../types';
import { useSfcKitMessages } from '../../hooks';

export interface IconTooltipProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	/**
	 * A discriminator used to determine which icon and color to use
	 */
	variant?: StatusVariant;

	/**
	 * The size of the Icon
	 */
	size?: IconSize;

	/**
	 * The icon to use instead of the one determined by the variant.
	 */
	iconOverride?: IconProps['icon'];

	/**
	 * Replace Icon with Clickable JSX Element
	 */
	componentTrigger?: JSX.Element;

	/**
	 * The color to use instead of the one determined by the variant.
	 */
	colorOverride?: Color;

	/**
	 * Whether the icon inside of the tooltip should be hidden.
	 */
	hideIconInTooltip?: boolean;

	/**
	 * Whether the X button should be hidden..
	 */
	hideCloseButton?: boolean;

	/**
	 * Whether the text "Close" under the X button should be hidden.
	 */
	hideCloseText?: boolean;

	/**
	 * The message to be displayed. Represented as a key to the messages dictionary. If provided, children will not be rendered.
	 */
	message?: LangText<TMessages>;

	/**
	 * Props to be applied to the text component that renders the passed message. Only used if message is not undefined.
	 */
	messageProps?: Omit<TextProps<TMessages>, 'children' | 'message' | 'messages'>;

	/**
	 * The class name to apply to the root element.
	 */
	className?: string;

	/**
	 * Props to be applied to the tooltip component.
	 */
	tooltipProps?: Omit<TooltipProps, 'children' | 'open' | 'anchorEl'>;

	/**
	 * Props to be applied to the icon button component.
	 */
	buttonProps?: Omit<IconButtonProps, 'children' | 'ref' | 'onClick' | 'className'>;

	/**
	 * Whether the tooltip should be forced open
	 */
	forceOpen?: boolean;

	ariaDescribedBy?: string;
	ariaId?: string;
	ariaLabel?: string;
	tabIndex?: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			padding: theme.spacing(1, 1, 0, 1),
			float: 'right',
			'& span': {
				flexDirection: 'column',
				fontSize: '.7rem',
			},
			'& p': {
				textDecoration: 'underline',
			},
			'& svg': {
				height: '.6em',
				width: '.6em',
			},
		},
	})
);

export default function IconTooltip<TMessages extends Messages = SfcKitMessages>({
	variant = 'Success',
	componentTrigger,

	size,
	iconOverride,
	colorOverride,

	hideIconInTooltip = false,
	hideCloseButton = false,
	hideCloseText = false,

	message,
	messages,
	messageProps = {},

	className,

	tooltipProps = {},
	buttonProps = {},
	tabIndex = 1,

	forceOpen = false,
	ariaDescribedBy,
	ariaLabel,
	ariaId,

	children,
}: PropsWithChildren<IconTooltipProps<TMessages>>): ReactElement | null {
	const classes = useStyles({} as any);
	const [open, setOpen] = useState(false);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const divRef = useRef<HTMLDivElement>(null);
	const sfcMessages = useSfcKitMessages();

	const downHandler = (key: KeyboardEvent): void => {
		if (key.key === 'Escape') {
			setOpen(false);
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', downHandler);
		return (): void => {
			window.removeEventListener('keydown', downHandler);
		};
	}, []);

	const {
		color,
		icons: { standard },
	} = ConvertStatusVariantToValues(variant);

	const onClick = (): void => {
		setOpen(x => !x);
	};

	const onClickAway = (): void => {
		setOpen(false);
	};

	return (
		<>
			<ClickAwayListener onClickAway={onClickAway}>
				<div>
					{componentTrigger ? (
						<div ref={divRef} className={className} onClick={onClick}>
							{componentTrigger}
						</div>
					) : (
						<IconButton
							ref={buttonRef}
							onClick={onClick}
							size="small"
							{...buttonProps}
							className={className}
							tabIndex={tabIndex}
							aria-label={ariaLabel}
							aria-describedby={ariaDescribedBy}>
							<Icon size={size} color={colorOverride ?? color} icon={iconOverride ?? standard} />
						</IconButton>
					)}
					<Tooltip
						onBlur={(): void => setOpen(!open)}
						open={forceOpen || open}
						anchorEl={divRef.current || buttonRef.current}
						ariaId={ariaId}
						data-testid="icon-tooltip"
						{...tooltipProps}
						style={{ zIndex: 1202 }}
						aria-hidden="false"
						aria-live="polite">
						{!hideCloseButton && (
							<IconButton className={classes.closeButton} onClick={onClickAway} aria-label="Close">
								<CloseIcon />
								{!hideCloseText && (
									<Text
										component="span"
										variant="caption"
										casing="capitalizeEachWord"
										messages={sfcMessages}
										message="misc.close"
									/>
								)}
							</IconButton>
						)}
						<FlexBox alignItems="center" spacing="xSmall">
							{!hideIconInTooltip && (
								<FlexItem>
									<Spacing mr="small">
										<Icon size={size} color={colorOverride ?? color} icon={iconOverride ?? standard} />
									</Spacing>
								</FlexItem>
							)}
							<FlexItem xs>
								{message ? <Text message={message} messages={messages} variant="caption" {...messageProps} /> : children}
							</FlexItem>
						</FlexBox>
					</Tooltip>
				</div>
			</ClickAwayListener>
		</>
	);
}
