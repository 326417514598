import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetLoanSoldDateResult = QueryResult<Envelope<string>, AxiosError<Envelope<string>>>;
export type GetLoanSoldDateConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<string>, AxiosError<Envelope<string>>>;
};

interface GetLoanSoldDateQuery {
	(request: GetLoanSoldDateRequestParameters, config?: GetLoanSoldDateConfig): GetLoanSoldDateResult;
	key: 'GetLoanSoldDate';
}

const useGetLoanSoldDateQuery: GetLoanSoldDateQuery = (request, config = { axiosConfig: {} }) => {
	async function GetLoanSoldDate(): Promise<Envelope<string>> {
		const client = ApiCSGService();

		const path = '/api/Loans/GetLoanSoldDate';

		const response = await client.get<Envelope<string>>(path, {
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetLoanSoldDate', ...Object.values(request)], GetLoanSoldDate, config.queryConfig);
};

useGetLoanSoldDateQuery.key = 'GetLoanSoldDate';

export default useGetLoanSoldDateQuery;
