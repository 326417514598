import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export function GetBorderLeftStyles(theme: Theme): Pick<CSSProperties, 'borderLeftStyle' | 'borderLeftColor' | 'borderLeftWidth'> {
	return {
		borderLeftStyle: 'solid',
		borderLeftColor: theme.palette.primary.light,
		borderLeftWidth: theme.spacing(0.75),
	};
}
