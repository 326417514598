import React, { useState, forwardRef, ForwardRefRenderFunction } from 'react';

import { makeStyles, createStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Text } from '../Typography';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';

import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';

import IDocument from './IDocument';
import Environment from '../../utils/Environment';

export interface IFullDocumentProps {
	ref: React.MutableRefObject<Document | null>;
	document: IDocument;
	handleError?: () => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		footer: {
			fontSize: '22px',
			textAlign: 'center',
			margin: '45px auto',
		},
		page: {
			'@media print': {
				height: '100vh',
				width: '100vw',
				overflow: 'hidden',
			},
		},
	})
);

/* istanbul ignore next */
const FullDocument: ForwardRefRenderFunction<Document, IFullDocumentProps> = ({ document, handleError }, ref) => {
	const classes = useStyles({} as any);
	const [fullNumberOfPages, setFullNumberOfPages] = useState<{}[]>();
	const [documentPages, setDocumentPages] = useState<number>(0);
	const currentDate = DateTime.local();

	const onFullDocumentLoadSuccess = (pdf: PDFDocumentProxy): void => {
		setFullNumberOfPages(
			Array.from(Array(pdf.numPages), () => {
				return {};
			})
		);
		setDocumentPages(pdf.numPages);
	};

	if (Environment.IsTest()) {
		return null;
	}

	return (
		<Box display="none">
			<Document file={document.source} onLoadSuccess={onFullDocumentLoadSuccess} onLoadError={handleError ?? undefined} ref={ref}>
				{fullNumberOfPages &&
					fullNumberOfPages.map((_, index) => {
						return (
							<>
								<Page
									key={'full-document-page-' + index}
									pageNumber={index + 1}
									scale={documentPages > 1 ? 1.8 : undefined}
									width={documentPages === 1 ? 790 : undefined}
									className={classes.page}
									renderAnnotationLayer={false}
								/>
								{document.isInitialDocument && index < documentPages && (
									<Text className={classes.footer}>
										Printed By: {document.dealerUsername} on{' '}
										{currentDate.toLocaleString(DateTime.DATE_SHORT as LocaleOptions & DateTimeFormatOptions)} at{' '}
										{currentDate.toLocaleString(DateTime.TIME_SIMPLE as LocaleOptions & DateTimeFormatOptions)}{' '}
									</Text>
								)}
							</>
						);
					})}
			</Document>
		</Box>
	);
};

export default forwardRef<Document, IFullDocumentProps>(FullDocument);
