import { useContext } from 'react';
import { useLoanSummaryQuery } from 'sfc-kit';
import { LoanContext } from 'context';
import { getTokenNls } from 'utils/Auth';

export default function useSelectedLoanSummaryQuery(): ReturnType<typeof useLoanSummaryQuery> {
	const nlsToken = getTokenNls();
	const { selectedLoanNumber, hasLoanSelected } = useContext(LoanContext);

	return useLoanSummaryQuery(
		{ loanNumber: selectedLoanNumber, authorization: nlsToken },
		{ queryConfig: { enabled: nlsToken && hasLoanSelected } }
	);
}
