import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { HistoricalPaymentsSortOption } from 'types';
import { useCustomerPortalNotificationStack, useMessages } from 'hooks';

import {
	Text,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	TablePagination,
	LoadingBoundary,
	CollapsibleTableRow,
	TablePaginationProps,
	NumberUtility,
	Constants,
	usePaginatedHistoricalPaymentsQuery,
} from 'sfc-kit';
import classnames from 'classnames';

export interface IPaymentActivityTableProps {
	loanNumber: string;

	sort?: HistoricalPaymentsSortOption;
}

const useStyles = makeStyles(theme =>
	createStyles({
		cell: {
			padding: theme.spacing(Constants.Spacing.medium, Constants.Spacing.small),
			textAlign: 'center',
		},
		childRow: {
			verticalAlign: 'top',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const DEFAULT_SORT: HistoricalPaymentsSortOption = HistoricalPaymentsSortOption.DateNewestToOldest;

export const getPaymentMethod = (code = ''): string => {
	if (code.includes('ACH')) {
		return 'ACH';
	}

	if (code === 'ELBX') {
		return 'Bank Bill Pay';
	}

	return code;
};

const PaymentActivityTable: React.FC<IPaymentActivityTableProps> = ({ loanNumber, sort = DEFAULT_SORT }) => {
	const styles = useStyles({} as any);
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

	const { createDangerNotification } = useCustomerPortalNotificationStack();

	const messages = useMessages();

	const [page, setPage] = useState(0);
	const [numRows, setNumRows] = useState(10);

	const [openRow, setOpenRow] = useState<number>();

	const { isLoading, resolvedData: historicalPaymentCollection } = usePaginatedHistoricalPaymentsQuery(
		{
			loanNumber,
			skip: page * numRows,
			take: numRows,
			sort,
		},
		{
			queryConfig: {
				onError: () => createDangerNotification({ title: 'notifications.oops', body: 'notifications.loadPaymentActivity.failure' }),
			},
		}
	);

	const handleChangePage: TablePaginationProps['onPageChange'] = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage: TablePaginationProps['onRowsPerPageChange'] = event => {
		setPage(0);
		setNumRows(+event.target.value);
	};

	return (
		<LoadingBoundary loading={isLoading}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={styles.cell}>
							<Text message="payments.paymentDate" messages={messages} casing="capitalizeEachWord" />
						</TableCell>
						{!isSmall && (
							<TableCell className={styles.cell}>
								<Text message="payments.paymentAmount" messages={messages} casing="capitalizeEachWord" />
							</TableCell>
						)}
						<TableCell className={styles.cell}>
							<Text message="payments.paymentMethod" messages={messages} casing="capitalizeEachWord" />
						</TableCell>
						<TableCell className={styles.cell}>
							<Text message="payments.referenceNo" messages={messages} casing="capitalizeEachWord" />
						</TableCell>
						{!isSmall && <TableCell />}
					</TableRow>
				</TableHead>
				<TableBody>
					{historicalPaymentCollection?.historicalPayments?.map(
						({ referenceNumber, paidBy, paymentMethod, datePaid, paymentAmount, dateDue, paymentNumber, paymentDescription }) => {
							const formattedDateDue = DateTime.fromISO(dateDue ?? '');
							const details = (
								<>
									<TableCell dense className={styles.cell}>
										<Text
											variant="caption"
											color="text.hint"
											message="payments.dueDate"
											messages={messages}
											casing="capitalizeEachWord"
										/>
										<Text variant="body2">{formattedDateDue.isValid ? formattedDateDue.toFormat('MM/dd/yyyy') : null}</Text>
									</TableCell>
									<TableCell dense className={styles.cell}>
										<Text
											variant="caption"
											color="text.hint"
											message="payments.paymentNo"
											messages={messages}
											casing="capitalizeEachWord"
										/>
										<Text variant="body2">{paymentNumber}</Text>
									</TableCell>
									{!isSmall && (
										<TableCell dense className={styles.cell}>
											<Text
												variant="caption"
												color="text.hint"
												message="misc.description"
												messages={messages}
												casing="capitalizeEachWord"
											/>
											<Text variant="body2">{paymentDescription}</Text>
										</TableCell>
									)}
									<TableCell dense className={styles.cell}>
										<Text
											variant="caption"
											color="text.hint"
											message="payments.paidBy"
											messages={messages}
											casing="capitalizeEachWord"
										/>
										<Text variant="body2">{paidBy}</Text>
									</TableCell>
								</>
							);

							const expanded = openRow === referenceNumber;
							const formattedDatePaid = DateTime.fromISO(datePaid ?? '');

							return (
								<CollapsibleTableRow
									details={details}
									key={referenceNumber}
									disableCollapse={false}
									expanded={expanded}
									onToggleExpansion={(): void => setOpenRow(expanded ? undefined : referenceNumber)}
									classes={{ childRow: styles.childRow, buttonCell: classnames(styles.cell, styles.focus) }}>
									<TableCell dense className={styles.cell}>
										{formattedDatePaid.isValid ? formattedDatePaid.toFormat('MM/dd/yyyy') : null}
									</TableCell>
									<TableCell dense className={styles.cell}>
										${NumberUtility.formatCurrency(paymentAmount ?? 0)}
									</TableCell>
									{!isSmall && (
										<TableCell dense className={styles.cell}>
											{getPaymentMethod(paymentMethod)}
										</TableCell>
									)}
									<TableCell dense className={styles.cell}>
										{referenceNumber}
									</TableCell>
								</CollapsibleTableRow>
							);
						}
					)}
				</TableBody>
			</Table>
			<TablePagination
				page={page}
				rowsPerPage={numRows}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				count={historicalPaymentCollection?.totalPayments ?? 0}
			/>
		</LoadingBoundary>
	);
};

export default PaymentActivityTable;
