import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetDealerInfoResult = QueryResult<DealerInfo, AxiosError<DealerInfo>>;
export type GetDealerInfoConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<DealerInfo, AxiosError<DealerInfo>>;
};

interface GetDealerInfoQuery {
	(request: GetDealerInfoRequestParameters, config?: GetDealerInfoConfig): GetDealerInfoResult;
	key: 'GetDealerInfo';
}

const useGetDealerInfoQuery: GetDealerInfoQuery = (request, config = { axiosConfig: {} }) => {
	async function GetDealerInfo(): Promise<DealerInfo> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetDealerInfo';

		const response = await client.get<DealerInfo>(path);

		return response.data;
	}

	return useQuery(['GetDealerInfo', ...Object.values(request)], GetDealerInfo, config.queryConfig);
};

useGetDealerInfoQuery.key = 'GetDealerInfo';

export default useGetDealerInfoQuery;
