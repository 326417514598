import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenNls } from 'utils/Auth';
import commonServicesGatewayApi from 'utils/CommonServicesGatewayAPI';

export const getLoans = createAsyncThunk('/api/Loans/GET', async (includeRelatedLoans?: boolean) => {
	const token = getTokenNls();

	const { data } = await commonServicesGatewayApi.Loans({ authorization: token, includeRelatedLoans });

	return data;
});
