import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { Hidden, makeStyles, createStyles } from '@material-ui/core';

import { Text, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TablePaginationProps, LoadingBoundary } from 'sfc-kit';

import StatementTableRow from './StatementTableRow';
import { usePaginatedStatements, useSelectedLoanSummaryQuery } from 'hooks';

export interface StatementTableProps {
	/**
	 * The initial page value or only value when `static === true`.
	 * @default 0
	 */
	initialPage?: number;

	/**
	 * The initial statements per page value or only value when `static === true`.
	 * @default 10
	 */
	initialStatementsPerPage?: number;

	/**
	 * If `true` the table will not be paginated and display data according to the `initialPage` and `initialStatementsPerPage` props
	 * @default false
	 */
	fixed?: boolean;

	startDate?: DateTime;

	endDate?: DateTime;
}

const useStyles = makeStyles(theme =>
	createStyles({
		borderLeft: {
			borderLeft: '5px solid',
			borderColor: theme.palette.primary.main,
			paddingLeft: theme.spacing(1),
		},
	})
);

const StatementTable: React.FC<StatementTableProps> = ({ initialPage = 0, initialStatementsPerPage = 10, fixed = false, startDate, endDate }) => {
	const styles = useStyles({} as any);

	const [page, setPage] = useState(initialPage);
	const [statementsPerPage, setStatementPage] = useState(initialStatementsPerPage);

	const { data: loanSummary } = useSelectedLoanSummaryQuery();
	const { isLoading, resolvedData: paginatedStatements } = usePaginatedStatements(page, statementsPerPage, startDate, endDate);

	const onPageChange: TablePaginationProps['onPageChange'] = (_, newPage) => {
		setPage(newPage);
	};

	const onStatementsPerPageChange: TablePaginationProps['onRowsPerPageChange'] = (event): void => {
		setPage(0);
		setStatementPage(+event.target.value);
	};

	useEffect(() => {
		setPage(0);
	}, [paginatedStatements?.totalStatements]);

	if (!isLoading && paginatedStatements === undefined) {
		return (
			<Text className={styles.borderLeft}>
				There are no payment{' '}
				<Text inline component="span" color="action" weight="medium">
					statements
				</Text>{' '}
				at the moment, the next declarations will be on the date{' '}
				<Text inline component="span" weight="medium">
					{loanSummary?.paymentDueDate ?? ''}
				</Text>
			</Text>
		);
	}

	return (
		<LoadingBoundary loading={isLoading}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Date</TableCell>
						<Hidden smDown>
							<TableCell>Due Date</TableCell>
						</Hidden>
						<Hidden mdDown>
							<TableCell>Past Due Balance</TableCell>
						</Hidden>
						<Hidden xsDown>
							<TableCell>Payment Amount</TableCell>
						</Hidden>
						<TableCell>Balance Remaining</TableCell>
						<TableCell>Statement</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{paginatedStatements?.statements?.map((statement, index) => (
						<StatementTableRow key={statement.documentId} index={index} statement={statement} />
					))}
				</TableBody>
			</Table>
			{!fixed && paginatedStatements !== undefined && (
				<TablePagination
					page={page}
					count={paginatedStatements.totalStatements}
					rowsPerPage={statementsPerPage}
					onPageChange={onPageChange}
					onRowsPerPageChange={onStatementsPerPageChange}
				/>
			)}
		</LoadingBoundary>
	);
};

export default StatementTable;
