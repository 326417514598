import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetBankNameResult = QueryResult<string, AxiosError<string>>;
export type GetBankNameConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<string, AxiosError<string>>;
};

interface GetBankNameQuery {
	(request: GetBankNameRequestParameters, config?: GetBankNameConfig): GetBankNameResult;
	key: 'GetBankName';
}

const useGetBankNameQuery: GetBankNameQuery = (request, config = { axiosConfig: {} }) => {
	async function GetBankName(): Promise<string> {
		const client = ApiCSGService();

		const path = '/api/ThirdParty/GetBankName';

		const response = await client.get<string>(path, {
			routingNumber: request?.routingNumber,
		});

		return response.data;
	}

	return useQuery(['GetBankName', ...Object.values(request)], GetBankName, config.queryConfig);
};

useGetBankNameQuery.key = 'GetBankName';

export default useGetBankNameQuery;
