import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetNextValidPaymentDateResult = QueryResult<string, AxiosError<string>>;
export type GetNextValidPaymentDateConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<string, AxiosError<string>>;
};

interface GetNextValidPaymentDateQuery {
	(request: GetNextValidPaymentDateRequestParameters, config?: GetNextValidPaymentDateConfig): GetNextValidPaymentDateResult;
	key: 'GetNextValidPaymentDate';
}

const useGetNextValidPaymentDateQuery: GetNextValidPaymentDateQuery = (request, config = { axiosConfig: {} }) => {
	async function GetNextValidPaymentDate(): Promise<string> {
		const client = ApiCSGService();

		const path = '/api/Payment/GetNextValidPaymentDate';

		const response = await client.get<string>(path, {
			paymentDate: request?.paymentDate,
		});

		return response.data;
	}

	return useQuery(['GetNextValidPaymentDate', ...Object.values(request)], GetNextValidPaymentDate, config.queryConfig);
};

useGetNextValidPaymentDateQuery.key = 'GetNextValidPaymentDate';

export default useGetNextValidPaymentDateQuery;
