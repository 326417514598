import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LockboxAddressesResult = QueryResult<LockboxAddresses, AxiosError<LockboxAddresses>>;
export type LockboxAddressesConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<LockboxAddresses, AxiosError<LockboxAddresses>>;
};

interface LockboxAddressesQuery {
	(request: LockboxAddressesRequestParameters, config?: LockboxAddressesConfig): LockboxAddressesResult;
	key: 'LockboxAddresses';
}

const useLockboxAddressesQuery: LockboxAddressesQuery = (request, config = { axiosConfig: {} }) => {
	async function LockboxAddresses(): Promise<LockboxAddresses> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/LockboxAddresses`;

		const response = await client.get<LockboxAddresses>(path);

		return response.data;
	}

	return useQuery(['LockboxAddresses', ...Object.values(request)], LockboxAddresses, config.queryConfig);
};

useLockboxAddressesQuery.key = 'LockboxAddresses';

export default useLockboxAddressesQuery;
