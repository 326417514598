import React, { useEffect } from 'react';
import { LoadingBoundary, Modal } from 'sfc-kit';
import { useMessages } from 'hooks';
import PaperlessOptinOutSwitch from 'components/Controls/PaperlessOptInOutSwitch';
import { LoanWithStatement } from 'types';
import TOSContent from '../Content/TOSContent';
import { TermsOfServiceCheckbox } from 'components';

interface PaperlessOptDialogProps {
	isOpen: boolean;
	onCloseClick: () => void;
	onContinueClick: (loans: Array<LoanWithStatement>, enablePaperlessFromUrl: boolean) => void;
	loans: Array<LoanWithStatement>;
	enablePaperlessFromUrl: boolean;
}

export const handlePaperlessOnChange = (
	loanNumber: string,
	value: boolean,
	currentLoans: Array<LoanWithStatement>,
	setCurrentLoans: (loans: LoanWithStatement[]) => void
): void => {
	setCurrentLoans(
		currentLoans.map(loan => {
			if (loanNumber === 'Toggle All') {
				return { ...loan, enrolledInPaperless: value };
			}

			return loan.loanNumber === loanNumber ? { ...loan, enrolledInPaperless: value } : loan;
		})
	);
};

const PaperlessOptDialog: React.FC<PaperlessOptDialogProps> = ({ isOpen, onCloseClick, onContinueClick, loans, enablePaperlessFromUrl }) => {
	const messages = useMessages();

	const printRef = React.useRef<HTMLDivElement>(null);
	const [currentLoans, setCurrentLoans] = React.useState<Array<LoanWithStatement>>([]);
	const [tosOpen, setTosOpen] = React.useState<boolean>(false);
	const [acceptedTOS, setAcceptedTOS] = React.useState<boolean>(false);
	const enrolledInPaperless = currentLoans.every(loan => loan.enrolledInPaperless);
	const someEnrolledInPaperless = currentLoans.some(loan => loan.enrolledInPaperless);

	useEffect(() => {
		setCurrentLoans(loans);

		if (enablePaperlessFromUrl) {
			handlePaperlessOnChange('Toggle All', true, loans, setCurrentLoans);
		}
	}, [loans, enablePaperlessFromUrl]);

	useEffect(() => {
		if (!someEnrolledInPaperless) {
			setAcceptedTOS(false);
		}
	}, [someEnrolledInPaperless]);

	return (
		<Modal
			open={isOpen}
			messages={messages}
			aria-hidden="false"
			aria-live="polite"
			variant="Success"
			id="Not-Verified-Email-Dialog"
			title="paperless.sfcEBillingsProgram"
			buttonCasing="capitalizeEachWord"
			confirmDenyProps={{
				confirmDisabled: currentLoans.length === 0 || (someEnrolledInPaperless && !acceptedTOS),
				onConfirm: (): void => onContinueClick(currentLoans, enablePaperlessFromUrl),
				onDeny: onCloseClick,
				confirmText: 'buttons.confirm',
				denyText: 'buttons.cancel',
			}}>
			<LoadingBoundary loading={!loans.length}>
				<PaperlessOptinOutSwitch
					dialog
					checked={enrolledInPaperless}
					onChange={(loanNumber, value): void => {
						handlePaperlessOnChange(loanNumber, value, currentLoans, setCurrentLoans);
					}}
					loans={currentLoans}
				/>
				{someEnrolledInPaperless && (
					<TermsOfServiceCheckbox
						checked={acceptedTOS}
						aria-label="Select to agree to the Terms of Service"
						modalOpen={tosOpen}
						required={false}
						currentPrintRef={printRef}
						setModalOpen={setTosOpen}
						data-testid="paperlessTermsAndConditions"
						openOnChecked
						checkboxText="paperless.yesConsent">
						<TOSContent
							printRef={printRef}
							handleCheckboxClick={setAcceptedTOS}
							checked={acceptedTOS}
							onCloseClick={(): void => setTosOpen(false)}
						/>
					</TermsOfServiceCheckbox>
				)}
			</LoadingBoundary>
		</Modal>
	);
};

export default PaperlessOptDialog;
