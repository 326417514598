import { useContext, useEffect } from 'react';

import { LoanContext } from 'context';
import { useLoanDetailsQuery } from 'sfc-kit';
import { getTokenNls } from 'utils/Auth';

export default function useLoanNickname(loanNumber?: string): { isLoading: boolean; nickname: string; defaultNickname: string } {
	const nlsToken = getTokenNls();

	const { selectedLoanNumber, selectLoan } = useContext(LoanContext);

	if (loanNumber === undefined) {
		loanNumber = selectedLoanNumber;
	}
	useEffect(() => {
		const sessionItem = sessionStorage.getItem('LoanToken');
		if (sessionItem !== null && sessionItem !== selectedLoanNumber) {
			selectLoan(sessionItem ?? selectedLoanNumber);
			sessionStorage.setItem('LoanToken', sessionItem ?? selectedLoanNumber.toString());
		}
	}, [selectedLoanNumber, selectLoan]);

	const { isLoading, data } = useLoanDetailsQuery(
		{ authorization: nlsToken, loanNumber },
		{ queryConfig: { enabled: nlsToken && loanNumber.length } }
	);

	if (data === undefined) {
		return { isLoading, nickname: '', defaultNickname: '' };
	}

	const { asset, nickname } = data;
	const defaultNickname = `${asset} - ${loanNumber}`;

	if (nickname.trim().length > 0) {
		return { isLoading, nickname, defaultNickname };
	}

	return { isLoading, nickname: defaultNickname, defaultNickname };
}
