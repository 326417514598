import { useContext } from 'react';

import { LoanContext } from 'context';
import { useScheduledAchPaymentsQuery } from 'sfc-kit';

export default function useScheduledAchPayments(): ReturnType<typeof useScheduledAchPaymentsQuery> {
	const { selectedLoanNumber } = useContext(LoanContext);

	return useScheduledAchPaymentsQuery({ loanNumber: selectedLoanNumber });
}
