import { MutateFunction, useQueryCache } from 'react-query';
import React, { useEffect, useState, useContext } from 'react';

import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import PaperlessSidebar from '../Content/PaperlessSidebar';
import GlobalContext, { GlobalActionTypes } from 'context/Global';
import { useProfileName, useCustomerPortalNotificationStack } from 'hooks';

import { useGetBorrowerContactQuery, useUpdateBorrowerShortNameMutation } from 'sfc-kit';
import { AxiosError } from 'axios';
import { getTokenNls } from 'utils/Auth';


interface SaveChangesProps {
	profileName: string;
	existingProfileName: string;
	updateShortName: MutateFunction<string, AxiosError<string>, UpdateBorrowerShortNameRequestParameters, unknown>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		userIcon: {
			color: theme.palette.grey[500],
			fontSize: '15em',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				width: '50vw',
			},
		},
		textAlignCenter: {
			textAlign: 'center',
		},
		inputLabel: {
			textAlign: 'center',
			paddingTop: theme.spacing(0.5),
		},
		cursorPointer: {
			cursor: 'pointer',
			color: theme.palette.action.main,
		},
		inputStyle: {
			textAlign: 'center',
			paddingLeft: theme.spacing(3),
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

export const saveChanges = ({ profileName, existingProfileName, updateShortName }: SaveChangesProps): void => {
	const shortName = profileName.trim().length > 0 ? profileName : existingProfileName;
	updateShortName({ body: { token: getTokenNls(), shortName } });
};

const UserAccountSettingsSidebar: React.FC = () => {
	const cache = useQueryCache();

	const classes = useStyles({} as any);
	const { dispatchForGlobal } = useContext(GlobalContext);

	const existingProfileName = useProfileName();

	const { createDangerNotification } = useCustomerPortalNotificationStack();

	const [profileName, setProfileName] = useState(existingProfileName);
	const [editingProfileName, setEditingProfileName] = useState<boolean>(false);

	useEffect(() => setProfileName(existingProfileName), [existingProfileName]);

	const [updateShortName] = useUpdateBorrowerShortNameMutation({
		mutationConfig: {
			onMutate: () =>
				dispatchForGlobal({ type: GlobalActionTypes.UpdateLoadingSnackbar, payload: { open: true, message: 'Updating Username' } }),
			onSuccess: (_, { body }) => {
				cache.invalidateQueries(useGetBorrowerContactQuery.key);
				setProfileName(body?.shortName ?? profileName);
			},
			onError: () => {
				createDangerNotification({ title: 'notifications.savingFailed', body: 'notifications.changeProfileName.failure' });
				setProfileName(existingProfileName);
			},
			onSettled: () => {
				dispatchForGlobal({ type: GlobalActionTypes.UpdateLoadingSnackbar, payload: { open: false } });
				setEditingProfileName(false);
			},
		},
	});

	const onInputKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === 'Enter') {
			saveChanges({ updateShortName, profileName, existingProfileName });
		}
	};

	const saveName = (): void => {
		saveChanges({ updateShortName, profileName, existingProfileName });
	};

	return (
		<>
			<Grid container justifyContent="center">
				<Grid item xs={12} className={classes.textAlignCenter}>
					<AccountCircle className={classes.userIcon} />
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						inputProps={{ className: classes.inputStyle, 'aria-label': 'Edit Profile Name', tabIndex: 1 }}
						value={profileName}
						onChange={(event): void => setProfileName(event.target.value.substring(0, 20))}
						placeholder={existingProfileName}
						onKeyPress={onInputKeyPress}
						onClick={(): void => setEditingProfileName(true)}
						onBlur={saveName}
						onFocus={(): void => setEditingProfileName(true)}
						helperText={'Profile Name'}
						FormHelperTextProps={{ className: classes.textAlignCenter }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{editingProfileName ? (
										<IconButton
											onClick={saveName}
											aria-label="Select to edit profile name"
											className={classes.focus}
											tabIndex={1}>
											<SaveIcon
												color="primary"
												fontSize="small"
												className={classes.cursorPointer}
												data-testid="SfcSaveUsernameIcon"
											/>
										</IconButton>
									) : (
										<IconButton
											className={classes.focus}
											tabIndex={1}
											onClick={(): void => setEditingProfileName(true)}
											aria-label="Select to edit profile name">
											<CreateIcon fontSize="small" className={classes.cursorPointer} data-testid="SfcEditUsernameIcon" />
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
					/>
					<PaperlessSidebar />
				</Grid>
			</Grid>
		</>
	);
};

export default UserAccountSettingsSidebar;
