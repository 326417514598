import React from 'react';

import { makeStyles, Theme, createStyles, lighten } from '@material-ui/core/styles';

import { Box, Card, CardHeader, CardContent, SvgIcon } from '@material-ui/core';

interface EditPaymentCardProps {
	title: string;
	Avatar: typeof SvgIcon;

	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			borderRadius: 0,
			maxWidth: 600,
			width: '100%',
		},
		cardHeader: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		cardContent: {
			[theme.breakpoints.up('md')]: {
				minHeight: '45vh',
			},
		},
		backButton: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,

			'&:hover': {
				backgroundColor: lighten(theme.palette.primary.main, 0.15),
			},
		},
		cancelButton: { color: theme.palette.primary.main },
		noButton: { color: theme.palette.primary.main },
	})
);

const EditPaymentCard: React.FC<EditPaymentCardProps> = ({ title, Avatar, children }) => {
	const classes = useStyles({} as any);

	return (
		<>
			<Card className={classes.card}>
				<CardHeader
					className={classes.cardHeader}
					avatar={
						<Box border="1px solid" borderRadius="360px" padding={1}>
							<Avatar />
						</Box>
					}
					title={title}
				/>
				<CardContent className={classes.cardContent}>{children}</CardContent>
			</Card>
		</>
	);
};

export default EditPaymentCard;
