import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanOverviewResult = QueryResult<LoanOverview, AxiosError<LoanOverview>>;
export type LoanOverviewConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<LoanOverview, AxiosError<LoanOverview>>;
};

interface LoanOverviewQuery {
	(request: LoanOverviewRequestParameters, config?: LoanOverviewConfig): LoanOverviewResult;
	key: 'LoanOverview';
}

const useLoanOverviewQuery: LoanOverviewQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanOverview(): Promise<LoanOverview> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/LoanOverview`;

		const response = await client.get<LoanOverview>(path);

		return response.data;
	}

	return useQuery(['LoanOverview', ...Object.values(request)], LoanOverview, config.queryConfig);
};

useLoanOverviewQuery.key = 'LoanOverview';

export default useLoanOverviewQuery;
