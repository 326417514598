import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type DealerInformationResult = QueryResult<Envelope<LoanDealerInformation>, AxiosError<Envelope<LoanDealerInformation>>>;
export type DealerInformationConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<LoanDealerInformation>, AxiosError<Envelope<LoanDealerInformation>>>;
};

interface DealerInformationQuery {
	(request: DealerInformationRequestParameters, config?: DealerInformationConfig): DealerInformationResult;
	key: 'DealerInformation';
}

const useDealerInformationQuery: DealerInformationQuery = (request, config = { axiosConfig: {} }) => {
	async function DealerInformation(): Promise<Envelope<LoanDealerInformation>> {
		const client = ApiCSGService();

		const path = '/api/Loans/DealerInformation';

		const response = await client.get<Envelope<LoanDealerInformation>>(path, {
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['DealerInformation', ...Object.values(request)], DealerInformation, config.queryConfig);
};

useDealerInformationQuery.key = 'DealerInformation';

export default useDealerInformationQuery;
