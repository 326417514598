import { makeAPaymentInitialState, MakeAPaymentStateType, MakeAPaymentActionTypes, MakeAPaymentActions } from './MakeAPaymentContext';
import { LoadState } from 'sfc-kit/src/utils/BrowserStorage';
import { DateTime } from 'luxon';

export const makeAPaymentReducer = (state: MakeAPaymentStateType = makeAPaymentInitialState, action: MakeAPaymentActions): MakeAPaymentStateType => {
	switch (action.type) {
		case MakeAPaymentActionTypes.SelectBankAccount:
			if (action.payload === undefined) {
				return { ...state, bankAccountInfo: undefined };
			}

			return {
				...state,

				...action.payload,
			};

		case MakeAPaymentActionTypes.ClearState:
			return makeAPaymentInitialState;

		case MakeAPaymentActionTypes.ConfirmMultiplePayments:
			return {
				...state,

				confirmedMultiplePayments: true,
			};

		case MakeAPaymentActionTypes.SetConfirmationNumber:
			return {
				...state,

				confirmationNumber: action.payload,
			};

		case MakeAPaymentActionTypes.SetPaymentDate:
			return {
				...state,

				paymentDate: action.payload,
			};

		case MakeAPaymentActionTypes.SetScheduledAchPayments:
			return {
				...state,

				scheduledAchPayments: action.payload,
			};

		case MakeAPaymentActionTypes.SetPaymentFrequency:
			return {
				...state,

				frequency: action.payload,
			};

		case MakeAPaymentActionTypes.SetPaymentAmount:
			return {
				...state,

				paymentAmountInfo: action.payload,
			};

		case MakeAPaymentActionTypes.InitiateWorkflow:
			return {
				...state,

				inProgress: true,
				loan: action.payload,
			};

		case MakeAPaymentActionTypes.UpdatePage:
			return {
				...state,

				page: action.payload,
			};

		case MakeAPaymentActionTypes.SetIsPayoffPayment:
			return {
				...state,

				isPayoffPayment: action.payload,
			};

		/* eslint-disable no-case-declarations */
		case MakeAPaymentActionTypes.LoadState:
			const LS = LoadState('MakeAPayment') as MakeAPaymentStateType;

			if (LS) {
				const { paymentDate, ...restState } = LS;

				const parsedDate = paymentDate ? DateTime.fromISO((paymentDate as unknown) as string) : null;

				return {
					paymentDate: parsedDate,
					...restState,
				};
			}

			return state;

		/* eslint-enable no-case-declarations */

		default:
			return state;
	}
};

export default makeAPaymentReducer;
