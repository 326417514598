import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type IsUsernameVerifiedEmailResult = MutationResultPair<
	IsUsernameVerifiedEmailResponse,
	AxiosError<IsUsernameVerifiedEmailResponse>,
	IsUsernameVerifiedEmailRequestParameters,
	unknown
>;

export type IsUsernameVerifiedEmailConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<
		IsUsernameVerifiedEmailResponse,
		AxiosError<IsUsernameVerifiedEmailResponse>,
		IsUsernameVerifiedEmailRequestParameters
	>;
};

export default function useIsUsernameVerifiedEmailMutation(
	config: IsUsernameVerifiedEmailConfig = { axiosConfig: {} }
): IsUsernameVerifiedEmailResult {
	async function IsUsernameVerifiedEmail(request: IsUsernameVerifiedEmailRequestParameters): Promise<IsUsernameVerifiedEmailResponse> {
		const client = ApiCSGService();

		const path = '/api/Users/IsUsernameVerifiedEmail';

		const response = await client.put<IsUsernameVerifiedEmailResponse>(path, request.body);

		return response.data;
	}

	return useMutation(IsUsernameVerifiedEmail, config.mutationConfig);
}
