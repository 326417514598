import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles, createStyles, FormControl, useMediaQuery, useTheme } from '@material-ui/core';

import { FlexBox, FlexItem, Spacing, Label, Text, Casing, LangText, Messages, SfcKitMessages, MessagesProps } from '../../..';

export interface InputControlProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	/**
	 * The value of the for attribute on the label
	 *
	 * Example:
	 * state-select-list
	 */
	htmlFor?: string;

	/**
	 * The LangText key to be displayed in the top left as a label
	 */
	label?: LangText<TMessages>;

	/**
	 * The LangText key to be displayed in the top right next to default label
	 */
	supplementalLabel?: LangText<TMessages>;

	/**
	 * The LangText key to be displayed in the top right as helper text
	 *
	 * Example:
	 * Select a state of residence
	 */
	helperText?: LangText<TMessages>;

	error?: boolean;

	/**
	 * The LangText key to be displayed below the input control as and error
	 */
	errorText?: LangText<TMessages>;

	/**
	 * Whether the label should be visible to the user. When false, the label will still be rendered but as srOnly.
	 */
	showLabel?: boolean;

	/**
	 * An object to specify the casing to be applied to the various elements
	 */
	casings?: {
		label?: Casing;
		supplementalLabel?: Casing;
		helper?: Casing;
		error?: Casing;
	};

	classes?: {
		inputControl?: string;
		label?: string;
		helperText?: string;
		errorText?: string;
	};

	children: ReactNode;
}

const useStyles = makeStyles(theme =>
	createStyles({
		inputControl: {
			display: 'flex',
			'& input, & svg, & textarea, & div': {
				//The fieldset background color is after the children in the dom so we need to make their zIndex higher
				zIndex: 2,
			},

			'& fieldset': {
				backgroundColor: theme.palette.background.default,

				borderRadius: 0,
				borderColor: '#CED4DA',

				zIndex: 1,
			},
		},
		supplementalLabel: {
			fontStyle: 'italic',
			color: '#707070',
		},
		inputWrapper: {
			flex: 1,
			alignContent: 'flex-start',
		},
		label: {
			whiteSpace: 'nowrap',
		},
		fullWidth: {
			width: '100%',
		},
		error: {
			padding: '3px 14px 0',
		},
		errorContainer: {
			borderColor: theme.palette.error.main,
		},
	})
);

export default function InputControl<TMessages extends Messages = SfcKitMessages>({
	htmlFor,

	label,
	supplementalLabel,
	showLabel = false,

	helperText,

	error,
	errorText,

	casings = {},

	classes = {},

	messages,

	children,
}: InputControlProps<TMessages>): React.ReactElement | null {
	const styles = useStyles({} as any);
	const theme = useTheme();
	const laptopSize = useMediaQuery(theme.breakpoints.down('lg'));

	const labelProps = {
		message: label,
		messages,
		for: htmlFor ?? '',
		casing: casings.label,
		className: classes.label,
	};

	return (
		<FormControl
			fullWidth
			variant="outlined"
			className={classnames(error === true && styles.errorContainer, styles.inputControl, classes.inputControl)}>
			<FlexBox alignItems="center" justifyContent="space-between" className={styles.inputWrapper}>
				<FlexBox direction={laptopSize ? 'column' : 'row'} justifyContent="space-between" alignItems={!laptopSize ? 'center' : undefined}>
					<FlexItem xs>
						{label !== undefined &&
							htmlFor !== undefined &&
							(showLabel ? (
								<Spacing className={styles.label} my="xSmall" mr={helperText ? 'xSmall' : undefined}>
									<Label {...labelProps} variant="body2" />
								</Spacing>
							) : (
								<Label {...labelProps} variant="srOnly" />
							))}
					</FlexItem>
					<FlexItem>
						{label && showLabel && supplementalLabel && (
							<Text
								casing={casings.supplementalLabel}
								className={styles.supplementalLabel}
								variant="caption"
								messages={messages}
								message={supplementalLabel}
							/>
						)}
					</FlexItem>
				</FlexBox>
				<FlexItem>
					{helperText && (
						<Spacing my="xSmall" ml={label !== undefined && htmlFor !== undefined && showLabel ? 'xSmall' : undefined}>
							<Text
								message={helperText}
								messages={messages}
								variant="caption"
								color="text.secondary"
								casing={casings.helper}
								className={classes.helperText}
							/>
						</Spacing>
					)}
				</FlexItem>
			</FlexBox>
			<FlexBox direction="column" className={styles.fullWidth}>
				{children}
				{error && errorText ? (
					<Text
						message={errorText}
						messages={messages}
						variant="caption"
						color="error.main"
						casing={casings.error}
						className={`${classes.errorText} ${styles.error} error-text`}
						aria-live="polite"
					/>
				) : (
					<Text variant="caption" className={`${classes.errorText} ${styles.error} error-text`}>
						{'\u00A0'}
					</Text>
				)}
			</FlexBox>
		</FormControl>
	);
}
