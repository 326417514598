import React from 'react';

import { DialogContent, DialogActions, DialogContentText, makeStyles, createStyles } from '@material-ui/core';

import { ZoomDialog } from '..';
import SquareButton from '../../SquareButton';
import classnames from 'classnames';

export interface ConfirmDenyDialogProps {
	id: string;
	title: string;
	isOpen: boolean;
	isIdleTimeout?: boolean;

	handleClose?: () => void;
	handleConfirm?: () => void;
	handleDeny?: () => void;

	confirmText?: string;
	denyText?: string;

	classes?: {
		root?: string;
		title?: string;
		dialogContent?: string;
		dialogContentText?: string;
		dialogActions?: string;
		denyButton?: string;
		confirmButton?: string;
	};
}

const useStyles = makeStyles(theme =>
	createStyles({
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const ConfirmDenyDialog: React.FC<ConfirmDenyDialogProps> = ({
	id,
	isOpen,
	title,

	handleClose,
	handleConfirm,
	handleDeny,

	confirmText,
	denyText,
	children,
	isIdleTimeout,

	classes = {},
}) => {
	const styles = useStyles({} as any);

	return (
		<ZoomDialog
			id={id}
			isIdleTimeout={isIdleTimeout}
			open={isOpen}
			onClose={handleClose}
			title={title}
			classes={{ root: classes.root, title: classes.title }}>
			<DialogContent className={classes.dialogContent} style={{ overflow: 'hidden' }}>
				<DialogContentText id={`${id}-description`} className={classes.dialogContentText}>
					{children}
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<SquareButton
					id={`${id}-deny-button`}
					onClick={handleDeny}
					color="default"
					variant="contained"
					className={classnames(styles.focus, classes.denyButton)}>
					{denyText ? denyText : 'No'}
				</SquareButton>
				<SquareButton
					id={`${id}-confirm-button`}
					onClick={handleConfirm}
					color="primary"
					variant="contained"
					className={classnames(styles.focus, classes.confirmButton)}>
					{confirmText ? confirmText : 'Yes'}
				</SquareButton>
			</DialogActions>
		</ZoomDialog>
	);
};

export default ConfirmDenyDialog;
