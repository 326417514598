export const RECOVERY_VERIFICATION_TOKEN = 'recoveryVerifyTk';
const PasswordRecoveryRoot = '/PasswordRecovery';

const PasswordRecovery = {
	Root: PasswordRecoveryRoot,
	Username: `${PasswordRecoveryRoot}/Username`,
	Complete: `${PasswordRecoveryRoot}/Complete`,
	EmailSent: `${PasswordRecoveryRoot}/EmailSent`,
	ChangePassword: `${PasswordRecoveryRoot}/ChangePassword`,
	EmailVerification: `${PasswordRecoveryRoot}/EmailVerification`,
	SecurityQuestions: `${PasswordRecoveryRoot}/SecurityQuestions`,
};

export default PasswordRecovery;
