import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface ISfcPopoverProps extends Omit<PopoverProps, 'classes'> {
	margin?: number;
	padding?: number;
	width?: number | string;
	arrowDirection: 'left' | 'right' | 'up' | 'down';
}

type ISfcPopoverStyleProps = Pick<ISfcPopoverProps, 'margin' | 'padding' | 'width' | 'arrowDirection'>;

const getHelpPopoverBoxPositionProperties = (props: ISfcPopoverStyleProps): Partial<CreateCSSProperties> => {
	switch (props.arrowDirection) {
		case 'up':
			return {
				left: 'calc(50% - 10px)',
				top: -10,
			};

		case 'right':
			return {
				right: -10,
				top: 'calc(50% - 10px)',
			};

		case 'down':
			return {
				left: 'calc(50% - 10px)',
				bottom: -10,
			};

		case 'left':
			return {
				left: -10,
				top: 'calc(50% - 10px)',
			};
	}
};

const getHelpPopoverMargin = (props: ISfcPopoverStyleProps): Partial<CreateCSSProperties> => {
	switch (props.arrowDirection) {
		case 'up':
			return {
				marginTop: props.margin ?? 0,
			};

		case 'right':
			return {
				marginRight: props.margin ?? 0,
			};

		case 'down':
			return {
				marginDown: props.margin ?? 0,
			};

		case 'left':
			return {
				marginLeft: props.margin ?? 0,
			};
	}
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		helpPopover: (props: ISfcPopoverStyleProps) => ({
			boxShadow: theme.shadows[10],
			overflow: 'visible',
			width: props.width ?? 250,
			...getHelpPopoverMargin(props),
		}),

		helpPopoverBox: (props: ISfcPopoverStyleProps) => ({
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[10],
			height: 20,
			position: 'absolute',
			transform: 'rotate(45deg)',
			width: 20,
			zIndex: 1,

			...getHelpPopoverBoxPositionProperties(props),
		}),

		helpPopoverPaper: {
			padding: (props: ISfcPopoverStyleProps): number => props.padding ?? theme.spacing(2),
			position: 'relative',
			zIndex: 2,
		},
	})
);

const SfcPopover: React.FC<ISfcPopoverProps> = ({
	children,

	margin,
	padding,
	width,
	arrowDirection = 'left',

	anchorOrigin = { vertical: 'center', horizontal: 'right' },
	transformOrigin = { vertical: 'center', horizontal: 'left' },
	...popoverProps
}) => {
	const styleProps: ISfcPopoverStyleProps = { margin, padding, width, arrowDirection };

	const classes = useStyles(styleProps);

	return (
		<Popover classes={{ paper: classes.helpPopover }} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} {...popoverProps}>
			<Box className={classes.helpPopoverBox} />
			<Paper className={classes.helpPopoverPaper} elevation={0}>
				{children}
			</Paper>
		</Popover>
	);
};

export default SfcPopover;
