import { globalInitialState, GlobalStateType, GlobalActionTypes, GlobalActions } from './GlobalContext';

export const globalReducer = (state: GlobalStateType = globalInitialState, action: GlobalActions): GlobalStateType => {
	switch (action.type) {
		case GlobalActionTypes.UpdateLoading:
			return {
				...state,
				isLoading: action.payload,
			};

		case GlobalActionTypes.UpdateLoadingSnackbar:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export default globalReducer;
