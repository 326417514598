import Colors, { flattenedColors } from './Colors';
import HttpStatus from './HttpStatus';
import IconSizes from './IconSizes';
import Inputs from './Inputs';
import Misc from './Misc';
import PasswordRecoveryForms from './PasswordRecoveryForms';
import Regex from './Regex';
import SecurityQuestions from './SecurityQuestions';
import Spacing from './Spacing';
import FontWeights from './FontWeights';
import States from './States';
import FontSizes from './FontSizes';
import Copyright from './Copyright';

const Constants = {
	Colors,
	FlattenedColors: flattenedColors,
	HttpStatus,
	IconSizes,
	Inputs,
	Misc,
	PasswordRecoveryForms,
	Regex,
	SecurityQuestions,
	Spacing,
	FontWeights,
	States,
	FontSizes,
	Copyright,
};

export default Constants;
