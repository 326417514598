import BaseTheme from 'sfc-kit/src/BaseTheme';
import { createTheme } from '@material-ui/core/styles';

export default createTheme({
	palette: {
		...BaseTheme.palette,
		accent: {
			main: '#FF5E00',
		},
		danger: {
			main: '#B52E38',
			dark: '#5A171C',
		},
		status: {
			completed: {
				main: '#02C58D',
			},
		},
	},
	appDrawer: {
		width: 285,
	},
});
