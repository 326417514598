import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';
import { ActionMap } from '../../types/Context';
import reducer from './FindDealerReducer';

export enum FindDealerActionTypes {
	VerifyInfo = 'FIND_DEALER_VERIFY_INFO',
	UpdateAppointment = 'FIND_DEALER_UPDATE_APPOINTMENT',
}

export interface Appointment {
	typeOfImprovement: string;
	projectTimeframe: string;
	appointmentDay: string;
	appointmentTime: string;
	comment?: string;
}

export type FindDealerInitialStateType = {
	firstName: string;
	lastName: string;
	email?: string | undefined;
	phoneNumber: string;
	streetAddressOne: string;
	streetAddressTwo?: string;
	city: string;
	state: string;
	zip: string;
	ownProperty?: 'true' | 'false';
	appointment: Appointment;
};

export const FindDealerInitialState: FindDealerInitialStateType = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	streetAddressOne: '',
	city: '',
	state: '',
	zip: '',
	appointment: {
		typeOfImprovement: '',
		projectTimeframe: '',
		appointmentDay: '',
		appointmentTime: '',
	},
};

type FindDealerPayload = {
	[FindDealerActionTypes.VerifyInfo]: Omit<FindDealerInitialStateType, 'appointment'>;
	[FindDealerActionTypes.UpdateAppointment]: Appointment;
};

export type FindDealerActions = ActionMap<FindDealerPayload>[keyof ActionMap<FindDealerPayload>];

export type FindDealerDispatchType = Dispatch<FindDealerActions>;

export const FindDealerContext = createContext<FindDealerInitialStateType & { dispatchForFindDealer: FindDealerDispatchType }>({
	...FindDealerInitialState,
	dispatchForFindDealer: () => null,
});

export function FindDealerProvider({
	initialState = FindDealerInitialState,
	children,
}: PropsWithChildren<{ initialState?: FindDealerInitialStateType }>): React.ReactElement {
	const [state, dispatch] = useReducer(reducer, { ...FindDealerInitialState, ...initialState });

	return <FindDealerContext.Provider value={{ ...state, dispatchForFindDealer: dispatch }}>{children}</FindDealerContext.Provider>;
}

export default FindDealerContext;
