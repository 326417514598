import Environment from './Environment';
import { logoProd, logoDev } from '../constants/Logos';

const GetLogoForEnvironment = (): string => {
	if (Environment.IsProduction() && Environment.isProductionURL()) {
		return logoProd;
	}
	return logoDev;
};

export default GetLogoForEnvironment;
