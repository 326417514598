import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { FlexItem, Button, FlexBox, Text, Spacing, LangText, Modal } from 'sfc-kit';
import { useMessages, useCustomerPortalNotificationStack } from 'hooks';
import LocalStorageKeys from 'constants/LocalStorageKeys';
import * as BrowserStorage from 'sfc-kit/src/utils/BrowserStorage';

interface PaperlessBillingDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onSignUpClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			padding: theme.spacing(2, 3),
			textAlign: 'center',
		},
		buttonWrapper: {
			paddingBottom: theme.spacing(1),
		},
		actionButton: {
			marginRight: theme.spacing(1),
		},
		alertWrapper: {
			maxWidth: '390px',
		},
	})
);

const PaperlessBillingDialog: React.FC<PaperlessBillingDialogProps> = ({ isOpen, onClose, onSignUpClick }) => {
	const messages = useMessages();
	const classes = useStyles({} as any);
	const { createInformationNotification } = useCustomerPortalNotificationStack();

	const generateNotification = useCallback(
		(body: LangText) =>
			createInformationNotification({
				className: classes.alertWrapper,
				title: 'notifications.info',
				dismissible: true,
				disableTimeout: true,
				body,
			}),
		[classes, createInformationNotification]
	);

	const handleOnSignUp = (): void => {
		BrowserStorage.RemoveItem(LocalStorageKeys.PaperlessPromptDate);
		onSignUpClick();
		onClose();
	};
	const handleOnRemindMeLater = (): void => {
		BrowserStorage.SetItem(
			LocalStorageKeys.PaperlessPromptDate,
			DateTime.local()
				.plus({ weeks: 2 })
				.toISO()
		);
		generateNotification('notifications.paperless.ignored');
		onClose();
	};
	const handleOnNotInterested = (): void => {
		BrowserStorage.SetItem(
			LocalStorageKeys.PaperlessPromptDate,
			DateTime.local()
				.plus({ months: 6 })
				.toISO()
		);
		generateNotification('notifications.paperless.ignored');
		onClose();
	};

	return (
		<Modal
			onClose={handleOnRemindMeLater}
			messages={messages}
			id="paperless-prompt"
			open={isOpen}
			title="paperless.optIntoPaperless"
			titleCasing="capitalizeEachWord"
			variant="Success">
			<FlexBox className={classes.contentWrapper} direction="column">
				<FlexItem>
					<Spacing pb="large">
						<Text weight="medium" messages={messages} message="paperless.wantToOptIn" />
					</Spacing>
				</FlexItem>
				<FlexItem>
					<Spacing pb="medium">
						<Button
							onClick={handleOnSignUp}
							casing="capitalizeEachWord"
							messages={messages}
							message="misc.signUp"
							fullWidth
							aria-label="sign up for paperless button"
						/>
					</Spacing>
				</FlexItem>
				<FlexBox className={classes.buttonWrapper} direction="row">
					<FlexItem xs={6}>
						<Spacing mr="small">
							<Button
								onClick={handleOnRemindMeLater}
								color="background.form"
								textColor="cancelButton"
								casing="capitalizeEachWord"
								messages={messages}
								message="misc.remindMeLater"
								fullWidth
								aria-label="remind me later button"
							/>
						</Spacing>
					</FlexItem>
					<FlexItem xs={6}>
						<Spacing ml="small">
							<Button
								onClick={handleOnNotInterested}
								color="background.form"
								textColor="cancelButton"
								casing="capitalizeEachWord"
								messages={messages}
								message="misc.notInterested"
								fullWidth
								aria-label="not interested button"
							/>
						</Spacing>
					</FlexItem>
				</FlexBox>
			</FlexBox>
		</Modal>
	);
};

export default PaperlessBillingDialog;
