import React, { ReactElement } from 'react';

import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, FormControlLabelProps } from '@material-ui/core';

import { Messages, SfcKitMessages, MessagesProps, LangText, Casing } from '../types';
import useSfcKitMessages from '../hooks/useSfcKitMessages';
import generateCasing from '../utils/GenerateCasing';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string;
}

export interface Props<TMessages extends Messages = SfcKitMessages> extends SwitchProps, MessagesProps<TMessages> {
	label?: LangText<TMessages>;
	labelPlacement?: FormControlLabelProps['labelPlacement'];
	labelCasing?: Casing;
	classes?: Styles;
}

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			width: 66,
			height: 24,
			padding: 0,
			margin: theme.spacing(1),
			marginLeft: 0,

			'&:focus-within': {
				boxShadow: '0 0 0 2pt black',
				borderRadius: '5%',
			},
		},
		switchBase: {
			padding: 2,
			'&$checked': {
				transform: 'translateX(32px)',
				color: theme.palette.common.white,
				'& + $track': {
					backgroundColor: '#007f45',
					opacity: 1,
					border: 'none',
				},
			},
			'&$focusVisible $thumb': {
				color: '#52d869',
				border: '0 solid #fff',
			},
		},
		thumb: {
			width: 30,
			height: 20,
			borderRadius: 0,
			color: theme.palette.common.white,
		},
		track: {
			borderRadius: 0,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: '#707070',
			opacity: 1,
			transition: theme.transitions.create(['background-color', 'border']),
		},
		checked: {},
		focusVisible: {},
		core: {
			position: 'absolute',
			top: 11,
			fontWeight: 'bold',
			color: theme.palette.common.white,
			userSelect: 'none',
			lineHeight: '16px',
			pointerEvents: 'none',
		},
		on: {
			left: 4,
		},
		off: {
			left: 36,
			color: theme.palette.common.white,
		},
		box: {
			position: 'relative',
		},
		label: ({ labelCasing }: { labelCasing?: Casing }) => {
			return {
				margin: 0,
				...generateCasing(labelCasing),
			};
		},
	})
);

export default function SquareSwitch<TMessages extends Messages = SfcKitMessages>({
	label,
	labelPlacement,
	messages,
	classes,
	className,
	labelCasing,
	...restProps
}: Props<TMessages>): ReactElement {
	const styles = useStyles({ labelCasing });

	const sfcKitMessages = useSfcKitMessages();

	return (
		<FormControlLabel
			className={`${className} ${styles.label}`}
			control={
				<Box className={styles.box}>
					<Switch
						style={{ marginLeft: 0 }}
						focusVisibleClassName={classes?.focusVisible}
						disableRipple
						classes={{
							root: styles.root,
							switchBase: styles.switchBase,
							thumb: styles.thumb,
							track: styles.track,
							checked: styles.checked,
						}}
						{...restProps}
					/>
					<span className={`${styles.core} ${styles.on}`}>Yes</span>
					<span className={`${styles.core} ${styles.off}`}>No</span>
				</Box>
			}
			label={(label && messages?.[label]) ?? sfcKitMessages[label as keyof SfcKitMessages]}
			labelPlacement={labelPlacement}
		/>
	);
}
