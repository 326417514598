import React from 'react';
import Paths from 'constants/Paths';
import { makeStyles, createStyles } from '@material-ui/core';
import { ErrorBoundaryFallbackComponent, Text, Button, Spacing, FlexBox } from 'sfc-kit';

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[2],
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const ErrorBoundaryFallback: ErrorBoundaryFallbackComponent = () => {
	const styles = useStyles({} as any);

	return (
		<Spacing p="medium" className={styles.root}>
			<FlexBox direction="column" justifyContent="center">
				<Text gutterBottom variant="h4" align="center">
					Oops, something went wrong.
				</Text>
				<Text gutterBottom align="center">
					Click the button below to be taken back to the home page.
				</Text>
				<Spacing mt="medium" mb="xLarge">
					<Button fullWidth to={Paths.Home} className={styles.focus}>
						Home
					</Button>
				</Spacing>
			</FlexBox>
		</Spacing>
	);
};

export default ErrorBoundaryFallback;
