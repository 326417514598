import { DateTime } from 'luxon';
import React, { useEffect, useContext, useCallback } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Box, Theme, IconButton, InputAdornment, makeStyles, createStyles } from '@material-ui/core';

import RegexUtility from 'sfc-kit/src/utils/RegEx';
import { Alert, Text, DateInput } from 'sfc-kit';
import SfcTextField from 'sfc-kit/src/components/SfcForm/SfcTextField';

import { useMessages } from 'hooks';
import { LoanContext } from 'context';
import { EditPaymentCard } from 'components';
import PaymentService from 'services/PaymentService';
import GlobalContext, { GlobalActionTypes } from 'context/Global';
import { Validation } from 'pages/private/PaymentEdit/EditPayment';
import EditPaymentContext, { EditPaymentActionTypes } from 'context/EditPayment';
import FormFieldStatus from 'sfc-kit/src/types/FormFieldStatus';

export interface EditPaymentDetailsFormProps {
	setPaymentAmount: (newPaymentAmount: string) => void;
	paymentAmountStatus: FormFieldStatus;
	paymentDateValidation: Validation;
	setPaymentDateValidation: (newPaymentDateValidation: Validation) => void;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		dividerProperty: {
			backgroundColor: theme.palette.primary.main,
			height: '7px',
			width: '100%',
			marginBottom: '40px',
		},
		noBorderRadius: {
			[`& fieldset`]: {
				borderRadius: 0,
			},
		},
		button: {
			marginTop: theme.spacing(0),
			textTransform: 'none',
			borderRadius: 0,
		},
		cancelButton: { color: theme.palette.primary.light },
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	});
});

const EditPaymentDetailsForm: React.FC<EditPaymentDetailsFormProps> = ({
	paymentDateValidation,
	setPaymentDateValidation,
	setPaymentAmount,
	paymentAmountStatus,
}) => {
	const {
		editPaymentState: { paymentBeingEdited, isPayoffPayment, paymentAmount, paymentDate, paymentFrequency },
		dispatchForEditPayment,
	} = useContext(EditPaymentContext);

	const { selectedLoan } = useContext(LoanContext);

	const { dispatchForGlobal } = useContext(GlobalContext);

	const classes = useStyles({} as any);
	const messages = useMessages();

	const datePickerRef = React.useRef<HTMLDivElement>(null);
	const [holidays, setHolidays] = React.useState<DateTime[]>([]);

	// Get the payoff amount for the loan to find out if this payment was to pay off the loan.
	React.useEffect(() => {
		if (paymentDate === null || selectedLoan === null) {
			return;
		}

		// User can see Date and Frequency disappear if not set early
		dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: true });
		dispatchForEditPayment({ type: EditPaymentActionTypes.SetIsPayoffPayment, payload: false });

		PaymentService.GetPayoffAmount(paymentDate, selectedLoan.loanNumber, selectedLoan.accountReferenceNumber)
			.then(amount => {
				if (parseFloat(amount) === paymentBeingEdited.paymentAmount) {
					dispatchForEditPayment({ type: EditPaymentActionTypes.SetIsPayoffPayment, payload: true });
				}
			})
			.finally(() => dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: false }));
	}, []); // eslint-disable-line

	const onPaymentAmountChange = (newPaymentAmount: string): void => {
		const formattedPaymentAmount = RegexUtility.formatTypedCurrency(newPaymentAmount);

		setPaymentAmount(formattedPaymentAmount);
		dispatchForEditPayment({
			type: EditPaymentActionTypes.SetPaymentAmount,
			payload: formattedPaymentAmount,
		});
	};

	const onPaymentDateChange = (newPaymentDate: DateTime | null): void => {
		dispatchForEditPayment({
			type: EditPaymentActionTypes.SetPaymentDate,
			payload: newPaymentDate,
		});
	};

	const validatePaymentDate = (date: DateTime | null): void => {
		const [isValid, errorText] = PaymentService.IsValidPaymentDateSync(date, holidays);
		setPaymentDateValidation({ isValid, errorText });
	};

	const updateAndValidatePaymentDate = (date: DateTime | null): void => {
		onPaymentDateChange(date);
		validatePaymentDate(date);
	};

	const updateHolidays = useCallback(
		async (date: DateTime | null): Promise<void> => {
			const newHolidays = await PaymentService.GetHolidays(date ?? DateTime.local());

			const [isValid, errorText] = PaymentService.IsValidPaymentDateSync(paymentDate, newHolidays);

			setHolidays(newHolidays);
			setPaymentDateValidation({ isValid, errorText });
		},
		[paymentDate, setHolidays, setPaymentDateValidation]
	);

	useEffect(() => {
		//Keep holidays in sync with payment date
		updateHolidays(paymentDate);
	}, [paymentDate, updateHolidays]);

	const shouldDisableDate = (date: DateTime | null): boolean => {
		if (date === null) {
			return true;
		}

		const [isValid] = PaymentService.IsValidPaymentDateSync(date, holidays);

		return !isValid;
	};

	const onPaymentDateBlur = (): void => {
		validatePaymentDate(paymentDate);
	};

	return (
		<EditPaymentCard title="Payment Details" Avatar={AttachMoneyIcon}>
			<Alert variant="InformationAlternative" messages={messages} title="alerts.paymentDateAndTimeDisclaimer.title">
				<Text variant="caption" messages={messages} message="alerts.paymentDateAndTimeDisclaimer.body" />
			</Alert>
			<SfcTextField
				id="PaymentAmount"
				disabled={isPayoffPayment}
				value={`$${paymentAmount}`}
				placeholder="Payment Amount"
				onChange={onPaymentAmountChange}
				helperText={paymentAmountStatus.errorMessage}
				error={paymentAmountStatus.isValid === false}
				label={isPayoffPayment ? 'Payoff Payment Amount' : 'Payment Amount'}
			/>
			{!isPayoffPayment && (
				<>
					<Box marginY={2}>
						<DateInput
							autoOk
							fullWidth
							disablePast
							id="payment-date"
							onOpen={async (): Promise<void> => updateHolidays(paymentDate)}
							inputVariant="outlined"
							messages={messages}
							label="payments.paymentDate"
							aria-label="Select to open calendar"
							format="MM/dd/yyyy"
							placeholder="mm/dd/yyyy"
							value={paymentDate}
							InputProps={{
								inputRef: datePickerRef,
								onBlur: onPaymentDateBlur,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											className={classes.focus}
											onClick={(): void => updateAndValidatePaymentDate(null)}
											aria-label="Select to clear the date"
											aria-describedby={'payment-date-helper-text'}
											data-testid="icon-button">
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								),
								inputProps: { classes: { root: classes.focus }, tabIndex: 1 },
							}}
							onChange={(date): void => onPaymentDateChange(date)}
							shouldDisableDate={shouldDisableDate}
							onMonthChange={updateHolidays}
							onAccept={updateAndValidatePaymentDate}
							error={!paymentDateValidation.isValid}
							errorText={paymentDateValidation.errorText}
						/>
					</Box>
					<Box className={classes.noBorderRadius}>
						<SfcTextField id="frequency" label="Frequency" disabled value={paymentFrequency} />
					</Box>
				</>
			)}
		</EditPaymentCard>
	);
};

export default EditPaymentDetailsForm;
