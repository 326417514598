import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type BorrowerUsernameResult = QueryResult<GetBorrowerUsernameResponse, AxiosError<GetBorrowerUsernameResponse>>;
export type BorrowerUsernameConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<GetBorrowerUsernameResponse, AxiosError<GetBorrowerUsernameResponse>>;
};

interface BorrowerUsernameQuery {
	(request: BorrowerUsernameRequestParameters, config?: BorrowerUsernameConfig): BorrowerUsernameResult;
	key: 'BorrowerUsername';
}

const useBorrowerUsernameQuery: BorrowerUsernameQuery = (request, config = { axiosConfig: {} }) => {
	async function BorrowerUsername(): Promise<GetBorrowerUsernameResponse> {
		const client = ApiCSGService();

		const path = '/api/Borrower/BorrowerUsername';

		const response = await client.get<GetBorrowerUsernameResponse>(path, {
			loanNumber: request.loanNumber,
			socialSecurityLastFour: request.socialSecurityLastFour,
		});

		return response.data;
	}

	return useQuery(['BorrowerUsername', ...Object.values(request)], BorrowerUsername, config.queryConfig);
};

useBorrowerUsernameQuery.key = 'BorrowerUsername';

export default useBorrowerUsernameQuery;
