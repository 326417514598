import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetLogsResult = QueryResult<void, AxiosError<void>>;
export type GetLogsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<void, AxiosError<void>>;
};

interface GetLogsQuery {
	(request: GetLogsRequestParameters, config?: GetLogsConfig): GetLogsResult;
	key: 'GetLogs';
}

const useGetLogsQuery: GetLogsQuery = (request, config = { axiosConfig: {} }) => {
	async function GetLogs(): Promise<void> {
		const client = ApiCSGService();

		const path = '/api/Log/GetLogs';

		await client.get<void>(path, {
			logEventOrigin: request?.logEventOrigin,
			date: request?.date,
		});

		return;
	}

	return useQuery(['GetLogs', ...Object.values(request)], GetLogs, config.queryConfig);
};

useGetLogsQuery.key = 'GetLogs';

export default useGetLogsQuery;
