import classnames from 'classnames';
import React, { ReactNode, useMemo, ReactElement } from 'react';

import { makeStyles, createStyles } from '@material-ui/core';

import {
	ExtractColorFromKeyString,
	ConvertStatusVariantToValues,
	//
	Constants,
	//
	Spacing,
	FlexBox,
	FlexItem,
	Text,
	Icon,
	//
	LangText,
	Color,
	StatusVariant,
	Messages,
	SfcKitMessages,
	MessagesProps,
	StatusValues,
} from '../..';

export interface AlertProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	title?: LangText<TMessages>;
	children?: ReactNode;
	hideIcon?: boolean;

	body?: LangText<TMessages>;
	variant?: StatusVariant;

	classes?: {
		root?: string;
		bar?: string;
		icon?: string;
		title?: string;
		body?: string;
	};
}

const useStyles = makeStyles(theme =>
	createStyles({
		alert: {
			backgroundColor: Constants.Colors.background.alert,
			boxShadow: theme.shadows[2],
		},
		bar: ({ color, hideIcon }: { color: Color; hideIcon: boolean }) => ({
			backgroundColor: ExtractColorFromKeyString(color),
			borderTopRightRadius: 20,
			borderBottomRightRadius: 20,
			marginRight: hideIcon ? 0 : theme.spacing(Constants.Spacing.small),
			width: 6,
		}),
	})
);

export default function Alert<TMessages extends Messages = SfcKitMessages>({
	title,
	body,
	children,
	variant = 'Success',
	messages,
	hideIcon = false,
	classes = {},
}: AlertProps<TMessages>): ReactElement | null {
	const {
		color,
		icons: { standard: icon },
	} = useMemo<StatusValues>(() => ConvertStatusVariantToValues(variant), [variant]);

	const styles = useStyles({ color, hideIcon });

	return (
		<FlexBox alignItems="center" className={classnames(styles.alert, classes.root)}>
			<FlexItem alignSelf="stretch" className={classnames(styles.bar, classes.bar)} />
			{!hideIcon && <Icon color={color} icon={icon} className={classes.icon} />}
			<FlexItem xs>
				<Spacing p="small">
					{title && <Text gutterBottom variant="subtitle2" weight="medium" message={title} className={classes.title} messages={messages} />}
					{body ? <Text variant="body2" message={body} className={classes.body} messages={messages} /> : children}
				</Spacing>
			</FlexItem>
		</FlexBox>
	);
}
