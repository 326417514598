export * from './IsSameDay';
export * from './AwaitBlockingPromise';
export * from './GetShortPaymentFrequency';
export * from './ToTitleCase';
export * from './GetFirstNonEmptyString';
export * from './generateLoanDetails';
export * from './GetHistorySelectedLoanNumber';
export * from './GetLoanLeadId';

export function AppendPercentSign(text: string): string {
	return `${text}%`;
}
export function PrependDollarSign(text: string): string {
	return `$${text}`;
}
