import React, { Component, ErrorInfo, FunctionComponent, ReactNode } from 'react';

interface ErrorBoundaryState {
	error: Error | null;
	errorInfo: ErrorInfo | null;
}

export interface ErrorBoundaryFallbackProps {
	error: Error | null;
	errorInfo: ErrorInfo;
}

export type ErrorBoundaryFallbackComponent = FunctionComponent<ErrorBoundaryFallbackProps>;

export interface ErrorBoundaryProps {
	Fallback: ErrorBoundaryFallbackComponent;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);

		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		this.setState({
			error,
			errorInfo,
		});
	}

	render(): ReactNode {
		const { error, errorInfo } = this.state;
		const { children, Fallback } = this.props;

		if (errorInfo !== null) {
			return <Fallback error={error} errorInfo={errorInfo} />;
		}

		return children;
	}
}
