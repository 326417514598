export default {
	xSmall: '0.625rem',
	small: '0.75rem',
	medium: '0.875rem',
	large: '1rem',
	xLarge: '1.125rem',
	h1: '6rem',
	h2: '3.75rem',
	h3: '3rem',
	h4: '2.125rem',
	h5: '1.5rem',
	h6: '1.25rem',
};
