import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetInitialDocumentPdfResult = QueryResult<FileDto, AxiosError<FileDto>>;
export type GetInitialDocumentPdfConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<FileDto, AxiosError<FileDto>>;
};

interface GetInitialDocumentPdfQuery {
	(request: GetInitialDocumentPdfRequestParameters, config?: GetInitialDocumentPdfConfig): GetInitialDocumentPdfResult;
	key: 'GetInitialDocumentPdf';
}

const useGetInitialDocumentPdfQuery: GetInitialDocumentPdfQuery = (request, config = { axiosConfig: {} }) => {
	async function GetInitialDocumentPdf(): Promise<FileDto> {
		const client = ApiCSGService();

		let path = '/api/InitialDocuments/{documentId}/Pdf';

		path = path.replace('{documentId}', request.documentId.toString());

		const response = await client.get<FileDto>(path, {
			nLSDocumentId: request?.nLSDocumentId,
			loanNumber: request?.loanNumber,
			taskId: request?.taskId,
		});

		return response.data;
	}

	return useQuery(['GetInitialDocumentPdf', ...Object.values(request)], GetInitialDocumentPdf, config.queryConfig);
};

useGetInitialDocumentPdfQuery.key = 'GetInitialDocumentPdf';

export default useGetInitialDocumentPdfQuery;
