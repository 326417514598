import { DateTime } from 'luxon';
import React, { createContext, useReducer, Dispatch, PropsWithChildren } from 'react';

import { ActionMap } from 'types/Context';
import { PaymentFrequencies } from 'types';
import editPaymentReducer from './EditPaymentReducer';

export interface EditPaymentStateType {
	paymentBeingEdited: ScheduledAchPayment;
	bankAccountInfo: {
		accountHolder?: string;
		routingNumber: string;
		accountNumber: string;
		accountType: number;
	};
	paymentAmount: string;
	paymentDate: DateTime | null;
	paymentFrequency: PaymentFrequencies;
	isPayoffPayment: boolean;
	extraAchPayment?: ScheduledAchPayment;
	confirmedMultiplePayments: boolean;
}

export const editPaymentInitialState: EditPaymentStateType = {
	paymentBeingEdited: {} as ScheduledAchPayment,
	bankAccountInfo: {
		accountNumber: '',
		routingNumber: '',
		accountHolder: '',
		accountType: 0,
	},
	paymentAmount: '',
	paymentDate: null,
	paymentFrequency: PaymentFrequencies.OneTime,
	isPayoffPayment: false,
	extraAchPayment: undefined,
	confirmedMultiplePayments: false,
};

export enum EditPaymentActionTypes {
	InitializeEditPayment = 'INITIALIZE_EDIT_PAYMENT',
	ClearState = 'CLEAR_EDIT_PAYMENT_STATE',
	SetPaymentDate = 'SET_PAYMENT_DATE',
	SetPaymentAmount = 'SET_PAYMENT_AMOUNT',
	SelectBankAccount = 'SELECT_BANK_ACCOUNT',
	SetPaymentFrequency = 'SET_PAYMENT_FREQUENCY',
	SetExtraAchPayment = 'SET_EXTRA_ACH_PAYMENT',
	ConfirmMultiplePayments = 'CONFIRM_MULTIPLE_PAYMENTS',
	SetIsPayoffPayment = 'EDIT_PAYMENT_SET_IS_PAYOFF_PAYMENT',
}

type EditPaymentPayload = {
	[EditPaymentActionTypes.InitializeEditPayment]: ScheduledAchPayment;
	[EditPaymentActionTypes.ClearState]: undefined;
	[EditPaymentActionTypes.SetPaymentDate]: EditPaymentStateType['paymentDate'];
	[EditPaymentActionTypes.SetPaymentAmount]: EditPaymentStateType['paymentAmount'];
	[EditPaymentActionTypes.SetPaymentFrequency]: EditPaymentStateType['paymentFrequency'];
	[EditPaymentActionTypes.SelectBankAccount]: EditPaymentStateType['bankAccountInfo'];
	[EditPaymentActionTypes.SetExtraAchPayment]: EditPaymentStateType['extraAchPayment'];
	[EditPaymentActionTypes.ConfirmMultiplePayments]: undefined;
	[EditPaymentActionTypes.SetIsPayoffPayment]: EditPaymentStateType['isPayoffPayment'];
};

export type EditPaymentActions = ActionMap<EditPaymentPayload>[keyof ActionMap<EditPaymentPayload>];

export type EditPaymentDispatchType = Dispatch<EditPaymentActions>;

export const EditPaymentContext = createContext<{
	editPaymentState: EditPaymentStateType;
	dispatchForEditPayment: EditPaymentDispatchType;
}>({
	editPaymentState: editPaymentInitialState,
	dispatchForEditPayment: () => null,
});

export function EditPaymentProvider({
	initialState = editPaymentInitialState,
	children,
}: PropsWithChildren<{ initialState?: Partial<EditPaymentStateType> }>): React.ReactElement {
	const [state, dispatch] = useReducer(editPaymentReducer, { ...editPaymentInitialState, ...initialState });

	return (
		<EditPaymentContext.Provider value={{ editPaymentState: state, dispatchForEditPayment: dispatch }}>{children}</EditPaymentContext.Provider>
	);
}

export default EditPaymentContext;
