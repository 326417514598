const arrayOfImages = ['/svcfin_welcome_1.jpeg', '/svcfin_welcome_2.jpeg', '/svcfin_welcome_3.jpeg', '/svcfin_welcome_4.jpeg'];

type RandomBackgroundCSSProperties = Pick<React.CSSProperties, 'background' | 'backgroundSize' | 'backgroundPosition'>;

export const generateRandomBackground = (): RandomBackgroundCSSProperties => {
	const randomBackground = arrayOfImages[Math.floor(Math.random() * 4)];

	const defaultStyling: RandomBackgroundCSSProperties = {
		background: `url(${randomBackground}) no-repeat`,
		backgroundSize: 'cover',
		backgroundPosition: '20% 25%',
	};

	if (randomBackground === arrayOfImages[0]) {
		return { ...defaultStyling, backgroundPosition: '20% 40%' };
	}

	return defaultStyling;
};

export default generateRandomBackground;
