import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { FlexBox, Spacing } from '..';
import Tab, { TabProps } from './Tab/Tab';

export interface TabOptions {
	id: number;
	name: string;
	label: string;
	status: TabProps['status'];
}

interface TabsProps {
	tabValue?: number;
	clickHandler: (value: number) => void;
	tabOptions?: TabOptions[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		documentStatus: ({ laptopSize }: { laptopSize: boolean }) => ({
			flex: laptopSize ? 1 : undefined,
			backgroundColor: theme.palette.grey[200],
			padding: theme.spacing(0.2, 1, 0, 1),
			'& button': laptopSize ? { flex: 1 } : undefined,
		}),

		spacing: {
			display: 'flex',
		},
	})
);

const Tabs: React.FC<TabsProps> = ({ tabOptions, tabValue, clickHandler }) => {
	const theme = useTheme();
	const laptopSize = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles({ laptopSize });

	return (
		<FlexBox direction={laptopSize ? 'column' : 'row'} className={classes.documentStatus}>
			{tabOptions &&
				tabOptions.map(({ id, name, label, status }) => (
					<Spacing key={`Tab-${name}`} mb={laptopSize ? 'xSmall' : undefined} className={classes.spacing} fullWidth={laptopSize}>
						<Tab active={tabValue === id} handleOnClick={clickHandler} label={label} value={id} status={status} />
					</Spacing>
				))}
		</FlexBox>
	);
};

export default Tabs;
