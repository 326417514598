import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Dialog as MuiDialog, DialogProps as MuiDialogProps, makeStyles, createStyles } from '@material-ui/core';

export type DialogProps = MuiDialogProps;

const useStyles = makeStyles(
	createStyles({
		paper: {
			borderRadius: 0,
		},
	})
);

const Dialog: FunctionComponent<DialogProps> = ({ classes, ...props }) => {
	const styles = useStyles();

	return <MuiDialog {...props} classes={{ ...classes, paper: classnames(styles.paper, classes?.paper) }} />;
};

export default Dialog;
