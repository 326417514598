import React from 'react';
import clsx from 'clsx';

import { Grid, createStyles, makeStyles, Theme, GridProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			backgroundColor: theme.palette.grey[100],
			marginBottom: theme.spacing(1),
			borderTop: `2px solid ${theme.palette.grey[200]}`,
			borderBottom: `2px solid ${theme.palette.grey[200]}`,
			padding: theme.spacing(1),
		},
	})
);

const ContainerHeader: React.FC<GridProps> = ({
	children,
	className,
	justify = 'space-between',
	alignItems = 'center',
	container = true,
	...GridProps
}) => {
	const classes = useStyles({} as any);
	return (
		<Grid container={container} justifyContent={justify} alignItems={alignItems} className={clsx(classes.header, className)} {...GridProps}>
			{children}
		</Grid>
	);
};

export default ContainerHeader;
