import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetSalesPersonForDealerResult = QueryResult<SalesPersonForDealer, AxiosError<SalesPersonForDealer>>;
export type GetSalesPersonForDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<SalesPersonForDealer, AxiosError<SalesPersonForDealer>>;
};

interface GetSalesPersonForDealerQuery {
	(request: GetSalesPersonForDealerRequestParameters, config?: GetSalesPersonForDealerConfig): GetSalesPersonForDealerResult;
	key: 'GetSalesPersonForDealer';
}

const useGetSalesPersonForDealerQuery: GetSalesPersonForDealerQuery = (request, config = { axiosConfig: {} }) => {
	async function GetSalesPersonForDealer(): Promise<SalesPersonForDealer> {
		const client = ApiCSGService();

		let path = '/api/SalesPeople/ForDealer/{taskId}';

		path = path.replace('{taskId}', request.taskId.toString());

		const response = await client.get<SalesPersonForDealer>(path);

		return response.data;
	}

	return useQuery(['GetSalesPersonForDealer', ...Object.values(request)], GetSalesPersonForDealer, config.queryConfig);
};

useGetSalesPersonForDealerQuery.key = 'GetSalesPersonForDealer';

export default useGetSalesPersonForDealerQuery;
