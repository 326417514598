import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanDetailTwoUdfResult = QueryResult<string, AxiosError<string>>;
export type LoanDetailTwoUdfConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<string, AxiosError<string>>;
};

interface LoanDetailTwoUdfQuery {
	(request: LoanDetailTwoUdfRequestParameters, config?: LoanDetailTwoUdfConfig): LoanDetailTwoUdfResult;
	key: 'LoanDetailTwoUdf';
}

const useLoanDetailTwoUdfQuery: LoanDetailTwoUdfQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanDetailTwoUdf(): Promise<string> {
		const client = ApiCSGService();
		
		const { loanNumber, udfNumber } = request;

		const path = `/api/Loans/${loanNumber}/LoanDetailTwoUdf/${udfNumber}`;

		const response = await client.get<string>(path);

		return response.data;
	}

	return useQuery(['LoanDetailTwoUdf', ...Object.values(request)], LoanDetailTwoUdf, config.queryConfig);
};

useLoanDetailTwoUdfQuery.key = 'LoanDetailTwoUdf';

export default useLoanDetailTwoUdfQuery;
