import React from 'react';
import { DateTime } from 'luxon';
import { Text } from 'sfc-kit';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Paths from 'constants/Paths';
import StatementPaths from 'constants/Paths/Statements';
import { useMessages } from 'hooks';

interface SecondParagraphPros {
	pastDue: boolean;
	dueDate?: DateTime;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			marginRight: theme.spacing(0.5),
		},
	})
);

const PaymentInfoSecondParagraph: React.FC<SecondParagraphPros> = ({ pastDue, dueDate }) => {
	const classes = useStyles({} as any);
	const messages = useMessages();

	if (pastDue) {
		return (
			<>
				<Text inline messages={messages} message="payments.reviewYourLatest" className={classes.text} />
				<Link to={StatementPaths.Root} tabIndex={1}>
					<Text inline className={classes.text} weight="medium" color="action.main" messages={messages} message="payments.statements" />
				</Link>
				<Text inline messages={messages} message="misc.or" className={classes.text} />
				<Link to={Paths.LoanOverview} tabIndex={1}>
					<Text inline className={classes.text} weight="medium" color="action.main" messages={messages} message="payments.loanOverview" />
				</Link>
				<Text inline messages={messages} message="payments.forMoreInfo" className={classes.text} />
			</>
		);
	}

	return (
		<>
			<Text inline className={classes.text} messages={messages} message="payments.nextMonthPayment" />
			<Text inline>{dueDate?.toUTC().toFormat('LL/dd/yyyy')}</Text>
		</>
	);
};

export default PaymentInfoSecondParagraph;
