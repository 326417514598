import { ActionMap } from 'types/Context';
import React, { Dispatch, createContext, PropsWithChildren, useReducer, useEffect } from 'react';
import reducer from './AuthenticationReducer';
import { DateTime } from 'luxon';
import Misc from 'constants/Misc';

export enum AuthenticationActionTypes {
	ClearAuthToken = 'CLEAR_AUTH_TOKEN',
	LoadAuthState = 'LOAD_AUTH_STATE',
	UpdateAuthState = 'UPDATE_AUTH_STATE',
	UpdateAuthToken = 'UPDATE_AUTH_TOKEN',
	UpdateUsername = 'UPDATE_USERNAME',
}

export type UseTypeType = {
	id: string;
	name: string;
	description: string;
};
export type AuthenticationInitialStateType = {
	authToken?: string; //bearer Token
	refreshToken?: string; //refresh Token
	otherToken?: string; //nls Token
	isAuthenticated: boolean;
	authTokenExpiration?: string | null;
	username: string;
	useType?: UseTypeType;
	roles?: Array<string>;
};

const parsedAuthSession: AuthenticationInitialStateType = JSON.parse(sessionStorage.getItem('Authentication') ?? '{}');

export const AuthenticationInitialState: AuthenticationInitialStateType = {
	isAuthenticated: false,
	authTokenExpiration: null,
	username: '',
};

type AuthenticationPayload = {
	[AuthenticationActionTypes.LoadAuthState]: undefined;
	[AuthenticationActionTypes.ClearAuthToken]: undefined;
	[AuthenticationActionTypes.UpdateAuthState]: AuthenticationInitialStateType;
	[AuthenticationActionTypes.UpdateAuthToken]: AuthenticationInitialStateType['authToken'];
	[AuthenticationActionTypes.UpdateUsername]: AuthenticationInitialStateType['username'];
};

export type AuthenticationActions = ActionMap<AuthenticationPayload>[keyof ActionMap<AuthenticationPayload>];

export type AuthenticationDispatchType = Dispatch<AuthenticationActions>;

export const AuthenticationContext = createContext<{
	authenticationState: AuthenticationInitialStateType;
	dispatchForAuthentication: AuthenticationDispatchType;
}>({
	authenticationState: AuthenticationInitialState,
	dispatchForAuthentication: () => null,
});

export function AuthenticationProvider({
	initialState = AuthenticationInitialState,
	children,
}: PropsWithChildren<{ initialState?: AuthenticationInitialStateType }>): React.ReactElement {
	const [state, dispatch] = useReducer(reducer, { ...AuthenticationInitialState, ...initialState });
	useEffect(() => {
		dispatch({ type: AuthenticationActionTypes.LoadAuthState });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const { isAuthenticated, authToken, authTokenExpiration } = state;

		const authExpired =
			!authToken ||
			!isAuthenticated ||
			!authTokenExpiration ||
			DateTime.fromISO(authTokenExpiration)
				.diffNow()
				.valueOf() <= 0;

		if (authExpired) {
			sessionStorage.setItem(Misc.SessionStorage.Authentication, JSON.stringify(AuthenticationInitialState));
		} else {
			sessionStorage.setItem(Misc.SessionStorage.Authentication, JSON.stringify({ ...state }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	return (
		<AuthenticationContext.Provider
			value={{
				authenticationState: {
					...state,
					authToken: state.authToken ?? parsedAuthSession.authToken ?? '',
					otherToken: state.otherToken ?? parsedAuthSession.otherToken ?? '',
					refreshToken: state.refreshToken ?? parsedAuthSession.refreshToken ?? '',
					authTokenExpiration: state.authTokenExpiration ?? parsedAuthSession.authTokenExpiration ?? '',
				},
				dispatchForAuthentication: dispatch,
			}}>
			{children}
		</AuthenticationContext.Provider>
	);
}

export default AuthenticationContext;
