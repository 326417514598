import { ILocationState } from '../types/LoactionState';

export const getHistorySelectedLoanNumber = (locationState: ILocationState): string => {
	let stateLocation = '';
	if (locationState && locationState !== null) {
		Object.entries(locationState).forEach(([key, value]) => {
			if (key === 'selectedLoanNumber') {
				stateLocation = value;
			}
		});
	}
	return stateLocation;
};

export default getHistorySelectedLoanNumber;
