import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PayoffProgressResult = QueryResult<PayoffProgress, AxiosError<PayoffProgress>>;
export type PayoffProgressConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<PayoffProgress, AxiosError<PayoffProgress>>;
};

interface PayoffProgressQuery {
	(request: PayoffProgressRequestParameters, config?: PayoffProgressConfig): PayoffProgressResult;
	key: 'PayoffProgress';
}

const usePayoffProgressQuery: PayoffProgressQuery = (request, config = { axiosConfig: {} }) => {
	async function PayoffProgress(): Promise<PayoffProgress> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/PayoffProgress`;

		const response = await client.get<PayoffProgress>(path);

		return response.data;
	}

	return useQuery(['PayoffProgress', ...Object.values(request)], PayoffProgress, config.queryConfig);
};

usePayoffProgressQuery.key = 'PayoffProgress';

export default usePayoffProgressQuery;
