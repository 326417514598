import * as Yup from 'yup';

export type ValidationResult = [boolean, string | undefined];

export type ValidateSyncFunc<TState> = (value: TState) => ValidationResult;
export type ValidateFunc<TState> = (value: TState) => Promise<ValidationResult>;
export default class Validator<TState> {
	private readonly validateFunc?: ValidateFunc<TState>;
	private readonly validateSyncFunc?: ValidateSyncFunc<TState>;

	/**
	 * Asynchronously validates the given value using the validateFunc given at object construction.
	 * @param value The value to validate
	 * @returns The promise of a ValidationResult
	 */
	public Validate(value: TState): Promise<ValidationResult> {
		if (this.validateFunc === undefined) {
			return Promise.reject('Validate cannot be called because an implementation was not provided.');
		}

		return this.validateFunc(value);
	}

	/**
	 * Validates the given value using the validateSyncFunc given at object construction.
	 * @param value The value to validate
	 * @returns A ValidationResult
	 */
	public ValidateSync(value: TState): ValidationResult {
		if (this.validateSyncFunc === undefined) {
			throw new Error('ValidateSync cannot be called because an implementation was not provided.');
		}

		return this.validateSyncFunc(value);
	}

	/**
	 * Creates a new Validator object
	 * @param validateFunc The function to call as part of the Validate method
	 * @param validateSyncFunc The function to call as part of the ValidateSync method
	 */
	constructor(validateFunc?: ValidateFunc<TState>, validateSyncFunc?: ValidateSyncFunc<TState>) {
		this.validateFunc = validateFunc;
		this.validateSyncFunc = validateSyncFunc;
	}

	public static AlwaysValid<T>(): Validator<T> {
		return new Validator<T>(
			async () => Promise.resolve([true, undefined]),
			() => [true, undefined]
		);
	}

	/**
	 * Creates a new Validator object from a Yup Schema.
	 * @see https://github.com/jquense/yup
	 */
	public static FromYupSchema<T = string>(schema: Yup.Schema<T>): Validator<T> {
		return new Validator<T>(
			async (value: T) => {
				try {
					await schema.validate(value);

					return [true, undefined];
				} catch (error) {
					// Handle Yup validation errors specifically
					return [false, (error as Error).message];
				}
			},
			(value: T) => {
				try {
					schema.validateSync(value);

					return [true, undefined];
				} catch (error) {
					return [false, (error as Error).message];
				}
			}
		);
	}
}

export type ValidatorSetupFunc<TState, TValidator extends Validator<TState>> = (value: TState, validator: TValidator) => TValidator;
