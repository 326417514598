export default class NumberUtility {
	static parseFloat(float?: string): number {
		if (float === undefined) {
			return NaN;
		}

		const matches = float.match(/[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/g);

		if (matches == null) {
			return NaN;
		}

		return parseFloat(matches.join(''));
	}

	static calculatePercentage(part: number, whole: number, maximum?: number): number {
		const percentage = Math.floor((part / whole) * 100);

		if (maximum) {
			return percentage > maximum ? maximum : percentage;
		}

		return percentage;
	}

	// Taken from https://stackoverflow.com/a/149099/5495159
	static formatCurrency(amount: any, decimalCount = 2, decimal = '.', thousands = ','): string {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < 0 ? '-' : '';

		const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
		const j = i.length > 3 ? i.length % 3 : 0;

		return (
			negativeSign +
			(j ? i.substr(0, j) + thousands : '') +
			i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
			(decimalCount
				? decimal +
				  Math.abs(amount - +i)
						.toFixed(decimalCount)
						.slice(2)
				: '')
		);
	}

	static maskPartialString(unformatted: string, maskChar = '*', trailingLen = 4): string {
		if (unformatted.length <= trailingLen) {
			return unformatted;
		}

		return unformatted.replace(new RegExp('.(?=.{' + trailingLen + '})', 'g'), maskChar);
	}

	static parseCurrency(currency?: string, decimalCount = 2): string {
		return NumberUtility.formatCurrency(NumberUtility.parseFloat(currency), decimalCount);
	}

	static stringToFloat(currency: string): number {
		return parseFloat(currency.split(',').join(''));
	}
}
