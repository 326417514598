import { DateTime } from 'luxon';

import { IsSameDay } from 'utils';
import { CustomerPortalMessages, PaymentFrequencies } from 'types';
import CommonServicesGatewayAPI from 'utils/CommonServicesGatewayAPI';

import { LangText } from 'sfc-kit';

const CLOSING_HOUR = 18; // Sfc closes at 6PM so add 12 to convert to 24-hour time.
const SATURDAY = 6;
const SUNDAY = 7;

/**
 * A service containing all of the business logic related to payments
 */
export class PaymentService {
	/**
	 * Gets an array of DateTimes representing the holidays for a given DateTime
	 * @param dateTime The date to retrieve holidays for
	 * @param monthsPrior The number of months prior to date to include. Default is 1
	 * @param monthsAfter The number of months after the date to include Default is 2
	 */
	public async GetHolidays(dateTime: DateTime | null, monthsPrior = 1, monthsAfter = 2): Promise<DateTime[]> {
		if (dateTime == null || !dateTime.isValid) {
			return [];
		}

		const params: HolidayDaysRequestParameters = {
			start: dateTime
				.minus({ month: monthsPrior })
				.startOf('day')
				.toISO(),
			end: dateTime
				.plus({ month: monthsAfter })
				.endOf('day')
				.toISO(),
			state: 0,
		};

		const { data: envelope } = await CommonServicesGatewayAPI.HolidayDays(params);

		if (!envelope.success) {
			throw new Error(
				`An unexpected error was encountered when retrieving holidays with the following parameters: ${JSON.stringify(params, null, 4)}`
			);
		}

		return envelope.response.holidays?.map(stringDate => DateTime.fromISO(stringDate)) ?? [];
	}

	/**
	 * Returns whether a given DateTime is valid for scheduling a payment
	 * @param dateTime The DateTime to validate
	 * @param holidays An array of DateTimes representing holidays
	 */
	public IsValidPaymentDateSync(dateTime: DateTime | null, holidays: DateTime[]): [boolean, LangText<CustomerPortalMessages>] | [boolean] {
		if (dateTime === null) {
			return [false, 'payments.paymentDateValidation.required'];
		}

		if (!dateTime.isValid) {
			return [false, 'payments.paymentDateValidation.invalidDate'];
		}

		const localNow = DateTime.local().setZone('America/New_York');

		// After close
		if (IsSameDay(dateTime, localNow) && localNow.hour >= CLOSING_HOUR) {
			return [false, 'payments.paymentDateValidation.afterClose'];
		}

		// In the past and is not the same day
		if (dateTime < localNow && !IsSameDay(dateTime, localNow)) {
			return [false, 'payments.paymentDateValidation.past'];
		}

		// Weekend
		if (dateTime.weekday === SATURDAY || dateTime.weekday === SUNDAY) {
			return [false, 'payments.paymentDateValidation.weekend'];
		}

		// Holiday
		if (holidays.some(holiday => IsSameDay(dateTime, holiday))) {
			return [false, 'payments.paymentDateValidation.holiday'];
		}

		// Within the year
		// it is now updated to within 30 days
		if (dateTime > localNow.plus({ days: 30 })) {
			return [false, 'payments.paymentDateValidation.withinAMonth'];
		}

		return [true];
	}

	/**
	 * Validates a given date, incrementing it by 1 day until it is valid
	 */
	public async GetNextValidPaymentDate(date: DateTime): Promise<DateTime> {
		const { data } = await CommonServicesGatewayAPI.GetNextValidPaymentDate({
			paymentDate: date.toISO(),
		});

		return DateTime.fromISO(data);
	}

	public async ScheduleAchPayment(body: ScheduleAchPaymentRequest): Promise<ScheduleAchPaymentResponse> {
		const { data: response } = await CommonServicesGatewayAPI.ScheduleAchPayment({ body });

		return response;
	}

	public async DeleteScheduledAchPayment(rowId: number, loanNumber: string): Promise<boolean> {
		const { data: emailSent } = await CommonServicesGatewayAPI.DeleteScheduledAchPayment({
			body: { rowId, loanNumber },
		});

		return emailSent;
	}

	public async ExpediteScheduledAchPayment(rowId: number, loanNumber: string, customerNumber: string): Promise<boolean> {
		const { data: emailSent } = await CommonServicesGatewayAPI.ExpediteScheduledAchPayment({
			body: {
				rowId,
				loanNumber,
				customerNumber,
			},
		});

		return emailSent;
	}

	public async UpdatedScheduledAchPayment(body: UpdateScheduledAchPaymentRequest): Promise<void> {
		await CommonServicesGatewayAPI.UpdateScheduledAchPayment({ body });
	}

	/**
	 * Gets the Payoff Amount for a given loan and date.
	 * @param date The date to calculate the payoff amount for.
	 * @param loanNumber The loan number for the loan to calculate the payoff of.
	 * @param loanAccountId The loan account ID for the loan to calculate the payoff of.
	 */
	public async GetPayoffAmount(date: DateTime, loanNumber: string, loanAccountId: number): Promise<string> {
		const { data: envelope } = await CommonServicesGatewayAPI.GetPayoffAmount({
			loanNumber,
			loanAccountId,
			payoffDate: date.toUTC().toString(),
		});

		if (!envelope.success) {
			throw new Error(envelope.errorMessage);
		}

		return envelope.response;
	}

	public GetPaymentFrequencyFromTypeAndPeriod(paymentType: string, paymentPeriod?: string): PaymentFrequencies {
		if (paymentType !== 'Recurring') {
			return PaymentFrequencies.OneTime;
		}

		if (paymentPeriod === 'Bi-Weekly') {
			return PaymentFrequencies.BiWeekly;
		}

		return PaymentFrequencies.Monthly;
	}

	public GetPaymentFrequencyFromPayment(payment?: ScheduledAchPayment): PaymentFrequencies {
		if (payment === undefined) {
			return PaymentFrequencies.OneTime;
		}

		return this.GetPaymentFrequencyFromTypeAndPeriod(payment.paymentType ?? '', payment.paymentPeriod);
	}

	public GetPaymentTypeFromFrequency(paymentFrequency: PaymentFrequencies): string {
		switch (paymentFrequency) {
			case PaymentFrequencies.BiWeekly:
				return 'Bi-Weekly';

			case PaymentFrequencies.Monthly:
				return 'Monthly';

			default:
			case PaymentFrequencies.OneTime:
				return 'Single';
		}
	}
}

const paymentService = new PaymentService();

export default paymentService;
