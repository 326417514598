import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanDocumentsResult = QueryResult<GetLoanDocumentsResponse, AxiosError<GetLoanDocumentsResponse>>;
export type LoanDocumentsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<GetLoanDocumentsResponse, AxiosError<GetLoanDocumentsResponse>>;
};

interface LoanDocumentsQuery {
	(request: LoanDocumentsRequestParameters, config?: LoanDocumentsConfig): LoanDocumentsResult;
	key: 'LoanDocuments';
}

const useLoanDocumentsQuery: LoanDocumentsQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanDocuments(): Promise<GetLoanDocumentsResponse> {
		const client = ApiCSGService();

		const path = '/api/Loans/LoanDocuments';

		const response = await client.get<GetLoanDocumentsResponse>(path, {
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['LoanDocuments', ...Object.values(request)], LoanDocuments, config.queryConfig);
};

useLoanDocumentsQuery.key = 'LoanDocuments';

export default useLoanDocumentsQuery;
