import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type UnlockAccountResult = MutationResultPair<boolean, AxiosError<boolean>, UnlockAccountRequestParameters, unknown>;

export type UnlockAccountConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<boolean, AxiosError<boolean>, UnlockAccountRequestParameters>;
};

export default function useUnlockAccountMutation(config: UnlockAccountConfig = { axiosConfig: {} }): UnlockAccountResult {
	async function UnlockAccount(request: UnlockAccountRequestParameters): Promise<boolean> {
		const api = ApiCSGService();

		const path = '/api/Authentication/UnlockAccount';

		const { data } = await api.post<boolean>(path, request.body);

		return data;
	}

	return useMutation(UnlockAccount, config.mutationConfig);
}
