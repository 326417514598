import React from 'react';
import classnames from 'classnames';
import { type FontWeightProperty } from 'csstype';

import Grid from '@material-ui/core/Grid';
import { red, amber } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export enum Strengths {
	Unknown = '',
	Weak = 'Weak',
	Good = 'Good',
	Strong = 'Strong',
}

interface IStrenghtMeterProps {
	strength: Strengths;
	helperTextId: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linearProgress: {
			height: 6,

			'& div': {
				backgroundColor: theme.palette.grey[200],
			},
		},
		linearProgressRed: {
			'& div': {
				backgroundColor: red[400],
			},
		},
		linearProgressYellow: {
			'& div': {
				backgroundColor: amber[300],
			},
		},
		linearProgressGreen: {
			'& div': {
				backgroundColor: theme.palette.primary.main,
			},
		},
		helperText: {
			color: theme.palette.common.black,
			fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
			margin: 0,
			textAlign: 'right',
		},
	})
);

const StrengthMeter: React.FC<IStrenghtMeterProps> = ({ strength, helperTextId }) => {
	const classes = useStyles({} as any);
	return (
		<Grid container spacing={1}>
			<Grid item xs={4}>
				<LinearProgress
					value={100}
					tabIndex={-1}
					aria-hidden="true"
					variant="determinate"
					data-testid="strength-meter-bar"
					className={classnames(classes.linearProgress, {
						[classes.linearProgressRed]: strength === Strengths.Weak,
						[classes.linearProgressYellow]: strength === Strengths.Good,
						[classes.linearProgressGreen]: strength === Strengths.Strong,
					})}
				/>
			</Grid>
			<Grid item xs={4}>
				<LinearProgress
					value={100}
					tabIndex={-1}
					aria-hidden="true"
					variant="determinate"
					data-testid="strength-meter-bar"
					className={classnames(classes.linearProgress, {
						[classes.linearProgressYellow]: strength === Strengths.Good,
						[classes.linearProgressGreen]: strength === Strengths.Strong,
					})}
				/>
			</Grid>
			<Grid item xs={4}>
				<LinearProgress
					value={100}
					tabIndex={-1}
					aria-hidden="true"
					variant="determinate"
					data-testid="strength-meter-bar"
					className={classnames(classes.linearProgress, {
						[classes.linearProgressGreen]: strength === Strengths.Strong,
					})}
				/>
			</Grid>
			{strength !== Strengths.Unknown && (
				<Grid item xs={12}>
					<FormHelperText id={helperTextId} className={classes.helperText}>
						{strength}
					</FormHelperText>
				</Grid>
			)}
		</Grid>
	);
};

export default StrengthMeter;
