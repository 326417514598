import React, { forwardRef, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import { makeStyles, Theme, createStyles, OutlinedInput, InputAdornment, TextFieldProps } from '@material-ui/core';

import {
	useSfcKitMessages,
	//
	GenerateCasing,
	//
	Constants,
	//
	Icon,
	IconProps,
	InputControl,
	//
	Messages,
	SfcKitMessages,
	MessagesProps,
	LangText,
	Casing,
	FlexBox,
	Variant,
} from '../../..';
import colors from '../../../constants/Colors';
import generateVariantPadding from '../../../utils/GenerateVariantPadding';

export interface TextInputProps<TMessages extends Messages = SfcKitMessages>
	extends MessagesProps<TMessages>,
		Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder' | 'onChange' | 'onBlur' | 'onClick' | 'color'> {
	id?: string;
	value?: string;

	/**
	 * If `true`, a textarea element will be rendered.
	 */
	multiline?: boolean;

	/**
	 * Whether the input should display an error.
	 */
	error?: boolean;

	/**
	 * Display an icon outside of the input.
	 */
	outerIcon?: IconProps['icon'];

	variant?: Variant;

	/**
	 * Whether the label should be visible to the user. When false, the label will still be rendered but as srOnly.
	 */
	showLabel?: boolean;

	/**
	 * The icon to be displayed before the input.
	 */
	startIcon?: IconProps['icon'];

	/**
	 * The icon to be displayed after the input.
	 */
	endIcon?: IconProps['icon'];

	/**
	 * The LangText key to be used as the label.
	 */
	label?: LangText<TMessages>;

	/**
	 * The LangText key to be used as the supplemental label.
	 */
	supplementalLabel?: LangText<TMessages>;

	/**
	 * The casing to be used for the supplemental label.
	 */
	supplementalLabelCasing?: Casing;

	/**
	 * The casing to be used for the label.
	 */
	labelCasing?: Casing;

	/**
	 * The LangText key to be used as the helper text.
	 */
	helperText?: LangText<TMessages>;

	/**
	 * The casing to be used for the helper text.
	 */
	helperCasing?: Casing;

	/**
	 * The LangText key to be used as the error message.
	 */
	errorText?: LangText<TMessages>;

	/**
	 * The casing to be used for the error message.
	 */
	errorCasing?: Casing;

	/**
	 * The LangText key to be used as the placeholder.
	 */
	placeholder?: LangText<TMessages>;

	/**
	 * The casing to be used for the placeholder.
	 */
	placeholderCasing?: Casing;

	inputProps?: TextFieldProps['inputProps'];

	ref?: React.Ref<HTMLInputElement>;

	rows?: string | number | undefined;
	rowsMax?: string | number | undefined;
	rowsMin?: string | number | undefined;

	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	onChange?: (value: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		startAdornment: { marginRight: 0 },
		endAdornment: { marginLeft: 0 },
		adornedStart: { paddingLeft: theme.spacing(Constants.Spacing.xSmall) },
		adornedEnd: { paddingRight: theme.spacing(Constants.Spacing.xSmall) },

		input: ({ placeholderCasing, variant = 'normal' }: { placeholderCasing?: Casing; variant: Variant }) => ({
			...generateVariantPadding(variant),

			'&:disabled': {
				outline: `1px solid ${colors.info.light}`,
				background: '#FAFAFA',
				'&:placeholder': { color: '#707070' },
			},

			'&:error': { outline: `1px solid ${colors.error.main}` },

			'&::placeholder': { ...GenerateCasing(placeholderCasing) },

			'&::-webkit-clear-button, &::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
				display: 'none',
			},
		}),

		multiline: {
			padding: 0,
		},
		inputRoot: {
			boxShadow: `0px 0px 0px 1px ${colors.misc.lightGray}`,
			borderRadius: '0px',
			flex: 1,

			'& > fieldset': {
				border: 'none !important',
			},

			'&:hover': {
				boxShadow: `0px 0px 0px 1px ${colors.misc.black}`,
			},
		},
		focused: {
			boxShadow: `0px 0px 0px 2px ${colors.primary.main} !important`,
		},
		error: {
			boxShadow: `0px 0px 0px 1px ${colors.error.main} !important`,
		},
		outerIcon: {
			height: 'inherit',
			display: 'flex',
			alignItems: 'center',
			boxShadow: `0px 0px 0px 1px ${colors.info.light}`,
			backgroundColor: colors.button.secondary,
			padding: '0px 14px',
		},
	})
);

function TextInput<TMessages extends Messages = SfcKitMessages>(
	{
		id,
		name,
		value,

		type,
		className,

		multiline = false,

		error = false,
		disabled = false,
		showLabel = false,

		startIcon,
		endIcon,
		outerIcon,

		label,
		labelCasing,

		supplementalLabel,
		supplementalLabelCasing,

		helperText,
		helperCasing,

		errorText,
		errorCasing,

		variant = 'normal',

		placeholder,
		placeholderCasing,

		onClick = (): void => undefined,
		onBlur = (): void => undefined,
		onChange = (): void => undefined,

		messages,

		rows,
		rowsMax,
		rowsMin,

		inputProps,

		...restProps
	}: TextInputProps<TMessages>,
	ref?: React.Ref<HTMLInputElement>
): React.ReactElement | null {
	const sfcKitMessages = useSfcKitMessages();

	const styles = useStyles({ placeholderCasing, variant });

	const placeholderContent = placeholder ? messages?.[placeholder] ?? sfcKitMessages[placeholder as keyof SfcKitMessages] : undefined;

	return (
		<InputControl
			htmlFor={id ?? name}
			messages={messages}
			label={label}
			supplementalLabel={supplementalLabel}
			showLabel={showLabel}
			helperText={helperText}
			error={error && !disabled}
			errorText={errorText}
			casings={{ label: labelCasing, helper: helperCasing, error: errorCasing, supplementalLabel: supplementalLabelCasing }}>
			<FlexBox>
				<OutlinedInput
					rows={rows}
					rowsMax={rowsMax}
					rowsMin={rowsMin}
					inputRef={ref}
					id={id ?? name}
					name={name}
					type={type}
					value={value}
					error={error}
					labelWidth={0}
					onBlur={onBlur}
					onClick={onClick}
					disabled={disabled}
					multiline={multiline}
					placeholder={placeholderContent}
					onChange={(event): void => onChange(event.target.value, event)}
					classes={{
						root: styles.inputRoot,
						input: classnames(styles.input, className),
						inputMultiline: classnames(styles.input, className),
						multiline: styles.multiline,
						adornedStart: styles.adornedStart,
						adornedEnd: styles.adornedEnd,
						focused: styles.focused,
						error: styles.error,
					}}
					startAdornment={
						startIcon && (
							<InputAdornment position="start" className={styles.startAdornment}>
								<Icon color="primary.main" icon={startIcon} />
							</InputAdornment>
						)
					}
					endAdornment={
						endIcon && (
							<InputAdornment position="end" className={styles.endAdornment}>
								<Icon color="primary" icon={endIcon} />
							</InputAdornment>
						)
					}
					inputProps={inputProps}
					{...restProps}
				/>
				{outerIcon && (
					<div data-testid="outericon" className={styles.outerIcon}>
						<Icon size="small" color="primary" icon={outerIcon} />
					</div>
				)}
			</FlexBox>
		</InputControl>
	);
}

export default forwardRef(TextInput) as typeof TextInput;
