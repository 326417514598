import React from 'react';

import { GridProps } from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles, SvgIcon, MenuItem } from '@material-ui/core';

import SecurityQuestionOptions from 'constants/SecurityQuestionOptions';
import FullWidthSelect from 'sfc-kit/src/components/FullWidthSelect';
import SfcTextField, { ISfcTextFieldProps } from 'sfc-kit/src/components/SfcForm/SfcTextField';
import { FlexBox, FlexItem } from 'sfc-kit';

export interface AnswerProps {
	label?: string;
	value: string;
	error?: boolean;
	helperText?: string;
	StartIcon?: typeof SvgIcon;
	InputProps?: ISfcTextFieldProps['InputProps'];

	onBlur: () => void;
	onChange: (value: string) => void;
}

export interface QuestionsProps {
	label?: string;
	value: string;
	error?: boolean;
	errorMessage?: string;
	StartIcon?: typeof SvgIcon;

	onBlur: () => void;
	onChange: (value: string) => void;
}

export interface SecurityQuestionsAndAnswerProps {
	QuestionProps: QuestionsProps;
	AnswerProps: AnswerProps;
	questionGrid?: GridProps;
	answerGrid?: GridProps;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles<string, Record<string, unknown>>({
		menuItem: {
			whiteSpace: 'initial',
			[theme.breakpoints.down('md')]: {
				...theme.typography.body2,
				fontSize: theme.typography.caption.fontSize,
			},
		},
		firstItem: {
			paddingRight: theme.spacing(2),
		},
	})
);

const SecurityQuestionAndAnswer: React.FC<SecurityQuestionsAndAnswerProps> = ({ QuestionProps, AnswerProps, questionGrid = {}, answerGrid = {} }) => {
	const classes = useStyles({} as any);

	const { onChange: onQuestionChange, ...questionProps } = QuestionProps;

	return (
		<FlexBox>
			<FlexItem xs={12} md={8} {...questionGrid} className={classes.firstItem}>
				<FullWidthSelect {...questionProps} onChange={(value): void => onQuestionChange(value as string)}>
					{SecurityQuestionOptions.map(({ value, text }) => (
						<MenuItem key={value} value={value} className={classes.menuItem} style={{ whiteSpace: 'normal' }}>
							{text}
						</MenuItem>
					))}
				</FullWidthSelect>
			</FlexItem>
			<FlexItem xs={12} md {...answerGrid}>
				<SfcTextField id="answer" margin="none" type="password" autoComplete="off" {...AnswerProps} placeholder="Answer" />
			</FlexItem>
		</FlexBox>
	);
};

export default SecurityQuestionAndAnswer;
