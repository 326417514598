import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { ContactUsLink } from 'components';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import Paths from 'constants/Paths';
import CommonServicesGatewayAPI from 'utils/CommonServicesGatewayAPI';
import UASPaths from 'constants/Paths/UserAccountSettings';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import MailIcon from '@material-ui/icons/Mail';

import { useCustomerPortalNotificationStack, useMessages } from 'hooks';
import NumericStepper from 'sfc-kit/src/components/NumericStepper';
import { Button, Text, Spacing, FlexBox } from 'sfc-kit';
import { ContactUsSubject } from 'constants/ContactUs';
import AuthenticationContext from 'context/Authentication';
import OnboardingContext from 'context/Onboarding';
import * as BrowserStorage from 'sfc-kit/src/utils/BrowserStorage';
import LocalStorageKeys from 'constants/LocalStorageKeys';
import { getTokenNls } from 'utils/Auth';

interface EmailSentProps {
	loans: Array<Loan>;
	onGoBack: () => void;
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		emailSentContainer: {
			alignSelf: 'center',
		},
		stepper: {
			padding: 0,
		},
		contactItems: {
			'& .contact-item-icon': {
				fill: theme.palette.primary.light,
				marginRight: theme.spacing(1),
			},
		},
		contactText: {
			width: '30%',
			textAlign: 'left',
		},
		buttons: {
			width: '49%',
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

// This function is checking the LoanCommunicationPreference Array from the Onboarding store.
// If it has any length, it checks to see if any of the loans are being set to Paperless.
// If they are, it'll add the &paperlessEnabled URL Param to generate the success notification.
// Otherwise, it'll default the return to Onboard Verification Complete.
export const handleDestinationPath = (
	paperlessOptInLoanNumbers: Array<string>,
	username: string,
	location: typeof UASPaths.Root | typeof Paths.Dashboard
): string => {
	const redirection = location === UASPaths.Root ? UASPaths.Root : Paths.Dashboard;

	return encodeURIComponent(`${redirection}?username=${username}${paperlessOptInLoanNumbers.length ? '&paperlessEnabled=true' : ''}`);
};

const EmailSent: FunctionComponent<EmailSentProps> = ({ loans, onGoBack, onClose }) => {
	const classes = useStyles({} as any);
	const messages = useMessages();
	const location = window.location.pathname;
	const [isMobileView, setIsMobileView] = useState<boolean>(false);
	const [reachedMaxAttempts, setReachedMaxAttempts] = useState<boolean>(false);
	const nlsToken = getTokenNls();
	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);

	const { createSuccessNotification, createDangerNotification } = useCustomerPortalNotificationStack();

	const numberOfAttempts = BrowserStorage.GetItem(LocalStorageKeys.OnboardingNumberOfAttempts);

	const {
		authenticationState: { username: existingUsername },
	} = useContext(AuthenticationContext);

	const {
		onboardingState: { email: username, paperlessOptInLoanNumbers, paperlessOptOutLoanNumbers },
	} = useContext(OnboardingContext);

	const sendEmail = (attempts: number): void => {
		if (reachedMaxAttempts) {
			return;
		}

		CommonServicesGatewayAPI.SendOnboardingVerificationEmail({
			body: {
				username,
				existingUsername,
				destinationPath: handleDestinationPath(paperlessOptInLoanNumbers, username, location),
				paperlessOptInLoanNumbers,
				paperlessOptOutLoanNumbers,
				loanNumbers: loans?.map(loan => loan.loanNumber),
				userAuthenticationToken: encodeURIComponent(nlsToken),
			},
		})
			.then(data => {
				if (data) {
					createSuccessNotification({ body: 'notifications.emailSent.short' });
				} else {
					createDangerNotification({ title: 'notifications.oops', body: 'notifications.somethingWentWrong' });
				}
			})
			.catch()
			.finally(() => {
				if (attempts === 2) {
					setReachedMaxAttempts(true);
				}
			});
	};

	const onContinueClick = (): void => {
		try {
			sendEmail(2);
		} catch {}
	};

	useEffect(() => {
		if (!username) {
			onGoBack();
		} else {
			if (!numberOfAttempts) {
				sendEmail(1);
			}
			BrowserStorage.SetItem(LocalStorageKeys.OnboardingNumberOfAttempts, '1');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (reachedMaxAttempts) {
		return (
			<FlexBox justifyContent="space-between" direction="column" className={classes.emailSentContainer}>
				<FlexBox direction="column" className={classes.emailSentContainer} justifyContent="center">
					<Spacing px="xLarge" pb="large">
						<Text gutterBottom messages={messages} weight="medium" variant="body1" message="email.verification.numberOfAttempts" />
						<Text gutterBottom>
							The email address may not be correct, please contact the{' '}
							{!isMobileView ? (
								<ContactUsLink tabIndex={1} subject={ContactUsSubject.WebsiteAccess} target="_self">
									support team.
								</ContactUsLink>
							) : (
								<Text gutterBottom inline>
									support team.
								</Text>
							)}
						</Text>
						<FlexBox justifyContent="center" alignItems="center" direction="row" className={classes.contactItems}>
							<PermPhoneMsgIcon className="contact-item-icon" />
							<Text variant="caption" messages={messages} message="sfc.phone.billingSupport" className={classes.contactText} />
						</FlexBox>
						<FlexBox justifyContent="center" alignItems="center" direction="row" className={classes.contactItems}>
							<MailIcon color="primary" className="contact-item-icon" />
							<Text variant="caption" messages={messages} message="sfc.email.infoEmail" className={classes.contactText} />
						</FlexBox>
					</Spacing>
				</FlexBox>
				<Button casing="capitalizeEachWord" messages={messages} message="misc.close" fullWidth onClick={onClose} />
			</FlexBox>
		);
	}

	return (
		<FlexBox direction="column" className={classes.emailSentContainer} justifyContent="space-between">
			<NumericStepper className={classes.stepper} id="onboarding-dialog" activeStep={2} numSteps={2} />
			<Spacing mb="medium">
				<Text messages={messages} message="customerEmail.anEmailHasBeenSent" inline />{' '}
				<Text weight="medium" inline>
					{username}
				</Text>
				{'. '}
				<Text messages={messages} message="customerEmail.ifNotReceived" inline />
				<Text casing="capitalizeEachWord" weight="medium" messages={messages} message="email.verification.resendEmail" inline />
				<Text gutterBottom messages={messages} message="customerEmail.buttonIfThisDoesNot" inline />
			</Spacing>
			<FlexBox direction="row" justifyContent="space-evenly">
				<Button
					color="button.secondary"
					textColor="primary"
					className={classes.buttons}
					casing="capitalizeEachWord"
					tabIndex={1}
					messages={messages}
					message="buttons.back"
					onClick={onGoBack}
					aria-label="Back"
				/>
				<Button
					className={classes.buttons}
					casing="capitalizeEachWord"
					messages={messages}
					tabIndex={1}
					message="email.verification.resendEmail"
					onClick={onContinueClick}
					aria-label="Resend Email"
				/>
			</FlexBox>
		</FlexBox>
	);
};

export default EmailSent;
