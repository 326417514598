import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetPayoffLetterResult = QueryResult<FileDto, AxiosError<FileDto>>;
export type GetPayoffLetterConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<FileDto, AxiosError<FileDto>>;
};

interface GetPayoffLetterQuery {
	(request: GetPayoffLetterRequestParameters, config?: GetPayoffLetterConfig): GetPayoffLetterResult;
	key: 'GetPayoffLetter';
}

const useGetPayoffLetterQuery: GetPayoffLetterQuery = (request, config = { axiosConfig: {} }) => {
	async function GetPayoffLetter(): Promise<FileDto> {
		const client = ApiCSGService();

		const path = '/api/Payment/GetPayoffLetter';

		const response = await client.get<FileDto>(path, {
			loanNumber: request?.loanNumber,
			taskId: request?.taskId,
			payoffDate: request?.payoffDate,
		});

		return response.data;
	}

	return useQuery(['GetPayoffLetter', ...Object.values(request)], GetPayoffLetter, config.queryConfig);
};

useGetPayoffLetterQuery.key = 'GetPayoffLetter';

export default useGetPayoffLetterQuery;
