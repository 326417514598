import { DateTime } from 'luxon';
import React, { useRef, useCallback } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import { InputAdornment, IconButton, makeStyles, createStyles } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

import DateInputFormatter from './DateInputFormatter';

import { GenerateCasing, useSfcKitMessages, LangText, SfcKitMessages, MessagesProps, Messages, Casing, Icon } from '../../..';

type Date = DateTime | null;

export interface DateInputProps<TMessages extends Messages = SfcKitMessages> extends KeyboardDatePickerProps, MessagesProps<TMessages> {
	id: string;
	value: Date;

	label?: LangText<TMessages>;
	labelCasing?: Casing;

	error?: boolean;
	errorText?: LangText<TMessages>;
	errorCasing?: Casing;

	disablePast?: boolean;
	hideClearIcon?: boolean;

	onOpen?: () => void;
	onChange: (date: Date, value?: string | null) => void;
	onAccept?: (date: Date) => void;
	onMonthChange?: (date: Date) => void | Promise<void>;
	onBlur?: () => void | Promise<void>;
	onClearClick?: () => void | Promise<void>;

	shouldDisableDate?: (date: Date) => boolean;
}

const useStyles = makeStyles(theme =>
	createStyles({
		DateInput: ({ labelCasing }: Pick<DateInputProps, 'labelCasing' | 'errorCasing'>) => ({
			...GenerateCasing(labelCasing),
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		}),
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

export default function DateInput<TMessages extends Messages = SfcKitMessages>({
	id,
	value,

	label,
	labelCasing = 'capitalizeEachWord',

	error = false,
	errorText = '',
	errorCasing,

	disablePast = false,
	hideClearIcon = false,

	onOpen,
	onChange,
	onAccept,
	onMonthChange,
	onBlur,
	onClearClick,

	shouldDisableDate,

	InputProps = {},
	InputAdornmentProps = {},

	messages,
}: DateInputProps<TMessages>): React.ReactElement | null {
	const styles = useStyles({ labelCasing, errorCasing });
	const sfcKitMessages = useSfcKitMessages();

	const inputRef = useRef<HTMLInputElement>(null);

	const formatter = useCallback(
		(value: string): string => DateInputFormatter(value, inputRef.current?.selectionStart, inputRef.current?.selectionEnd),
		[inputRef]
	);

	return (
		<KeyboardDatePicker
			id={id}
			autoOk
			fullWidth
			format="MM/dd/yyyy"
			inputVariant="outlined"
			placeholder="mm/dd/yyyy"
			rifmFormatter={formatter}
			allowKeyboardControl
			KeyboardButtonProps={{ tabIndex: 1, className: styles.focus }}
			className={styles.DateInput}
			InputAdornmentProps={{ position: 'start', ...InputAdornmentProps, className: styles.focus }}
			InputProps={{
				inputRef,
				endAdornment: hideClearIcon ? null : (
					<InputAdornment position="end">
						<IconButton
							className={styles.focus}
							tabIndex={1}
							onClick={onClearClick}
							onBlur={onBlur}
							aria-label="select to clear the date"
							aria-describedby={`${id}-helper-text`}>
							<Icon icon={ClearIcon} />
						</IconButton>
					</InputAdornment>
				),
				inputProps: { classes: { root: styles.focus } },
				...InputProps,
			}}
			value={value}
			error={error}
			label={label ? messages?.[label] ?? sfcKitMessages[label as LangText<SfcKitMessages>] : undefined}
			helperText={error ? messages?.[errorText] ?? sfcKitMessages[errorText as LangText<SfcKitMessages>] : undefined}
			onOpen={onOpen}
			onChange={onChange}
			onAccept={onAccept}
			onMonthChange={onMonthChange}
			onBlur={onBlur}
			shouldDisableDate={shouldDisableDate}
			disablePast={disablePast}
		/>
	);
}

export { DateInputFormatter };
