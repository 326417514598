import React, { useContext, useEffect } from 'react';
import { type FontWeightProperty } from 'csstype';

import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import { FlexBox, FlexItem, DateTimeUtils, useLoanOverviewQuery, LoadingBoundary } from 'sfc-kit';

import { LoanContext } from 'context';
import { DashboardCard } from 'components';
import { useSelectedLoanSummaryQuery } from 'hooks';
import { GetBorderLeftStyles } from 'utils/GetBorderLeftStyles';
import PaymentInfoFirstParagraph from './PaymentInfoFirstParagraph';
import PaymentInfoSecondParagraph from './PaymentInfoSecondParagraph';
import { useMediaQuery } from '@material-ui/core';

export interface PaymentInformationProps {
	isDashboard: boolean;
}

const useStyles = makeStyles(theme => {
	return createStyles({
		paragraph: ({ isDashboard }: { isDashboard: boolean }) => ({
			paddingLeft: theme.spacing(),
			paddingRight: theme.spacing(3),
			marginBottom: theme.spacing(isDashboard ? 2 : 3),
			minHeight: '50px',

			...GetBorderLeftStyles(theme),
		}),
		boldText: {
			fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
		},
		button: {
			marginTop: theme.spacing(3),
			textTransform: 'none',
			borderRadius: 0,
		},
		underlined: {
			color: theme.palette.action.main,
			fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
			cursor: 'pointer',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	});
});

const PaymentInformation: React.FC<PaymentInformationProps> = ({ isDashboard }) => {
	const classes = useStyles({ isDashboard });
	const theme = useTheme();
	const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

	const { selectedLoanNumber, selectLoan } = useContext(LoanContext);
	useEffect(() => {
		const sessionItem = sessionStorage.getItem('LoanToken');

		if (sessionItem !== null && sessionItem !== selectedLoanNumber) {
			selectLoan(sessionItem ?? selectedLoanNumber);
			sessionStorage.setItem('LoanToken', sessionItem ?? selectedLoanNumber.toString());
		}
	}, [selectedLoanNumber, selectLoan]);

	const { data: overview, isLoading: isLoadingOverview } = useLoanOverviewQuery({ loanNumber: selectedLoanNumber });

	const pastDue = (overview?.daysPastDue ?? 0) > 0;
	const totalPastDue = overview?.totalPastDue ?? 0;

	const { data: loanSummary } = useSelectedLoanSummaryQuery();

	const currentAmountDue = loanSummary?.amountDue;
	const dueDate = DateTimeUtils.TryFromISO(loanSummary?.paymentDueDate);

	return (
		<DashboardCard title="dashboard.paymentInformation">
			<LoadingBoundary loading={isLoadingOverview}>
				<FlexBox>
					{(pastDue || currentAmountDue === 0) && (
						<FlexItem xs={12} md={12} lg={isDashboard === true ? 12 : 6}>
							<FlexBox alignItems="center" className={classes.paragraph}>
								<PaymentInfoFirstParagraph pastDue={pastDue} totalPastDue={totalPastDue.toString()} />
							</FlexBox>
						</FlexItem>
					)}
					{!isMobileView && (
						<FlexItem xs={12} md={12} lg={isDashboard === true ? 12 : 6}>
							<FlexBox alignItems="center" className={classes.paragraph}>
								<PaymentInfoSecondParagraph pastDue={pastDue} dueDate={dueDate} />
							</FlexBox>
						</FlexItem>
					)}
				</FlexBox>
			</LoadingBoundary>
		</DashboardCard>
	);
};

export default PaymentInformation;
