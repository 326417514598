import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetPropertyAddressResult = QueryResult<PropertyAddress, AxiosError<PropertyAddress>>;
export type GetPropertyAddressConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<PropertyAddress, AxiosError<PropertyAddress>>;
};

interface GetPropertyAddressQuery {
	(request: GetPropertyAddressRequestParameters, config?: GetPropertyAddressConfig): GetPropertyAddressResult;
	key: 'GetPropertyAddress';
}

const useGetPropertyAddressQuery: GetPropertyAddressQuery = (request, config = { axiosConfig: {} }) => {
	async function GetPropertyAddress(): Promise<PropertyAddress> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetPropertyAddress';

		const response = await client.get<PropertyAddress>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetPropertyAddress', ...Object.values(request)], GetPropertyAddress, config.queryConfig);
};

useGetPropertyAddressQuery.key = 'GetPropertyAddress';

export default useGetPropertyAddressQuery;
