import React, { FunctionComponent, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import Countdown from 'sfc-kit/src/components/Countdown';
import { ConfirmDenyDialog } from 'sfc-kit';
import useRenewToken from 'hooks/auth/useRenewToken';
import useLogout from 'hooks/auth/useLogout';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface IdleTimeoutProps {
	timeout?: number;
	throttle?: number;
	logout?: () => void;
}

const defaultThrottle = 60_000; // 1 Minute in Milliseconds
const defaultTimeout = 270_000; // 5 Minutes in Milliseconds

const IdleTimeout: FunctionComponent<IdleTimeoutProps> = ({ throttle = defaultThrottle, timeout = defaultTimeout, logout }: IdleTimeoutProps) => {
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

	const renewToken = useRenewToken();

	const [displayModal, setDisplayModal] = useState(false);

	const localLogout = useLogout();

	// When no activity is detected from the user.
	const onIdle = async (): Promise<void> => {
		setDisplayModal(true);
	};

	// When a user action is detected.
	const onAction = () => {
		if (!displayModal) {
			renewToken();

			reset();
		}
	};

	const { reset } = useIdleTimer({
		timeout,
		eventsThrottle: throttle,
		onAction,
		onIdle,
	});

	// When user in modal decides to stay signed in.
	const modalRenewToken = () => {
		renewToken();
		setDisplayModal(false);

		reset();
	};

	// If user clicks the modal sign out button, or the timer in the countdown modal goes off.
	const timeoutUser = async (): Promise<void> => {
		reset();
		logout === undefined ? localLogout() : logout();
	};

	if (!displayModal) {
		return null;
	}

	return (
		<ConfirmDenyDialog
			data-testid="timeout-dialog"
			id="timeout-dialog"
			title="Session Inactive"
			isOpen
			isIdleTimeout
			handleClose={modalRenewToken}
			handleConfirm={modalRenewToken}
			handleDeny={timeoutUser}
			denyText="Sign Out"
			confirmText="Stay Signed In">
			{!isSmall && (
				<>
					Your session is about to expire. You will be logged out in{' '}
					<Countdown active handleTimeout={timeoutUser} timeout={timeout} format="m:ss" />
				</>
			)}
		</ConfirmDenyDialog>
	);
};

export default IdleTimeout;
