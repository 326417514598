import React, { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles, IconButton } from '@material-ui/core';

import { TableRow, TableCell, Icon } from '..';

export interface CollapsibleTableRowProps {
	children: React.ReactNode[];
	details: React.ReactNode;
	disableCollapse?: boolean;

	classes?: {
		parentRow?: string;
		childRow?: string;
		buttonCell?: string;
	};

	expanded?: boolean;
	onToggleExpansion?: () => void;
}

const useStyles = makeStyles(theme =>
	createStyles({
		parent: {
			cursor: 'pointer',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const CollapsibleTableRow: FunctionComponent<CollapsibleTableRowProps> = ({
	children,
	details,
	expanded,
	onToggleExpansion,
	disableCollapse,
	classes = {},
}) => {
	const styles = useStyles({} as any);

	const [unControlledExpanded, setUncontrolledExpanded] = useState(false);

	const toggleExpansion = (event: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement, MouseEvent>): void => {
		event.stopPropagation();

		if (onToggleExpansion !== undefined) {
			return onToggleExpansion();
		}

		setUncontrolledExpanded(x => !x);
	};

	const isExpanded = expanded !== undefined ? expanded : unControlledExpanded;

	return (
		<>
			<TableRow hover selected={isExpanded} className={classnames(styles.parent, classes.parentRow)} onClick={toggleExpansion}>
				{children}
				<TableCell className={classes.buttonCell}>
					<IconButton size="small" onClick={toggleExpansion} className={styles.focus} tabIndex={1}>
						<Icon color="action" icon={isExpanded ? ExpandLessIcon : ExpandMoreIcon} />
					</IconButton>
				</TableCell>
			</TableRow>
			{isExpanded && !disableCollapse && (
				<TableRow selected={isExpanded} className={classes.childRow}>
					{details}
					<TableCell className={classes.buttonCell} />
				</TableRow>
			)}
		</>
	);
};

export default CollapsibleTableRow;
