import classnames from 'classnames';
import React, { ReactNode, useMemo, ReactElement } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { makeStyles, createStyles, IconButton } from '@material-ui/core';

import { Spacing, FlexBox, FlexItem, Text, Icon } from '..';

import SpacingValues from '../../constants/Spacing';
import extractColorFromKeyString from '../../utils/ExtractColorFromKeyString';
import ConvertStatusVariantToValues, { StatusValues } from '../../utils/ConvertStatusVariantToValues';
import { LangText, Color, StatusVariant, Messages, SfcKitMessages, MessagesProps } from '../../types';

export interface NotificationProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	title?: LangText<TMessages>;
	children?: ReactNode;

	onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

	body?: LangText<TMessages>;
	variant?: StatusVariant;

	classes?: {
		root?: string;
		icon?: string;
		title?: string;
		body?: string;
	};
}

const useStyles = makeStyles(theme =>
	createStyles({
		icon: {
			margin: theme.spacing(0, SpacingValues.small),
		},
		alert: ({ color, textColor }: { color: Color; textColor: Color }) => ({
			backgroundColor: extractColorFromKeyString(color),
			borderRadius: 2,
			boxShadow: theme.shadows[2],
			color: extractColorFromKeyString(textColor),
		}),
	})
);

function Notification<TMessages extends Messages = SfcKitMessages>({
	title,
	body,
	children,
	onClose,
	variant = 'Success',
	messages,
	classes = {},
}: NotificationProps<TMessages>): ReactElement | null {
	const {
		color,
		textColor,
		icons: { standard: icon },
	} = useMemo<StatusValues>(() => ConvertStatusVariantToValues(variant), [variant]);

	const styles = useStyles({ color, textColor });

	return (
		<FlexBox alignItems="center" spacing="xSmall" className={classnames(styles.alert, classes.root)}>
			<Icon color="misc.white" icon={icon} className={classnames(styles.icon, classes.icon)} />
			<FlexItem xs>
				<Spacing p="small">
					{title && body ? (
						<div role="alert">
							<Text
								gutterBottom
								variant="subtitle2"
								weight="medium"
								color="inherit"
								aria-hidden="false"
								aria-live="polite"
								message={title}
								messages={messages}
								className={classes.title}
							/>
							<Text
								variant="body2"
								color="inherit"
								aria-live="polite"
								aria-hidden="false"
								message={body}
								messages={messages}
								className={classes.body}
							/>
						</div>
					) : title && children ? (
						<div role="alert">
							<Text
								gutterBottom
								variant="subtitle2"
								weight="medium"
								color="inherit"
								aria-hidden="false"
								aria-live="polite"
								message={title}
								messages={messages}
								className={classes.title}
							/>
							<div>{children}</div>
						</div>
					) : (
						<>
							{title && (
								<Text
									gutterBottom
									variant="subtitle2"
									weight="medium"
									color="inherit"
									role="alert"
									aria-hidden="false"
									aria-live="polite"
									message={title}
									messages={messages}
									className={classes.title}
								/>
							)}
							{body ? (
								<Text
									variant="body2"
									color="inherit"
									aria-live="polite"
									role={!title ? 'alert' : undefined}
									aria-hidden="false"
									message={body}
									messages={messages}
									className={classes.body}
								/>
							) : (
								<div aria-live="polite" role={!title && !body ? 'alert' : undefined} aria-hidden="false">
									{children}
								</div>
							)}
						</>
					)}
				</Spacing>
			</FlexItem>
			{onClose && (
				<FlexItem alignSelf="flex-start">
					<IconButton size="small" onClick={onClose}>
						<Text variant="srOnly">Close Notification</Text>
						<Icon size="small" color="misc.white" icon={CloseIcon} />
					</IconButton>
				</FlexItem>
			)}
		</FlexBox>
	);
}

export default Notification;
