import { useState, useCallback, SetStateAction, Dispatch, useEffect } from 'react';

import FieldValidation from '../types/FieldValidation';
import Validator, { ValidatorSetupFunc } from '../types/Validator';

/**
 * A Custom React Hook. Returns an array with the following order:
 * The State, A React Dispatch<SetStateAction<TState>>, and a Field Validation object containing properties and methods related to validation.
 * @param initialState The initial value for the State to take
 * @param validator The Validator to be used against the state
 */
export default function useField<TState, TValidator extends Validator<TState>>(
	initialState: TState,
	validator: TValidator = Validator.AlwaysValid<TState>() as TValidator,
	updateState?: boolean
): [TState, Dispatch<SetStateAction<TState>>, FieldValidation<TState, TValidator>] {
	const [state, setState] = useState<TState>(initialState);
	const [invalid, setInvalid] = useState<boolean>(false);
	const [reasonForInvalidity, setReasonForInvalidity] = useState<string>();

	useEffect(() => {
		if (updateState) {
			setState(initialState);
		}
	}, [initialState, updateState]);

	const validate = useCallback(
		async (validatorSetupFunc?: ValidatorSetupFunc<TState, TValidator>) => {
			if (validatorSetupFunc !== undefined) {
				validatorSetupFunc(state, validator);
			}

			const [valid, errorMessage] = await validator.Validate(state);

			setInvalid(!valid);
			setReasonForInvalidity(errorMessage);

			return valid;
		},
		[state, validator]
	);

	const validateSync = useCallback(
		(validatorSetupFunc?: ValidatorSetupFunc<TState, TValidator>) => {
			if (validatorSetupFunc !== undefined) {
				validatorSetupFunc(state, validator);
			}

			const [valid, errorMessage] = validator.ValidateSync(state);

			setInvalid(!valid);
			setReasonForInvalidity(errorMessage);

			return valid;
		},
		[state, validator]
	);

	return [state, setState, new FieldValidation(invalid, reasonForInvalidity, validate, validateSync)];
}
