import { PaymentFrequencies } from 'types';

export function GetShortPaymentFrequency(frequency: PaymentFrequencies): string {
	switch (frequency) {
		case PaymentFrequencies.OneTime:
			return 'Once';

		case PaymentFrequencies.BiWeekly:
			return 'Bi-Weekly';

		case PaymentFrequencies.Monthly:
			return 'Monthly';

		default:
			throw new Error(`The provided payment frequency (${frequency}) cannot be shortened.`);
	}
}
