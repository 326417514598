import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetTaskForDealerResult = QueryResult<TaskForDealer, AxiosError<TaskForDealer>>;
export type GetTaskForDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<TaskForDealer, AxiosError<TaskForDealer>>;
};

interface GetTaskForDealerQuery {
	(request: GetTaskForDealerRequestParameters, config?: GetTaskForDealerConfig): GetTaskForDealerResult;
	key: 'GetTaskForDealer';
}

const useGetTaskForDealerQuery: GetTaskForDealerQuery = (request, config = { axiosConfig: {} }) => {
	async function GetTaskForDealer(): Promise<TaskForDealer> {
		const client = ApiCSGService();

		const path = '/api/Tasks/ForDealer';

		const response = await client.get<TaskForDealer>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetTaskForDealer', ...Object.values(request)], GetTaskForDealer, config.queryConfig);
};

useGetTaskForDealerQuery.key = 'GetTaskForDealer';

export default useGetTaskForDealerQuery;
