import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type UpdateBorrowerShortNameResult = MutationResultPair<string, AxiosError<string>, UpdateBorrowerShortNameRequestParameters, unknown>;

export type UpdateBorrowerShortNameConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<string, AxiosError<string>, UpdateBorrowerShortNameRequestParameters>;
};

export default function useUpdateBorrowerShortNameMutation(
	config: UpdateBorrowerShortNameConfig = { axiosConfig: {} }
): UpdateBorrowerShortNameResult {
	async function UpdateBorrowerShortName(request: UpdateBorrowerShortNameRequestParameters): Promise<string> {
		const client = ApiCSGService();

		const path = '/api/Borrower/UpdateBorrowerShortName';

		const response = await client.patch<string>(path, request.body);

		return response.data;
	}

	return useMutation(UpdateBorrowerShortName, config.mutationConfig);
}
