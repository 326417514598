import { AxiosError, AxiosRequestConfig } from 'axios';
import { usePaginatedQuery, QueryConfig, PaginatedQueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PaginatedHistoricalPaymentsResult = PaginatedQueryResult<
	CombinedHistoricalPaymentCollection,
	AxiosError<CombinedHistoricalPaymentCollection>
>;
export type PaginatedHistoricalPaymentsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<CombinedHistoricalPaymentCollection, AxiosError<CombinedHistoricalPaymentCollection>>;
};

interface PaginatedHistoricalPaymentsQuery {
	(request: PaginatedHistoricalPaymentsRequestParameters, config?: PaginatedHistoricalPaymentsConfig): PaginatedHistoricalPaymentsResult;
	key: 'PaginatedHistoricalPayments';
}

const usePaginatedHistoricalPaymentsQuery: PaginatedHistoricalPaymentsQuery = (request, config = { axiosConfig: {} }) => {
	async function PaginatedHistoricalPayments(): Promise<CombinedHistoricalPaymentCollection> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/PaginatedHistoricalPayments`;

		const response = await client.get<CombinedHistoricalPaymentCollection>(path, {
			skip: request?.skip,
			take: request?.take,
			sort: request?.sort,
		});

		return response.data;
	}

	return usePaginatedQuery(['PaginatedHistoricalPayments', ...Object.values(request)], PaginatedHistoricalPayments, config.queryConfig);
};

usePaginatedHistoricalPaymentsQuery.key = 'PaginatedHistoricalPayments';

export default usePaginatedHistoricalPaymentsQuery;
