import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type HolidayDaysResult = QueryResult<Envelope<SfcHolidaysDto>, AxiosError<Envelope<SfcHolidaysDto>>>;
export type HolidayDaysConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<SfcHolidaysDto>, AxiosError<Envelope<SfcHolidaysDto>>>;
};

interface HolidayDaysQuery {
	(request: HolidayDaysRequestParameters, config?: HolidayDaysConfig): HolidayDaysResult;
	key: 'HolidayDays';
}

const useHolidayDaysQuery: HolidayDaysQuery = (request, config = { axiosConfig: {} }) => {
	async function HolidayDays(): Promise<Envelope<SfcHolidaysDto>> {
		const client = ApiCSGService();

		const path = '/api/Payment/HolidayDays';

		const response = await client.get<Envelope<SfcHolidaysDto>>(path, {
			start: request?.start,
			end: request?.end,
			state: request?.state,
		});

		return response.data;
	}

	return useQuery(['HolidayDays', ...Object.values(request)], HolidayDays, config.queryConfig);
};

useHolidayDaysQuery.key = 'HolidayDays';

export default useHolidayDaysQuery;
