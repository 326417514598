const inputs = {
	PlainText: 'plainText',
	PhoneNumber: 'phoneNumber',
	Text: 'text',
	SecurityQuestions: 'securityQuestions',
	Password: 'password',
	Error: 'error',
	Captcha: 'captcha',
	ReadOnly: 'readOnly',
	SocialSecurityNumber: 'ssn',
	SecurityQuestion: 'securityQuestion',
	Success: 'success',
};

export default inputs;
