import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LogoutBorrowerResult = MutationResultPair<string, AxiosError<string>, LogoutBorrowerRequestParameters, unknown>;

export type LogoutBorrowerConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<string, AxiosError<string>, LogoutBorrowerRequestParameters>;
};

export default function useLogoutBorrowerMutation(config: LogoutBorrowerConfig = { axiosConfig: {} }): LogoutBorrowerResult {
	async function LogoutBorrower(request: LogoutBorrowerRequestParameters): Promise<string> {
		const api = ApiCSGService();
		
		const path = '/api/Authentication/LogoutBorrower';

		const { data } = await api.post<string>(path, request.body);

		return data;
	}

	return useMutation(LogoutBorrower, config.mutationConfig);
}
