import { useEffect } from 'react';

export default function useScrollToTop(): void {
	useEffect(() => {
		window.scrollTo(0, 0);
		window.onbeforeunload = (): void => {
			window.scrollTo(0, 0);
		};
	}, []);
}
