import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type AuthenticationAuthenticateResult = MutationResultPair<
	AuthenticationAuthenticateResponse,
	AxiosError<AuthenticationAuthenticateResponse>,
	AuthenticateRequestParameters,
	unknown
>;

export type AuthenticationAuthenticateConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<
		AuthenticationAuthenticateResponse,
		AxiosError<AuthenticationAuthenticateResponse>,
		AuthenticateRequestParameters
	>;
};

export default function useAuthenticationAuthenticateMutation(
	config: AuthenticationAuthenticateConfig = { axiosConfig: {} }
): AuthenticationAuthenticateResult {
	async function AuthenticationAuthenticate(request: AuthenticateRequestParameters): Promise<AuthenticationAuthenticateResponse> {
		const api = ApiCSGService();

		api.clearOtherToken();

		const path = '/api/Authentication/Authenticate';

		const { data } = await api.post<AuthenticationAuthenticateResponse>(path, request.body);

		return data;
	}

	return useMutation(AuthenticationAuthenticate, config.mutationConfig);
}
