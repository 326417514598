import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type ScheduledAchPaymentCollectionResult = QueryResult<ScheduledAchPaymentCollection, AxiosError<ScheduledAchPaymentCollection>>;
export type ScheduledAchPaymentCollectionConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<ScheduledAchPaymentCollection, AxiosError<ScheduledAchPaymentCollection>>;
};

interface ScheduledAchPaymentCollectionQuery {
	(request: ScheduledAchPaymentCollectionRequestParameters, config?: ScheduledAchPaymentCollectionConfig): ScheduledAchPaymentCollectionResult;
	key: 'ScheduledAchPaymentCollection';
}

const useScheduledAchPaymentCollectionQuery: ScheduledAchPaymentCollectionQuery = (request, config = { axiosConfig: {} }) => {
	async function ScheduledAchPaymentCollection(): Promise<ScheduledAchPaymentCollection> {
		const client = ApiCSGService();

		const path = '/api/Payment/ScheduledAchPaymentCollection';

		const response = await client.get<ScheduledAchPaymentCollection>(path, {
			loanNumber: request?.loanNumber,
			skip: request?.skip,
			take: request?.take,
			sort: request?.sort,
		});

		return response.data;
	}

	return useQuery(['ScheduledAchPaymentCollection', ...Object.values(request)], ScheduledAchPaymentCollection, config.queryConfig);
};

useScheduledAchPaymentCollectionQuery.key = 'ScheduledAchPaymentCollection';

export default useScheduledAchPaymentCollectionQuery;
