import React, { useRef, useState, ReactInstance, ReactElement, useEffect } from 'react';

import clsx from 'clsx';
import ReactToPrint from 'react-to-print';
import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';

import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/GetApp';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import ExpandFullscreenIcon from '@material-ui/icons/CallMade';
import PreviousPageIcon from '@material-ui/icons/NavigateBefore';
import CollapseFullscreenIcon from '@material-ui/icons/CallReceived';

import Text from '../../components/Typography/Text/Text';

import FullDocument from './FullDocument';
import ContainerHeader from '../ContainerHeader';

import IDocument from './IDocument';
import { GetViewWidth } from '../../utils/Sizing';
import base64ToBlob from '../../utils/Base64ToBlob';

export interface IPdfViwerProps {
	document: IDocument;
	isInitialDocument?: boolean;
	dealerUsername?: string;
	hideTitle?: boolean;

	classes?: {
		body?: string;
		bodyFullScreen?: string;
	};

	isLandscape?: boolean;
	height?: string;
	iconColor?: string;
	width?: string;
	disableFullscreen?: boolean;
	handleLoadError?: () => void;
}

type StyleProps = Pick<IPdfViwerProps, 'width' | 'height' | 'iconColor'>;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: ({ iconColor: color }: StyleProps) => ({
			color,
			marginRight: theme.spacing(1),
		}),
		link: {
			cursor: 'pointer',
			verticalAlign: 'middle',
		},
		body: ({ height, width }: StyleProps) => ({
			backgroundColor: theme.palette.common.white,
			padding: theme.spacing(1),
			position: 'absolute',
			height,
			width,
		}),
		pdfContainer: {
			padding: theme.spacing(2),
			backgroundColor: theme.palette.grey[100],
			overflow: 'auto',
			height: `calc(100% - 1rem - ${theme.spacing(5)}px)`,
		},
		fullscreen: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100vw !important',
			height: '100vh !important',
			backgroundColor: theme.palette.common.white,
			zIndex: 1300,
			padding: theme.spacing(1),
			overflow: 'hidden',
		},
		pdfObject: {
			paddingBottom: theme.spacing(2),
		},
		disabledPaging: {
			color: theme.palette.grey[500],
			verticalAlign: 'middle',
		},
		wholePdf: {
			width: '100vw',
			height: '100vh',
			position: 'static',
			top: 0,
			left: 0,
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		printButton: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const PdfViewer: React.FC<IPdfViwerProps> = ({
	document,
	classes = {},
	height = '100%',
	width = '100%',
	hideTitle = false,
	iconColor,
	isLandscape = false,
	disableFullscreen = false,
	handleLoadError,
}) => {
	const theme = useTheme();
	const styles = useStyles({ height, width, iconColor });

	const printRef = useRef(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [fullscreen, setFullscreen] = useState(false);
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [downloadOptions, setDownloadOptions] = useState({
		href: '',
		download: '',
	});
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	const onDocumentLoadSuccess = (pdf: PDFDocumentProxy): void => {
		setNumberOfPages(pdf.numPages);
	};

	const setPdfDownloadLink = ({ title, source }: IDocument): void => {
		const fileName = `${title}.pdf`;
		let url;

		if (source instanceof Blob) {
			url = URL.createObjectURL(source);
		} else {
			try {
				const blob = base64ToBlob(source);
				url = URL.createObjectURL(blob);
			} catch (e) {
				// Error code 5 means the base64 decode failed due to an invalid character.
				// https://stackoverflow.com/a/7860443
				if ((e as any).code === 5) {
					url = source as string;
				} else {
					throw e;
				}
			}
		}

		setDownloadOptions({
			href: url,
			download: fileName,
		});
	};

	useEffect(() => {
		if (document.source) {
			setPdfDownloadLink(document);
		}
	}, [document]);

	useEffect(() => {
		const sessionItem = sessionStorage.getItem('Authentication');
		if (sessionItem !== null && sessionItem.includes('"isAuthenticated":true')) {
			setIsLoggedIn(true);
		}
	}, [isLoggedIn]);

	return (
		<>
			<Box
				className={clsx({
					[styles.fullscreen]: fullscreen,
					[classes.bodyFullScreen ?? '']: fullscreen,
					[styles.body]: !fullscreen,
					[classes.body ?? '']: !fullscreen,
				})}>
				<ContainerHeader>
					<Grid item>{!hideTitle && <Text variant="h6">{document.title}</Text>}</Grid>
					<Grid item>
						<IconButton
							onClick={(): void => setPageNumber(1)}
							className={styles.focus}
							aria-label="select to go to the first page"
							disabled={pageNumber === 1}>
							<FirstPageIcon className={pageNumber !== 1 ? styles.link : styles.disabledPaging} data-testid="pdf-first-button" />
						</IconButton>
						<IconButton
							onClick={(): void => {
								if (pageNumber > 1) {
									setPageNumber(pageNumber - 1);
								}
							}}
							disabled={pageNumber === 1}
							className={styles.focus}
							aria-label="select to go to the previous page">
							<PreviousPageIcon className={pageNumber !== 1 ? styles.link : styles.disabledPaging} data-testid="pdf-prev-button" />
						</IconButton>
						<Text inline>
							Page {pageNumber} of {numberOfPages}
						</Text>
						<IconButton
							onClick={(): void => {
								if (pageNumber < numberOfPages) {
									setPageNumber(pageNumber + 1);
								}
							}}
							className={styles.focus}
							aria-label="select to go to the next page"
							disabled={pageNumber === numberOfPages}>
							<NextPageIcon
								className={pageNumber !== numberOfPages ? styles.link : styles.disabledPaging}
								data-testid="pdf-next-button"
							/>
						</IconButton>
						<IconButton
							onClick={(): void => setPageNumber(numberOfPages)}
							disabled={pageNumber === numberOfPages}
							className={styles.focus}
							aria-label="select to go to the last page">
							<LastPageIcon
								className={pageNumber !== numberOfPages ? styles.link : styles.disabledPaging}
								data-testid="pdf-last-button"
							/>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton {...downloadOptions} aria-label={'Click to download'} data-testid="download-button" className={styles.focus}>
							<DownloadIcon className={clsx(styles.icon, styles.link)} />
						</IconButton>

						{isLandscape ? (
							<ReactToPrint
								pageStyle="@page {
								size: landscape;
								margin: 0in 0.44in 0.2in 0.44in;
							}"
								trigger={(): ReactElement => (
									<IconButton aria-label={'Click to print'} className={styles.printButton}>
										<PrintIcon className={clsx(styles.icon, styles.link)} />
									</IconButton>
								)}
								content={(): ReactInstance => printRef.current!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
							/>
						) : (
							<ReactToPrint
								pageStyle="@page {
								size: LETTER;
							}"
								trigger={(): ReactElement => (
									<IconButton aria-label={'Click to print'} className={styles.printButton}>
										<PrintIcon className={clsx(styles.icon, styles.link)} />
									</IconButton>
								)}
								content={(): ReactInstance => printRef.current!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
							/>
						)}

						{!disableFullscreen &&
							(fullscreen ? (
								<IconButton
									onClick={(): void => setFullscreen(!fullscreen)}
									className={styles.focus}
									aria-label="select to collapse the full screen view ">
									<CollapseFullscreenIcon className={clsx(styles.icon, styles.link)} data-testid="pdf-collapse-fullscreen" />
								</IconButton>
							) : (
								<IconButton
									onClick={(): void => setFullscreen(!fullscreen)}
									className={styles.focus}
									aria-label="select to view the document in full screen">
									<ExpandFullscreenIcon className={clsx(styles.icon, styles.link)} data-testid="pdf-expand-fullscreen" />
								</IconButton>
							))}
					</Grid>
				</ContainerHeader>
				<Grid container direction="column" justifyContent="flex-start" alignItems="center" className={styles.pdfContainer}>
					<div tabIndex={0} className={styles.focus} aria-label={'document viewer'}>
						<Document file={document.source} onLoadSuccess={onDocumentLoadSuccess} className={styles.pdfObject}>
							<Page
								pageNumber={pageNumber}
								renderAnnotationLayer={false}
								width={fullscreen || (isLandscape && !isLoggedIn) ? GetViewWidth() - theme.spacing(8) : document.width ?? 1150}
							/>
						</Document>
					</div>
				</Grid>
			</Box>
			<FullDocument ref={printRef} document={document} handleError={handleLoadError} />
		</>
	);
};

export default PdfViewer;
