import React, { ReactNode } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export interface LoadingBoundaryProps {
	children: ReactNode;

	/**
	 * Whether the loading indicator should be displayed.
	 *
	 * Setting this value to false prevents the loading indicator from rendering at all.
	 */
	loading?: boolean;

	/**
	 * The size of the loading indicator expressed as a string or number.
	 *
	 * This value is passed to the CircularProgress component which sets the width and height of the Svg's container to the value.
	 */
	size?: string | number;

	/**
	 * The color of the CircularProgress loading indicator. The default value is pulled from the theme's palette.
	 *
	 * @default Primary.Main
	 */
	color?: string;

	/**
	 * The background color of the grid that will cover the content passed as `children`.
	 *
	 * Whatever color is specified, or the default, will be faded to an alpha value of .75 to
	 * allow the content to be seen behind it.
	 *
	 * The default value is pulled from the theme's palette
	 *
	 * @default Background.Paper
	 */
	backgroundColor?: string;
}

type StyleProps = Pick<Required<LoadingBoundaryProps>, 'color' | 'backgroundColor'>;

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			height: '100%',
			position: 'relative',
			width: '100%',
		},
		circularProgress: ({ color }: StyleProps) => ({
			color,
		}),
		loadingGridContainer: ({ backgroundColor }: StyleProps) => ({
			backgroundColor: alpha(backgroundColor, 0.75),
			height: '100%',
			left: 0,
			position: 'absolute',
			top: 0,
			zIndex: 1500,
		}),
	})
);

/**
 * The `LoadingBoundary` component is useful when your content can have a loading state that should
 * prevent interaction with it.
 *
 * To use an `LoadingBoundary` simply wrap your content with the component and specify whether your content is `loading`.
 */
const LoadingBoundary: React.FC<LoadingBoundaryProps> = ({ loading = false, children, color, backgroundColor, size = 75 }) => {
	const theme = useTheme();
	const classes = useStyles({ color: color ?? theme.palette.primary.main, backgroundColor: backgroundColor ?? theme.palette.background.paper });

	return (
		<div className={classes.root}>
			{loading && (
				<Grid container className={classes.loadingGridContainer} justifyContent="center" alignItems="center">
					<Grid item>
						<CircularProgress size={size} className={classes.circularProgress} />
					</Grid>
				</Grid>
			)}
			{children}
		</div>
	);
};

export default LoadingBoundary;
