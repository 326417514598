import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetSendToSignAddressesResult = QueryResult<SendToSignAddresses, AxiosError<SendToSignAddresses>>;
export type GetSendToSignAddressesConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<SendToSignAddresses, AxiosError<SendToSignAddresses>>;
};

interface GetSendToSignAddressesQuery {
	(request: GetSendToSignAddressesRequestParameters, config?: GetSendToSignAddressesConfig): GetSendToSignAddressesResult;
	key: 'GetSendToSignAddresses';
}

const useGetSendToSignAddressesQuery: GetSendToSignAddressesQuery = (request, config = { axiosConfig: {} }) => {
	async function GetSendToSignAddresses(): Promise<SendToSignAddresses> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetSendToSignAddresses';

		const response = await client.get<SendToSignAddresses>(path, {
			taskId: request?.taskId,
		});

		return response.data;
	}

	return useQuery(['GetSendToSignAddresses', ...Object.values(request)], GetSendToSignAddresses, config.queryConfig);
};

useGetSendToSignAddressesQuery.key = 'GetSendToSignAddresses';

export default useGetSendToSignAddressesQuery;
