import React, { FunctionComponent, useContext } from 'react';

import { makeStyles, Theme, createStyles, alpha } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import GlobalContext from 'context/Global';

interface SpinnerProps {
	loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: alpha(theme.palette.grey[800], 0.5),
			height: '100vh',
			left: 0,
			position: 'fixed',
			top: 0,
			width: '100vw',
			zIndex: 1000,
		},
	})
);

const SfcSpinner: FunctionComponent<SpinnerProps> = ({ loading = false }) => {
	const styles = useStyles({} as any);
	const {
		globalState: { isLoading },
	} = useContext(GlobalContext);

	if (!isLoading && !loading) {
		return null;
	}

	return (
		<Grid container alignItems="center" justifyContent="center" className={styles.root}>
			<CircularProgress size={200} />
		</Grid>
	);
};

export default SfcSpinner;
