import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetCertificateOfCompletionDocumentForDealerResult = QueryResult<COCDocumentForDealer, AxiosError<COCDocumentForDealer>>;
export type GetCertificateOfCompletionDocumentForDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<COCDocumentForDealer, AxiosError<COCDocumentForDealer>>;
};

interface GetCertificateOfCompletionDocumentForDealerQuery {
	(
		request: GetCertificateOfCompletionDocumentForDealerRequestParameters,
		config?: GetCertificateOfCompletionDocumentForDealerConfig
	): GetCertificateOfCompletionDocumentForDealerResult;
	key: 'GetCertificateOfCompletionDocumentForDealer';
}

const useGetCertificateOfCompletionDocumentForDealerQuery: GetCertificateOfCompletionDocumentForDealerQuery = (
	request,
	config = { axiosConfig: {} }
) => {
	async function GetCertificateOfCompletionDocumentForDealer(): Promise<COCDocumentForDealer> {
		const client = ApiCSGService();

		const path = '/api/CertificateOfCompletionDocuments/ForDealer';

		const response = await client.get<COCDocumentForDealer>(path, {
			taskId: request?.taskId,
		});

		return response.data;
	}

	return useQuery(
		['GetCertificateOfCompletionDocumentForDealer', ...Object.values(request)],
		GetCertificateOfCompletionDocumentForDealer,
		config.queryConfig
	);
};

useGetCertificateOfCompletionDocumentForDealerQuery.key = 'GetCertificateOfCompletionDocumentForDealer';

export default useGetCertificateOfCompletionDocumentForDealerQuery;
