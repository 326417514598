import { Variant } from '../types';

const generateVariantPadding = (variant: Variant | undefined): object => {
	switch (variant) {
		case 'normal':
			return { padding: '18.5px 14px' };

		case 'dense':
			return { padding: '12px' };

		default:
			return { padding: '18.5px 14px' };
	}
};

export default generateVariantPadding;
