import { OnboardingInitialStateType, OnboardingInitialState, OnboardingActions, OnboardingActionTypes } from './OnboardingContext';

export const onboardingReducer = (
	state: OnboardingInitialStateType = OnboardingInitialState,
	action: OnboardingActions
): OnboardingInitialStateType => {
	switch (action.type) {
		case OnboardingActionTypes.Reset:
			return OnboardingInitialState;

		case OnboardingActionTypes.VerifyEmail:
			return {
				...state,
				...action.payload,
			};

		case OnboardingActionTypes.PaperlessOptInLoanNumbers:
			return {
				...state,
				paperlessOptInLoanNumbers: action.payload,
			};

		case OnboardingActionTypes.PaperlessOptOutLoanNumbers:
			return {
				...state,
				paperlessOptOutLoanNumbers: action.payload,
			};

		default:
			return state;
	}
};

export default onboardingReducer;
