import React, { ReactNode } from 'react';
import { DateTime } from 'luxon';

import { Grid, Divider } from '@material-ui/core';

import { NumberUtility, Text } from 'sfc-kit';
import { PrependDollarSign, AppendPercentSign } from 'utils';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const generateLoanDetails = (loanDetails: LoanOverview, classes: Record<'promotions', string>): SectionRow[] => {
	const details = {
		originalLoanAmount: NumberUtility.formatCurrency(loanDetails.originalLoanAmount ?? 0),
		currentUnpaidPrincipalBalance: NumberUtility.formatCurrency(loanDetails.currentUnpaidPrincipalBalance ?? 0),
		interestAccrued: NumberUtility.formatCurrency(loanDetails.interestAccrued ?? 0),
		otherCharges: NumberUtility.formatCurrency(loanDetails.otherCharges ?? 0),
		fees: NumberUtility.formatCurrency(loanDetails.fees ?? 0),
		lateCharges: NumberUtility.formatCurrency(loanDetails.lateCharges ?? 0),
		totalBalanceOwed: NumberUtility.formatCurrency(loanDetails.totalBalanceOwed ?? 0),
		interestRate: NumberUtility.formatCurrency(loanDetails.interestRate ?? 0),
		perDiemInterest: NumberUtility.formatCurrency(loanDetails.perDiemInterest ?? 0),
		interestPaidPriorYear: NumberUtility.formatCurrency(loanDetails.interestPaidPriorYear ?? 0),
		interestPaidYearToDate: NumberUtility.formatCurrency(loanDetails.interestPaidYearToDate ?? 0),
		lastPayment: NumberUtility.formatCurrency(loanDetails.lastPayment ?? 0),
		dateReceived: loanDetails.dateReceived
			? DateTime.fromISO(loanDetails.dateReceived)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		nextTotalBilling: NumberUtility.formatCurrency(loanDetails.nextTotalBilling ?? 0),
		daysPastDue: loanDetails.daysPastDue,
		totalPastDue: NumberUtility.formatCurrency(loanDetails.totalPastDue ?? 0),
		totalCurrentDue: NumberUtility.formatCurrency(loanDetails.totalCurrentDue ?? 0),
		openDate: loanDetails.openDate
			? DateTime.fromISO(loanDetails.openDate)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		interestAccruedThrough: loanDetails.interestAccruedThrough
			? DateTime.fromISO(loanDetails.interestAccruedThrough ?? '')
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		loanMaturityDate: loanDetails.loanMaturityDate
			? DateTime.fromISO(loanDetails.loanMaturityDate)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		nextStatementDate: loanDetails.nextStatementDate
			? DateTime.fromISO(loanDetails.nextStatementDate)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		payoffDate: loanDetails.payoffDate
			? DateTime.fromISO(loanDetails.payoffDate ?? '')
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		closedDate: loanDetails.closedDate
			? DateTime.fromISO(loanDetails.closedDate)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		promotionExpiryDate: loanDetails.promotionExpiryDate
			? DateTime.fromISO(loanDetails.promotionExpiryDate)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		promotionIneligibleDate: loanDetails.promotionIneligibleDate
			? DateTime.fromISO(loanDetails.promotionIneligibleDate)
					.toUTC()
					.toFormat('MM/dd/yyyy')
			: 'N/A',
		deferredInterestCharge: NumberUtility.formatCurrency(loanDetails.deferredInterestCharge ?? 0),
	};

	const sections = [
		{
			label: 'misc.amount',
			rows: [
				{
					name: 'Original Loan Amount',
					value: PrependDollarSign(details.originalLoanAmount),
				},
				{
					name: 'Current Unpaid Principal Balance',
					value: PrependDollarSign(details.currentUnpaidPrincipalBalance),
				},
				{
					name: 'Interest Accrued',
					value: PrependDollarSign(details.interestAccrued),
				},
				{
					name: 'Other Charges',
					value: PrependDollarSign(details.otherCharges),
				},
				{
					name: 'Fees',
					value: PrependDollarSign(details.fees),
				},
				{
					name: 'Late Charges',
					value: PrependDollarSign(details.lateCharges),
				},
				{
					name: 'Total Balance Owed',
					value: PrependDollarSign(details.totalBalanceOwed),
				},
			],
		},
		{
			label: 'misc.interest',
			rows: [
				{
					name: 'Interest Rate',
					value: AppendPercentSign(details.interestRate),
				},
				{
					name: 'Per Diem Interest',
					value: PrependDollarSign(details.perDiemInterest),
				},
				{
					name: 'Interest Paid Prior Year',
					value: PrependDollarSign(details.interestPaidPriorYear),
				},
				{
					name: 'Interest Paid YTD',
					value: PrependDollarSign(details.interestPaidYearToDate),
				},
			],
		},
		{
			label: 'misc.payment',
			rows: [
				{
					name: 'Last Payment',
					value: PrependDollarSign(details.lastPayment),
				},
				{
					name: 'Date Received',
					value: details.dateReceived,
				},
				{
					name: 'Next Total Billing',
					value: PrependDollarSign(details.nextTotalBilling),
				},
				{
					name: 'Days Past Due',
					value: details.daysPastDue,
				},
				{
					name: 'Total Past Due',
					value: PrependDollarSign(details.totalPastDue),
				},
				{
					name: 'Total Current Due',
					value: PrependDollarSign(details.totalCurrentDue),
				},
			],
		},
		{
			label: 'misc.dates',
			rows: [
				{
					name: 'Open Date',
					value: details.openDate,
				},
				{
					name: 'Interest Accrued Through',
					value: details.interestAccruedThrough,
				},
				{
					name: 'Loan Maturity Date',
					value: details.loanMaturityDate,
				},
				{
					name: 'Next Statement Date',
					value: details.nextStatementDate,
				},
				{
					name: 'Payoff Date',
					value: details.payoffDate,
				},
				{
					name: 'Closed Date',
					value: details.closedDate,
				},
			],
		},
		{
			children: (
				<Grid item xs={12}>
					<Grid item xs md={6} className={classes.promotions}>
						<Text variant="body2" component="h2">
							Promotions Details
						</Text>
						<Divider aria-hidden="true" />
					</Grid>
				</Grid>
			),
		},
		{
			label: 'misc.dates',
			rows: [
				{
					name: 'Promotion Expiry Date',
					value: details.promotionExpiryDate,
				},
				{
					name: 'Promotion Ineligible Date',
					value: details.promotionIneligibleDate,
				},
				{
					name: 'Deferred Interest Charge',
					value: PrependDollarSign(details.deferredInterestCharge),
				},
			],
		},
	];

	return sections;
};

interface SectionRow {
	label?: string;
	rows?: Array<{
		name: string;
		value: string | number | undefined;
	}>;
	children?: ReactNode;
}

export default generateLoanDetails;
