import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AuthenticationSessionGet } from '../utils/BrowserStorage';

const renewMobile = 'AUTH_RENEW_MOBILE';
class ApiService {
	axiosInstance: AxiosInstance;

	constructor(baseURL: string) {
		if (!baseURL) {
			throw new Error(`Cannot complete the request because baseURL prop is undefined.`);
		}

		//create an axios instance with default configuration
		this.axiosInstance = axios.create({
			baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
			timeout: 60000,
			withCredentials: false,
		});

		this.setupInterceptors();
	}

	//method to set the authorization token
	setOtherToken(token: string): void {
		this.axiosInstance.defaults.headers['Lauthorization'] = token;
	}

	//method to set the authorization bearer token
	setAuthToken(token: string): void {
		this.axiosInstance.defaults.headers['Authorization'] = token.startsWith('Bearer') ? token : `Bearer ${token}`;
	}

	//method to clear the authorization token
	clearOtherToken(): void {
		delete this.axiosInstance.defaults.headers['Lauthorization'];
	}

	//method to clear the authorization bearer token
	clearAuthToken(): void {
		delete this.axiosInstance.defaults.headers['Authorization'];
	}

	clearTokens(): void {
		this.clearOtherToken();
		this.clearAuthToken();
	}

	setAuthentication = (response: any) => {
		const { userName, ...rest } = response;

		const payload = {
			...rest,
			isAuthenticated: true,
			username: userName.toLowerCase(),
		};

		sessionStorage.removeItem(renewMobile);
		sessionStorage.set('Authentication', JSON.stringify({ payload }));
	};

	renewToken(authToken: string) {
		try {
			axios
				.post(
					`${process.env.REACT_APP_GATEWAY_API_URL}/api/Authentication/RenewToken`,
					{ requestToken: '' },
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: authToken.startsWith('Bearer') ? authToken : `Bearer ${authToken}`,
						},
						timeout: 5000,
					}
				)
				.then(({ data }) => {
					this.setAuthentication(data);
				});
		} catch (error) {}
	}

	setupInterceptors() {
		//add a request interceptor
		this.axiosInstance.interceptors.request.use(
			(config: any) => {
				const { authToken, otherToken } = AuthenticationSessionGet();

				if (authToken) {
					config.headers['Authorization'] = authToken.startsWith('Bearer') ? authToken : `Bearer ${authToken}`;
				}

				if (otherToken) {
					config.headers['Lauthorization'] = otherToken;
				}

				return config;
			},
			error => {
				return Promise.reject(error);
			}
		);

		this.axiosInstance.interceptors.response.use(
			(response: AxiosResponse) => {
				return response;
			},
			(error: AxiosError) => {
				throw error;
			}
		);
	}

	//GET request method
	async get<T>(url: string, params?: any): Promise<AxiosResponse<T>> {
		try {
			return await this.axiosInstance.get<T>(url, { params });
		} catch (error) {
			return this.handleError(error);
		}
	}

	//POST request method
	async post<T>(url: string, data?: any): Promise<AxiosResponse<T>> {
		try {
			return await this.axiosInstance.post<T>(url, data);
		} catch (error) {
			return this.handleError(error);
		}
	}

	//PUT request method
	async put<T>(url: string, data?: any): Promise<AxiosResponse<T>> {
		try {
			return await this.axiosInstance.put<T>(url, data);
		} catch (error) {
			return this.handleError(error);
		}
	}

	//PATCH request method
	async patch<T>(url: string, data?: any): Promise<AxiosResponse<T>> {
		try {
			return await this.axiosInstance.patch<T>(url, data);
		} catch (error) {
			return this.handleError(error);
		}
	}

	//DELETE request method
	async delete(url: string, body?: any): Promise<AxiosResponse<boolean>> {
		try {
			return await this.axiosInstance.request<boolean>({
				method: 'DELETE',

				url: url,

				headers: {},

				params: {},

				data: body,
			});
		} catch (error) {
			return this.handleError(error);
		}
	}

	//handle errors
	handleError(error: any): any {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unknown error:', error);
		}

		throw error;
	}
}

export const ApiCSGService = (): ApiService => {
	const baseURL = process.env.REACT_APP_GATEWAY_API_URL ?? '';

	return new ApiService(baseURL);
};

export const ApiHeimdalService = (): ApiService => {
	const baseURL = process.env.REACT_APP_WS_HEIMDAL_PATH ?? '';

	return new ApiService(baseURL);
};

export default ApiService;
