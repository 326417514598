import React, { FunctionComponent } from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, Theme, createStyles, AppBar, Toolbar, IconButton } from '@material-ui/core';

import UserDropdown from './UserDropdown';
import Image from 'sfc-kit/src/components/Image';
import { FlexBox, FlexItem, Text } from 'sfc-kit';

export interface IHeaderProps {
	onMenuClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary,
			display: 'flex',
			zIndex: theme.zIndex.drawer + 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up(1281)]: {
				display: 'none',
			},
		},
		logoWrapper: {
			display: 'flex',
			justifyContent: 'center',
			flexGrow: 1,
		},
		menuText: {
			paddingLeft: theme.spacing(0),
			marginRight: theme.spacing(2),
			[theme.breakpoints.down(1279)]: {
				display: 'none',
			},
		},
		imageHeight: {
			height: 27,
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		focusDropdown: {
			'&:focus-visible': {
				border: `2px solid ${theme.palette.primary.main}`,
			},
			'&:focus:not(:focus-visible)': {
				border: 'none',
			},
		},
	})
);

const Header: FunctionComponent<IHeaderProps> = ({ onMenuClick }) => {
	const classes = useStyles({} as any);

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<FlexBox direction="row" justifyContent="space-between">
					<FlexItem className={classes.menuButton}>
						<IconButton color="inherit" edge="start" className={classes.focus} onClick={onMenuClick} tabIndex={1}>
							<MenuIcon />
							<Text variant="srOnly">Menu</Text>
						</IconButton>
					</FlexItem>
					<FlexItem className={classes.menuText}>
						<IconButton color="inherit" edge="start" onClick={onMenuClick} className={classes.focusDropdown} tabIndex={1}>
							<Image src="/svcfin_logo_36px.png" alt="Service Finance Company, LLC" />
							<Image src="/svcfin_customer_portal_logo_text_only.png" alt="Customer Portal" className={classes.imageHeight} />
						</IconButton>
					</FlexItem>
					<FlexItem>
						<UserDropdown />
					</FlexItem>
				</FlexBox>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
