import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanSummaryResult = QueryResult<LoanSummary, AxiosError<LoanSummary>>;
export type LoanSummaryConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<LoanSummary, AxiosError<LoanSummary>>;
};

interface LoanSummaryQuery {
	(request: LoanSummaryRequestParameters, config?: LoanSummaryConfig): LoanSummaryResult;
	key: 'LoanSummary';
}

const useLoanSummaryQuery: LoanSummaryQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanSummary(): Promise<LoanSummary> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/LoanSummary`;

		const response = await client.get<LoanSummary>(path);

		return response.data;
	}

	return useQuery(['LoanSummary', ...Object.values(request)], LoanSummary, config.queryConfig);
};

useLoanSummaryQuery.key = 'LoanSummary';

export default useLoanSummaryQuery;
