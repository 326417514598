import React, { createContext, useReducer, Dispatch, useEffect, PropsWithChildren } from 'react';
import IDocument from 'sfc-kit/src/components/PdfViewer/IDocument';
import { ActionMap } from 'types/Context';
import reducer from './LoginReducer';
import { SaveState } from 'sfc-kit/src/utils/BrowserStorage';
import FormFieldStatus from 'sfc-kit/src/types/FormFieldStatus';
import { DateTime } from 'luxon';

export type LoginInitialStateType = {
	username: string;
	usernameStatus: FormFieldStatus;

	password: string;
	passwordStatus: FormFieldStatus;

	loginAttempts: number;
	lockedOutUntil?: DateTime;

	loginStatus: {
		wasSuccessful?: boolean;
		errorTitle?: string;
		errorMessage?: string;
	};

	rememberMe: boolean;
};

export const loginInitialState: LoginInitialStateType = {
	username: '',
	usernameStatus: {},

	password: '',
	passwordStatus: {},

	loginAttempts: 0,
	lockedOutUntil: undefined,

	loginStatus: {
		wasSuccessful: undefined,
		errorTitle: undefined,
		errorMessage: undefined,
	},

	rememberMe: false,
};

export enum LoginActionTypes {
	UpdateLoginUsername = 'UPDATE_LOGIN_USERNAME',
	UpdateLoginPassword = 'UPDATE_LOGIN_PASSWORD',
	UpdateRememberMe = 'UPDATE_REMEMBER_ME',
	ResetLoginAttempts = 'RESET_LOGIN_ATTEMPTS',
	IncrementLoginAttempts = 'INCERMENT_LOGIN_ATTEMPTS',
	RefreshLogin = 'REFRESH_LOGIN',
	LockedOut = 'LOCKED_OUT',
	LoadState = 'LOAD_STATE',
}

export interface LoginPayloadType {
	source?: IDocument['source'];
	date: string;
}

type UpdateUsernameType = string;
type UpdatePasswordType = string;
type UpdateRememberMeType = boolean;

type LoginPayload = {
	[LoginActionTypes.UpdateLoginUsername]: UpdateUsernameType;
	[LoginActionTypes.UpdateLoginPassword]: UpdatePasswordType;
	[LoginActionTypes.UpdateRememberMe]: UpdateRememberMeType;
	[LoginActionTypes.ResetLoginAttempts]: undefined;
	[LoginActionTypes.IncrementLoginAttempts]: undefined;
	[LoginActionTypes.RefreshLogin]: undefined;
	[LoginActionTypes.LockedOut]: undefined;
	[LoginActionTypes.LoadState]: undefined;
};

export type LoginActions = ActionMap<LoginPayload>[keyof ActionMap<LoginPayload>];

export type LoginDispatchType = Dispatch<LoginActions>;

export const LoginContext = createContext<{
	loginState: LoginInitialStateType;
	dispatchForLogin: LoginDispatchType;
}>({
	loginState: loginInitialState,
	dispatchForLogin: () => null,
});

export const LoginProvider: React.FC = ({
	initialState = loginInitialState,
	children,
}: PropsWithChildren<{ initialState?: LoginInitialStateType }>) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		dispatch({ type: LoginActionTypes.LoadState });
	}, []);

	useEffect(() => {
		SaveState('Login', state);
	}, [state]);

	return <LoginContext.Provider value={{ loginState: state, dispatchForLogin: dispatch }}>{children}</LoginContext.Provider>;
};

export default LoginContext;
