/* istanbul ignore file */
class Environment {
	private readonly environment: string | undefined;
	private readonly URL: string | undefined;

	constructor() {
		this.environment = process.env.NODE_ENV;
		this.URL = process.env.REACT_APP_GATEWAY_API_URL;
	}

	IsTest(): boolean {
		return this.environment === 'test';
	}

	IsDev(): boolean {
		return this.environment === 'development';
	}

	IsRC(): boolean {
		return this.environment === 'rc';
	}

	IsProduction(): boolean {
		return this.environment === 'production';
	}

	isProductionURL(): boolean {
		const URL = this.URL || '';
		if (URL.indexOf('http://192.168.20.6') !== -1 || URL.indexOf('csgtest.svcfin.com') !== -1) {
			return false;
		}
		return true;
	}
}

const environment = new Environment();

export default environment;
