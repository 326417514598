const Root = '/Registration';

const Registration = {
	Root,
	BorrowerInfo: `${Root}/BorrowerInformation`,
	SecurityQuestions: `${Root}/SecurityQuestions`,
	CreatePassword: `${Root}/CreatePassword`,
	Confirmation: `${Root}/Confirmation`,
	VerifyEmail: `${Root}/VerifyEmail`,
	Completed: `${Root}/Completed`,
};

export default Registration;
