import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { type FontWeightProperty } from 'csstype';

import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Messages, SfcKitMessages, MessagesProps } from '../../';
import { LangText } from '../types';
import { Text } from './Typography';

export interface IRadioListGroupItem<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	label: React.ReactNode;
	value: LangText<TMessages>;
	labelString?: string;
}

interface IRadioListGroupProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	label?: LangText<TMessages>;
	direction?: 'row' | 'column';

	name: string;
	value: string;
	onChange: (value: string) => void;

	items: IRadioListGroupItem<TMessages>[];
	disabledItems?: Array<LangText<TMessages>>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: { width: '100%' },
		label: {
			border: '3px solid',
			borderColor: theme.palette.divider,

			marginLeft: 0,
			marginRight: 0,

			'& span': {
				fontSize: theme.typography.body2.fontSize,

				'& strong': {
					fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
				},
			},
		},
		activeLabel: {
			borderColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
		},
		formLabel: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		radioGroup: ({ direction }: { direction: IRadioListGroupProps['direction'] }) => ({
			flexDirection: direction,

			...(direction === 'row' && {
				justifyContent: 'space-between',
				'&> *': {
					width: '49%',
				},
			}),
		}),
	})
);

export default function RadioListGroup<TMessages extends Messages = SfcKitMessages>({
	label,
	name,
	value,
	onChange,
	items,
	direction = 'column',
	messages,
	disabledItems = [],
}: IRadioListGroupProps<TMessages>): ReactElement {
	const classes = useStyles({ direction });
	const labelExists = label && messages?.[label];

	return (
		<FormControl component="fieldset" className={classes.formControl}>
			{labelExists ? (
				<Text message={label} messages={messages} className={classes.formLabel} />
			) : (
				label && (
					<FormLabel className={classes.formLabel} component="legend">
						{label}
					</FormLabel>
				)
			)}
			<RadioGroup className={classes.radioGroup} name={name} value={value} onChange={(event): void => onChange(event.target.value)}>
				{items.map((item, index) => {
					const { value: itemValue, label, labelString } = item;
					const valueExists = messages?.[itemValue];

					return (
						<FormControlLabel
							className={classnames(classes.label, { [classes.activeLabel]: value === itemValue })}
							key={`${itemValue.toString()}-${index}`}
							value={valueExists || itemValue}
							disabled={disabledItems.includes(itemValue)}
							control={<Radio tabIndex={1} color="primary" inputProps={{ 'aria-label': `${labelString}` ?? undefined }} />}
							label={label}
						/>
					);
				})}
			</RadioGroup>
		</FormControl>
	);
}
