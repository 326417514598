import CommonServicesGatewayAPI from './api/CommonServicesGatewayApi';

export enum LogEventOriginEnum {
	Unknown = 0,
	CustomerPortal = 1,
	DealerPortal = 2,
}

export enum LogLevel {
	Trace = 0,
	Debug = 1,
	Information = 2,
	Warning = 3,
	Error = 4,
	Critical = 5,
}

export default class LoggingService {
	private readonly Origin: LogEventOriginEnum;
	private readonly CommonServicesGatewayAPI: CommonServicesGatewayAPI;

	constructor(origin: LogEventOriginEnum, commonServicesGatewayAPI: CommonServicesGatewayAPI) {
		this.Origin = origin;
		this.CommonServicesGatewayAPI = commonServicesGatewayAPI;
	}

	/**
	 * Sends a request to the CommonServiceGatewayAPI to append information level data to the log.
	 * @param message The message to be appended.
	 * @param args Arguments to be inserted into the message.
	 *
	 * If you prepend the name of the variable in the message with an `@` than it will be serialized as JSON in the logs.
	 *
	 * @example LogInfo('Scheduling payment {payment} for loan #', { paymentAmount: '$123.00' }, '918273645')
	 */
	public LogInfo(message: string, ...args: {}[]): void {
		this.Log(LogLevel.Information, message, args);
	}

	/**
	 * Sends a request to the CommonServiceGatewayAPI to append warning level data to the log.
	 * @param message The message to be appended.
	 * @param args Arguments to be inserted into the message.
	 *
	 * If you prepend the name of the variable in the message with an `@` than it will be serialized as JSON in the logs.
	 *
	 * @example LogWarning('Payment Date was null for the following payment {payment}', { paymentDate: null })
	 */
	public LogWarning(message: string, ...args: {}[]): void {
		this.Log(LogLevel.Warning, message, args);
	}

	/**
	 * Sends a request to the CommonServiceGatewayAPI to append error level data to the log.
	 * @param message The message to be appended.
	 * @param args Arguments to be inserted into the message.
	 *
	 * If you prepend the name of the variable in the message with an `@` than it will be serialized as JSON in the logs.
	 *
	 * @example LogError('Something went wrong when trying to schedule the following payment {payment} for loan #', { paymentDate: null }, '918273645')
	 */
	public LogError(message: string, ...args: {}[]): void {
		this.Log(LogLevel.Error, message, args);
	}

	/**
	 * Sends a request to the CommonServiceGatewayAPI to append critical level data to the log.
	 * @param message The message to be appended.
	 * @param args Arguments to be inserted into the message.
	 *
	 * If you prepend the name of the variable in the message with an `@` than it will be serialized as JSON in the logs.
	 *
	 * @example LogCritical('Something unexpected happened and the app has now exploded. {error}', error)
	 */
	public LogCritical(message: string, ...args: {}[]): void {
		this.Log(LogLevel.Critical, message, args);
	}

	private Log(logLevel: LogLevel, message: string, args: {}[]): void {
		this.CommonServicesGatewayAPI.Log({
			body: {
				logEventOrigin: this.Origin,
				logLevel,
				message,
				arguments: args as any,
			},
		});
	}
}
