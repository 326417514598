import React from 'react';
import { Button, FlexBox, FlexItem, Spacing, Text } from 'sfc-kit';
import { useMessages } from 'hooks';
import Image from 'sfc-kit/src/components/Image';
import { homeInstallExperts } from 'constants/Paths/FindDealer';

import { createStyles, Dialog, makeStyles } from '@material-ui/core';

interface FindDealerDialogProps {
	isOpen: boolean;
	onCloseClick: () => void;
}

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			backgroundColor: theme.palette.grey[200],
			padding: theme.spacing(3, 0),
		},
		images: { width: '410px' },
		textWrapper: { maxWidth: '750px' },
		disclaimerText: { color: theme.palette.grey[900], textTransform: 'none' },
	})
);

const FindDealerDialog: React.FC<FindDealerDialogProps> = ({ isOpen, onCloseClick }) => {
	const messages = useMessages();
	const classes = useStyles({} as any);

	return (
		<Dialog onClose={onCloseClick} open={isOpen} maxWidth="md">
			<FlexBox direction="column" className={classes.root} alignItems="center">
				<FlexItem>
					<FlexBox direction="column" alignItems="center">
						<Spacing py="medium">
							<Image className={classes.images} src="/svcfin_logo_company_latest.png" alt="Service Financy Company, LLC" />
						</Spacing>
						<Spacing px="xLarge">
							<Text messages={messages} message="findDealer.findDealerLeaving" weight="medium" />
						</Spacing>
						<Spacing py="medium">
							<Image className={classes.images} src="/samsclub.png" alt="Home Install Experts by Service Finance" />
						</Spacing>
					</FlexBox>
				</FlexItem>
				<FlexItem className={classes.textWrapper}>
					<Spacing py="medium">
						<Text messages={messages} message="findDealer.findDealerBeAware" variant="button" className={classes.disclaimerText} />
					</Spacing>
					<Spacing py="medium">
						<Text messages={messages} message="findDealer.findDealerCompensation" variant="button" className={classes.disclaimerText} />
					</Spacing>
				</FlexItem>
				<FlexItem>
					<FlexBox>
						<Spacing py="medium" pr="small">
							<Button
								size="large"
								messages={messages}
								message="buttons.continue"
								onClick={(event): void => {
									event.preventDefault();
									window.open(`${homeInstallExperts}`, '_self');
								}}
							/>
						</Spacing>
						<Spacing py="medium" pl="small">
							<Button onClick={onCloseClick} size="large" messages={messages} message="buttons.cancel" />
						</Spacing>
					</FlexBox>
				</FlexItem>
			</FlexBox>
		</Dialog>
	);
};

export default FindDealerDialog;
