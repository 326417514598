import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetStipulationPdfResult = QueryResult<FileDto, AxiosError<FileDto>>;
export type GetStipulationPdfConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<FileDto, AxiosError<FileDto>>;
};

interface GetStipulationPdfQuery {
	(request: GetStipulationPdfRequestParameters, config?: GetStipulationPdfConfig): GetStipulationPdfResult;
	key: 'GetStipulationPdf';
}

const useGetStipulationPdfQuery: GetStipulationPdfQuery = (request, config = { axiosConfig: {} }) => {
	async function GetStipulationPdf(): Promise<FileDto> {
		const client = ApiCSGService();

		const path = '/api/Stipulations/GetStipulationPdf';

		const response = await client.get<FileDto>(path, {
			loanNumber: request?.loanNumber,
			taskId: request?.taskId,
			categoryId: request?.categoryId,
		});

		return response.data;
	}

	return useQuery(['GetStipulationPdf', ...Object.values(request)], GetStipulationPdf, config.queryConfig);
};

useGetStipulationPdfQuery.key = 'GetStipulationPdf';

export default useGetStipulationPdfQuery;
