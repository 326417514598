import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetPayoffAmountResult = QueryResult<Envelope<string>, AxiosError<Envelope<string>>>;
export type GetPayoffAmountConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<string>, AxiosError<Envelope<string>>>;
};

interface GetPayoffAmountQuery {
	(request: GetPayoffAmountRequestParameters, config?: GetPayoffAmountConfig): GetPayoffAmountResult;
	key: 'GetPayoffAmount';
}

const useGetPayoffAmountQuery: GetPayoffAmountQuery = (request, config = { axiosConfig: {} }) => {
	async function GetPayoffAmount(): Promise<Envelope<string>> {
		const client = ApiCSGService();

		const path = '/api/Payment/GetPayoffAmount';

		const response = await client.get<Envelope<string>>(path, {
			loanNumber: request?.loanNumber,
			loanAccountId: request?.loanAccountId,
			payoffDate: request?.payoffDate,
		});

		return response.data;
	}

	return useQuery(['GetPayoffAmount', ...Object.values(request)], GetPayoffAmount, config.queryConfig);
};

useGetPayoffAmountQuery.key = 'GetPayoffAmount';

export default useGetPayoffAmountQuery;
