import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PricingMatrixResult = QueryResult<PricingMatrix, AxiosError<PricingMatrix>>;
export type PricingMatrixConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<PricingMatrix, AxiosError<PricingMatrix>>;
};

interface PricingMatrixQuery {
	(request: PricingMatrixRequestParameters, config?: PricingMatrixConfig): PricingMatrixResult;
	key: 'PricingMatrix';
}

const usePricingMatrixQuery: PricingMatrixQuery = (request, config = { axiosConfig: {} }) => {
	async function PricingMatrix(): Promise<PricingMatrix> {
		const client = ApiCSGService();

		const path = '/api/Dealer/PricingMatrix';

		const response = await client.get<PricingMatrix>(path);

		return response.data;
	}

	return useQuery(['PricingMatrix', ...Object.values(request)], PricingMatrix, config.queryConfig);
};

usePricingMatrixQuery.key = 'PricingMatrix';

export default usePricingMatrixQuery;
