import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import SquareButton, { ISquareButtonProps } from './SquareButton';

const SquareButtonLink: React.FC<ISquareButtonProps & LinkProps> = ({ to, ...props }) => {
	/* eslint-disable-next-line */
	// @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877#issuecomment-493518825
	return <SquareButton component={Link} to={to} {...props} />;
};

export default SquareButtonLink;
