import {
	AuthenticationInitialStateType,
	AuthenticationInitialState,
	AuthenticationActions,
	AuthenticationActionTypes,
} from './AuthenticationContext';
import { getTokenExpirationDate } from 'utils/Auth';

export const authenticationReducer = (
	state: AuthenticationInitialStateType = AuthenticationInitialState,
	action: AuthenticationActions
): AuthenticationInitialStateType => {
	switch (action.type) {
		case AuthenticationActionTypes.ClearAuthToken:
			return AuthenticationInitialState;

		case AuthenticationActionTypes.UpdateAuthToken:
			return {
				authToken: action.payload,
				authTokenExpiration: getTokenExpirationDate(),
				isAuthenticated: true,
				username: state.username,
			};
			
		case AuthenticationActionTypes.UpdateUsername:
			return {
				...state,
				username: action.payload.toLowerCase(),
			};

		case AuthenticationActionTypes.UpdateAuthState:
			return {
				...action.payload,
			};

		/* eslint-disable no-case-declarations */
		case AuthenticationActionTypes.LoadAuthState:
			const sessionItem = sessionStorage.getItem('Authentication');

			if (!sessionItem) {
				return AuthenticationInitialState;
			}

			return JSON.parse(sessionItem);
		/* eslint-enable no-case-declarations */
		default:
			return state;
	}
};

export default authenticationReducer;
