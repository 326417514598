import React, { FunctionComponent } from 'react';

import LockIcon from '@material-ui/icons/Lock';

import { AlreadyHaveAnAccountPrompt } from 'components';
import { WizardCard, WizardCardProps } from 'sfc-kit';

export interface PasswordRecoveryCardProps extends Omit<WizardCardProps, 'stepperProps'> {
	activeStep: number;
}

const PasswordRecoveryCard: FunctionComponent<PasswordRecoveryCardProps> = ({ activeStep, contentProps, ...props }) => {
	return (
		<WizardCard
			icon={LockIcon}
			title="Forgot Password"
			sidebarFooter={<AlreadyHaveAnAccountPrompt />}
			stepperProps={{ activeStep, id: 'forgot-password-stepper', numSteps: 4 }}
			contentProps={{ title: contentProps?.title ?? '', ...contentProps }}
			{...props}
		/>
	);
};

export default PasswordRecoveryCard;
