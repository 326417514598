import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetPaperlessAddressesResult = QueryResult<
	Envelope<GetCommunicationAddressResponse>,
	AxiosError<Envelope<GetCommunicationAddressResponse>>
>;
export type GetPaperlessAddressesConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<GetCommunicationAddressResponse>, AxiosError<Envelope<GetCommunicationAddressResponse>>>;
};

interface GetPaperlessAddressesQuery {
	(request: GetPaperlessAddressesRequestParameters, config?: GetPaperlessAddressesConfig): GetPaperlessAddressesResult;
	key: 'GetPaperlessAddresses';
}

const useGetPaperlessAddressesQuery: GetPaperlessAddressesQuery = (request, config = { axiosConfig: {} }) => {
	async function GetPaperlessAddresses(): Promise<Envelope<GetCommunicationAddressResponse>> {
		const client = ApiCSGService();

		const path = '/api/LoanCommunication/GetPaperlessAddresses';

		const response = await client.get<Envelope<GetCommunicationAddressResponse>>(path, {
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetPaperlessAddresses', ...Object.values(request)], GetPaperlessAddresses, config.queryConfig);
};

useGetPaperlessAddressesQuery.key = 'GetPaperlessAddresses';

export default useGetPaperlessAddressesQuery;
