import { AxiosError, AxiosRequestConfig } from 'axios';
import { usePaginatedQuery, QueryConfig, PaginatedQueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type PaginatedStatementsResult = PaginatedQueryResult<PaginatedStatements, AxiosError<PaginatedStatements>>;
export type PaginatedStatementsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<PaginatedStatements, AxiosError<PaginatedStatements>>;
};

interface PaginatedStatementsQuery {
	(request: PaginatedStatementsRequestParameters, config?: PaginatedStatementsConfig): PaginatedStatementsResult;
	key: 'PaginatedStatements';
}

const usePaginatedStatementsQuery: PaginatedStatementsQuery = (request, config = { axiosConfig: {} }) => {
	async function PaginatedStatements(): Promise<PaginatedStatements> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/PaginatedStatements`;

		const response = await client.get<PaginatedStatements>(path, {
			page: request.page,
			statementsPerPage: request.statementsPerPage,
			startDate: request?.startDate,
			endDate: request?.endDate,
		});

		return response.data;
	}

	return usePaginatedQuery(['PaginatedStatements', ...Object.values(request)], PaginatedStatements, config.queryConfig);
};

usePaginatedStatementsQuery.key = 'PaginatedStatements';

export default usePaginatedStatementsQuery;
