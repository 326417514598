import React from 'react';
import WaringIcon from '@material-ui/icons/Warning';
import { Dialog } from 'sfc-kit';
import SquareButton from 'sfc-kit/src/components/SquareButton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, DialogContent, DialogActions, DialogContentText, DialogTitle, Container } from '@material-ui/core';
import classnames from 'classnames';

interface StopPaymentDialogProps {
	isOpen: boolean;
	stopPayment: (payment: ScheduledAchPayment) => void;
	setShowDialog: (arg: boolean) => void;
	payment: ScheduledAchPayment;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cancelButton: { color: theme.palette.primary.light },
		stopButton: {
			backgroundColor: theme.palette.danger.main,
			'&:hover': { backgroundColor: theme.palette.danger.dark },
			color: 'white',
		},
		iconTheme: { width: 60, height: 60, color: theme.palette.danger.main, marginRight: 20 },
		dialogTheme: { overflow: 'hidden !important', marginBottom: 20 },
		focus: {
			'&:focus-visible': {
				border: `2px solid ${theme.palette.primary.main}`,
			},
			'&:focus:not(:focus-visible)': {
				border: 'none',
			},
		},
	})
);

const StopPaymentDialog: React.FC<StopPaymentDialogProps> = ({ isOpen, payment, stopPayment, setShowDialog }) => {
	const classes = useStyles({});

	return (
		<Dialog
			id="stop-payment-dialog"
			title={`${payment.rowId}-cancel-dialog`}
			aria-describedby="stop-payment-description"
			aria-hidden="false"
			aria-live="polite"
			open={isOpen}>
			<DialogTitle role="alert" aria-label="Are you sure you want to cancel this payment?">
				<WaringIcon className={classes.iconTheme} />
				Alert
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="stop-payment-description" aria-hidden="false" aria-live="polite">
					You are cancelling a scheduled payment, are you sure you want to continue?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Container className={classes.dialogTheme}>
					<Grid container spacing={4} justifyContent={'center'}>
						<Grid item xs={12} sm={6}>
							<SquareButton
								fullWidth
								className={classnames(classes.focus, classes.stopButton)}
								variant="contained"
								id="stop-payment-button"
								tabIndex={1}
								aria-label="select to cancel payment"
								onClick={(): void => {
									stopPayment(payment);
									setShowDialog(false);
								}}>
								Yes
							</SquareButton>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SquareButton
								fullWidth
								autoFocus
								className={classnames(classes.cancelButton, classes.focus)}
								aria-label="Select to go back"
								tabIndex={1}
								variant="contained"
								onClick={(): void => setShowDialog(false)}>
								No
							</SquareButton>
						</Grid>
					</Grid>
				</Container>
			</DialogActions>
		</Dialog>
	);
};

export default StopPaymentDialog;
