import React, { useContext, useEffect, useState } from 'react';

import MailIcon from '@material-ui/icons/Mail';
import ContactsIcon from '@material-ui/icons/Contacts';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { makeStyles, Theme, createStyles, useTheme, useMediaQuery } from '@material-ui/core';

import RegexUtility from 'sfc-kit/src/utils/RegEx';
import NumericStepper from 'sfc-kit/src/components/NumericStepper';
import SfcTextField from 'sfc-kit/src/components/SfcForm/SfcTextField';
import SfcMaskedField from 'sfc-kit/src/components/SfcForm/SfcMaskedField';
import { FlexItem, Button, FlexBox, Text, Spacing, useField, Validator, useVerifyBorrowerInfoMutation, LangText } from 'sfc-kit';

import { useMessages } from 'hooks';
import SfcAlert from 'sfc-kit/src/components/SfcAlert';
import AuthenticationContext from 'context/Authentication';
import VerifyEmailVerification from 'schemas/VerifyEmail.Schema';
import OnboardingContext, { OnboardingActionTypes } from 'context/Onboarding';
import { CustomerPortalMessages } from 'types';
import CommonServicesGatewayAPI from 'utils/CommonServicesGatewayAPI';

interface VerifyEmailProps {
	contact: ContactDto | undefined
	onNextClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			maxWidth: '480px',
			padding: theme.spacing(1),
			textAlign: 'center',
		},
		stepper: { padding: 0 },
		textFields: {
			marginBottom: theme.spacing(3),
			[theme.breakpoints.down(1281)]: { marginBottom: 0 },
		},
		alertText: {
			marginBottom: theme.spacing(3),
			textAlign: 'left',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const VerifyEmail: React.FC<VerifyEmailProps> = ({contact, onNextClick }) => {
	const theme = useTheme();
	const classes = useStyles({} as any);
	const messages = useMessages();

	const [alertOpen, setAlertOpen] = useState<boolean>(false);
	const [verifyAttempts, setVerifyAttempts] = useState<number>(0);
	const [alertMessage, setAlertMessage] = useState<LangText<CustomerPortalMessages>>();

	const laptop = useMediaQuery(theme.breakpoints.down(1281));

	const generateAlert = (message: LangText<CustomerPortalMessages>): void => {
		setAlertOpen(true);
		setAlertMessage(message);
	};

	useEffect(() => {
		if (verifyAttempts) {
			if (verifyAttempts < 3) {
				generateAlert('user.verification.unableToValidate');
			} else {
				generateAlert('onboarding.cannotValidateCallSupport');
			}
		}
	}, [verifyAttempts]);

	const {
		authenticationState: { username },
	} = useContext(AuthenticationContext);

	
	const [verifyBorrowerInfo] = useVerifyBorrowerInfoMutation();

	const { onboardingState, dispatchForOnboarding } = useContext(OnboardingContext);

	const [loanNumber, setLoanNumber, loanNumberValidation] = useField(
		onboardingState.loanNumber,
		Validator.FromYupSchema(VerifyEmailVerification.fields.loanNumber)
	);

	const [ssn, setSSN, socialSecurityNumberValidation] = useField(
		onboardingState.ssn,
		Validator.FromYupSchema(VerifyEmailVerification.fields.socialSecurityNumber)
	);

	const [email, setEmail, emailValidation] = useField(onboardingState.email, Validator.FromYupSchema(VerifyEmailVerification.fields.email));

	const handleNextClick = async (): Promise<void> => {
		if (contact === undefined) {
			return;
		}

		const allFieldsAreValid =
			loanNumberValidation.ValidateSync() && emailValidation.ValidateSync() && socialSecurityNumberValidation.ValidateSync() && username;

		if (allFieldsAreValid) {
			const emailExists = await CommonServicesGatewayAPI.IsUserTaken({
				username: email.toLowerCase(),
				existingUsername: username,
				loanNumber,
			});

			if (emailExists.data.usernameIsTaken) {
				generateAlert('user.username.usernameTaken');
				const attempts = verifyAttempts + 1;
				setVerifyAttempts(attempts);
			} else {
				const borrowerInfoValid = await verifyBorrowerInfo({
					body: {
						existingUsername: username,
						username: email.toLowerCase(),
						firstName: contact.firstNameOne,
						lastName: contact.lastNameOne,
						loanNumber,
						ssNumber: ssn,
					},
				});

				if (borrowerInfoValid) {
					dispatchForOnboarding({ type: OnboardingActionTypes.VerifyEmail, payload: { loanNumber, ssn, email: email.toLowerCase() } });
					setAlertOpen(false);
					onNextClick();
				} else {
					const attempts = verifyAttempts + 1;
					setVerifyAttempts(attempts);
				}

				const attempts = verifyAttempts + 1;
				setVerifyAttempts(attempts);
			}
		} else {
			const attempts = verifyAttempts + 1;
			setVerifyAttempts(attempts);
		}
	};

	return (
		<>
			<NumericStepper className={classes.stepper} activeStep={1} id="email-verified-stepper" numSteps={2} />
			<Spacing pb="large">
				<Text
					messages={messages}
					message="onboarding.notVerified"
					aria-label="onboarding.notVerified"
					variant={laptop ? 'body2' : 'body1'}
					inline
					tabIndex={1}
					className={classes.focus}
				/>
				<Text variant={laptop ? 'body2' : 'body1'} inline>
					{' '}
				</Text>
				<Text
					weight="medium"
					messages={messages}
					message="onboarding.emailWillBeNewUsername"
					aria-label="onboarding.emailWillBeNewUsername"
					variant={laptop ? 'body2' : 'body1'}
					inline
					tabIndex={1}
					className={classes.focus}
				/>
			</Spacing>
			<SfcAlert
				variant="standard"
				className={classes.alertText}
				open={alertOpen}
				type="error"
				positioned
				message={alertMessage}
				messages={messages}
			/>
			<FlexBox>
				<FlexItem xs={12} className={classes.textFields}>
					<SfcTextField
						required
						margin="none"
						id="loanNumber"
						name="loanNumber"
						tabIndex={1}
						value={loanNumber}
						placeholder="Loan Number"
						error={loanNumberValidation.Invalid}
						helperText={loanNumberValidation.ReasonForInvalidity}
						onBlur={(): boolean => loanNumberValidation.ValidateSync()}
						StartIcon={SubtitlesIcon}
						onChange={(value): void => setLoanNumber(RegexUtility.formatNumeric(value))}
						inputProps={{ 'aria-label': 'Loan Number' }}
					/>
				</FlexItem>
				<FlexItem xs={12} className={classes.textFields}>
					<SfcMaskedField
						required
						margin="none"
						name="ssn"
						answerTabIndex={1}
						value={ssn}
						placeholder="Social Security Number"
						error={socialSecurityNumberValidation.Invalid}
						helperText={socialSecurityNumberValidation.ReasonForInvalidity}
						onBlur={(): boolean => socialSecurityNumberValidation.ValidateSync()}
						StartIcon={ContactsIcon}
						onChange={(value): void => setSSN(RegexUtility.formatSSN(value))}
						inputProps={{ 'aria-label': 'Social Security Number' }}
					/>
				</FlexItem>
				<FlexItem xs={12} className={classes.textFields}>
					<SfcTextField
						required
						margin="none"
						id="email"
						tabIndex={1}
						name="email"
						value={email}
						placeholder="Email Address"
						onBlur={(): void => {
							emailValidation.ValidateSync();
						}}
						error={emailValidation.Invalid}
						helperText={emailValidation.ReasonForInvalidity}
						StartIcon={MailIcon}
						onChange={(value): void => setEmail(value)}
						inputProps={{ 'aria-label': 'Email Address' }}
					/>
				</FlexItem>
			</FlexBox>
			<Button
				casing="capitalizeEachWord"
				messages={messages}
				message="buttons.continue"
				className={classes.focus}
				fullWidth
				tabIndex={1}
				onMouseDown={handleNextClick}
				disabled={verifyAttempts >= 3}
				aria-label="Continue"
			/>
		</>
	);
};

export default VerifyEmail;
