import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetActiveInitialDocumentResult = QueryResult<InitialDocument, AxiosError<InitialDocument>>;
export type GetActiveInitialDocumentConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<InitialDocument, AxiosError<InitialDocument>>;
};

interface GetActiveInitialDocumentQuery {
	(request: GetActiveInitialDocumentRequestParameters, config?: GetActiveInitialDocumentConfig): GetActiveInitialDocumentResult;
	key: 'GetActiveInitialDocument';
}

const useGetActiveInitialDocumentQuery: GetActiveInitialDocumentQuery = (request, config = { axiosConfig: {} }) => {
	async function GetActiveInitialDocument(): Promise<InitialDocument> {
		const client = ApiCSGService();

		const path = '/api/InitialDocuments/Active';

		const response = await client.get<InitialDocument>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetActiveInitialDocument', ...Object.values(request)], GetActiveInitialDocument, config.queryConfig);
};

useGetActiveInitialDocumentQuery.key = 'GetActiveInitialDocument';

export default useGetActiveInitialDocumentQuery;
