import React, { FunctionComponent } from 'react';

import { TableCell as MuiTableCell } from '@material-ui/core';

export interface TableCellProps {
	dense?: boolean;
	rowSpan?: number;
	columnSpan?: number;

	className?: string;

	children?: React.ReactNode;
}

const TableCell: FunctionComponent<TableCellProps> = ({ dense = false, rowSpan, columnSpan, className, children }) => {
	return (
		<MuiTableCell size={dense ? 'small' : 'medium'} rowSpan={rowSpan} colSpan={columnSpan} className={className}>
			{children}
		</MuiTableCell>
	);
};

export default TableCell;
