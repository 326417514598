import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type DocumentResult = QueryResult<string, AxiosError<string>>;
export type DocumentConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<string, AxiosError<string>>;
};

interface DocumentQuery {
	(request: DocumentRequestParameters, config?: DocumentConfig): DocumentResult;
	key: 'Document';
}

const useDocumentQuery: DocumentQuery = (request, config = { axiosConfig: {} }) => {
	async function Document(): Promise<string> {
		const client = ApiCSGService();

		const path = '/api/Loans/Document';

		const response = await client.get<string>(path, {
			documentId: request?.documentId,
		});

		return response.data;
	}

	return useQuery(['Document', ...Object.values(request)], Document, config.queryConfig);
};

useDocumentQuery.key = 'Document';

export default useDocumentQuery;
