import React, { useContext, useEffect } from 'react';

import { Skeleton } from '@material-ui/lab';
import PlaceIcon from '@material-ui/icons/Place';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import { createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { FlexBox, FlexItem, Spacing, Text, useLockboxAddressesQuery } from 'sfc-kit';
import Fieldset from 'sfc-kit/src/components/Fieldset/Fieldset';

import { LoanContext } from 'context';
import { useMessages } from 'hooks';

const useStyles = makeStyles(() =>
	createStyles({
		addressWrapper: {
			display: 'flex',
		},
	})
);

const MailingInformation: React.FunctionComponent = () => {
	const theme = useTheme();
	const messages = useMessages();
	const classes = useStyles({} as any);
	const laptopSize = useMediaQuery(theme.breakpoints.up('md'));
	const maxSize = useMediaQuery(theme.breakpoints.down('lg'));
	const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));

	const { selectedLoanNumber, selectLoan, hasLoanSelected } = useContext(LoanContext);
	useEffect(() => {
		const sessionItem = sessionStorage.getItem('LoanToken');

		if (sessionItem !== null && sessionItem !== selectedLoanNumber) {
			selectLoan(sessionItem ?? selectedLoanNumber);
			sessionStorage.setItem('LoanToken', sessionItem ?? selectedLoanNumber.toString());
		}
	}, [selectedLoanNumber, selectLoan]);

	const { isLoading, data: lockboxAddresses } = useLockboxAddressesQuery(
		{ loanNumber: selectedLoanNumber },
		{ queryConfig: { enabled: hasLoanSelected } }
	);

	if (isLoading) {
		return (
			<FlexBox>
				<FlexItem xs={laptopSize ? 6 : 12} xl={maxSize ? 6 : 12}>
					<Spacing mb="medium" pr={maxSize ? 'medium' : undefined}>
						<Fieldset messages={messages} label="misc.standard" labelCasing="capitalizeFirstWord" round>
							<Skeleton animation="wave" height={25} />
							<Skeleton animation="wave" height={25} />
							<Skeleton animation="wave" height={25} />
						</Fieldset>
					</Spacing>
				</FlexItem>
				<FlexItem xs={laptopSize ? 6 : 12} xl={maxSize ? 6 : 12}>
					<Spacing pl={maxSize ? 'medium' : undefined}>
						<Fieldset borderColor="misc.black" messages={messages} label="misc.overnight" labelCasing="capitalizeFirstWord" round>
							<Skeleton animation="wave" height={25} />
							<Skeleton animation="wave" height={25} />
							<Skeleton animation="wave" height={25} />
						</Fieldset>
					</Spacing>
				</FlexItem>
			</FlexBox>
		);
	}

	if (!lockboxAddresses?.hasLockboxAddresses) {
		return null;
	}

	return (
		<FlexBox>
			<FlexItem xs={laptopSize ? 6 : 12} xl={maxSize ? 6 : 12}>
				<Spacing mb="medium" pr={mobileSize ? undefined : maxSize ? 'medium' : undefined}>
					<Fieldset messages={messages} label="misc.standard" labelCasing="capitalizeEachWord" round>
						<FlexItem xs={12} className={classes.addressWrapper}>
							<Spacing pr="medium">
								<Text variant="srOnly" messages={messages} message="misc.recipientName" />
								<CreditCardOutlinedIcon fontSize="small" />
							</Spacing>

							<Text variant="body2">{lockboxAddresses.addressOne}</Text>
						</FlexItem>
						<FlexItem xs={12} className={classes.addressWrapper}>
							<FlexItem>
								<Spacing pr="medium">
									<Text variant="srOnly" messages={messages} message="misc.address" />
									<PlaceIcon fontSize="small" />
								</Spacing>
							</FlexItem>

							<FlexItem>
								<Text variant="body2">{lockboxAddresses.addressTwo}</Text>
								<Text variant="body2">{lockboxAddresses.addressThree}</Text>
							</FlexItem>
						</FlexItem>
					</Fieldset>
				</Spacing>
			</FlexItem>
			{lockboxAddresses.hasOvernightAddress && (
				<>
					<FlexItem xs={laptopSize ? 6 : 12} xl={maxSize ? 6 : 12}>
						<Spacing pl={mobileSize ? undefined : maxSize ? 'medium' : undefined}>
							<Fieldset borderColor="misc.black" messages={messages} label="misc.overnight" labelCasing="capitalizeEachWord" round>
								<FlexItem xs={12} className={classes.addressWrapper}>
									<Spacing pr="medium">
										<CreditCardOutlinedIcon fontSize="small" />
										<Text variant="srOnly" messages={messages} message="misc.recipientName" />
									</Spacing>
									<Text variant="body2">{lockboxAddresses.overnightAddressOne}</Text>
								</FlexItem>
								<FlexItem xs={12} className={classes.addressWrapper}>
									<FlexItem>
										<Spacing pr="medium">
											<Text variant="srOnly" messages={messages} message="misc.address" />
											<PlaceIcon fontSize="small" />
										</Spacing>
									</FlexItem>

									<FlexItem xs={10}>
										<Text variant="body2">{lockboxAddresses.overnightAddressTwo}</Text>
										<Text variant="body2">{lockboxAddresses.overnightAddressThree}</Text>
										<Text variant="body2">{lockboxAddresses.overnightAddressFour}</Text>
									</FlexItem>
								</FlexItem>
							</Fieldset>
						</Spacing>
					</FlexItem>
				</>
			)}
		</FlexBox>
	);
};

export default MailingInformation;
