import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanDetailUdfsResult = QueryResult<LoanDetailUdfs, AxiosError<LoanDetailUdfs>>;
export type LoanDetailUdfsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<LoanDetailUdfs, AxiosError<LoanDetailUdfs>>;
};

interface LoanDetailUdfsQuery {
	(request: LoanDetailUdfsRequestParameters, config?: LoanDetailUdfsConfig): LoanDetailUdfsResult;
	key: 'LoanDetailUdfs';
}

const useLoanDetailUdfsQuery: LoanDetailUdfsQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanDetailUdfs(): Promise<LoanDetailUdfs> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/LoanDetailUdfs`;

		const response = await client.get<LoanDetailUdfs>(path);

		return response.data;
	}

	return useQuery(['LoanDetailUdfs', ...Object.values(request)], LoanDetailUdfs, config.queryConfig);
};

useLoanDetailUdfsQuery.key = 'LoanDetailUdfs';

export default useLoanDetailUdfsQuery;
