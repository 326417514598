import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, CSSProperties } from 'react';

import { Grid, GridProps, makeStyles, createStyles, GridJustification } from '@material-ui/core';

export type AlignSelf = 'auto' | 'normal' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch' | 'inherit' | 'initial' | 'unset';

export interface FlexItemProps {
	children?: ReactNode;

	/**
	 * Specifies how much space should be taken up when the viewport is at least `xs` in size.
	 *
	 * @default auto
	 */
	xs?: GridProps['xs'];

	/**
	 * Specifies how much space should be taken up when the viewport is at least `sm` in size.
	 *
	 * @default auto
	 */
	sm?: GridProps['sm'];

	/**
	 * Specifies how much space should be taken up when the viewport is at least `md` in size.
	 *
	 * @default auto
	 */
	md?: GridProps['md'];

	/**
	 * Specifies how much space should be taken up when the viewport is at least `lg` in size.
	 *
	 * @default auto
	 */
	lg?: GridProps['lg'];

	/**
	 * Specifies how much space should be taken up when the viewport is at least `xl` in size.
	 *
	 * @default auto
	 */
	xl?: GridProps['xl'];

	/**
	 * Specifies how the item should be aligned in the `FlexBox` according to the cross axis
	 */
	alignSelf?: AlignSelf;
	container?: boolean;

	justify?: GridJustification;

	className?: string;
	style?: CSSProperties;
}

const useStyles = makeStyles(
	createStyles({
		flexItem: ({ alignSelf }: { alignSelf: AlignSelf }) => ({ alignSelf }),
	})
);

/**
 * A `FlexItem` is a container for content inside of a FlexBox and can be used to control the size said content will take up.
 *
 * The `FlexItem`s work on a 12 column basis and the props `xs`, `sm`, `md`, `lg`, and `xl` all take a number 1-12 that specifies how much of the 12 columns
 * the item should take up. The props correlate to a viewport breakpoint and affect that breakpoint and larger.
 *
 * To have a `FlexItem` take up as much space as available you can specify the prop without a value (e.g. `<FlexItem xs>`).
 *
 * The default behavior is for the `FlexItem` to expand according to it's content.
 */
const FlexItem: FunctionComponent<FlexItemProps> = ({ children, alignSelf = 'auto', container = false, className, ...props }) => {
	const styles = useStyles({ alignSelf });

	return (
		<Grid item className={classnames(styles.flexItem, className)} container={container} {...props}>
			{children}
		</Grid>
	);
};

export default FlexItem;
