import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetFinanceProgramForDealerResult = QueryResult<FinanceProgramForDealer, AxiosError<FinanceProgramForDealer>>;
export type GetFinanceProgramForDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<FinanceProgramForDealer, AxiosError<FinanceProgramForDealer>>;
};

interface GetFinanceProgramForDealerQuery {
	(request: GetFinanceProgramForDealerRequestParameters, config?: GetFinanceProgramForDealerConfig): GetFinanceProgramForDealerResult;
	key: 'GetFinanceProgramForDealer';
}

const useGetFinanceProgramForDealerQuery: GetFinanceProgramForDealerQuery = (request, config = { axiosConfig: {} }) => {
	async function GetFinanceProgramForDealer(): Promise<FinanceProgramForDealer> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetFinanceProgramForDealer';

		const response = await client.get<FinanceProgramForDealer>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});

		return response.data;
	}

	return useQuery(['GetFinanceProgramForDealer', ...Object.values(request)], GetFinanceProgramForDealer, config.queryConfig);
};

useGetFinanceProgramForDealerQuery.key = 'GetFinanceProgramForDealer';

export default useGetFinanceProgramForDealerQuery;
