const Misc = {
	LocalStorage: {
		PayoffLetter: 'SFC_PAYOFF_LETTER_STATE',
		Login: 'SFC_LOGIN_STATE',
		Loan: 'SFC_LOAN_STATE',
		MakeAPayment: 'SFC_MAKE_A_PAYMENT',
		Filter: 'SFC_FILTER_STATE',
	},
	SessionStorage: {
		Authentication: 'Authentication',
	},
	AxiosTimeout: 3000,
	AlertDefaultTimeout: 3000,
	ResetLoginAttempts: 0,
	MaxLoginAttempts: 3,
	SSNLength: 9,

	paymentFrequency: [
		'Daily',
		'Weekly',
		'Bi-Weekly',
		'Semi-Weekly',
		'28 Day',
		'Monthly',
		'Bi-Monthly',
		'Quarterly',
		'Semi-Annually',
		'Annual',
		'Maturity',
	],
};

export default Misc;
