import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type IsEmailVerifiedResult = QueryResult<boolean, AxiosError<boolean>>;
export type IsEmailVerifiedConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<boolean, AxiosError<boolean>>;
};

interface IsEmailVerifiedQuery {
	(request: IsEmailVerifiedRequestParameters, config?: IsEmailVerifiedConfig): IsEmailVerifiedResult;
	key: 'IsEmailVerified';
}

const useIsEmailVerifiedQuery: IsEmailVerifiedQuery = (request, config = { axiosConfig: {} }) => {
	async function IsEmailVerified(): Promise<boolean> {
		const client = ApiCSGService();

		const path = '/api/Users/EmailVerified';

		const response = await client.get<boolean>(path, {
			username: request.username,
		});

		return response.data;
	}

	return useQuery(['IsEmailVerified', ...Object.values(request)], IsEmailVerified, config.queryConfig);
};

useIsEmailVerifiedQuery.key = 'IsEmailVerified';

export default useIsEmailVerifiedQuery;
