import React, { ReactNode, useMemo, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@material-ui/icons/Close';

import SpacingValues from '../../constants/Spacing';
import { makeStyles, createStyles, IconButton } from '@material-ui/core';

import { FlexBox, FlexItem, Spacing, Text, Button, Icon } from '..';

import {
	ConvertStatusVariantToValues,
	StatusValues,
	GenerateCasing,
	LangText,
	StatusVariant,
	Messages,
	SfcKitMessages,
	MessagesProps,
	Casing,
	CSSProperties,
} from '../..';

export interface ModalProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	id: string;
	modalActions?: JSX.Element;
	title: LangText<TMessages>;
	subTitle?: LangText<TMessages>;
	subTitleCasing?: Casing;
	titleCasing?: Casing;
	buttonCasing?: Casing;
	closeIconText?: boolean;
	size?: 'small' | 'medium' | 'large';

	open?: boolean;
	onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeIconClick') => void;

	variant?: StatusVariant;

	positioned?: 'center' | 'top' | 'bottom';

	hideIcon?: boolean;

	confirmDenyProps?: {
		confirmText?: LangText<TMessages>;
		onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
		confirmDisabled?: boolean;

		denyText?: LangText<TMessages>;
		onDeny: () => void;
		denyDisabled?: boolean;
	};

	zIndex?: number;

	children?: ReactNode;
}

const positionGenerator = (positioned: 'center' | 'top' | 'bottom' | undefined): CSSProperties => {
	switch (positioned) {
		case 'center':
			return { top: '50%', transform: 'translate(-50%, -50%)' };

		case 'top':
			return { top: '5%' };

		case 'bottom':
			return { bottom: '5%' };

		default:
			return { top: '50%', transform: 'translate(-50%, -50%)' };
	}
};

const sizeGenerator = (size: ModalProps['size']): CSSProperties => {
	switch (size) {
		case 'small':
			return { maxHeight: '30vh' };

		case 'medium':
			return { maxHeight: '60vh' };

		default:
			return { maxHeight: '90vh' };
	}
};

const useStyles = makeStyles(theme =>
	createStyles({
		header: ({
			closeIconText,
		}: {
			closeIconText?: boolean;
			titleCasing?: Casing;
			subTitleCasing?: Casing;
			size: ModalProps['size'];
			positioned: ModalProps['positioned'];
			zIndex: ModalProps['zIndex'];
		}) => ({
			backgroundColor: theme.palette.background.default,
			borderBottom: `3px solid ${theme.palette.divider}`,
			padding: closeIconText ? theme.spacing(0, 1) : theme.spacing(1),
		}),
		root: ({ positioned, zIndex = 1202 }) => ({
			minWidth: 300,
			maxWidth: 550,
			boxShadow: theme.shadows[2],
			zIndex,
			position: 'fixed',
			transform: 'translate(-50%)',
			left: '50%',
			overflow: 'hidden',
			...positionGenerator(positioned),
			[theme.breakpoints.down('md')]: {
				fontSize: '0.6rem',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				overflowX: 'auto',
			},
		}),
		icon: { marginLeft: theme.spacing(SpacingValues.medium) },
		body: ({ size }) => ({ backgroundColor: theme.palette.background.paper, ...sizeGenerator(size), overflowY: 'auto' }),
		title: ({ titleCasing }) => ({
			...GenerateCasing(titleCasing),
		}),
		subtitle: ({ subTitleCasing }) => ({
			...GenerateCasing(subTitleCasing),
		}),
		closeIconText: {
			textDecoration: 'underline',
		},
		focus: {
			'&:focus-visible': {
				border: '4px solid blue',
			},
			'&:focus:not(:focus-visible)': {
				border: 'none',
			},
		},
		iconFocus: {
			'&:focus-visible': {
				outline: '2px solid blue',
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const useModalStyles = makeStyles(theme =>
	createStyles({
		modalWrapper: {
			position: 'fixed',
			backgroundColor: 'rgb(0,0,0, 0.5) ',
			zIndex: 1201,
			left: 0,
			top: 0,
			width: '100vw',
			height: '100vh',
			[theme.breakpoints.down('md')]: {
				backgroundRepeat: 'repeat',
			},
		},
	})
);

export default function Modal<TMessages extends Messages = SfcKitMessages>(props: ModalProps<TMessages>): ReactElement | null {
	const { id, open = false, positioned = 'center', zIndex } = props;

	const styles = useModalStyles({ positioned, zIndex });

	function ModalBody<TMessages extends Messages = SfcKitMessages>({
		id,
		modalActions,
		title,
		subTitle,
		titleCasing,
		subTitleCasing,
		buttonCasing,
		size = 'large',
		onClose,
		children,
		variant = 'Success',
		hideIcon = false,
		closeIconText = false,
		confirmDenyProps,
		positioned,
		messages,
		zIndex,
	}: ModalProps<TMessages>): ReactElement | null {
		const styles = useStyles({ titleCasing, subTitleCasing, closeIconText, size, positioned, zIndex });

		const {
			color,
			icons: { standard: icon },
		} = useMemo<StatusValues>(() => ConvertStatusVariantToValues(variant), [variant]);

		const modalElement = (
			<FlexItem className={styles.root}>
				<FlexBox alignItems="center" className={styles.header}>
					{!hideIcon && <Icon size="large" color={color} icon={icon} />}
					<FlexItem xs>
						<FlexBox direction="column">
							<FlexItem xs>
								<Spacing p="small" pb="xSmall">
									<Text
										variant={subTitle ? 'subtitle2' : 'body1'}
										className={styles.title}
										weight="medium"
										message={title}
										messages={messages}
										tabIndex={1}
									/>
								</Spacing>
							</FlexItem>
							{subTitle && (
								<FlexItem xs>
									<Spacing p="small" pt="xSmall">
										<Text variant="subtitle2" className={styles.subtitle} message={subTitle} messages={messages} tabIndex={1} />
									</Spacing>
								</FlexItem>
							)}
						</FlexBox>
					</FlexItem>
					{modalActions && modalActions}
					{onClose && (
						<FlexItem>
							<IconButton tabIndex={1} className={styles.focus} onClick={(event): void => onClose(event, 'closeIconClick')}>
								<FlexBox direction="column" alignItems="center">
									<Text aria-controls={id} variant="srOnly">
										Close Modal
									</Text>
									<CloseIcon />
									{closeIconText && (
										<Text
											role="close-icon-text"
											className={styles.closeIconText}
											variant="caption"
											casing="capitalizeEachWord"
											messages={messages}
											message="misc.close"
										/>
									)}
								</FlexBox>
							</IconButton>
						</FlexItem>
					)}
				</FlexBox>
				<Spacing p="medium" className={styles.body}>
					{children && children}
					{confirmDenyProps !== undefined && (
						<FlexBox>
							<FlexItem xs={6}>
								<Spacing mt="medium" mr="small">
									<Button
										casing={buttonCasing}
										className={styles.focus}
										fullWidth
										tabIndex={1}
										disabled={confirmDenyProps.confirmDisabled}
										onClick={confirmDenyProps.onConfirm}
										message={confirmDenyProps.confirmText ?? 'buttons.submit'}
										messages={messages}
									/>
								</Spacing>
							</FlexItem>
							<FlexItem xs={6}>
								<Spacing mt="medium" ml="small">
									<Button
										casing={buttonCasing}
										fullWidth
										tabIndex={1}
										className={styles.focus}
										disabled={confirmDenyProps.denyDisabled}
										onClick={confirmDenyProps.onDeny}
										color="button.secondary"
										textColor="cancelButton"
										message={confirmDenyProps.denyText ?? 'buttons.cancel'}
										messages={messages}
									/>
								</Spacing>
							</FlexItem>
						</FlexBox>
					)}
				</Spacing>
			</FlexItem>
		);

		return ReactDOM.createPortal(modalElement, document.body);
	}

	if (!open) {
		return null;
	}

	return (
		<div id={id} className={styles.modalWrapper}>
			<FlexBox fullHeight justifyContent="center" alignItems="center">
				<ModalBody {...props} />
			</FlexBox>
		</div>
	);
}
