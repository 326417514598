import React, { useContext } from 'react';
import { Redirect } from 'react-router';

import Paths from 'constants/Paths';

import { makeStyles, createStyles, lighten } from '@material-ui/core/styles';

import SquareButton from 'sfc-kit/src/components/SquareButton';
import { ConfirmDenyDialog } from 'sfc-kit';

import { Box, Grid, Card, CardHeader, CardContent, SvgIcon, CardActions } from '@material-ui/core';
import MakeAPaymentContext, { MakeAPaymentActionTypes } from 'context/MakeAPayment';

interface MakeAPaymentCardProps {
	title: string;
	Avatar?: typeof SvgIcon;

	onNext?: () => void;
	onBack?: () => void;

	nextDisabled?: boolean;
	hideCancel?: boolean;
	loading?: boolean;
	isError?: boolean;

	children: React.ReactNode;

	nextText?: string;
	minHeight?: string;
	paddingBottom?: string;
}

const useStyles = makeStyles(theme =>
	createStyles({
		card: {
			borderRadius: 0,
			maxWidth: 600,
			width: '100%',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		cardHeader: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		cardHeaderError: {
			backgroundColor: theme.palette.danger.main,
			color: theme.palette.primary.contrastText,
			textAlign: 'center',
		},
		cardContent: ({ minHeight, paddingBottom }: { minHeight?: string; paddingBottom?: string }) => ({
			minHeight: minHeight ? `${minHeight}vh` : '45vh',
			paddingBottom: paddingBottom ? `${paddingBottom}px` : '',
		}),
		backButton: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,

			'&:hover': {
				backgroundColor: lighten(theme.palette.primary.main, 0.15),
			},
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		cancelButton: {
			color: '#007D46',
			backgroundColor: '#EEEEEE',
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		noButton: { color: '#007d46', backgroundColor: '#EEEEEE' },
	})
);

const MakeAPaymentCard: React.FC<MakeAPaymentCardProps> = ({
	title,
	Avatar,
	children,

	onNext,
	onBack,
	isError,

	minHeight,
	paddingBottom,
	hideCancel = false,
	nextDisabled = false,
	loading = false,
	nextText = 'Next',
}) => {
	const classes = useStyles({ minHeight, paddingBottom });
	const { dispatchForMakeAPayment } = useContext(MakeAPaymentContext);

	const [redirectTo, setRedirectTo] = React.useState<string>();
	const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);

	if (redirectTo != null) {
		if (isCancelDialogOpen) {
			dispatchForMakeAPayment({ type: MakeAPaymentActionTypes.ClearState });
		}
		return <Redirect to={redirectTo} />;
	}

	const onCancelConfirm = (): void => {
		setRedirectTo(Paths.PaymentCenter);
	};

	return (
		<>
			<Card className={classes.card}>
				{Avatar ? (
					<CardHeader
						className={isError ? classes.cardHeaderError : classes.cardHeader}
						avatar={
							<Box border="2px solid" borderRadius="360px" padding={1}>
								<Avatar />
							</Box>
						}
						title={title}
					/>
				) : (
					<CardHeader className={isError ? classes.cardHeaderError : classes.cardHeader} title={title} />
				)}
				<CardContent className={classes.cardContent}>{children}</CardContent>
				<CardActions>
					<Grid container spacing={1}>
						{onNext && (
							<Grid item xs={12}>
								<SquareButton
									className={classes.focus}
									fullWidth
									tabIndex={1}
									color="primary"
									variant="contained"
									onClick={onNext}
									disabled={nextDisabled || loading}>
									{nextText}
								</SquareButton>
							</Grid>
						)}
						{onBack && (
							<Grid item xs={12} md={6}>
								<SquareButton
									fullWidth
									variant="contained"
									tabIndex={1}
									className={classes.backButton}
									onClick={onBack}
									disabled={loading}>
									Back
								</SquareButton>
							</Grid>
						)}
						{!hideCancel && (
							<Grid item xs={12} md={6}>
								<SquareButton
									fullWidth
									tabIndex={1}
									variant="contained"
									className={classes.cancelButton}
									onClick={(): void => setIsCancelDialogOpen(true)}
									disabled={loading}>
									Cancel
								</SquareButton>
							</Grid>
						)}
					</Grid>
				</CardActions>
			</Card>
			<ConfirmDenyDialog
				id="cancel-make-a-payment-dialog"
				title="Cancel Payment"
				isOpen={isCancelDialogOpen}
				handleClose={(): void => setIsCancelDialogOpen(false)}
				handleDeny={(): void => setIsCancelDialogOpen(false)}
				handleConfirm={onCancelConfirm}
				classes={{ denyButton: classes.noButton }}>
				Are you sure you want to cancel making a payment?
			</ConfirmDenyDialog>
		</>
	);
};

export default MakeAPaymentCard;
