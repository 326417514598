import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type RecoveryPasswordResult = MutationResultPair<void, AxiosError<void>, RecoveryPasswordRequestParameters, unknown>;

export type RecoveryPasswordConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<void, AxiosError<void>, RecoveryPasswordRequestParameters>;
};

export default function useRecoveryPasswordMutation(config: RecoveryPasswordConfig = { axiosConfig: {} }): RecoveryPasswordResult {
	async function RecoveryPassword(request: RecoveryPasswordRequestParameters): Promise<void> {
		const client = ApiCSGService();
		client.clearOtherToken();
		
		const path = '/api/Authentication/PasswordRecovery';

		if (request.token) {
			client.setOtherToken(request.token);
			client.post(path, request.body).then(() => {
				return Promise.resolve(true);
			});
		} else {
			client.put(path, request.body).then(() => {
				return Promise.resolve(true);
			});
		}
	}

	return useMutation(RecoveryPassword, config.mutationConfig);
}
