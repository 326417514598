import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Button from '../../Button/Button';

export interface TabProps {
	label: string;
	value: number;
	active: boolean;
	status: 'none' | 'rejected' | 'pending' | 'cleared' | 'reviewing' | 'received' | 'cleared' | 'completed';
	handleOnClick: (value: number) => void;
}

export const getBorderColor = (status: TabProps['status'], theme: Theme): string => {
	switch (status) {
		case 'rejected':
			return theme.palette.status.rejected.main;
		case 'cleared':
			return theme.palette.status.completed.main;
		case 'pending':
			return theme.palette.warning.main;
		case 'none':
		default:
			return theme.palette.grey[500];
	}
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonTab: ({ active, status }: Pick<TabProps, 'active' | 'status'>) => ({
			padding: theme.spacing(1, 3),
			marginLeft: '8px',
			boxShadow: `-5px 0px 0px 0px ${getBorderColor(status, theme)}`,

			'&:hover': {
				backgroundColor: 'inherit',
				boxShadow: `-5px 0px 0px 0px ${getBorderColor(status, theme)}`,
				borderBottom: active ? `2px solid ${theme.palette.secondary.main}` : 'none',
				marginBottom: active ? '-2px' : 0,
			},

			borderBottom: active ? `2px solid  ${theme.palette.secondary.main}` : 'none',
			marginBottom: active ? '-2px' : 0,
		}),
	})
);

const Tab: React.FC<TabProps> = ({ label, value, active, handleOnClick, status }) => {
	const classes = useStyles({ active, status });

	return (
		<Button
			onClick={(): void => handleOnClick(value)}
			className={classes.buttonTab}
			color="background.default"
			casing="capitalizeEachWord"
			fontSize="large"
			disableElevation
			disableFocusRipple
			disableRipple
			disableTouchRipple>
			{label}
		</Button>
	);
};

export default Tab;
