import { DateTime } from 'luxon';

export default class DateTimeUtils {
	public static TryFromISO(value: string | undefined | null): DateTime | undefined {
		if (value != null) {
			return DateTime.fromISO(value);
		}

		return;
	}
}
