import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LogoutBorrowerResult = MutationResultPair<string, AxiosError<string>, AuthenticatLogoutParameters, unknown>;

export type LogoutBorrowerConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<string, AxiosError<string>, AuthenticatLogoutParameters>;
};

export default function useAuthenticationLogoutMutation(config: LogoutBorrowerConfig = { axiosConfig: {} }): LogoutBorrowerResult {
	async function AuthenticationLogout(request: AuthenticatLogoutParameters): Promise<string> {
		const client = ApiCSGService();
		client.clearOtherToken();
		client.setAuthToken(request.token);

		const path = '/api/Authentication/Logout';

		const payload = {
			refreshToken: request.refreshToken,
		};

		const { data } = await client.post<string>(path, payload);

		return data;
	}

	return useMutation(AuthenticationLogout, config.mutationConfig);
}
