import React, { FunctionComponent } from 'react';

import { TableRow as MuiTableRow } from '@material-ui/core';

export interface TableRowProps {
	/** If `true`, the table row will shade on hover */
	hover?: boolean;

	/** If `true`, the table row will have the selected shading */
	selected?: boolean;

	className?: string;
	children: React.ReactNode;

	onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
}

const TableRow: FunctionComponent<TableRowProps> = ({ hover = false, selected = false, className, children, onClick }) => {
	return (
		<MuiTableRow hover={hover} selected={selected} className={className} onClick={onClick}>
			{children}
		</MuiTableRow>
	);
};

export default TableRow;
