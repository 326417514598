import { Link as RouterLink } from 'react-router-dom';
import React, { FunctionComponent, useContext, useEffect } from 'react';

import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles, Box, Grid, Link, useMediaQuery, Theme, useTheme, GridSize } from '@material-ui/core';

import { usePayoffProgressQuery, Text } from 'sfc-kit';
import MediumBold from 'sfc-kit/src/components/MediumBold';
import NumberUtility from 'sfc-kit/src/utils/NumberUtility';
import ProgressCircle from 'sfc-kit/src/components/ProgressCircle';

import Paths from 'constants/Paths';
import { PaymentAmounts } from 'types';
import LoanContext from 'context/LoanContext';
import MakeAPaymentPaths from 'constants/Paths/MakeAPayment';
import MakeAPaymentContext, { MakeAPaymentActionTypes } from 'context/MakeAPayment';

export interface PayoffProgressProps {
	isLoading: boolean;
	onOneTimePayment: () => void;
	amountPaid: number | undefined;
	loanAmount: number | undefined;
	xs: boolean | GridSize | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		progressCircle: {
			minWidth: '100%',
			maxWidth: '100%',
			[theme.breakpoints.down('md')]: {
				paddingBottom: theme.spacing(2),
			},
		},
		payoffLetter: {
			borderLeftStyle: 'solid',
			borderLeftWidth: 6,
			borderLeftColor: theme.palette.primary.light,
			padding: theme.spacing(1, 2),
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(4),
		},
		link: {
			color: theme.palette.action.main,
		},
	})
);

const dotSize = 20;

const PayoffDetails: FunctionComponent<PayoffProgressProps> = ({ isLoading, amountPaid, loanAmount, onOneTimePayment, xs }) => {
	const classes = useStyles({} as any);
	return (
		<Grid item xs={xs}>
			<Grid container wrap="nowrap" alignItems="center">
				<Box bgcolor="primary.main" height={dotSize} width={dotSize} borderRadius={dotSize} marginRight={2} flexShrink={0} />
				<Text variant="body2">
					{isLoading ? (
						<Skeleton animation="wave" />
					) : (
						<>
							You have completed <MediumBold inline>${NumberUtility.formatCurrency(amountPaid)}</MediumBold> of{' '}
							<MediumBold inline>${NumberUtility.formatCurrency(loanAmount)}</MediumBold>
						</>
					)}
				</Text>
			</Grid>
			<Text variant="body2" className={classes.payoffLetter}>
				Generate your{' '}
				<MediumBold inline>
					<Link component={RouterLink} to={Paths.PayoffLetter} className={classes.link} tabIndex={1}>
						Payoff Letter
					</Link>
				</MediumBold>{' '}
				for detailed instructions on how to pay off your loan. You can also pay off your loan today with our easy{' '}
				<MediumBold inline>
					<Link
						component={RouterLink}
						to={MakeAPaymentPaths.BankAccountSelection}
						onClick={onOneTimePayment}
						className={classes.link}
						tabIndex={1}
						aria-label={'make a one-time payment'}>
						One-Time
					</Link>
				</MediumBold>{' '}
				payment option.
			</Text>
		</Grid>
	);
};

const PayoffProgress: FunctionComponent = () => {
	const classes = useStyles({} as any);
	const theme = useTheme();
	const { dispatchForMakeAPayment } = useContext(MakeAPaymentContext);
	const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

	const { selectedLoan, selectedLoanNumber, hasLoanSelected, selectLoan } = useContext(LoanContext);
	useEffect(() => {
		const sessionItem = sessionStorage.getItem('LoanToken');

		if (sessionItem !== null && sessionItem !== selectedLoanNumber) {
			selectLoan(sessionItem ?? selectedLoanNumber);
			sessionStorage.setItem('LoanToken', sessionItem ?? selectedLoanNumber.toString());
		}
	}, [selectedLoanNumber, selectLoan]);

	const { isLoading, data: payoffProgress } = usePayoffProgressQuery(
		{ loanNumber: selectedLoanNumber },
		{ queryConfig: { enabled: hasLoanSelected } }
	);

	const onOneTimePayment = (): void => {
		dispatchForMakeAPayment({ type: MakeAPaymentActionTypes.SetIsPayoffPayment, payload: true });

		dispatchForMakeAPayment({
			type: MakeAPaymentActionTypes.SetPaymentAmount,
			payload: {
				paymentAmount: payoffProgress?.amountRemaining.toString() ?? '',
				type: PaymentAmounts.TotalPayoffAmount,
			},
		});

		dispatchForMakeAPayment({ type: MakeAPaymentActionTypes.InitiateWorkflow, payload: selectedLoan });
	};

	return (
		<Grid container justifyContent="center">
			<Grid item xs={!isMobileView && 5}>
				<ProgressCircle
					className={classes.progressCircle}
					progress={payoffProgress?.percentagePaid ?? 0}
					text={`${payoffProgress?.percentagePaid ?? 0}%`}
					subText="Complete"
				/>
			</Grid>
			{!isMobileView ? (
				<PayoffDetails
					xs={7}
					isLoading={isLoading}
					onOneTimePayment={onOneTimePayment}
					amountPaid={payoffProgress?.amountPaid}
					loanAmount={payoffProgress?.loanAmount}
				/>
			) : (
				<PayoffDetails
					xs={12}
					isLoading={isLoading}
					onOneTimePayment={onOneTimePayment}
					amountPaid={payoffProgress?.amountPaid}
					loanAmount={payoffProgress?.loanAmount}
				/>
			)}
		</Grid>
	);
};

export default PayoffProgress;
