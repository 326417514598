import React, { FunctionComponent } from 'react';

import { Table as MuiTable } from '@material-ui/core';

export interface TableProps {
	children: React.ReactNode;
}

const Table: FunctionComponent<TableProps> = ({ children }) => {
	return <MuiTable>{children}</MuiTable>;
};

export default Table;
