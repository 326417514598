import React from 'react';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import { type FontWeightProperty } from 'csstype';

import { Grid, createStyles, makeStyles, Theme, Chip, Hidden } from '@material-ui/core';

import NumberUtility from 'sfc-kit/src/utils/NumberUtility';

import { PaymentFrequencies } from 'types';
import { GetShortPaymentFrequency } from 'utils';
import { Text } from 'sfc-kit';

export interface PaymentConfirmationDetailsProps {
	paymentDate?: DateTime | null;
	paymentAmount?: string;
	frequency?: PaymentFrequencies;
	bankAccountInfo?:
		| {
				accountHolder?: string;
				routingNumber: string;
				accountNumber: string;
		  }
		| undefined;
	multiplePayments: boolean;
	confirmationNumber?: string | null;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		boldListItem: {
			fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
		},
		spacing: {
			marginLeft: theme.spacing(6),
			marginBottom: theme.spacing(2.5),
			[theme.breakpoints.down('xs')]: {
				marginLeft: theme.spacing(2),
			},
		},
		firstItem: {
			marginTop: theme.spacing(2),
		},

		paymentAmountFont: {
			color: theme.palette.primary.main,
		},
		greenChip: {
			minHeight: 0,
			padding: 0,
			verticalAlign: 'middle',
			textTransform: 'none',
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main,
			horizontalAlign: 'middle',
			minWidth: '70px',
			marginLeft: theme.spacing(3),
			marginRight: theme.spacing(3),
			borderRadius: '8px',
			float: 'right',
		},
	});
});

const PaymentConfirmationDetails: React.FC<PaymentConfirmationDetailsProps> = ({
	paymentDate,
	paymentAmount,
	frequency,
	bankAccountInfo,
	multiplePayments,
	confirmationNumber,
}) => {
	const classes = useStyles({} as any);

	const hasAccountHolder = bankAccountInfo?.accountHolder && bankAccountInfo?.accountHolder !== (null && '');

	return (
		<Grid container>
			<Grid item xs={7} md={6}>
				<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing, classes.firstItem)}>
					Payment Type
				</Text>
			</Grid>
			<Grid item xs={5} md={6}>
				<Text variant="body2" className={classnames(classes.spacing, classes.firstItem)}>
					{GetShortPaymentFrequency(frequency ?? PaymentFrequencies.OneTime)}
				</Text>
			</Grid>
			{hasAccountHolder && (
				<>
					<Grid item xs={7} md={6}>
						<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing)}>
							Account Holder
						</Text>
					</Grid>
					<Grid item xs={5} md={6}>
						<Text variant="body2" className={classes.spacing}>
							{bankAccountInfo?.accountHolder ?? ''}
						</Text>
					</Grid>
				</>
			)}
			<Grid item xs={7} md={6}>
				<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing)}>
					Routing Number
				</Text>
			</Grid>
			<Grid item xs={5} md={3}>
				<Text variant="body2" className={classes.spacing}>
					{NumberUtility.maskPartialString(bankAccountInfo?.routingNumber ?? '')}
				</Text>
			</Grid>
			<Grid item xs={7} md={6}>
				<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing)}>
					Account Number
				</Text>
			</Grid>
			<Grid item xs={5} md={6}>
				<Text variant="body2" className={classes.spacing}>
					{NumberUtility.maskPartialString(bankAccountInfo?.accountNumber ?? '')}
				</Text>
			</Grid>
			<Grid item xs={7} md={6}>
				<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing)}>
					Payment Amount
				</Text>
			</Grid>
			<Grid container item xs={5} md={6} alignContent="center">
				<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing, classes.paymentAmountFont)}>
					${paymentAmount ?? ''}{' '}
					<Hidden smDown>{multiplePayments === true && <Chip size="small" className={classes.greenChip} label="Verified" />}</Hidden>
				</Text>
			</Grid>
			<Grid item xs={7} md={6}>
				<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing)}>
					Payment Date
				</Text>
			</Grid>
			<Grid container item xs={5} md={6} alignContent="center">
				<Text variant="body2" className={classes.spacing}>
					{paymentDate?.toFormat('MM/dd/yyyy') ?? ''}
					<Hidden smDown>
						{paymentDate?.toUTC().toFormat('MM/dd/yyyy') === DateTime.local().toFormat('MM/dd/yyyy') && (
							<Chip component="span" size="small" className={classes.greenChip} label="Now" />
						)}
					</Hidden>
				</Text>
			</Grid>
			{confirmationNumber && (
				<>
					<Grid item xs={7} md={6}>
						<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing)}>
							Confirmation Number
						</Text>
					</Grid>
					<Grid container item xs={5} md={6} alignContent="center">
						<Text variant="body2" className={classnames(classes.boldListItem, classes.spacing, classes.paymentAmountFont)}>
							{confirmationNumber}{' '}
							<Hidden smDown>{multiplePayments && <Chip size="small" className={classes.greenChip} label="Verified" />}</Hidden>
						</Text>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default PaymentConfirmationDetails;
