import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type IsUserTakenResult = QueryResult<UsernameAvailability, AxiosError<UsernameAvailability>>;
export type IsUserTakenConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<UsernameAvailability, AxiosError<UsernameAvailability>>;
};

interface IsUserTakenQuery {
	(request: IsUserTakenRequestParameters, config?: IsUserTakenConfig): IsUserTakenResult;
	key: 'IsUserTaken';
}

const useIsUserTakenQuery: IsUserTakenQuery = (request, config = { axiosConfig: {} }) => {
	async function IsUserTaken(): Promise<UsernameAvailability> {
		const client = ApiCSGService();

		const path = '/api/Authentication/IsUserTaken';

		const response = await client.get<UsernameAvailability>(path, {
			loanNumber: request.loanNumber,
			username: request.username,
			existingUsername: request?.existingUsername,
		});

		return response.data;
	}

	return useQuery(['IsUserTaken', ...Object.values(request)], IsUserTaken, config.queryConfig);
};

useIsUserTakenQuery.key = 'IsUserTaken';

export default useIsUserTakenQuery;
