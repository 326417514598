import { useMemo } from 'react';

import { useLoansQuery } from 'sfc-kit';
import LoanService from 'services/LoanService';
import { getTokenNls } from 'utils/Auth';

export default function useLoans(): ReturnType<typeof useLoansQuery> {
	const nlsToken = getTokenNls();

	const { data, ...rest } = useLoansQuery({ authorization: nlsToken }, { queryConfig: { enabled: nlsToken } });

	const loans = useMemo(() => data?.filter(LoanService.IsNotShadowLoan), [data]);
	return { ...rest, data: loans };
}
