import React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import SfcTextField, { ISfcTextFieldProps } from './SfcTextField';

import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
				display: 'none',
			},
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const SfcMaskedField: React.FC<ISfcTextFieldProps> = ({ placeholder, onChange, InputProps, answerTabIndex, ...textFieldProps }) => {
	const classes = useStyles({} as any);
	const [isMasked, setIsMasked] = React.useState(true);

	// Sets the aria-label for the screen reader to let the user know which button is being toggled,
	// with a fallback
	const SHOW_PASSWORD_LABEL = placeholder !== undefined ? `Toggle to show ${placeholder}` : `Toggle to show user input`;
	const HIDE_PASSWORD_LABEL = placeholder !== undefined ? `Toggle to hide ${placeholder}` : `Toggle to show user input`;

	const toggleMasked = (): void => {
		setIsMasked(!isMasked);
	};

	return (
		<SfcTextField
			type={isMasked ? 'password' : 'text'}
			placeholder={placeholder}
			onChange={(event): void => onChange && onChange(event)}
			data-testid="maskedinput"
			InputProps={{
				...InputProps,
				inputProps: { tabIndex: answerTabIndex ?? 0 },
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label={isMasked ? SHOW_PASSWORD_LABEL : HIDE_PASSWORD_LABEL}
							onClick={toggleMasked}
							tabIndex={answerTabIndex ?? 0}
							edge="end"
							className={classes.focus}
							aria-expanded={isMasked}>
							{isMasked ? <Visibility data-testid="show" /> : <VisibilityOff data-testid="hide" />}
						</IconButton>
					</InputAdornment>
				),
			}}
			className={classes.root}
			{...textFieldProps}
		/>
	);
};

export default SfcMaskedField;
