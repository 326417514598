import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import React, { ReactNode, FunctionComponent } from 'react';
import { type FontWeightProperty } from 'csstype';

import { Link } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import Paths from 'constants/Paths';
import { ContactUsSubject } from 'constants/ContactUs';

export interface ContactUsLinkProps {
	subject: ContactUsSubject;
	tabIndex?: number;
	className?: string;

	target?: '_blank' | '_self' | '_parent' | '_top';

	children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
			color: theme.palette.action.main,

			'&:hover': {
				textDecoration: 'none',
			},
		},
	})
);

const ContactUsLink: FunctionComponent<ContactUsLinkProps> = ({ subject, className, tabIndex, target = '_blank', children }) => {
	const styles = useStyles({} as any);

	return (
		<Link
			component={RouterLink}
			tabIndex={tabIndex ?? 0}
			to={{ pathname: Paths.Contact, state: { subject } }}
			className={classnames(styles.root, className)}
			target={target}>
			{children}
		</Link>
	);
};


export default ContactUsLink;
