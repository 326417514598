import { AuthenticationInitialStateType } from "context/Authentication";
import { getTokenExpirationDate } from "utils/Auth";

export const parseAuthenticateResponse = (response: any): AuthenticationInitialStateType => {
	const { authTokenExpiration, bearerToken, userName, ...rest } = response;

	let authToken = bearerToken;
	if (!authToken?.startsWith('Bearer')) {
		authToken = `Bearer ${authToken}`;
	}

	const payload: AuthenticationInitialStateType = {
		...rest,
		isAuthenticated: true,
		authTokenExpiration: authTokenExpiration ?? getTokenExpirationDate(),
		username: userName.toLowerCase(),
		authToken,
	};

	return payload;
};
