import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetLeadProductsResult = QueryResult<Array<LeadProduct>, AxiosError<Array<LeadProduct>>>;
export type GetLeadProductsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Array<LeadProduct>, AxiosError<Array<LeadProduct>>>;
};

interface GetLeadProductsQuery {
	(config?: GetLeadProductsConfig): GetLeadProductsResult;
	key: 'GetLeadProducts';
}

const useGetLeadProductsQuery: GetLeadProductsQuery = (config = { axiosConfig: {} }) => {
	async function GetLeadProducts(): Promise<Array<LeadProduct>> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetLeadProducts';

		const response = await client.get<Array<LeadProduct>>(path);

		return response.data;
	}

	return useQuery(['GetLeadProducts'], GetLeadProducts, config.queryConfig);
};

useGetLeadProductsQuery.key = 'GetLeadProducts';

export default useGetLeadProductsQuery;
