import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';
import { AuthenticationSessionGet } from '../../utils';

export type AuthenticationRenewTokenResult = MutationResultPair<
	AuthenticationAuthenticateResponse,
	AxiosError<AuthenticationAuthenticateResponse>,
	AuthenticateRenewParameters,
	unknown
>;

export type AuthenticationRenewTokenConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<AuthenticationAuthenticateResponse, AxiosError<AuthenticationAuthenticateResponse>, AuthenticateRenewParameters>;
};

export default function useAuthenticationRenewTokenMutation(
	config: AuthenticationRenewTokenConfig = { axiosConfig: {} }
): AuthenticationRenewTokenResult {
	async function AuthenticationRenewToken(): Promise<AuthenticationAuthenticateResponse> {
		const client = ApiCSGService();
		client.clearOtherToken();

		const path = '/api/Authentication/RenewToken';
		const { refreshToken = '' } = AuthenticationSessionGet();

		const payload = {
			refreshToken,
		};

		const { data } = await client.post<AuthenticationAuthenticateResponse>(path, payload);

		return data;
	}

	return useMutation(AuthenticationRenewToken, config.mutationConfig);
}
