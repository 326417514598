import React, { useState } from 'react';
import VerifyEmail from './VerifyEmail';
import EmailSent from './EmailSent';
import VerificationComplete from './VerificationComplete';

import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { FlexBox, Modal } from 'sfc-kit';
import { useContactQuery, useMessages } from 'hooks';
import useLoans from 'hooks/useLoans';

interface OnboardingDialogProps {
	isOpen: boolean;
	onClose: () => void;
	currentStep?: 0 | 1 | 2;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			maxWidth: '480px',
			padding: theme.spacing(1),
			textAlign: 'center',
		},
	})
);

const arrOfComponent = (
	setActiveStep: React.Dispatch<React.SetStateAction<0 | 1 | 2>>,
	onClose: () => void,
	loans: Array<Loan>,
	contact: ContactDto | undefined
): Array<JSX.Element> => [
	<VerifyEmail key="onboarding-dialog-verify-email" contact={contact} onNextClick={(): void => setActiveStep(1)} />,
	<EmailSent key="onboarding-dialog-email-sent" loans={loans} onGoBack={(): void => setActiveStep(0)} onClose={onClose} />,
	<VerificationComplete key="onboarding-dialog-verification-complete" onClose={onClose} />,
];

const OnboardingDialog: React.FC<OnboardingDialogProps> = ({ isOpen, onClose, currentStep = 0 }) => {
	const classes = useStyles({} as any);
	const messages = useMessages();
	const { data: loans = [] } = useLoans();
	const { isSuccess, data: contact } = useContactQuery();
	const [activeStep, setActiveStep] = useState(currentStep);

	return (
		<Modal
			zIndex={1203}
			closeIconText
			onClose={onClose}
			messages={messages}
			id="email-verified-prompt"
			open={isOpen && isSuccess}
			title="email.verification.verifyEmailAddress"
			titleCasing="capitalizeEachWord"
			variant="Success">
			<FlexBox className={classes.contentWrapper} direction="column">
				{arrOfComponent(setActiveStep, onClose, loans, contact)[activeStep]}
			</FlexBox>
		</Modal>
	);
};

export default OnboardingDialog;
