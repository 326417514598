import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

export interface DetailsDrawerProps {
	open: boolean;
	placement?: 'right' | 'left';
	fixed?: boolean;
	width?: string;
	className?: string;
}

export const generatePlacement = (placement: DetailsDrawerProps['placement']): object => {
	switch (placement) {
		case 'right':
			return {
				position: 'fixed',
				top: 0,
				right: 0,
			};
		case 'left':
			return {
				position: 'fixed',
				top: 0,
				left: 0,
			};

		default:
			return {};
	}
};

export const generateWidthAndHeight = (
	open: DetailsDrawerProps['open'],
	fixed: DetailsDrawerProps['fixed'],
	width: DetailsDrawerProps['width']
): object => {
	if (!open) {
		return { width: 0, opacity: 0 };
	}

	return fixed ? { width: width ? width : '30vw', height: '100vh' } : { width: width ? width : '30%', height: '100%' };
};

const useStyles = makeStyles(() =>
	createStyles({
		drawer: ({ open, fixed, placement, width }: Pick<DetailsDrawerProps, 'open' | 'fixed' | 'placement' | 'width'>) => ({
			...generatePlacement(placement),
			...generateWidthAndHeight(open, fixed, width),
			overflow: 'hidden',
			transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
		}),
	})
);

const DetailsDrawer: React.FC<DetailsDrawerProps> = ({ open, placement, fixed = false, width, className, children }) => {
	const classes = useStyles({ open, fixed, placement, width });

	return <div className={`${classes.drawer} ${className}`}>{children}</div>;
};

export default DetailsDrawer;
