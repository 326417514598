import { GlobalActionTypes, GlobalDispatchType } from 'context/Global';

import CommonServicesGatewayAPI from 'utils/CommonServicesGatewayAPI';

export class LoanService {
	public GetScheduledAchPaymentByAccountNumber(
		scheduledAchPayments: ScheduledAchPayment[],
		selectedAccountNumber: string
	): ScheduledAchPayment | undefined {
		return scheduledAchPayments.find(payment => payment.bankAccountNumber === selectedAccountNumber);
	}

	/**
	 * Updates the nickname of a given loan using the CommonServicesGateway
	 * @param loanNumber The loan to update the nickname of
	 * @param nickname The new nickname of the loan
	 */
	public async UpdateLoanNickname(loanNumber: string, nickname: string, dispatchForGlobal: GlobalDispatchType): Promise<void> {
		try {
			dispatchForGlobal({ type: GlobalActionTypes.UpdateLoadingSnackbar, payload: { open: true, message: 'Updating Nickname' } });

			await CommonServicesGatewayAPI.UpdateLoanNickname({ loanNumber, nickname });
		} finally {
			dispatchForGlobal({ type: GlobalActionTypes.UpdateLoadingSnackbar, payload: { open: false } });
		}
	}

	/**
	 * Returns a boolean indicating whether a loan is a shadow loan or not
	 * @param loan A loan object
	 */
	public IsNotShadowLoan(loan: Loan): boolean {
		return !loan.loanNumber.includes('S');
	}
}

const loanService = new LoanService();

export default loanService;
