import { Casing } from '../types';

const generateCasing = (casing: Casing | undefined): object | undefined => {
	switch (casing) {
		case 'lowercase':
			return { textTransform: 'lowercase' };

		case 'uppercase':
			return { textTransform: 'uppercase' };

		case 'capitalizeEachWord':
			return { textTransform: 'capitalize' };

		case 'capitalizeFirstWord':
			return {
				textTransform: 'lowercase',
				'&:first-letter': {
					textTransform: 'capitalize',
				},
			};

		default:
			return undefined;
	}
};

export default generateCasing;
