import { useCallback, useContext } from 'react';
import { useUnlockAccountMutation, useAuthenticationAuthenticateMutation, useAuthenticateBorrowerMutation } from 'sfc-kit';
import { AuthenticationActionTypes, AuthenticationContext } from 'context/Authentication';
import LoginContext, { LoginActionTypes } from 'context/Login';
import GlobalContext, { GlobalActionTypes } from 'context/Global';
import { useCustomerPortalNotificationStack } from 'hooks';
import Misc from 'constants/Misc';
import { parseAuthenticateResponse } from './utils';

export default function useAuthenticate(): (username: string, password: string) => Promise<void> {
	const { createDangerNotification } = useCustomerPortalNotificationStack();

	const { dispatchForLogin } = useContext(LoginContext);
	const { dispatchForAuthentication } = useContext(AuthenticationContext);
	const { dispatchForGlobal } = useContext(GlobalContext);

	const [unlockAccount] = useUnlockAccountMutation();

	const [authenticateBorrower] = useAuthenticateBorrowerMutation({
		mutationConfig: {
			onMutate: () => dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: true }),
			onSuccess: ({ isLockedOut, token }, { body }) => {
				if (!isLockedOut && token !== undefined) {
					// ensure that loanToken is reset on login
					if (sessionStorage.getItem('LoanToken')) {
						sessionStorage.removeItem('LoanToken');
					}

					dispatchForAuthentication({ type: AuthenticationActionTypes.UpdateAuthToken, payload: token });

					if (body) {
						dispatchForAuthentication({ type: AuthenticationActionTypes.UpdateUsername, payload: body.username });
						dispatchForLogin({ type: LoginActionTypes.ResetLoginAttempts });
						unlockAccount({ body });
					}
				}

				if (isLockedOut) {
					dispatchForLogin({ type: LoginActionTypes.LockedOut });
				}
			},
			onError: ({ response }) => {
				if (response) {
					dispatchForLogin({ type: LoginActionTypes.IncrementLoginAttempts });
				} else {
					createDangerNotification({ title: 'notifications.oops', body: 'notifications.somethingWentWrong' });
				}
			},
			onSettled: () => dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: false }),
		},
	});

	const [authenticate] = useAuthenticationAuthenticateMutation({
		mutationConfig: {
			onSuccess: (resp, { body }) => {
				if (resp?.userType?.id !== 2) {
					dispatchForLogin({ type: LoginActionTypes.IncrementLoginAttempts });
					return;
				}

				if (!resp.isLockedOut && resp.bearerToken !== undefined) {
					// ensure that loanToken is reset on login
					if (sessionStorage.getItem('LoanToken')) {
						sessionStorage.removeItem('LoanToken');
					}

					const payload = parseAuthenticateResponse(resp);

					dispatchForAuthentication({ type: AuthenticationActionTypes.UpdateAuthState, payload });

					if (body) {
						dispatchForLogin({ type: LoginActionTypes.ResetLoginAttempts });
						unlockAccount({ body });
					}
				}

				if (resp.isLockedOut) {
					dispatchForLogin({ type: LoginActionTypes.LockedOut });
				}
			},
			onError: ({ response }) => {
				if (response) {
					dispatchForLogin({ type: LoginActionTypes.IncrementLoginAttempts });
				} else {
					createDangerNotification({ title: 'notifications.oops', body: 'notifications.somethingWentWrong' });
				}
			},
		},
	});

	return useCallback(
		async (username: string, password: string) => {
			dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: true });

			try {
				sessionStorage.getItem(Misc.SessionStorage.ShowMobileView)
					? await authenticateBorrower({ body: { username, password } })
					: await authenticate({ body: { username, password } });
			} finally {
				dispatchForGlobal({ type: GlobalActionTypes.UpdateLoading, payload: false });
			}
		},
		[authenticate, authenticateBorrower, dispatchForGlobal]
	);
}
