import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetCreditInformationResult = QueryResult<CreditInformation, AxiosError<CreditInformation>>;
export type GetCreditInformationConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<CreditInformation, AxiosError<CreditInformation>>;
};

interface GetCreditInformationQuery {
	(request: GetCreditInformationRequestParameters, config?: GetCreditInformationConfig): GetCreditInformationResult;
	key: 'GetCreditInformation';
}

const useGetCreditInformationQuery: GetCreditInformationQuery = (request, config = { axiosConfig: {} }) => {
	async function GetCreditInformation(): Promise<CreditInformation> {
		const client = ApiCSGService();

		const path = '/api/Dealer/GetCreditInformation';

		const response = await client.get<CreditInformation>(path, {
			authToken: request?.authToken,
		});

		return response.data;
	}

	return useQuery(['GetCreditInformation', ...Object.values(request)], GetCreditInformation, config.queryConfig);
};

useGetCreditInformationQuery.key = 'GetCreditInformation';

export default useGetCreditInformationQuery;
