import Validator, { ValidatorSetupFunc } from './Validator';

/** A Class to contain the validation information related to the useTextField custom hook. */
export default class FieldValidation<TState, TValidator extends Validator<TState>> {
	public Invalid: boolean;
	public ReasonForInvalidity: string | undefined;

	/** A function that validates the field and returns a boolean promise denoting if the field is valid. */
	public Validate: (setupFunc?: ValidatorSetupFunc<TState, TValidator>) => Promise<boolean>;

	/** A function that validates the field and returns a boolean denoting if the field is valid. */
	public ValidateSync: (setupFunc?: ValidatorSetupFunc<TState, TValidator>) => boolean;

	/**
	 * Creates a new FieldValidation object.
	 * @param invalid
	 * @param reasonForInvalidity The reason the field is invalid or undefind when the field is valid.
	 * @param validate A function that validates the field and returns a boolean promise denoting if the field is valid.
	 * @param validateSync A function that validates the field and returns a boolean denoting if the field is valid.
	 */
	constructor(
		invalid: boolean,
		reasonForInvalidity: string | undefined,
		validate: (setupFunc?: ValidatorSetupFunc<TState, TValidator>) => Promise<boolean>,
		validateSync: (setupFunc?: ValidatorSetupFunc<TState, TValidator>) => boolean
	) {
		this.Invalid = invalid;
		this.ReasonForInvalidity = reasonForInvalidity;
		this.Validate = validate;
		this.ValidateSync = validateSync;
	}
}
