import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanDetailsResult = QueryResult<LoanDetails, AxiosError<LoanDetails>>;
export type LoanDetailsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<LoanDetails, AxiosError<LoanDetails>>;
};

interface LoanDetailsQuery {
	(request: LoanDetailsRequestParameters, config?: LoanDetailsConfig): LoanDetailsResult;
	key: 'LoanDetails';
}

const useLoanDetailsQuery: LoanDetailsQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanDetails(): Promise<LoanDetails> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/LoanDetails`;

		const response = await client.get<LoanDetails>(path);

		return response.data;
	}

	return useQuery(['LoanDetails', ...Object.values(request)], LoanDetails, config.queryConfig);
};

useLoanDetailsQuery.key = 'LoanDetails';

export default useLoanDetailsQuery;
