import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';

import App from './App';
import Theme from './Theme';
import History from './utils/History';
import { NotificationStack } from 'sfc-kit';
import * as ServiceWorker from './registerServiceWorker';
import { AuthenticationProvider } from 'context/Authentication';

import LuxonUtils from '@date-io/luxon';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';

// import reportWebVitals from 'reportWebVitals';
// import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context';
// import oidcConfig from 'constants/OIDC';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/* eslint-disable */
const styles = 'border-radius: 3px; color: black; font-weight: bold; padding: 2px 5px; margin: 2px 5px;';

const _log = console.log;
console.log = (() => Function.prototype.bind.call(_log, console, '%cService Finance Company', `background: #17a2b8; ${styles}`))();

const _error = console.error;
console.error = (() => Function.prototype.bind.call(_error, console, '%cService Finance Company', `background: #D8000C; ${styles}`))();

const _warn = console.warn;
console.warn = (() => Function.prototype.bind.call(_warn, console, '%cService Finance Company', `background: #ffae42; ${styles}`))();
/* eslint-enable */

const rootElement = document.getElementById('root');

render(
	<Provider store={store}>
		<ThemeProvider theme={Theme}>
			<NotificationStack>
				<CssBaseline />
				<MuiPickersUtilsProvider utils={LuxonUtils}>
					{/* <OIDCAuthProvider {...oidcConfig}> */}
					<PersistGate loading={null} persistor={persistor}>
						<AuthenticationProvider>
							<Router history={History}>
								<App />
							</Router>
						</AuthenticationProvider>
					</PersistGate>
					{/* </OIDCAuthProvider> */}
				</MuiPickersUtilsProvider>
			</NotificationStack>
		</ThemeProvider>
	</Provider>,
	rootElement
);

// eslint-disable-next-line no-console
// reportWebVitals(console.log);

ServiceWorker.unregister();
