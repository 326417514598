export default function DateInputFormatter(value: string, selectionStart: number | null = 0, selectionEnd: number | null = 0): string {
	const mask = '__/__/____';
	const acceptRegex = /[\d]/gi;

	if (value.length === mask.length + 1 && selectionEnd === selectionStart) {
		const locationAfterNewEntry = selectionStart ?? 0;

		let [month, day, year] = value.split('/');

		switch (locationAfterNewEntry) {
			case 1:
				month = month[0] + month[2];
				break;
			case 2:
				month = month[0] + month[1];
				break;
			case 3:
				day = month[2] + day[1];
				month = month[0] + month[1];
				break;
			case 4:
				day = day[0] + day[2];
				break;
			case 5:
				day = day[0] + day[1];
				break;
			case 6:
				year = day[2] + year.slice(1);
				day = day[0] + day[1];
				break;
			case 7:
				year = year[0] + year.slice(2);
				break;
			case 8:
				year = year.slice(0, 2) + year.slice(3);
				break;
			case 9:
				year = year.slice(0, 3) + year[4];
				break;
			case 10:
				year = year.slice(0, 4);
				break;
			default:
				break;
		}

		value = [month, day, year].join('/');
	}

	return value
		.split('')
		.map((char, i) => {
			acceptRegex.lastIndex = 0;

			if (i > mask.length - 1) {
				return '';
			}

			const maskChar = mask[i];
			const nextMaskChar = mask[i + 1];

			const acceptedChar = acceptRegex.test(char) ? char : '';
			const formattedChar = maskChar === '_' ? acceptedChar : maskChar + acceptedChar;

			if (i === value.length - 1 && nextMaskChar && nextMaskChar !== '_') {
				// when cursor at the end of mask part (e.g. month) prerender next symbol "21" -> "21/"
				return formattedChar ? formattedChar + nextMaskChar : '';
			}

			return formattedChar;
		})
		.join('');
}
