import React, { ReactElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

import * as ColorManipulator from '@material-ui/core/styles/colorManipulator';
import { makeStyles, createStyles, Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';

import {
	useSfcKitMessages,
	ExtractColorFromKeyString,
	GenerateCasing,
	//
	Constants,
	//
	Color,
	LangText,
	Messages,
	SfcKitMessages,
	MessagesProps,
	Casing,
	FontWeight,
	FontSize,
} from '../..';

export interface ButtonProps<TMessages extends Messages = SfcKitMessages> extends Omit<MuiButtonProps, 'color'>, MessagesProps<TMessages> {
	casing?: Casing;
	color?: Color;
	textColor?: Color;
	to?: LinkProps['to'];
	round?: boolean;
	message?: LangText<TMessages>;
	weight?: FontWeight;
	fontSize?: FontSize;
}

export const styleFromVariant = (variant: ButtonProps['variant'], color: string, hover: boolean): object | void => {
	switch (variant) {
		case 'contained':
			return { backgroundColor: color, border: 'none' };

		case 'outlined':
			if (hover) {
				return { border: `1px solid ${color}`, backgroundColor: ColorManipulator.alpha(color, 0.3) };
			}

			return { border: `1px solid ${color}` };

		case 'text':
			return { border: 'none', backgroundColor: 'unset' };
	}
};

const useStyles = makeStyles(theme =>
	createStyles({
		styledButton: ({
			round = false,
			color = 'primary.main',
			textColor,
			variant,
			casing,
			weight = 'light',
			fontSize = 'medium',
		}: Pick<ButtonProps, 'round' | 'color' | 'textColor' | 'casing' | 'weight' | 'fontSize'> & Pick<MuiButtonProps, 'variant'>) => {
			const backgroundColor = ExtractColorFromKeyString(color);

			return {
				borderRadius: round ? undefined : 0,

				...styleFromVariant(variant, backgroundColor, false),

				...GenerateCasing(casing),

				'& [class*="label"]': {
					color: textColor ? ExtractColorFromKeyString(textColor) : theme.palette.getContrastText(backgroundColor),
				},

				'&:disabled [class*="label"]': {
					color: theme.palette.text.disabled,
				},

				'&:hover': {
					...styleFromVariant(variant, ColorManipulator.darken(backgroundColor, 0.2), true),
				},
				fontWeight: Constants.FontWeights[weight],
				fontSize: Constants.FontSizes[fontSize],
			};
		},
	})
);

export default function Button<TMessages extends Messages = SfcKitMessages>({
	to,
	color,
	casing,
	textColor,
	variant = 'contained',
	round,
	message,
	children,
	messages,
	weight = 'light',
	className,
	fontSize = 'medium',
	...props
}: ButtonProps<TMessages>): ReactElement | null {
	const classes = useStyles({ casing, color, textColor, variant, round, weight, fontSize });

	const sfcKitMessages = useSfcKitMessages();

	const content = message ? messages?.[message] ?? sfcKitMessages[message as keyof SfcKitMessages] : children;

	if (to !== undefined) {
		return (
			/* eslint-disable-next-line */
			// @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877#issuecomment-493518825
			<MuiButton component={Link} to={to} variant={variant} className={classnames(classes.styledButton, className)} {...props}>
				{content}
			</MuiButton>
		);
	}

	return (
		<MuiButton variant={variant} className={classnames(classes.styledButton, className)} {...props}>
			{content}
		</MuiButton>
	);
}
