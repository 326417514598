import Paths from 'constants/Paths';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ForumIcon from '@material-ui/icons/Forum';
import { SvgIcon } from '@material-ui/core';
import GridOnIcon from '@material-ui/icons/GridOn';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import DescriptionIcon from '@material-ui/icons/Description';
import LockIcon from '@material-ui/icons/Lock';
import PolicyIcon from '@material-ui/icons/Policy';

import { LangText, Casing } from 'sfc-kit';
import { CustomerPortalMessages } from 'types';

export type TRoute = {
	icon: typeof SvgIcon;
	heading: LangText;
	headingCasing: Casing;
	mobileHeading?: LangText;
	text?: LangText<CustomerPortalMessages>;
	link: keyof typeof Paths;
	external?: boolean;
};

export interface IRoute {
	icon: typeof SvgIcon;
	heading: string;
	mobileHeading?: string;
	text?: string;
	link: keyof typeof Paths;
}

const LandingFooter: TRoute[] = [
	{
		icon: LiveHelpIcon,
		heading: 'misc.contactUs',
		headingCasing: 'capitalizeEachWord',
		text: 'landing.contactUsLanding',
		link: 'Contact',
	},
	{
		icon: LibraryBooksIcon,
		heading: 'misc.privacyPolicy',
		headingCasing: 'capitalizeEachWord',
		text: 'landing.privacyPolicyLanding',
		link: 'Privacy',
		external: true,
	},
	{
		icon: ForumIcon,
		heading: 'misc.faqs',
		headingCasing: 'uppercase',
		mobileHeading: 'misc.faqs',
		text: 'landing.faqsLanding',
		link: 'FAQs',
	},
	{
		icon: AccessibilityIcon,
		heading: 'misc.accessibility',
		headingCasing: 'capitalizeEachWord',
		mobileHeading: 'misc.accessibility',
		text: 'landing.accessibilityLanding',
		link: 'Accessibility',
	},
	{
		icon: LockIcon,
		heading: 'misc.rightsNotice',
		headingCasing: 'capitalizeEachWord',
		mobileHeading: 'misc.rightsNotice',
		text: 'landing.rightsNotice',
		link: 'RightsNotice',
		external: true,
	},
	{
		icon: DescriptionIcon,
		heading: 'misc.instructionalDocuments',
		headingCasing: 'capitalizeEachWord',
		mobileHeading: 'misc.instructionalDocuments',
		text: 'landing.instructionalDocuments',
		link: 'InstructionalDocuments',
	},
	{
		icon: PolicyIcon,
		heading: 'misc.onlinePrivacyPractices',
		headingCasing: 'capitalizeEachWord',
		mobileHeading: 'misc.onlinePrivacyPractices',
		text: 'landing.onlinePrivacyPractices',
		link: 'OnlinePrivacyPractices',
		external: true,
	},
];

interface IRoutes {
	LandingFooter: TRoute[];
	PublicSidebar: {
		Main: TRoute[];
	};
	PrivateApp: {
		SecondaryDrawer: IRoute[];
	};
}

const Routes: IRoutes = {
	LandingFooter,
	PublicSidebar: {
		Main: [...LandingFooter],
	},
	PrivateApp: {
		SecondaryDrawer: [
			{
				icon: ViewQuiltIcon,
				heading: 'Dashboard',
				link: 'Dashboard',
			},
			{
				icon: LocalAtmIcon,
				heading: 'Payment Center',
				link: 'PaymentCenter',
			},
			{
				icon: GridOnIcon,
				heading: 'Statements',
				link: 'Statements',
			},
			{
				icon: CropOriginalIcon,
				heading: 'Loan Overview',
				link: 'LoanOverview',
			},
		],
	},
};

export default Routes;
