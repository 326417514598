import React, { useEffect, useState } from 'react';
import { type FontWeightProperty } from 'csstype';

import { createStyles, makeStyles, Theme, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Paths from 'constants/Paths';
import { Text } from 'sfc-kit';

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		root: {
			padding: theme.spacing(2),
		},
		message: {
			margin: theme.spacing(2, 2, 0, 0),
			padding: theme.spacing(1, 1, 1, 2),
			borderLeft: `6px solid ${theme.palette.primary.main}`,
			height: '100%',
		},
		messageNoBorder: {
			margin: theme.spacing(2, 2, 0, 0),
			padding: theme.spacing(1, 1, 1, 2),
			height: '100%',
		},
		header: {
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
			textTransform: 'uppercase',
		},
		button: {
			textTransform: 'none',
			color: theme.palette.action.main,
			fontWeight: theme.typography.fontWeightBold  as FontWeightProperty,
			padding: 0,
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		messageGrid: {
			overflow: 'hidden',
		},
	});
});

const LoanOverviewInformationMessages: React.FC = () => {
	const classes = useStyles({} as any);
	const [isMobileView, setIsMobileView] = useState<boolean>(false);

	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);

	return (
		<div className={classes.root}>
			<Text variant="body2" color="primary" className={classes.header} component="h2">
				Information
			</Text>
			<Grid container className={classes.messageGrid}>
				<Grid item xs={12} md={6}>
					<Text variant="body2" className={isMobileView ? classes.messageNoBorder : classes.message}>
						Balances displayed on your loan summary do not reflect your payoff amount. Generate your{' '}
						{!isMobileView ? (
							<Button size="small" variant="text" tabIndex={1} component={Link} to={Paths.PayoffLetter} className={classes.button}>
								Payoff Letter
							</Button>
						) : (
							<Text variant="body2" inline>
								Payoff Letter
							</Text>
						)}{' '}
						for detailed instructions on how to pay off your loan.
					</Text>
				</Grid>
				<Grid item xs={12} md={6}>
					<Text variant="body2" className={isMobileView ? classes.messageNoBorder : classes.message}>
						If you have a With Pay Deferred Interest account (WPDI), the balances displayed on the loan summary do not reflect the credit
						for the deferred interest, please refer to your monthly statement to see this information.
					</Text>
				</Grid>
			</Grid>
		</div>
	);
};

export default LoanOverviewInformationMessages;
