import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetInitialDocumentsForDealerResult = QueryResult<InitialDocumentForDealer, AxiosError<InitialDocumentForDealer>>;
export type GetInitialDocumentsForDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<InitialDocumentForDealer, AxiosError<InitialDocumentForDealer>>;
};

interface GetInitialDocumentsForDealerQuery {
	(request: GetInitialDocumentsForDealerRequestParameters, config?: GetInitialDocumentsForDealerConfig): GetInitialDocumentsForDealerResult;
	key: 'GetInitialDocumentsForDealer';
}

const useGetInitialDocumentsForDealerQuery: GetInitialDocumentsForDealerQuery = (request, config = { axiosConfig: {} }) => {
	async function GetInitialDocumentsForDealer(): Promise<InitialDocumentForDealer> {
		const client = ApiCSGService();

		const path = '/api/InitialDocuments/ForDealer';

		const response = await client.get<InitialDocumentForDealer>(path, {
			taskId: request?.taskId,
		});

		return response.data;
	}

	return useQuery(['GetInitialDocumentsForDealer', ...Object.values(request)], GetInitialDocumentsForDealer, config.queryConfig);
};

useGetInitialDocumentsForDealerQuery.key = 'GetInitialDocumentsForDealer';

export default useGetInitialDocumentsForDealerQuery;
