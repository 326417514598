import { useHistory } from 'react-router-dom';
import { useContext, useCallback } from 'react';

import Misc from 'constants/Misc';
import Paths from 'constants/Paths';
import { AuthenticationActionTypes, AuthenticationContext } from 'context/Authentication';
import { useCustomerPortalNotificationStack } from 'hooks';

import { useAuthenticateLogoutMutation, useLogoutBorrowerMutation } from 'sfc-kit';
import * as BrowserStorage from 'sfc-kit/src/utils/BrowserStorage';

export default function useLogout(): () => void {
	const history = useHistory();
	const { createSuccessNotification } = useCustomerPortalNotificationStack();

	const {
		authenticationState: { authToken, refreshToken },
		dispatchForAuthentication,
	} = useContext(AuthenticationContext);

	const [logout] = useAuthenticateLogoutMutation();
	const [logoutBorrower] = useLogoutBorrowerMutation();

	return useCallback(() => {
		// Transition the site to a logged out state even if the token wasn't successfully invalidated
		const lsKeysToPersist = [Misc.LocalStorage.Login];
		Object.values(Misc.LocalStorage)
			.filter(lsKey => !lsKeysToPersist.includes(lsKey))
			.forEach(BrowserStorage.RemoveItem);

		if (authToken !== null && sessionStorage.getItem(Misc.SessionStorage.ShowMobileView)) {
			logoutBorrower({ body: { token: authToken ?? '' } });
		} else {
			logout({ token: authToken ?? '', refreshToken: refreshToken ?? '' });
		}

		dispatchForAuthentication({ type: AuthenticationActionTypes.ClearAuthToken });

		history.push(Paths.Home);

		createSuccessNotification({ title: 'notifications.logout.success.title', body: 'notifications.logout.success.body' });
	}, [authToken, refreshToken, history, logout, logoutBorrower, dispatchForAuthentication, createSuccessNotification]);
}
