import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetTaskRelationshipsResult = QueryResult<TaskRelationships, AxiosError<TaskRelationships>>;
export type GetTaskRelationshipsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<TaskRelationships, AxiosError<TaskRelationships>>;
};

interface GetTaskRelationshipsQuery {
	(request: GetTaskRelationshipsRequestParameters, config?: GetTaskRelationshipsConfig): GetTaskRelationshipsResult;
	key: 'GetTaskRelationships';
}

const useGetTaskRelationshipsQuery: GetTaskRelationshipsQuery = (request, config = { axiosConfig: {} }) => {
	async function GetTaskRelationships(): Promise<TaskRelationships> {
		const client = ApiCSGService();

		const path = `/api/Tasks/${request.taskId}/Relationships`;

		const response = await client.get<TaskRelationships>(path);

		return response.data;
	}

	return useQuery(['GetTaskRelationships', ...Object.values(request)], GetTaskRelationships, config.queryConfig);
};

useGetTaskRelationshipsQuery.key = 'GetTaskRelationships';

export default useGetTaskRelationshipsQuery;
