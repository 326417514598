import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Text } from 'sfc-kit';

import { PaymentFrequencies } from 'types';

export interface ACHTermsAndConditionsProps {
	frequency: PaymentFrequencies;
}

const useStyles = makeStyles(() => {
	return createStyles({
		centeredHeadings: {
			textAlign: 'center',
		},
		leftAlign: {
			textAlign: 'left',
		},
	});
});

const ACHTermsAndConditions: React.FC<ACHTermsAndConditionsProps> = ({ frequency }) => {
	const classes = useStyles({} as any);

	return (
		<>
			<div className={classes.centeredHeadings}>
				<Text variant="h4" component="h1" gutterBottom>
					Online Payment Terms and Conditions
				</Text>
				{frequency !== PaymentFrequencies.OneTime ? (
					<>
						<p>
							If any payment due date falls on a weekend or holiday, the payment will be deducted on the next business day after the
							payment due date. You authorize us to initiate ACH debit entries to transfer funds from your account to collect a fee of
							equal the amount shown on your retail installment sale contract if any payment is rejected by your bank because you do not
							have an account with bank, you do not have sufficient funds in your account or you do not have sufficient credit with the
							bank.
						</p>
						<p>
							You understand and agree that this authorization will remain in effect until (i) your contract with us is paid in full or
							(ii) you cancel this authorization by calling us at 1(866) 587 9445, or by submitting a new copy of this form to us. We
							must be notified of cancellation at least three (3) business days prior to the payment due date. You may only sign up and
							cancel your authorization three (3) times during the term of your contract. You agree to notify us of any changes to your
							bank account information. You acknowledge that the origination of ACH transactions to your account must comply with the
							provisions of applicable law, including the Electronic Funds Transfer Act, and Regulation E, and the Rules of the National
							Automated Clearing House Association. Your rights and obligations with respect to your bank will be determined by your
							agreement with your bank. You request the bank that holds the account to honor the debit entries that we initiate.
						</p>
					</>
				) : (
					<p>
						You acknowledge that you are an authorized signer on the deposit account identified above and that you will not dispute a
						payment that corresponds to the terms of this authorization form. You acknowledge your rights and obligations with respect to
						your bank will be determined by your agreement with your bank and that you have or will request the bank that holds your
						deposit account to honor the debit entries that we initiate pursuant to this authorization form. You acknowledge that the
						origination of the ACH transaction to your deposit account must comply with the provisions of applicable law, including the
						Electronic Funds Transfer Act, and Regulation E, and the Rules and Operating Guidelines of NACHA (formerly known as the
						National Automated Clearing House Association).
					</p>
				)}
			</div>
			<div className={classes.leftAlign}></div>
		</>
	);
};

export default ACHTermsAndConditions;
