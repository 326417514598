import React, { ReactElement } from 'react';

import { GridProps } from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles, SvgIcon, MenuItem } from '@material-ui/core';

import SecurityQuestionOptions from '../../constants/SecurityQuestionOptions';
import FullWidthSelect from '../FullWidthSelect';
import { ISfcTextFieldProps } from '../SfcForm/SfcTextField';
import { FlexBox, FlexItem } from '../';
import { Control, Controller, UseFormMethods } from 'react-hook-form';
import SfcMaskedField from '../SfcForm/SfcMaskedField';
import { SfcKitMessages, Messages, LangText, MessagesProps } from '../..';

export interface QuestionsProps<TMessages extends Messages = SfcKitMessages> {
	name: string;
	label?: string;
	error?: boolean;
	errorMessage?: LangText<TMessages>;
	StartIcon?: typeof SvgIcon;
	control?: Control;
	defaultValue?: string;
}

export interface AnswerProps {
	label?: string;
	error?: boolean;
	helperText?: string;
	StartIcon?: typeof SvgIcon;
	InputProps?: ISfcTextFieldProps['InputProps'];
	inputRef?: UseFormMethods['register'];
	name: string;
	onBlur?: () => void;
}

export interface SecurityQuestionsAndAnswerProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	QuestionProps: QuestionsProps<TMessages>;
	AnswerProps: AnswerProps;
	ariaLabel?: string;
	tabIndex?: number;
	title?: string;
	questionGrid?: GridProps;
	answerGrid?: GridProps;
	questions: { value: string; text: string }[];
	isDealerPortal?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		menuItem: {
			whiteSpace: 'initial',
			[theme.breakpoints.down('md')]: {
				...theme.typography.body2,
				fontSize: theme.typography.caption.fontSize,
			},
		},
		firstItem: {
			paddingRight: theme.spacing(2),
			[theme.breakpoints.down('md')]: {
				paddingRight: '0px',
			},
		},
	})
);

export default function SecurityQuestionAndAnswer<TMessages extends Messages = SfcKitMessages>({
	QuestionProps,
	AnswerProps,
	questionGrid = {},
	answerGrid = {},
	messages,
	title,
	ariaLabel,
	tabIndex,
	questions,
	isDealerPortal = false,
}: SecurityQuestionsAndAnswerProps<TMessages>): ReactElement {
	const classes = useStyles({} as any);

	const { errorMessage, ...questionProps } = QuestionProps;
	const { helperText, ...answerProps } = AnswerProps;

	const questionErrorMessage = messages?.[errorMessage as LangText];
	const answerErrorMessage = messages?.[helperText as LangText];

	return (
		<FlexBox>
			<FlexItem xs={12} md={6} {...questionGrid} className={classes.firstItem}>
				<Controller
					as={
						<FullWidthSelect
							label={questionProps.label}
							ariaLabel={ariaLabel}
							title={title}
							tabIndex={tabIndex ?? undefined}
							errorMessage={questionErrorMessage}>
							{isDealerPortal
								? questions.map(({ value, text }) => (
										<MenuItem key={value} value={value} className={classes.menuItem} style={{ whiteSpace: 'normal' }}>
											{text}
										</MenuItem>
								  ))
								: SecurityQuestionOptions.map(({ value, text }) => (
										<MenuItem key={value} value={value} className={classes.menuItem} style={{ whiteSpace: 'normal' }}>
											{text}
										</MenuItem>
								  ))}
						</FullWidthSelect>
					}
					{...questionProps}
				/>
			</FlexItem>
			<FlexItem xs={12} md {...answerGrid}>
				<SfcMaskedField
					margin="none"
					autoComplete="off"
					answerTabIndex={tabIndex ?? undefined}
					helperText={answerErrorMessage}
					{...answerProps}
					placeholder="Answer"
				/>
			</FlexItem>
		</FlexBox>
	);
}
