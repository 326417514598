import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type WillExceedAmountDueByDateResult = MutationResultPair<boolean, AxiosError<boolean>, WillExceedAmountDueByDateRequestParameters, unknown>;

export type WillExceedAmountDueByDateConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<boolean, AxiosError<boolean>, WillExceedAmountDueByDateRequestParameters>;
};

export default function useWillExceedAmountDueByDateMutation(
	config: WillExceedAmountDueByDateConfig = { axiosConfig: {} }
): WillExceedAmountDueByDateResult {
	async function WillExceedAmountDueByDate(request: WillExceedAmountDueByDateRequestParameters): Promise<boolean> {
		const client = ApiCSGService();

		const path = '/api/Payment/WillExceedAmountDueByDate';

		const response = await client.post<boolean>(path, request.body);

		return response.data;
	}

	return useMutation(WillExceedAmountDueByDate, config.mutationConfig);
}
