import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type GetDealerSecurityQuestionsResult = QueryResult<DealerSecurityQuestions, AxiosError<DealerSecurityQuestions>>;
export type GetDealerSecurityQuestionsConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<DealerSecurityQuestions, AxiosError<DealerSecurityQuestions>>;
};

interface GetDealerSecurityQuestionsQuery {
	(request: GetDealerSecurityQuestionsRequestParameters, config?: GetDealerSecurityQuestionsConfig): GetDealerSecurityQuestionsResult;
	key: 'GetDealerSecurityQuestions';
}

const useGetDealerSecurityQuestionsQuery: GetDealerSecurityQuestionsQuery = (request, config = { axiosConfig: {} }) => {
	async function GetDealerSecurityQuestions(): Promise<DealerSecurityQuestions> {
		const client = ApiCSGService();

		const path = '/api/Authentication/GetDealerSecurityQuestions';

		const response = await client.get<DealerSecurityQuestions>(path, {
			request: request?.request,
		});

		return response.data;
	}

	return useQuery(['GetDealerSecurityQuestions', ...Object.values(request)], GetDealerSecurityQuestions, config.queryConfig);
};

useGetDealerSecurityQuestionsQuery.key = 'GetDealerSecurityQuestions';

export default useGetDealerSecurityQuestionsQuery;
