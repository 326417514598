import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import Step from '@material-ui/core/Step';
import Avatar from '@material-ui/core/Avatar';
import Stepper from '@material-ui/core/Stepper';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel, { StepLabelProps } from '@material-ui/core/StepLabel';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import colors from '../../../src/constants/Colors';

export interface INumericStepperProps {
	/**
	 * A string ID representing the component. Used when building the key for the iterated steps
	 *
	 * Exmaple:
	 * password-recovery-stepper
	 */
	id: string;

	/** The number of steps to be displayed */
	numSteps: number;

	/** The one-based index representing the currently active step */
	activeStep: number;

	/** Class name to be applied to the root Stepper */
	className?: string;

	/** An array containing string labels to be placed below their corresponding zero-based index*/
	labels?: string[];

	/** A component to be used as the icon for each step */
	StepIconComponent?: StepLabelProps['StepIconComponent'];

	/** If enabled, the label will be shown next to the stepper */
	inlineLabel?: boolean;
}

const useNumericStepIconStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.default,
			border: '2px solid #707070',
			color: '#707070',
			fontSize: theme.typography.body1.fontSize,
			height: theme.spacing(4.25),
			width: theme.spacing(4.25),
		},
		active: {
			backgroundColor: theme.palette.background.default,
			borderColor: colors.primary.main,
			color: colors.primary.main,
		},
		completed: {
			backgroundColor: colors.primary.main,
			borderColor: colors.primary.main,
			color: colors.misc.white,
		},
		alternativeLabel: {
			'& .MuiStepConnector-alternativeLabel': { top: '50% !important' },
		},
	})
);

export const NumericStepIcon: FunctionComponent<StepIconProps> = ({ icon, active, completed }) => {
	const classes = useNumericStepIconStyles();

	return <Avatar className={classnames(classes.root, { [classes.active]: active, [classes.completed]: completed })}>{icon}</Avatar>;
};

const NumericStepper: FunctionComponent<INumericStepperProps> = ({
	id,
	numSteps,
	activeStep,
	className,
	StepIconComponent = NumericStepIcon,
	inlineLabel,
	labels = [],
}) => {
	const classes = useNumericStepIconStyles();

	return (
		<Stepper
			activeStep={activeStep - 1}
			alternativeLabel={!inlineLabel}
			className={className}
			classes={{ alternativeLabel: classes.alternativeLabel }}>
			{[...new Array(numSteps)].map((_, index) => (
				<Step key={`${id}-${index}`}>
					<StepLabel aria-hidden="true" StepIconComponent={StepIconComponent}>
						{labels[index]}
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

export default NumericStepper;
