import React, { useEffect, useState } from 'react';

import { MenuItem } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import SfcDropdownPopper from 'sfc-kit/src/components/SfcDropdownPopper';

import { HistoricalPaymentsSortOption } from 'types';

export interface IPaymentActivitySortDropdownProps {
	sort: HistoricalPaymentsSortOption;
	onChange: (sort: HistoricalPaymentsSortOption) => void;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		button: {
			margin: theme.spacing(0, 1),
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	});
});

const PaymentActivitySortDropdown: React.FC<IPaymentActivitySortDropdownProps> = ({ sort, onChange }) => {
	const classes = useStyles({} as any);
	const [buttonText, setButtonText] = useState('');
	const [popperOpen, setPopperOpen] = useState(false);

	useEffect(() => {
		switch (sort) {
			case HistoricalPaymentsSortOption.DateOldestToNewest:
				setButtonText('Date Oldest');
				break;

			case HistoricalPaymentsSortOption.DateNewestToOldest:
				setButtonText('Date Newest');
				break;
		}
	}, [sort]);

	const getOnClick = (newSort: HistoricalPaymentsSortOption) => (): void => {
		onChange(newSort);
		setPopperOpen(false);
	};

	return (
		<SfcDropdownPopper buttonText={buttonText} open={popperOpen} onChange={setPopperOpen} closeOnClickAway classes={{ button: classes.button }}>
			<MenuItem tabIndex={1} onClick={getOnClick(HistoricalPaymentsSortOption.DateNewestToOldest)}>
				Date Newest
			</MenuItem>
			<MenuItem tabIndex={1} onClick={getOnClick(HistoricalPaymentsSortOption.DateOldestToNewest)}>
				Date Oldest
			</MenuItem>
		</SfcDropdownPopper>
	);
};

export default PaymentActivitySortDropdown;
