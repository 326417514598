import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type VersionResult = QueryResult<void, AxiosError<void>>;
export type VersionConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<void, AxiosError<void>>;
};

interface VersionQuery {
	(config?: VersionConfig): VersionResult;
	key: 'Version';
}

const useVersionQuery: VersionQuery = (config = { axiosConfig: {} }) => {
	async function Version(): Promise<void> {
		const client = ApiCSGService();

		const path = '/api/Dev/Version';

		await client.get<void>(path);

		return;
	}

	return useQuery(['Version'], Version, config.queryConfig);
};

useVersionQuery.key = 'Version';

export default useVersionQuery;
