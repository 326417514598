import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { SpacingProps, Spacing } from '..';
import { Grid, GridProps, makeStyles, createStyles } from '@material-ui/core';

export interface FlexBoxProps {
	children: ReactNode;

	fullHeight?: boolean;

	wrap?: GridProps['wrap'];
	justifyContent?: GridProps['justifyContent'];
	direction?: GridProps['direction'];
	alignItems?: GridProps['alignItems'];
	innerRef?: GridProps['innerRef'];

	// grow?: CSSProperties['flexGrow'];
	grow?: number;

	spacing?: SpacingProps['p'];

	className?: string;
	style?: React.CSSProperties;
}

const useStyles = makeStyles(
	createStyles({
		flexBox: ({ fullHeight, flexGrow }: { fullHeight: boolean; flexGrow?: number }) => ({
			height: fullHeight ? '100%' : undefined,
			flexGrow: flexGrow !== undefined ? flexGrow : undefined,
		}),
	})
);

/**
 * A FlexBox is the basic building block for layouts and usually has many FlexItems as children.
 */
const FlexBox: FunctionComponent<FlexBoxProps> = ({ children, fullHeight = false, grow, spacing, className, ...props }) => {
	const styles = useStyles({ fullHeight, flexGrow: grow });

	if (spacing !== undefined) {
		return (
			<Grid component={Spacing} p={spacing} container className={classnames(styles.flexBox, className)} {...props}>
				{children}
			</Grid>
		);
	}

	return (
		<Grid container className={classnames(styles.flexBox, className)} {...props}>
			{children}
		</Grid>
	);
};

export default FlexBox;
