import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type BorrowersResult = QueryResult<Envelope<LoanBorrowers>, AxiosError<Envelope<LoanBorrowers>>>;
export type BorrowersConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<Envelope<LoanBorrowers>, AxiosError<Envelope<LoanBorrowers>>>;
};

interface BorrowersQuery {
	(request: BorrowersRequestParameters, config?: BorrowersConfig): BorrowersResult;
	key: 'Borrowers';
}

const useBorrowersQuery: BorrowersQuery = (request, config = { axiosConfig: {} }) => {
	async function Borrowers(): Promise<Envelope<LoanBorrowers>> {
		const api = ApiCSGService();
		
		const path = '/api/Loans/Borrowers';

		const { data } = await api.get<Envelope<LoanBorrowers>>(path, {
			loanNumber: request.loanNumber,
		});

		return data;
	}

	return useQuery(['Borrowers', ...Object.values(request)], Borrowers, config.queryConfig);
};

useBorrowersQuery.key = 'Borrowers';

export default useBorrowersQuery;
