const colors = {
	primary: {
		light: '#02C58D',
		main: '#007F45',
		dark: '#028455',
	},
	secondary: {
		main: '#008C59',
	},
	action: {
		light: '#FF7E33',
		main: '#C34C17',
		dark: '#B24100',
	},
	success: {
		light: '#FF7E33',
		main: '#6BB69A',
		dark: '#B24100',
	},
	error: {
		light: '#D65E67',
		main: '#CC3641',
		dark: '#8E252D',
	},
	warning: {
		light: '#F9D58F',
		main: '#F8CB74',
		dark: '#AD8E51',
	},
	info: {
		light: '#A3A3A3',
		main: '#8D8D8D',
		dark: '#626262',
		darkAlt: '#707070',
	},
	infoAlt: {
		light: '#E7B185',
		main: '#DF9457',
		dark: '#A5764F',
	},
	background: {
		default: '#F8F9FA',
		darkPaper: '#E6E6E6',
		paper: '#FFFFFF',
		menu: '#485560',
		menuDark: '#353F48',
		alert: '#FCFCFC',
		form: '#efefef',
	},
	misc: {
		black: '#000000',
		lightGray: '#E0E0E0',
		white: '#ffffff',
	},
	text: {
		hint: 'rgba(0, 0, 0, 0.70)',
		primary: '#212529',
		secondary: 'rgba(0, 0, 0, 0.54)',
		disabled: 'rgba(0, 0, 0, 0.26)',
	},
	button: {
		secondary: '#EEEEEE',
	},
	findDealer: {
		main: '#007F45',
	},
	cancelButton: {
		main: '#007D46',
	},
	landingPage: {
		main: '#006638',
	},
	statementAction: {
		main: '#C34C17',
	},
};

export const flattenedColors = {
	// Primary
	primary: 'primary.main',
	'primary.light': 'primary.light',
	'primary.main': 'primary.main',
	'primary.dark': 'primary.dark',

	// Secondary
	secondary: 'secondary.main',
	'secondary.main': 'secondary.main',

	// Action
	action: 'action.main',
	'action.light': 'action.light',
	'action.main': 'action.main',
	'action.dark': 'action.dark',

	// Success
	success: 'success.main',
	'success.light': 'success.light',
	'success.main': 'success.main',
	'success.dark': 'success.dark',

	// Error
	error: 'error.main',
	'error.light': 'error.light',
	'error.main': 'error.main',
	'error.dark': 'error.dark',

	// Warning
	warning: 'warning.main',
	'warning.light': 'warning.light',
	'warning.main': 'warning.main',
	'warning.dark': 'warning.dark',

	// Info
	info: 'info.main',
	'info.light': 'info.light',
	'info.main': 'info.main',
	'info.dark': 'info.dark',
	'info.darkAlt': 'info.darkAlt',

	// Info Two
	infoAlt: 'infoAlt.main',
	'infoAlt.light': 'infoAlt.light',
	'infoAlt.main': 'infoAlt.main',
	'infoAlt.dark': 'infoAlt.dark',

	statementAction: 'statementAction.main',

	cancelButton: 'cancelButton.main',

	// Background
	'background.default': 'background.default',
	'background.paper': 'background.paper',
	'background.menu': 'background.menu',
	'background.menuDark': 'background.menuDark',
	'background.form': 'background.form',

	// Misc
	'misc.black': 'misc.black',
	'misc.white': 'misc.white',
	'misc.lightGray': 'misc.lightGray',

	// Text
	'text.hint': 'text.hint',
	'text.primary': 'text.primary',
	'text.secondary': 'text.secondary',
	'text.disabled': 'text.disabled',

	// Button
	'button.secondary': 'button.secondary',

	//Find Dealer
	'findDealer.main': 'findDealer.main',

	//Landing Page Icons
	'landingPage.main': 'landingPage.main',
};

export default colors;
