import React from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core';

import { Text, FlexBox, IconTooltip, LangText } from 'sfc-kit';

import { useMessages } from 'hooks';
import { CustomerPortalMessages } from 'types';
import classnames from 'classnames';
import colors from '../../../src/sfc-kit/src/constants/Colors';

export interface LoanSummaryTooltipTitleProps {
	message: LangText<CustomerPortalMessages>;
	tooltipTitle: LangText<CustomerPortalMessages>;
	tooltipMessage: LangText<CustomerPortalMessages>;
	ariaDescribedBy?: string;
	ariaId?: string;
	ariaLabel?: string;
}

const useStyles = makeStyles(theme =>
	createStyles({
		tooltip: {
			padding: 0,
			margin: `0 ${theme.spacing(1)}px`,
			'& svg path': {
				fill: colors.action.main,
			},
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const LoanSummaryTooltipTitle: React.FC<LoanSummaryTooltipTitleProps> = ({
	message,
	tooltipTitle,
	tooltipMessage,
	ariaId,
	ariaDescribedBy,
	ariaLabel,
}) => {
	const theme = useTheme();
	const styles = useStyles({} as any);
	const messages = useMessages();

	return (
		<FlexBox direction="row" justifyContent="flex-start">
			<Text variant="caption" inline messages={messages} message={message} />
			<IconTooltip
				ariaDescribedBy={ariaDescribedBy}
				ariaId={ariaId}
				hideCloseButton={true}
				hideIconInTooltip={true}
				ariaLabel={ariaLabel}
				variant="InformationAlternative"
				messages={messages}
				className={classnames(styles.tooltip, styles.focus)}
				tooltipProps={{ arrowDirection: 'down', maxWidth: 800, style: { zIndex: theme.zIndex.tooltip } }}>
				<Text variant="caption" weight="heavy" messages={messages} message={tooltipTitle} />
				<Text variant="caption" messages={messages} message={tooltipMessage} />
			</IconTooltip>
		</FlexBox>
	);
};

export default LoanSummaryTooltipTitle;
