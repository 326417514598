import classnames from 'classnames';
import React, { ReactElement, ElementType } from 'react';

import { Typography, TypographyProps, makeStyles, createStyles } from '@material-ui/core';

import {
	useSfcKitMessages,
	//
	ExtractColorFromKeyString,
	GenerateCasing,
	//
	Constants,
	//
	Color,
	Casing,
	LangText,
	FontWeight,
	Messages,
	SfcKitMessages,
	MessagesProps,
} from '../../..';

export interface TextProps<TMessages extends Messages = SfcKitMessages> extends Omit<TypographyProps, 'color' | 'display'>, MessagesProps<TMessages> {
	color?: Color;
	inline?: boolean;
	message?: LangText<TMessages>;
	casing?: Casing;
	weight?: FontWeight;
	component?: ElementType<any>;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: ({ color = 'text.primary', weight = 'medium', casing }: { color: Color; weight: FontWeight; casing?: Casing }) => {
			const extractedColor = ExtractColorFromKeyString(color);

			return { color: extractedColor, fontWeight: Constants.FontWeights[weight], ...GenerateCasing(casing) };
		},
	})
);

export default function Text<TMessages extends Messages = SfcKitMessages>({
	children,
	variant = 'body1',
	color = 'text.primary',
	weight = 'light',
	inline = false,
	message,
	casing,
	messages,
	className,
	component = 'span',
	...restProps
}: TextProps<TMessages>): ReactElement | null {
	const styles = useStyles({ color, weight, casing });

	const sfcKitMessages = useSfcKitMessages();

	const display = inline ? 'inline' : 'block';

	if (message && children) {
		return (
			<div>
				<Typography component={component} variant={variant} display={display} className={classnames(styles.root, className)} {...restProps}>
					{messages?.[message]}
				</Typography>
				{children}
			</div>
		);
	}

	return (
		<Typography component={component} variant={variant} display={display} className={classnames(styles.root, className)} {...restProps}>
			{message ? messages?.[message] ?? sfcKitMessages[message as keyof SfcKitMessages] : children}
		</Typography>
	);
}
