import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

import { WizardCard, WizardCardProps } from 'sfc-kit';
import { AlreadyHaveAnAccountPrompt } from 'components';

export interface RegistrationCardProps extends Omit<WizardCardProps, 'icon' | 'title' | 'sidebarContent' | 'sidebarFooter' | 'stepperProps'> {
	activeStep: number;
	subTitle: WizardCardProps['sidebarContent'];
	isCaptcha?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wizardCardRoot: {
			[theme.breakpoints.up('xl')]: {
				height: 600,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},

			maxWidth: 1200,
		},
		icon: {
			fontSize: '8rem',
		},
	})
);

const RegistrationCard: FunctionComponent<RegistrationCardProps> = ({ subTitle, classes = {}, activeStep, isCaptcha, ...props }) => {
	const styles = useStyles({} as any);

	return (
		<WizardCard
			stepperProps={{ id: 'registration-stepper', numSteps: 5, activeStep }}
			icon={GroupAddIcon}
			title="Registration"
			sidebarContent={subTitle}
			sidebarFooter={<AlreadyHaveAnAccountPrompt />}
			classes={{
				...classes,
				root: classnames(styles.wizardCardRoot, classes.root),
				icon: classnames(styles.icon, classes.icon),
			}}
			isCaptcha={isCaptcha}
			{...props}
		/>
	);
};

export default RegistrationCard;
