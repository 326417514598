import React from 'react';

import { Button } from 'sfc-kit';

import Paths from 'constants/Paths';
import { usePaginatedStatements } from 'hooks';
import { DashboardCard, StatementTable } from 'components';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
	createStyles({
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const DashboardStatements: React.FunctionComponent = () => {
	const classes = useStyles({} as any);
	const { resolvedData: paginatedStatements } = usePaginatedStatements();

	const totalStatements = paginatedStatements?.totalStatements ?? 0;

	return (
		<DashboardCard
			title="payments.statements"
			action={
				totalStatements > 5 && (
					<Button
						to={Paths.Statements}
						size="small"
						variant="text"
						textColor="action"
						casing="capitalizeEachWord"
						message="misc.viewAll"
						tabIndex={1}
						aria-label="View All Statements"
						className={classes.focus}
					/>
				)
			}>
			<StatementTable fixed initialStatementsPerPage={5} />
		</DashboardCard>
	);
};

export default DashboardStatements;
