import { useCallback, useContext } from 'react';
import { DateTime } from 'luxon';

import { useAuthenticateRenewMutation } from 'sfc-kit';

import useLogout from './useLogout';
import { AuthenticationActionTypes, AuthenticationContext } from 'context/Authentication';
import { parseAuthenticateResponse } from './utils';

export default function useRenewToken(): () => void {
	const logout = useLogout();
	const {
		authenticationState: { isAuthenticated, authToken, refreshToken, username, authTokenExpiration },
		dispatchForAuthentication,
	} = useContext(AuthenticationContext);

	const [renewToken] = useAuthenticateRenewMutation({
		mutationConfig: {
			onSuccess: resp => {
				const payload = parseAuthenticateResponse(resp);

				dispatchForAuthentication({ type: AuthenticationActionTypes.UpdateAuthState, payload });
			},
			onError: logout,
		},
	});

	return useCallback(() => {
		if (!isAuthenticated || !authToken) {
			logout();
			return;
		}

		const localDate = DateTime.local().plus({ minutes: 5 });
		const serverDate = DateTime.fromISO(authTokenExpiration ?? '');
		const expiredDate = localDate >= serverDate;

		if (isAuthenticated && (expiredDate || !username)) {
			renewToken({ body: { refreshToken } });
		}
	}, [isAuthenticated, authToken, authTokenExpiration, refreshToken, renewToken, logout, username]);
}
