import { AxiosResponse } from 'axios';
import ApiService, { ApiCSGService } from './ApiService';
import { AuthenticationSessionGet } from '../utils/BrowserStorage';
export default class CommonServicesGatewayApi {
	protected api: ApiService;

	constructor() {
		this.api = ApiCSGService();
	}

	public async AuthenticateBorrower(request: AuthenticateBorrowerRequestParameters): Promise<AxiosResponse<AuthenticateUserResponse>> {
		const path = '/api/Authentication/AuthenticateBorrower';

		return this.api.post<AuthenticateUserResponse>(path, request.body);
	}


	public async LogoutBorrower(request: LogoutBorrowerRequestParameters): Promise<AxiosResponse<string>> {
		return this.api.get<string>('/api/Authentication/LogoutBorrower', request.body);
	}

	public async AuthenticateDealer(request: AuthenticateDealerRequestParameters): Promise<AxiosResponse<AuthenticateDealerResponse>> {
		const path = '/api/Authentication/AuthenticateDealer';

		return this.api.post<AuthenticateDealerResponse>(path, request.body);
	}

	public async RenewDealerToken(request: RenewDealerTokenRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Authentication/RenewDealerToken';

		return this.api.post<string>(path, request.body);
	}

	public async LogoutDealer(request: LogoutDealerRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Authentication/LogoutDealer';

		return this.api.post<string>(path, request.body);
	}

	public async UpdateBorrowerPassword(request: UpdateBorrowerPasswordRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Authentication/UpdateBorrowerPassword';

		return this.api.patch<string>(path, request.body);
	}

	public async UpdateBorrowerSecurityQuestions(request: UpdateBorrowerSecurityQuestionsRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Authentication/UpdateBorrowerSecurityQuestions';

		return this.api.patch<string>(path, request.body);
	}

	public async UpdateBorrowerUsername(request: UpdateBorrowerUsernameRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Authentication/UpdateBorrowerUsername';

		return this.api.patch<string>(path, request.body);
	}

	public async VerifyCaptcha(request: VerifyCaptchaRequestParameters): Promise<AxiosResponse<VerifyCaptchaResponse>> {
		const path = '/api/Authentication/VerifyCaptcha';

		return this.api.post<VerifyCaptchaResponse>(path, request.body);
	}

	public async ValidateDealerCredentials(request: ValidateDealerCredentialsRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Authentication/ValidateDealerCredentials';

		return this.api.post<boolean>(path, request.body);
	}

	public async UnlockAccount(request: UnlockAccountRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Authentication/UnlockAccount';

		return this.api.post<boolean>(path, request.body);
	}

	public async CompleteEmailVerification(request: CompleteEmailVerificationRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Authentication/CompleteEmailVerification';

		return this.api.post<void>(path, request.body);
	}

	public async IsUserTaken(request: IsUserTakenRequestParameters): Promise<AxiosResponse<UsernameAvailability>> {
		const path = '/api/Authentication/IsUserTaken';

		return this.api.get<UsernameAvailability>(path, {
			loanNumber: request.loanNumber,
			username: request.username,
			existingUsername: request?.existingUsername,
		});
	}

	public async IsUserTakenPost(request: IsUserTakenPostRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Authentication/IsUserTakenPost';

		return this.api.post<boolean>(path, request);
	}

	public async GetDealerSecurityQuestions(request: GetDealerSecurityQuestionsRequestParameters): Promise<AxiosResponse<DealerSecurityQuestions>> {
		const path = '/api/Authentication/GetDealerSecurityQuestions';

		return this.api.get<DealerSecurityQuestions>(path, {
			request: request?.request,
		});
	}

	public async DealerCreateToken(request: DealerCreateTokenRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Authentication/DealerCreateToken';

		return this.api.post<string>(path, request.body);
	}

	public async DealerValidateCredentials(request: DealerValidateCredentialsRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Authentication/DealerValidateCredentials';

		return this.api.put<void>(path, request.body);
	}

	public async DealerCreateCredentials(request: DealerCreateCredentialsRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Authentication/DealerCreateCredentials';

		return this.api.post<void>(path, request.body);
	}

	public async BorrowerUsername(request: BorrowerUsernameRequestParameters): Promise<AxiosResponse<GetBorrowerUsernameResponse>> {
		const path = '/api/Borrower/BorrowerUsername';

		return this.api.get<GetBorrowerUsernameResponse>(path, {
			loanNumber: request.loanNumber,
			socialSecurityLastFour: request.socialSecurityLastFour,
		});
	}

	public async UpdateBorrowerShortName(request: UpdateBorrowerShortNameRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Borrower/UpdateBorrowerShortName';

		return this.api.patch<string>(path, request.body);
	}

	public async UpdateBorrowerPhoneNumber(request: UpdateBorrowerPhoneNumberRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Borrower/UpdateBorrowerPhoneNumber';

		return this.api.patch<string>(path, request.body);
	}

	public async UpdateBorrowerEmail(request: UpdateBorrowerEmailRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Borrower/UpdateBorrowerEmail';

		return this.api.patch<string>(path, request.body);
	}

	public async UpdateBorrowerAddress(request: UpdateBorrowerAddressRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Borrower/UpdateBorrowerAddress';

		return this.api.patch<string>(path, request.body);
	}

	public async VerifyBorrowerInfo(request: VerifyBorrowerInfoRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Borrower/VerifyBorrowerInfo';

		return this.api.post<boolean>(path, request.body);
	}

	public async LinkCoBorrowerToLoan(request: LinkCoBorrowerToLoanRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Borrower/LinkCoBorrowerToLoan';

		return this.api.post<string>(path, request.body);
	}

	public async GetBorrowerContact(request: GetBorrowerContactRequestParameters): Promise<AxiosResponse<ContactDto>> {
		const path = '/api/Borrower/GetBorrowerContact';
		return this.api.get<ContactDto>(path);
	}

	public async GetBorrowerForDealer(request: GetBorrowerForDealerRequestParameters): Promise<AxiosResponse<BorrowerForDealer>> {
		let path = '/api/Borrower/{borrowerId}/ForDealer';

		path = path.replace('{borrowerId}', request.borrowerId.toString());

		return this.api.get<BorrowerForDealer>(path);
	}

	public async VerifyBorrowerCoBorrower(request: VerifyBorrowerCoBorrowerRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Borrower/VerifyBorrowerCoBorrower';

		return this.api.post<void>(path, request.body);
	}

	public async GetCertificateOfCompletionDocumentForDealer(
		request: GetCertificateOfCompletionDocumentForDealerRequestParameters
	): Promise<AxiosResponse<COCDocumentForDealer>> {
		const path = '/api/CertificateOfCompletionDocuments/ForDealer';

		return this.api.get<COCDocumentForDealer>(path, {
			taskId: request?.taskId,
		});
	}

	public async GetCertificateOfCompletionDocumentPdf(
		request: GetCertificateOfCompletionDocumentPdfRequestParameters
	): Promise<AxiosResponse<FileDto>> {
		let path = '/api/CertificateOfCompletionDocuments/{documentId}/Pdf';

		path = path.replace('{documentId}', request.documentId.toString());

		return this.api.get<FileDto>(path);
	}

	public async Dealer(request: DealerRequestParameters): Promise<AxiosResponse<Dealer>> {
		const path = '/api/Dealer';

		return this.api.get<Dealer>(path);
	}

	public async ForgotDealerUsername(request: ForgotDealerUsernameRequestParameters): Promise<AxiosResponse<Envelope<string>>> {
		const path = '/api/Dealer/ForgotDealerUsername';

		return this.api.post<Envelope<string>>(path, request.body);
	}

	public async PaginatedDealerPipeline(request: PaginatedDealerPipelineRequestParameters): Promise<AxiosResponse<DealerPipeline>> {
		const path = '/api/Dealer/PaginatedDealerPipeline';

		return this.api.get<DealerPipeline>(path, {
			page: request?.page,
			take: request?.take,
			sort: request?.sort,
			loanNumber: request?.loanNumber,
			loanStatus: request?.loanStatus,
			borrowerName: request?.borrowerName,
			salesName: request?.salesName,
			pipelineStatus: request?.pipelineStatus,
			stipulationType: request?.stipulationType,
			borrowerSigned: request?.borrowerSigned,
			dealerSigned: request?.dealerSigned,
			stipulationRejected: request?.stipulationRejected,
			stipulationPending: request?.stipulationPending,
			stipulationCompleted: request?.stipulationCompleted,
			minimumLoanAmount: request?.minimumLoanAmount,
			maximumLoanAmount: request?.maximumLoanAmount,
			createdDateFrom: request?.createdDateFrom,
			createdDateTo: request?.createdDateTo,
		});
	}

	public async PricingMatrix(request: PricingMatrixRequestParameters): Promise<AxiosResponse<PricingMatrix>> {
		const path = '/api/Dealer/PricingMatrix';

		return this.api.get<PricingMatrix>(path);
	}

	public async PipelineDetails(request: PipelineDetailsRequestParameters): Promise<AxiosResponse<PipelineDetails>> {
		const path = '/api/Dealer/DealerPipeline/Details';

		return this.api.get<PipelineDetails>(path, {
			taskId: request.taskId,
		});
	}

	public async GetProgressPaymentInfo(request: GetProgressPaymentInfoRequestParameters): Promise<AxiosResponse<ProgressPaymentForDealer>> {
		const path = '/api/Dealer/GetProgressPaymentInfo';

		return this.api.get<ProgressPaymentForDealer>(path, {
			taskId: request?.taskId,
		});
	}

	public async GetLeadProducts(): Promise<AxiosResponse<Array<LeadProduct>>> {
		const path = '/api/Dealer/GetLeadProducts';

		return this.api.get<Array<LeadProduct>>(path);
	}

	public async DealerPipelineReport(request: DealerPipelineReportRequestParameters): Promise<AxiosResponse<PipelineReport>> {
		const path = '/api/Dealer/DealerPipelineReport';

		return this.api.get<PipelineReport>(path, {
			authToken: request?.authorization,
			currentPage: request?.currentPage,
			pageSize: request?.pageSize,
			search: request?.search,
		});
	}

	public async GetDealerInfo(request: GetDealerInfoRequestParameters): Promise<AxiosResponse<DealerInfo>> {
		const path = '/api/Dealer/GetDealerInfo';

		return this.api.get<DealerInfo>(path);
	}

	public async GetActiveFinancePrograms(request: GetActiveFinanceProgramsRequestParameters): Promise<AxiosResponse<Array<ActiveFinanceProgram>>> {
		const path = '/api/Dealer/GetActiveFinancePrograms';

		return this.api.get<Array<ActiveFinanceProgram>>(path, {
			dealerNumber: request?.dealerNumber,
		});
	}

	public async GetCreditInformation(request: GetCreditInformationRequestParameters): Promise<AxiosResponse<CreditInformation>> {
		const path = '/api/Dealer/GetCreditInformation';

		return this.api.get<CreditInformation>(path, {
			authToken: request?.authToken,
		});
	}

	public async GetSendToSignAddresses(request: GetSendToSignAddressesRequestParameters): Promise<AxiosResponse<SendToSignAddresses>> {
		const path = '/api/Dealer/GetSendToSignAddresses';

		return this.api.get<SendToSignAddresses>(path, {
			taskId: request?.taskId,
		});
	}

	public async GetFinanceProgramForDealer(request: GetFinanceProgramForDealerRequestParameters): Promise<AxiosResponse<FinanceProgramForDealer>> {
		const path = '/api/Dealer/GetFinanceProgramForDealer';

		return this.api.get<FinanceProgramForDealer>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});
	}

	public async GetRecalculatedFinanceProgramForDealer(
		request: GetRecalculatedFinanceProgramForDealerRequestParameters
	): Promise<AxiosResponse<GetRecalculatedFinanceProgramResponse>> {
		const path = '/api/Dealer/GetRecalculatedFinanceProgramForDealer';

		return this.api.post<GetRecalculatedFinanceProgramResponse>(path, {
			contractAmount: request?.contractAmount,
			financeTypeId: request?.financeTypeId,
			username: request?.username,
			loanNumber: request?.loanNumber,
			taskId: request?.taskId,
		});
	}

	public async DealerESignEncode(request: DealerESignEncodeRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Dealer/DealerESignEncode';

		return this.api.post<string>(path, {
			taskId: request?.taskId,
		});
	}

	public async GetPropertyAddress(request: GetPropertyAddressRequestParameters): Promise<AxiosResponse<PropertyAddress>> {
		const path = '/api/Dealer/GetPropertyAddress';

		return this.api.get<PropertyAddress>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});
	}

	public async GetDealerFundingReport(request: GetDealerFundingReportRequestParameters): Promise<AxiosResponse<GetDealerFundingReportResponse>> {
		const path = '/api/Dealer/GetDealerFundingReport';

		return this.api.post<GetDealerFundingReportResponse>(path, request.body);
	}

	public async GetDealerExportFundingReport(request: GetDealerExportFundingReportRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Dealer/GetDealerExportFundingReport';

		return this.api.post<string>(path, request.body);
	}

	public async Version(): Promise<AxiosResponse<void>> {
		const path = '/api/Dev/Version';

		return this.api.get<void>(path);
	}

	public async DeserializeToken(request: DeserializeTokenRequestParameters): Promise<AxiosResponse<void>> {
		let path = '/api/Dev/DeserializeToken/{token}';

		path = path.replace('{token}', request.token.toString());

		return this.api.post<void>(path);
	}

	public async SendBorrowerRegistrationEmail(request: SendBorrowerRegistrationEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendBorrowerRegistrationEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendDealerRegistrationEmail(request: SendDealerRegistrationEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendDealerRegistrationEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendNewUserNotificationEmail(request: SendNewUserNotificationEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendNewUserNotificationEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendPasswordUpdatedEmail(request: SendPasswordUpdatedEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendPasswordUpdatedEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendContactUsEmail(request: SendContactUsEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendContactUsEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendDealerContactUsEmail(request: SendDealerContactUsEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendDealerContactUsEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendAccountNotFoundEmail(request: SendAccountNotFoundEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendAccountNotFoundEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendPaymentDeletedEmail(request: SendPaymentDeletedEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendPaymentDeletedEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendPaymentSubmittedEmail(request: SendPaymentSubmittedEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendPaymentSubmittedEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendLoanDocumentsMissingEmail(request: SendLoanDocumentsMissingEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendLoanDocumentsMissingEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendLoanStatementsMissingEmail(request: SendLoanStatementsMissingEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendLoanStatementsMissingEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendUsernameUpdatedEmail(request: SendUsernameUpdatedEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendUsernameUpdatedEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendBorrowerAddressUpdatedEmail(request: SendBorrowerAddressUpdatedEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendBorrowerAddressUpdatedEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendVerifyNewUsernameEmail(request: SendVerifyNewUsernameEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendVerifyNewUsernameEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendOnboardingVerificationEmail(request: SendOnboardingVerificationEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendOnboardingVerificationEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendBorrowerPasswordRecoveryEmail(request: SendBorrowerPasswordRecoveryEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendBorrowerPasswordRecoveryEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendDealerPasswordRecoveryEmail(request: SendDealerPasswordRecoveryEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendDealerPasswordRecoveryEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendPaymentExpeditedEmail(request: SendPaymentExpeditedEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendPaymentExpeditedEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendExistingUserEmail(request: SendExistingUserEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendExistingUserEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendFailedStipulationUpload(request: SendFailedStipulationUploadRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendFailedStipulationUpload';

		return this.api.post<boolean>(path, request.body);
	}

	public async SendNewDealerUserNotificationEmail(request: SendNewDealerUserNotificationEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Email/SendNewDealerUserNotificationEmail';

		return this.api.post<boolean>(path, request.body);
	}

	public async GetInitialDocuments(request: GetInitialDocumentsRequestParameters): Promise<AxiosResponse<Array<InitialDocument>>> {
		const path = '/api/InitialDocuments';

		return this.api.get<Array<InitialDocument>>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});
	}

	public async GetActiveInitialDocument(request: GetActiveInitialDocumentRequestParameters): Promise<AxiosResponse<InitialDocument>> {
		const path = '/api/InitialDocuments/Active';

		return this.api.get<InitialDocument>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});
	}

	public async GetInitialDocumentPdf(request: GetInitialDocumentPdfRequestParameters): Promise<AxiosResponse<FileDto>> {
		let path = '/api/InitialDocuments/{documentId}/Pdf';

		path = path.replace('{documentId}', request.documentId.toString());

		return this.api.get<FileDto>(path, {
			nLSDocumentId: request?.nLSDocumentId,
			loanNumber: request?.loanNumber,
			taskId: request?.taskId,
		});
	}

	public async GetInitialDocumentsForDealer(
		request: GetInitialDocumentsForDealerRequestParameters
	): Promise<AxiosResponse<InitialDocumentForDealer>> {
		const path = '/api/InitialDocuments/ForDealer';

		return this.api.get<InitialDocumentForDealer>(path, {
			taskId: request?.taskId,
		});
	}

	public async SendToSignEmail(request: SendToSignEmailRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/InitialDocuments/SendToSign';

		return this.api.put<void>(path, request.body);
	}

	public async InitialDocumentUpload(request: InitialDocumentUploadRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/InitialDocuments/InitialDocumentUpload';

		return this.api.post<void>(path, request.body);
	}

	public async PaperlessStatus(request: PaperlessStatusRequestParameters): Promise<AxiosResponse<Envelope<boolean>>> {
		const path = '/api/LoanCommunication/PaperlessStatus';

		return this.api.get<Envelope<boolean>>(path, {
			username: request.username,
			loanNumber: request.loanNumber,
		});
	}

	public async PaperlessOptIn(request: PaperlessOptInRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/LoanCommunication/PaperlessOptIn';

		return this.api.post<void>(path, request.body);
	}

	public async PaperlessOptOut(request: PaperlessOptOutRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/LoanCommunication/PaperlessOptOut';

		return this.api.post<void>(path, request.body);
	}

	public async AddAddresses(request: AddAddressesRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/LoanCommunication/AddAddresses';

		return this.api.post<void>(path, request.body);
	}

	public async RemoveAddresses(request: RemoveAddressesRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/LoanCommunication/RemoveAddresses';

		return this.api.delete(path, request.body);
	}

	public async ReplaceAddress(request: ReplaceAddressRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/LoanCommunication/ReplaceAddress';

		return this.api.put<void>(path, request.body);
	}

	public async GetPaperlessAddresses(
		request: GetPaperlessAddressesRequestParameters
	): Promise<AxiosResponse<Envelope<GetCommunicationAddressResponse>>> {
		const path = '/api/LoanCommunication/GetPaperlessAddresses';

		return this.api.get<Envelope<GetCommunicationAddressResponse>>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async Document(request: DocumentRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Loans/Document';

		return this.api.get<string>(path, {
			documentId: request?.documentId,
		});
	}

	public async LoanDocuments(request: LoanDocumentsRequestParameters): Promise<AxiosResponse<GetLoanDocumentsResponse>> {
		const path = '/api/Loans/LoanDocuments';

		return this.api.get<GetLoanDocumentsResponse>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async AddLoanComment(request: AddLoanCommentRequestParameters): Promise<AxiosResponse<Envelope<string>>> {
		const path = '/api/Loans/AddLoanComment';

		return this.api.post<Envelope<string>>(path, request.body);
	}

	public async DealerInformation(request: DealerInformationRequestParameters): Promise<AxiosResponse<Envelope<LoanDealerInformation>>> {
		const path = '/api/Loans/DealerInformation';

		return this.api.get<Envelope<LoanDealerInformation>>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async Borrowers(request: BorrowersRequestParameters): Promise<AxiosResponse<Envelope<LoanBorrowers>>> {
		const path = '/api/Loans/Borrowers';

		return this.api.get<Envelope<LoanBorrowers>>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async GetLoanSoldDate(request: GetLoanSoldDateRequestParameters): Promise<AxiosResponse<Envelope<string>>> {
		const path = '/api/Loans/GetLoanSoldDate';

		return this.api.get<Envelope<string>>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async Loans(request: LoansRequestParameters): Promise<AxiosResponse<Array<Loan>>> {
		const path = '/api/Loans';

		return this.api.get<Array<Loan>>(path, {
			includeRelatedLoans: request?.includeRelatedLoans,
		});
	}

	public async LoanDetails(request: LoanDetailsRequestParameters): Promise<AxiosResponse<LoanDetails>> {
		let path = '/api/Loans/{loanNumber}/LoanDetails';

		path = path.replace('{loanNumber}', request.loanNumber.toString());

		return this.api.get<LoanDetails>(path);
	}

	public async LoanDetailUdfs(request: LoanDetailUdfsRequestParameters): Promise<AxiosResponse<LoanDetailUdfs>> {
		const path = `/api/Loans/${request.loanNumber}/LoanDetailUdfs`;

		return this.api.get<LoanDetailUdfs>(path);
	}

	public async LoanDetailOneUdf(request: LoanDetailOneUdfRequestParameters): Promise<AxiosResponse<string>> {
		const { loanNumber, udfNumber } = request;
		const path = `/api/Loans/${loanNumber}/LoanDetailOneUdf/${udfNumber}`;

		return this.api.get<string>(path);
	}

	public async LoanDetailTwoUdf(request: LoanDetailTwoUdfRequestParameters): Promise<AxiosResponse<string>> {
		const { loanNumber, udfNumber } = request;
		const path = `/api/Loans/${loanNumber}/LoanDetailTwoUdf/${udfNumber}`;

		return this.api.get<string>(path);
	}

	public async LockboxAddresses(request: LockboxAddressesRequestParameters): Promise<AxiosResponse<LockboxAddresses>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/LockboxAddresses`;

		return this.api.get<LockboxAddresses>(path);
	}

	public async LoanPromotion(request: LoanPromotionRequestParameters): Promise<AxiosResponse<LoanPromotion>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/LoanPromotion`;

		return this.api.get<LoanPromotion>(path);
	}

	public async LoanSummary(request: LoanSummaryRequestParameters): Promise<AxiosResponse<LoanSummary>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/LoanSummary`;

		return this.api.get<LoanSummary>(path);
	}

	public async PaginatedHistoricalPayments(
		request: PaginatedHistoricalPaymentsRequestParameters
	): Promise<AxiosResponse<CombinedHistoricalPaymentCollection>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/PaginatedHistoricalPayments`;

		return this.api.get<CombinedHistoricalPaymentCollection>(path, {
			skip: request?.skip,
			take: request?.take,
			sort: request?.sort,
		});
	}

	public async LoanOverview(request: LoanOverviewRequestParameters): Promise<AxiosResponse<LoanOverview>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/LoanOverview`;

		return this.api.get<LoanOverview>(path);
	}

	public async PayoffProgress(request: PayoffProgressRequestParameters): Promise<AxiosResponse<PayoffProgress>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/PayoffProgress`;

		return this.api.get<PayoffProgress>(path);
	}

	public async StatementPdf(request: StatementPdfRequestParameters): Promise<AxiosResponse<FileDto>> {
		const { loanNumber, documentId } = request;
		const path = `/api/Loans/${loanNumber}/StatementPdf/${documentId}`;

		return this.api.get<FileDto>(path);
	}

	public async Statements(request: StatementsRequestParameters): Promise<AxiosResponse<Array<Statement>>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/Statements`;

		return this.api.get<Array<Statement>>(path);
	}

	public async PaginatedStatements(request: PaginatedStatementsRequestParameters): Promise<AxiosResponse<PaginatedStatements>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/PaginatedStatements`;

		return this.api.get<PaginatedStatements>(path, {
			page: request.page,
			statementsPerPage: request.statementsPerPage,
			startDate: request?.startDate,
			endDate: request?.endDate,
		});
	}

	public async LoanNickname(request: LoanNicknameRequestParameters): Promise<AxiosResponse<string>> {
		const { loanNumber } = request;
		const path = `/api/Loans/${loanNumber}/Nickname`;

		return this.api.get<string>(path);
	}

	public async UpdateLoanNickname(request: UpdateLoanNicknameRequestParameters): Promise<AxiosResponse<void>> {
		const { loanNumber, nickname } = request;
		const path = `/api/Loans/${loanNumber}/Nickname/${nickname}`;

		return this.api.patch<void>(path);
	}

	public async FindDealer(request: FindDealerRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Loans/FindDealer';

		return this.api.post<string>(path, request.body);
	}

	public async GetLogs(request: GetLogsRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Log/GetLogs';

		return this.api.get<void>(path, {
			logEventOrigin: request?.logEventOrigin,
			date: request?.date,
		});
	}

	public async Log(request: LogRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Log';

		return this.api.post<void>(path, request.body);
	}

	public async GetSecurityQuestionsByToken(request: GetSecurityQuestionsByTokenRequestParameters): Promise<AxiosResponse<SecurityQuestionsDto>> {
		const path = '/api/PasswordRecovery/GetSecurityQuestionsByToken';

		return this.api.post<SecurityQuestionsDto>(path, request.body);
	}

	public async GetSecurityQuestionsByUsername(
		request: GetSecurityQuestionsByUsernameRequestParameters
	): Promise<AxiosResponse<SecurityQuestionsDto>> {
		const path = '/api/PasswordRecovery/GetSecurityQuestionsByUsername';

		return this.api.post<SecurityQuestionsDto>(path, request.body);
	}

	public async ValidateAnswers(request: ValidateAnswersRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/PasswordRecovery/ValidateAnswers';

		return this.api.post<string>(path, request.body);
	}

	public async ValidateAnswersWithToken(request: ValidateAnswersWithTokenRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/PasswordRecovery/ValidateAnswersWithToken';

		return this.api.post<string>(path, request.body);
	}

	public async ForceChangePassword(request: ForceChangePasswordRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/PasswordRecovery/ForceChangePassword';

		return this.api.patch<string>(path, request.body);
	}

	public async ForceChangePasswordWithToken(request: ForceChangePasswordWithTokenRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/PasswordRecovery/ForceChangePasswordWithToken';

		return this.api.patch<string>(path, request.body);
	}

	public async HolidayDays(request: HolidayDaysRequestParameters): Promise<AxiosResponse<Envelope<SfcHolidaysDto>>> {
		const path = '/api/Payment/HolidayDays';

		return this.api.get<Envelope<SfcHolidaysDto>>(path, {
			start: request?.start,
			end: request?.end,
			state: request?.state,
		});
	}

	public async GetPayoffAmount(request: GetPayoffAmountRequestParameters): Promise<AxiosResponse<Envelope<string>>> {
		const path = '/api/Payment/GetPayoffAmount';

		return this.api.get<Envelope<string>>(path, {
			loanNumber: request?.loanNumber,
			loanAccountId: request?.loanAccountId,
			payoffDate: request?.payoffDate,
		});
	}

	public async GetPayoffLetter(request: GetPayoffLetterRequestParameters): Promise<AxiosResponse<FileDto>> {
		const path = '/api/Payment/GetPayoffLetter';

		return this.api.get<FileDto>(path, {
			loanNumber: request?.loanNumber,
			taskId: request?.taskId,
			payoffDate: request?.payoffDate,
		});
	}

	public async ScheduledAchPayments(request: ScheduledAchPaymentsRequestParameters): Promise<AxiosResponse<Array<ScheduledAchPayment>>> {
		const path = '/api/Payment/ScheduledAchPayments';

		return this.api.get<Array<ScheduledAchPayment>>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async ScheduledAchPaymentCollection(
		request: ScheduledAchPaymentCollectionRequestParameters
	): Promise<AxiosResponse<ScheduledAchPaymentCollection>> {
		const path = '/api/Payment/ScheduledAchPaymentCollection';

		return this.api.get<ScheduledAchPaymentCollection>(path, {
			loanNumber: request?.loanNumber,
			skip: request?.skip,
			take: request?.take,
			sort: request?.sort,
		});
	}

	public async ScheduleAchPayment(request: ScheduleAchPaymentRequestParameters): Promise<AxiosResponse<ScheduleAchPaymentResponse>> {
		const path = '/api/Payment/ScheduleAchPayment';

		return this.api.post<ScheduleAchPaymentResponse>(path, request.body);
	}

	public async DeleteScheduledAchPayment(request: DeleteScheduledAchPaymentRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Payment/DeleteScheduledAchPayment';

		return this.api.delete(path, request.body);
	}

	public async ExpediteScheduledAchPayment(request: ExpediteScheduledAchPaymentRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Payment/ExpediteScheduledAchPayment';

		return this.api.put<boolean>(path, request.body);
	}

	public async UpdateScheduledAchPayment(request: UpdateScheduledAchPaymentRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Payment/UpdateScheduledAchPayment';

		return this.api.put<void>(path, request.body);
	}

	public async ValidatePaymentDate(request: ValidatePaymentDateRequestParameters): Promise<AxiosResponse<ValidatePaymentDateResponse>> {
		const path = '/api/Payment/ValidatePaymentDate';

		return this.api.post<ValidatePaymentDateResponse>(path, request.body);
	}

	public async GetNextValidPaymentDate(request: GetNextValidPaymentDateRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/Payment/GetNextValidPaymentDate';

		return this.api.get<string>(path, {
			paymentDate: request?.paymentDate,
		});
	}

	public async WillExceedAmountDueByDate(request: WillExceedAmountDueByDateRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Payment/WillExceedAmountDueByDate';

		return this.api.post<boolean>(path, request.body);
	}

	public async GetBankHistory(request: GetBankHistoryRequestParameters): Promise<AxiosResponse<Envelope<Array<LoanAccountAchDto>>>> {
		const path = '/api/Payment/GetBankHistory';

		return this.api.get<Envelope<Array<LoanAccountAchDto>>>(path, {
			loanNumber: request?.loanNumber,
		});
	}

	public async ACHValidate(request: ACHValidateRequestParameters): Promise<AxiosResponse<ACHValidate>> {
		const path = '/api/Payment/ACHValidate';

		return this.api.get<ACHValidate>(path, {
			routingNumber: request?.routingNumber,
			accountNumber: request?.accountNumber,
			accountType: request?.accountType,
		});
	}

	public async VerifyBorrower(request: VerifyBorrowerRequestParameters): Promise<AxiosResponse<VerifyBorrower>> {
		const path = '/api/Registration/VerifyBorrower';

		return this.api.post<VerifyBorrower>(path, request.body);
	}

	public async VerifyBorrowerEmail(request: VerifyBorrowerEmailRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Borrower/Verify';

		return this.api.post<boolean>(path, request.body);
	}

	public async CreateBorrowerCredentials(request: CreateBorrowerCredentialsRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Registration/CreateBorrowerCredentials';

		return this.api.post<boolean>(path, request.body);
	}

	public async VerifyDealer(request: VerifyDealerRequestParameters): Promise<AxiosResponse<Envelope<string>>> {
		const path = '/api/Registration/VerifyDealer';

		return this.api.post<Envelope<string>>(path, request.body);
	}

	public async CreateDealerCredentials(request: CreateDealerCredentialsRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Registration/CreateDealerCredentials';

		return this.api.post<void>(path, request.body);
	}

	public async CreateSalesPerson(request: CreateSalesPersonRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/SalesPeople';

		return this.api.post<string>(path, request.body);
	}

	public async GetSalesPersonForDealer(request: GetSalesPersonForDealerRequestParameters): Promise<AxiosResponse<SalesPersonForDealer>> {
		const { taskId } = request;
		const path = `/api/SalesPeople/ForDealer/${taskId}`;

		return this.api.get<SalesPersonForDealer>(path);
	}

	public async GetStipulations(request: GetStipulationsRequestParameters): Promise<AxiosResponse<Array<Stipulation>>> {
		const path = '/api/Stipulations/GetStipulations';

		return this.api.get<Array<Stipulation>>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});
	}

	public async GetStipulationComments(request: GetStipulationCommentsRequestParameters): Promise<AxiosResponse<StipulationComments>> {
		const path = '/api/Stipulations/GetStipulationComments';

		return this.api.get<StipulationComments>(path, {
			taskId: request?.taskId,
			categoryId: request?.categoryId,
		});
	}

	public async GetStipulationPdf(request: GetStipulationPdfRequestParameters): Promise<AxiosResponse<FileDto>> {
		const path = '/api/Stipulations/GetStipulationPdf';

		return this.api.get<FileDto>(path, {
			documentId: request?.taskId,
		});
	}

	public async UploadStipulation(request: UploadStipulationRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Stipulations/UploadStipulation';

		return this.api.post<void>(path, request.body);
	}

	public async ViewStipulationDocument(request: ViewStipulationDocumentRequestParameters): Promise<AxiosResponse<ViewStipulationDocument>> {
		const path = '/api/Stipulations/ViewStipulationDocument';

		return this.api.get<ViewStipulationDocument>(path, {
			taskId: request?.taskId,
			documentId: request?.taskId,
			categoryId: request?.categoryId,
		});
	}

	public async GetTaskRelationships(request: GetTaskRelationshipsRequestParameters): Promise<AxiosResponse<TaskRelationships>> {
		const { taskId } = request;
		const path = `/api/Tasks/${taskId}/Relationships`;

		return this.api.get<TaskRelationships>(path);
	}

	public async GetTaskForDealer(request: GetTaskForDealerRequestParameters): Promise<AxiosResponse<TaskForDealer>> {
		const path = '/api/Tasks/ForDealer';

		return this.api.get<TaskForDealer>(path, {
			taskId: request?.taskId,
			loanNumber: request?.loanNumber,
		});
	}

	public async UpdateReferenceNumber(request: UpdateReferenceNumberRequestParameters): Promise<AxiosResponse<Envelope<string>>> {
		const path = '/api/Tasks/UpdateReferenceNumber';

		return this.api.post<Envelope<string>>(path, request.body);
	}

	public async RecalculateLoan(request: RecalculateLoanRequestParameters): Promise<AxiosResponse<Envelope<RecalculateLoanResponse>>> {
		const path = '/api/Tasks/RecalculateLoan';

		return this.api.put<Envelope<RecalculateLoanResponse>>(path, request.body);
	}

	public async ValidateAddress(request: ValidateAddressRequestParameters): Promise<AxiosResponse<Array<Address>>> {
		const path = '/api/ThirdParty/ValidateAddress';

		return this.api.post<Array<Address>>(path, request.body);
	}

	public async GetBankName(request: GetBankNameRequestParameters): Promise<AxiosResponse<string>> {
		const path = '/api/ThirdParty/GetBankName';

		return this.api.get<string>(path, {
			routingNumber: request?.routingNumber,
		});
	}

	public async User(request: UserRequestParameters): Promise<AxiosResponse<User>> {
		const path = '/api/Users';

		return this.api.get<User>(path, {
			customerNumber: request?.customerNumber,
			username: request?.username,
		});
	}

	public async IsEmailVerified(request: IsEmailVerifiedRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Users/EmailVerified';

		return this.api.get<boolean>(path, {
			username: request.username,
		});
	}

	public async UpdateEmailVerified(request: UpdateEmailVerifiedRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Users/EmailVerified';

		return this.api.patch<void>(path, request.body);
	}

	public async ChangeUsername(request: ChangeUsernameRequestParameters): Promise<AxiosResponse<void>> {
		const path = '/api/Users/Username';

		return this.api.patch<void>(path, request.body);
	}

	public async IsUser(request: IsUserRequestParameters): Promise<AxiosResponse<boolean>> {
		const path = '/api/Users/IsUser';

		return this.api.get<boolean>(path, {
			email: request?.email,
		});
	}

	public async IsUsernameVerifiedEmail(request: IsUsernameVerifiedEmailRequestParameters): Promise<AxiosResponse<IsUsernameVerifiedEmailResponse>> {
		const path = '/api/Users/IsUsernameVerifiedEmail';

		return this.api.put<IsUsernameVerifiedEmailResponse>(path, request.body);
	}

	public async AuthenticationAuthenticate(request: AuthenticateRequestParameters): Promise<AxiosResponse<AuthenticationAuthenticateResponse>> {
		const path = '/api/Authentication/Authenticate';

		const client = this.api;
		client.clearTokens();

		return client.post<AuthenticationAuthenticateResponse>(path, request.body);
	}

	public async AuthenticationRenewToken(): Promise<AxiosResponse<AuthenticationAuthenticateResponse>> {
		const path = '/api/Authentication/RenewToken';

		const { refreshToken = '' } = AuthenticationSessionGet();

		const payload = {
			refreshToken,
		};

		const client = this.api;
		client.clearOtherToken();

		return client.post<AuthenticationAuthenticateResponse>(path, payload);
	}

	public async AuthenticationLogout(): Promise<AxiosResponse<boolean>> {
		const path = '/api/Authentication/Logout';

		const { refreshToken } = AuthenticationSessionGet();


		return this.api.post<boolean>(path, { refreshToken });
	}
}
