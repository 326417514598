import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

interface ImageProps {
	/** The source of the image to be displayed. */
	src: string;

	/** The alternative if the image failes to load. */
	alt?: string;

	/** Any styles that should be applied to the image. */
	className?: string;

	hidden?: boolean;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			height: 'auto',
			maxWidth: '100%',
		},
	})
);

const Image: React.FC<ImageProps> = ({ src, alt, className, hidden = false }) => {
	const classes = useStyles({} as any);

	return <img src={src} aria-hidden={hidden} alt={alt} className={`${classes.root} ${className}`}></img>;
};

export default Image;
