import React, { useContext, useEffect } from 'react';
import { DateTime } from 'luxon';

import { Skeleton } from '@material-ui/lab';
import { makeStyles, createStyles } from '@material-ui/core';

import { DateTimeUtils, FlexBox } from 'sfc-kit';
import NumberUtility from 'sfc-kit/src/utils/NumberUtility';

import { SummaryItem } from 'components';
import { useSelectedLoanSummaryQuery } from 'hooks';
import LoanSummaryTooltipTitle from './LoanSummaryTooltipTitle';
import { LoanContext } from 'context';

const useStyles = makeStyles(theme =>
	createStyles({
		container: {
			marginLeft: 0,
			padding: 5,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
	})
);

const DashboardTitle: React.FC = () => {
	const classes = useStyles({} as any);
	const { selectedLoan, selectLoan } = useContext(LoanContext);
	const [isMobileView, setIsMobileView] = React.useState<boolean>(false);

	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);
	useEffect(() => {
		const sessionItem = sessionStorage.getItem('LoanToken');

		if (sessionItem !== null && sessionItem !== selectedLoan?.loanNumber) {
			selectLoan(sessionItem ?? selectedLoan?.loanNumber);
			sessionStorage.setItem('LoanToken', sessionItem ?? selectedLoan?.loanNumber.toString());
		}
	}, [selectedLoan?.loanNumber, selectLoan]);

	const { isLoading, data: loanSummary } = useSelectedLoanSummaryQuery();

	if (isLoading) {
		return (
			<FlexBox className={classes.container} justifyContent="space-between">
				<SummaryItem title="Payment Due">
					<Skeleton animation="wave" />
				</SummaryItem>
				<SummaryItem title="Payment Due Date">
					<Skeleton animation="wave" />
				</SummaryItem>
				<SummaryItem title="Monthly Payment">
					<Skeleton animation="wave" />
				</SummaryItem>
				<SummaryItem
					title={
						!isMobileView ? (
							<LoanSummaryTooltipTitle
								ariaDescribedBy="balanceRemaningTooltip"
								ariaLabel="Select for more information on the Principal balance remaining"
								ariaId="balanceRemaningTooltip"
								message="loan.principalBalanceRemaining"
								tooltipTitle="loan.withPayDeferredInterestTitle"
								tooltipMessage="loan.withPayDeferredInterestInfo"
							/>
						) : (
							'loan.principalBalanceRemaining'
						)
					}>
					<Skeleton animation="wave" />
				</SummaryItem>
				<SummaryItem
					title={
						!isMobileView ? (
							<LoanSummaryTooltipTitle
								ariaDescribedBy="paymentsRemainingTooltip"
								ariaLabel="Select for more information on the Scheduled Payments Remaining"
								ariaId="paymentsRemainingTooltip"
								message="loan.scheduledPaymentsRemaining"
								tooltipTitle="loan.scheduledPaymentsRemaining"
								tooltipMessage="loan.scheduledPaymentsDisclaimer"
							/>
						) : (
							'loan.scheduledPaymentsRemaining'
						)
					}>
					<Skeleton animation="wave" />
				</SummaryItem>
			</FlexBox>
		);
	}

	if (loanSummary === undefined) {
		return null;
	}

	return (
		<FlexBox className={classes.container} justifyContent="space-between">
			<SummaryItem title="Payment Due">${NumberUtility.formatCurrency(loanSummary.amountDue)}</SummaryItem>
			<SummaryItem title="Payment Due Date">
				{DateTimeUtils.TryFromISO(selectedLoan?.heimdalInformation?.currentPaymentDate)?.toLocaleString(DateTime.DATE_SHORT) ?? 'N/A'}
			</SummaryItem>
			<SummaryItem title="Monthly Payment">${NumberUtility.formatCurrency(loanSummary.monthlyPayment)}</SummaryItem>
			<SummaryItem
				title={
					!isMobileView ? (
						<LoanSummaryTooltipTitle
							ariaDescribedBy="balanceRemaningTooltip"
							ariaLabel="Select for more information on the Principal balance remaining"
							ariaId="balanceRemaningTooltip"
							message="loan.principalBalanceRemaining"
							tooltipTitle="loan.withPayDeferredInterestTitle"
							tooltipMessage="loan.withPayDeferredInterestInfo"
						/>
					) : (
						'Principal Balance Remaining'
					)
				}>
				${NumberUtility.formatCurrency(loanSummary.balanceRemaining)}
			</SummaryItem>
			<SummaryItem
				title={
					!isMobileView ? (
						<LoanSummaryTooltipTitle
							ariaDescribedBy="paymentsRemainingTooltip"
							ariaLabel="Select for more information on the Scheduled Payments Remaining"
							ariaId="paymentsRemainingTooltip"
							message="loan.scheduledPaymentsRemaining"
							tooltipTitle="loan.scheduledPaymentsRemaining"
							tooltipMessage="loan.scheduledPaymentsDisclaimer"
						/>
					) : (
						'Scheduled Payments Remaining'
					)
				}>
				{loanSummary.paymentsRemaining}
			</SummaryItem>
		</FlexBox>
	);
};

export default DashboardTitle;
