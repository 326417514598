import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type AuthenticateBorrowerResult = MutationResultPair<
	AuthenticateUserResponse,
	AxiosError<AuthenticateUserResponse>,
	AuthenticateBorrowerRequestParameters,
	unknown
>;

export type AuthenticateBorrowerConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<AuthenticateUserResponse, AxiosError<AuthenticateUserResponse>, AuthenticateBorrowerRequestParameters>;
};

export default function useAuthenticateBorrowerMutation(config: AuthenticateBorrowerConfig = { axiosConfig: {} }): AuthenticateBorrowerResult {
	async function AuthenticateBorrower(request: AuthenticateBorrowerRequestParameters): Promise<AuthenticateUserResponse> {
		const api = ApiCSGService();
		
		api.clearOtherToken();

		const path = '/api/Authentication/AuthenticateBorrower';

		const { data } = await api.post<AuthenticateUserResponse>(path, request.body);

		return data;
	}

	return useMutation(AuthenticateBorrower, config.mutationConfig);
}
