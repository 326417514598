import { DateTime } from 'luxon';
import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';

import FindInPageIcon from '@material-ui/icons/FindInPage';
import { Hidden, createStyles, makeStyles } from '@material-ui/core';

import { Button, NumberUtility, TableCell, TableRow } from 'sfc-kit';

import Paths from 'constants/Paths';

interface StatementTableRowProps {
	index: number;
	statement: Statement;
}

const useStyles = makeStyles(theme =>
	createStyles({
		highlightedBackground: {
			backgroundColor: '#f8f9fa',
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const StatementTableRow: FunctionComponent<StatementTableRowProps> = ({ index, statement }) => {
	const formattedDate = statement.statementDueDate && DateTime.fromISO(statement.statementDueDate).toLocaleString(DateTime.DATE_SHORT);

	const styles = useStyles({} as any);

	const [redirectTo, setRedirectTo] = useState<RedirectProps['to']>();

	const onViewStatementPdf = () => {
		setRedirectTo({
			pathname: Paths.StatementDocument,
			state: { statement },
		});
	};

	if (redirectTo) {
		return <Redirect push to={redirectTo} />;
	}

	return (
		<TableRow key={statement.documentId} className={classNames({ [styles.highlightedBackground]: index % 2 })}>
			<TableCell>{DateTime.fromISO(statement.statementDate).toLocaleString(DateTime.DATE_SHORT)}</TableCell>
			<Hidden smDown>
				<TableCell>
					{statement.statementDueDate && DateTime.fromISO(statement.statementDueDate).toLocaleString(DateTime.DATE_SHORT)}
				</TableCell>
			</Hidden>
			<Hidden mdDown>
				<TableCell>${NumberUtility.formatCurrency(statement.totalPastDueBalance)}</TableCell>
			</Hidden>
			<Hidden xsDown>
				<TableCell>${NumberUtility.formatCurrency(statement.totalCurrentDueBalance)}</TableCell>
			</Hidden>
			<TableCell>${NumberUtility.formatCurrency(statement.currentPayoffBalance)}</TableCell>
			<TableCell>
				<Button
					variant="text"
					textColor="statementAction"
					casing="capitalizeEachWord"
					message="misc.view"
					endIcon={<FindInPageIcon />}
					onClick={onViewStatementPdf}
					tabIndex={1}
					className={styles.focus}
					aria-label={`Click to view statement, for which the due date is ${formattedDate}`}
				/>
			</TableCell>
		</TableRow>
	);
};

export default StatementTableRow;
