import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { Constants, CSSProperties } from '../../../';

export type SpacingAmountProp = keyof typeof Constants.Spacing; //Used to easily change the type of all of the spacing props.

export interface SpacingProps {
	/**
	 * Whether the spacing component should have a `min-height` of `100%`
	 */
	fullHeight?: boolean;

	/**
	 * Whether the spacing component should have a `min-width` of `100%`
	 */
	fullWidth?: boolean;

	/**
	 * The amount of padding to add on all sides.
	 */
	p?: SpacingAmountProp;

	/**
	 * The amount of padding to add on the left and right sides.
	 */
	px?: SpacingAmountProp;

	/**
	 * The amount of padding to add on the top and bottom.
	 */
	py?: SpacingAmountProp;

	/**
	 * The amount of padding to add on the top.
	 */
	pt?: SpacingAmountProp;

	/**
	 * The amount of padding to add on the right side.
	 */
	pr?: SpacingAmountProp;

	/**
	 * The amount of padding to add on the bottom.
	 */
	pb?: SpacingAmountProp;

	/**
	 * The amount of padding to add on the left side.
	 */
	pl?: SpacingAmountProp;

	/**
	 * The amount of margin to add on all sides.
	 */
	m?: SpacingAmountProp;

	/**
	 * The amount of margin to add on the left and right sides.
	 */
	mx?: SpacingAmountProp;

	/**
	 * The amount of margin to add on the top and bottom.
	 */
	my?: SpacingAmountProp;

	/**
	 * The amount of margin to add on the top.
	 */
	mt?: SpacingAmountProp;

	/**
	 * The amount of margin to add on the right side.
	 */
	mr?: SpacingAmountProp;

	/**
	 * The amount of margin to add on the bottom.
	 */
	mb?: SpacingAmountProp;

	/**
	 * The amount of margin to add on the left side.
	 */
	ml?: SpacingAmountProp;

	className?: string;
	style?: React.CSSProperties;

	children?: ReactNode;
}

interface StyleProps extends Omit<SpacingProps, 'className' | 'style' | 'children' | 'fullHeight' | 'fullWidth'> {
	fullHeight: boolean;
	fullWidth: boolean;
}

export const CreatePaddingStyles = (theme: Theme, { p, py, px, pt, pr, pb, pl }: Omit<StyleProps, 'fullHeight' | 'fullWidth'>): CSSProperties => {
	const paddingStyles: CSSProperties = {};

	if (p !== undefined) {
		paddingStyles.padding = theme.spacing(Constants.Spacing[p]);
	}

	if (py !== undefined) {
		paddingStyles.paddingTop = theme.spacing(Constants.Spacing[py]);
		paddingStyles.paddingBottom = theme.spacing(Constants.Spacing[py]);
	}

	if (px !== undefined) {
		paddingStyles.paddingRight = theme.spacing(Constants.Spacing[px]);
		paddingStyles.paddingLeft = theme.spacing(Constants.Spacing[px]);
	}

	if (pt !== undefined) {
		paddingStyles.paddingTop = theme.spacing(Constants.Spacing[pt]);
	}

	if (pr !== undefined) {
		paddingStyles.paddingRight = theme.spacing(Constants.Spacing[pr]);
	}

	if (pb !== undefined) {
		paddingStyles.paddingBottom = theme.spacing(Constants.Spacing[pb]);
	}

	if (pl !== undefined) {
		paddingStyles.paddingLeft = theme.spacing(Constants.Spacing[pl]);
	}

	return paddingStyles;
};

export const CreateMarginStyles = (theme: Theme, { m, my, mx, mt, mr, mb, ml }: Omit<StyleProps, 'fullHeight' | 'fullWidth'>): CSSProperties => {
	const marginStyles: CSSProperties = {};

	if (m !== undefined) {
		marginStyles.margin = theme.spacing(Constants.Spacing[m]);
	}

	if (my !== undefined) {
		marginStyles.marginTop = theme.spacing(Constants.Spacing[my]);
		marginStyles.marginBottom = theme.spacing(Constants.Spacing[my]);
	}

	if (mx !== undefined) {
		marginStyles.marginRight = theme.spacing(Constants.Spacing[mx]);
		marginStyles.marginLeft = theme.spacing(Constants.Spacing[mx]);
	}

	if (mt !== undefined) {
		marginStyles.marginTop = theme.spacing(Constants.Spacing[mt]);
	}

	if (mr !== undefined) {
		marginStyles.marginRight = theme.spacing(Constants.Spacing[mr]);
	}

	if (mb !== undefined) {
		marginStyles.marginBottom = theme.spacing(Constants.Spacing[mb]);
	}

	if (ml !== undefined) {
		marginStyles.marginLeft = theme.spacing(Constants.Spacing[ml]);
	}

	return marginStyles;
};

const useStyles = makeStyles(theme =>
	createStyles({
		spacing: ({ fullHeight, fullWidth, ...styleProps }: StyleProps) => ({
			minHeight: fullHeight ? '100%' : undefined,
			minWidth: fullWidth ? '100%' : undefined,

			...CreatePaddingStyles(theme, styleProps),
			...CreateMarginStyles(theme, styleProps),
		}),
	})
);

/**
 * The `Spacing` container component is useful when you want to add padding around something.
 */
const Spacing: FunctionComponent<SpacingProps> = ({ className, style, children, fullHeight = false, fullWidth = false, ...styleProps }) => {
	const styles = useStyles({ fullHeight, fullWidth, ...styleProps });

	return (
		<div className={classnames(styles.spacing, className)} style={style}>
			{children}
		</div>
	);
};

export default Spacing;
