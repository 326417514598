export default class RegexUtility {
	static formatPhone(unformattedPhone?: string): string {
		if (unformattedPhone == null) {
			return '';
		}

		// Remove all formatting from input
		let matchedValue = unformattedPhone.replace(/[^0-9]*/g, '').match(/\d*/g);

		const cleanedValue = matchedValue ? matchedValue.join('') : '';

		// group between areaCode, firstGroup, and secondGroup
		matchedValue = cleanedValue.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		const [areaCode, firstGroup, secondGroup] = matchedValue ? matchedValue.slice(1) : ['', '', '']; // eslint-disable-line no-magic-numbers

		// initialize phoneNumber
		let phoneNumber = '';

		// begin with '(' after any digit input
		if (areaCode !== '') {
			phoneNumber = `(${areaCode}`;
		}

		// end area code with ')' if there are more than 3 digits (at least 1 digit in firstGroup)
		if (firstGroup !== '') {
			phoneNumber = `${phoneNumber}) ${firstGroup}`;
		}

		// add '-' if there are more than 6 digits (at least 1 digit in secondGroup)
		if (secondGroup !== '') {
			phoneNumber = `${phoneNumber}-${secondGroup}`;
		}

		return phoneNumber;
	}

	static formatPhoneHyphensOnly(unformattedPhone?: string): string {
		if (unformattedPhone === undefined) {
			return '';
		}

		// Remove all formatting from input
		let matchedValue = unformattedPhone.replace(/[^0-9]*/g, '').match(/\d*/g);

		const cleanedValue = matchedValue ? matchedValue.join('') : '';

		// group between areaCode, firstGroup, and secondGroup
		matchedValue = cleanedValue.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		const [areaCode, firstGroup, secondGroup] = matchedValue?.slice(1) ?? ['', '', '']; // eslint-disable-line no-magic-numbers

		// initialize phoneNumber
		let phoneNumber = '';

		if (areaCode !== '') {
			phoneNumber = `${areaCode}`;
		}

		if (firstGroup !== '') {
			phoneNumber = `${phoneNumber}-${firstGroup}`;
		}

		if (secondGroup !== '') {
			phoneNumber = `${phoneNumber}-${secondGroup}`;
		}

		return phoneNumber;
	}

	static maskNumber(unmaskedNumber?: string, numUnmaskedChars = 0): string {
		if (unmaskedNumber == null) {
			return '';
		}

		// Remove all formatting from input
		const matchedValue = unmaskedNumber.toString().match(/[\d*]*/g);

		const cleanedValue = matchedValue ? matchedValue.join('') : '';

		return cleanedValue.substr(cleanedValue.length - numUnmaskedChars).padStart(cleanedValue.length, '*');
	}

	static formatSSN(unformattedSSN?: string): string {
		if (unformattedSSN == null) {
			return '';
		}

		// Remove all formatting from input
		let matchedValue = unformattedSSN.toString().match(/\d*/g);

		const cleanedValue = matchedValue ? matchedValue.join('') : '';

		matchedValue = cleanedValue.match(/([\d*]{0,3})([\d*]{0,2})([\d*]{0,4})/);

		return matchedValue
			? matchedValue
					.slice(1)
					.filter(group => group !== '')
					.join('-')
			: '';
	}

	static formatNumeric(unformatted?: string, length = Infinity): string {
		if (unformatted == null) {
			return '';
		}

		const matchedValue = unformatted.match(/\d*/g);
		return matchedValue ? matchedValue.join('').substr(0, length) : '';
	}

	static formatName(unformatted?: string): string {
		if (unformatted == null) {
			return '';
		}
		const matchedValue = unformatted.match(/[a-zA-Z'-. ]*/g);
		return matchedValue ? matchedValue.join('') : '';
	}

	static formatFullName(unformatted?: string): string {
		if (unformatted == null) {
			return '';
		}

		const matchedValue = unformatted.match(/[a-zA-Z'\- .]*/g);

		return matchedValue ? matchedValue.join('') : '';
	}

	static formatSingleNumeric(unformatted?: string): string {
		if (unformatted == null) {
			return '';
		}

		// So that we can just change the value by typing, we just match all
		// numbers, then grab the last one (since the event will see 2 after
		// the second keystroke, then trim the input down to one with this)
		const matchedValue = unformatted.match(/[0-9]/g);
		return (matchedValue && matchedValue.pop()) || '';
	}
	static formatTypedCurrency(unformatted?: string): string {
		if (!unformatted) {
			return '0.00';
		}

		const numbersOnly = RegexUtility.formatNumeric(unformatted, 12);
		const matchValue = numbersOnly.replace('.', '');

		if (matchValue === '') {
			return '0.00';
		}

		const formatted = parseFloat(matchValue) / 100;
		const split = unformatted.split('.');
		if (split.length >= 1 || split[1].length < 3) {
			return formatted.toFixed(2);
		}

		return formatted.toString();
	}

	static formatCity(unformatted?: string): string {
		if (unformatted == null) {
			return '';
		}

		const matchedValue = unformatted.match(/([a-zA-Z]+ ?)+?/g);

		return matchedValue ? matchedValue.join('') : '';
	}

	static formatPostalCode(unformatted?: string): string {
		if (unformatted == null) {
			return '';
		}

		const matchedValue = unformatted.replace(/[^0-9]*/g, '').match(/\d*/g);

		const cleaned = matchedValue ? matchedValue.join('') : '';
		const zipFormatted = cleaned
			.replace(/(\d{5})/g, '$1-')
			.replace(/-$/gi, '')
			.match(/^[0-9/-]{1,10}/g);

		return zipFormatted ? zipFormatted.join('') : '';
	}
}
