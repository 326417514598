import React, { useContext } from 'react';
import { ButtonProps } from '@material-ui/core';

import SquareButtonLink from 'sfc-kit/src/components/SquareButtonLink';
import MakeAPaymentContext, { MakeAPaymentActionTypes } from 'context/MakeAPayment';

export interface MakeAPaymentButtonProps extends Pick<ButtonProps, 'fullWidth'> {
	openLoan: Loan | null;
	className?: string;
	tabIndex?: number;
}

const MakeAPaymentButton: React.FC<MakeAPaymentButtonProps> = ({ openLoan, className, fullWidth, tabIndex }) => {
	const {
		makeAPaymentState: { page, inProgress },
		dispatchForMakeAPayment,
	} = useContext(MakeAPaymentContext);

	return (
		<SquareButtonLink
			fullWidth={fullWidth}
			color="primary"
			variant="contained"
			className={className}
			tabIndex={tabIndex}
			onClick={(): void => {
				dispatchForMakeAPayment({ type: MakeAPaymentActionTypes.InitiateWorkflow, payload: openLoan });
			}}
			to={page}>
			{inProgress ? 'Resume Payment' : 'Make a Payment'}
		</SquareButtonLink>
	);
};

export default MakeAPaymentButton;
