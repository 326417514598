import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { createStyles, makeStyles, Theme, Menu, Hidden, MenuItem, ClickAwayListener } from '@material-ui/core';

import { ConfirmDenyDialog } from 'sfc-kit';
import SquareButton from 'sfc-kit/src/components/SquareButton';

import { LoanContext } from 'context';
import UASRoutes from 'constants/Paths/UserAccountSettings';
import { useProfileName } from 'hooks';
import useLogout from 'hooks/auth/useLogout';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		userButton: {
			textTransform: 'inherit',
			[theme.breakpoints.down('xs')]: {
				marginRight: -theme.spacing(2),
				paddingRight: theme.spacing(0),
			},
		},
		noButton: { color: theme.palette.primary.main },
		menuPaper: { borderRadius: 0 },

		startIcon: {
			[theme.breakpoints.down('xs')]: {
				margin: 0,

				'& svg': {
					fontSize: '28px !important',
				},
			},
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const UserDropdown: React.FC = () => {
	const classes = useStyles({} as any);
	const { selectLoan } = useContext(LoanContext);
	const logout = useLogout();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const [signOutModalIsOpen, setSignOutModalIsOpen] = React.useState(false);

	const profileName = useProfileName();

	const onUserAccountSettingsClick = (): void => {
		selectLoan();
		setDropdownOpen(false);
	};

	const onUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
		setDropdownOpen(isOpen => !isOpen);
	};

	const onSignOut = (): void => {
		logout();

		setDropdownOpen(false);
	};

	const downHandler = (key: React.KeyboardEvent<Element>): void => {
		if (key.key === 'Escape') {
			setDropdownOpen(false);
		}
	};

	return (
		<>
			<ClickAwayListener onClickAway={(): void => setDropdownOpen(false)} disableReactTree>
				<div>
					<SquareButton
						tabIndex={1}
						size="large"
						className={classnames(classes.focus, classes.userButton)}
						startIcon={<AccountCircle fontSize="large" />}
						aria-label="Drop down menu for account settings or to sign out"
						endIcon={
							<Hidden xsDown>
								<ArrowDropDownIcon />
							</Hidden>
						}
						onClick={onUserMenuClick}
						classes={{ startIcon: classes.startIcon }}>
						<Hidden xsDown>{profileName}</Hidden>
					</SquareButton>
					<Menu
						keepMounted
						getContentAnchorEl={null}
						anchorEl={anchorEl}
						title="user-dropdown-menu"
						open={dropdownOpen}
						onKeyDown={(event): void => downHandler(event)}
						anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
						transformOrigin={{ vertical: 'top', horizontal: 'center' }}
						PaperProps={{ className: classes.menuPaper }}>
						<MenuItem
							component={Link}
							to={UASRoutes.Root}
							onClick={onUserAccountSettingsClick}
							aria-label="User account settings"
							tabIndex={1}
							className={classes.focus}>
							User Account Settings
						</MenuItem>
						<MenuItem
							title="Sign Out"
							tabIndex={1}
							className={classes.focus}
							onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
								event.preventDefault();
								setSignOutModalIsOpen(true);
							}}>
							Sign Out
						</MenuItem>
					</Menu>
				</div>
			</ClickAwayListener>

			<ConfirmDenyDialog
				id="sign-out-dialog"
				title="Sign Out"
				isOpen={signOutModalIsOpen}
				handleClose={(): void => setSignOutModalIsOpen(false)}
				handleConfirm={onSignOut}
				handleDeny={(): void => setSignOutModalIsOpen(false)}
				denyText="No"
				confirmText="Yes">
				Are you sure you want to sign out?
			</ConfirmDenyDialog>
		</>
	);
};

export default UserDropdown;
