import React from 'react';
import { DateTime } from 'luxon';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { createStyles, makeStyles, Theme, Grid, Divider } from '@material-ui/core';
import { type FontWeightProperty } from 'csstype';

import { LangText, useSfcKitMessages, Text } from 'sfc-kit';
import SquareButton from 'sfc-kit/src/components/SquareButton';
import Fieldset from 'sfc-kit/src/components/Fieldset/Fieldset';

import { generateLoanDetails } from 'utils';

export interface OverviewDetailsProps {
	loanOverview: LoanOverview;
	onViewInitialDocuments: () => void | Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		wrapper: {
			padding: theme.spacing(2),
		},
		subtitle: {
			color: theme.palette.grey[700],
			padding: theme.spacing(0, 1, 0, 1),
		},
		fontBold: {
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
		},
		fontUppercase: {
			textTransform: 'uppercase',
		},
		header: {
			marginBottom: theme.spacing(2),
		},
		promotions: {
			'&> p': {
				fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
			},
		},
		equalHeight: {
			display: 'flex',
			'&> *': {
				height: '100%',
			},
		},
		button: {
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
			color: theme.palette.action.main,
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		dateFormat: {
			display: 'flex',
		},
	});
});

const fieldsetBackground =
	'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(248, 249, 250, 1) 50%, rgba(248, 249, 250, 1) 100%)';

const OverviewDetails: React.FC<OverviewDetailsProps> = ({ loanOverview: loanDetails, onViewInitialDocuments }) => {
	const classes = useStyles({} as any);
	const messages = useSfcKitMessages();

	const sections = generateLoanDetails(loanDetails, classes);

	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>
				<Grid container>
					<Grid item xs>
						<Text variant="body2" color="primary" className={`${classes.fontBold} ${classes.fontUppercase}`} component="h2">
							Finance Information
						</Text>
					</Grid>
					<Grid item>
						<SquareButton
							size="small"
							variant="text"
							tabIndex={1}
							className={classes.button}
							onClick={onViewInitialDocuments}
							endIcon={<FindInPageIcon />}>
							View Your Loan Documents
						</SquareButton>
					</Grid>
				</Grid>
				<div className={classes.dateFormat}>
					<Text variant="body2" component="span" className={classes.subtitle}>
						Balances as of:
					</Text>
					<Text variant="body2" component="span" className={classes.fontBold}>
						{DateTime.local().toFormat('MM/dd/yyyy')}
					</Text>
					<Divider aria-hidden="true" />
				</div>
			</div>

			<Grid container spacing={2}>
				{sections.map(({ label, rows, children }, index) => {
					if (label) {
						return (
							<Grid item xs={12} md={6} className={classes.equalHeight} key={index}>
								<Fieldset
									component="h3"
									borderColor="background.form"
									labelColor="primary.light"
									label={label as LangText}
									messages={messages}
									background={fieldsetBackground}>
									{rows?.map(({ name, value }, index) => {
										return (
											<Grid container justifyContent="space-between" key={`${name}-${index}`}>
												<Grid item xs>
													<Text variant="body2" className={classes.subtitle}>
														{name}
													</Text>
												</Grid>

												<Grid item>
													<Text variant="body2" className={classes.fontBold}>
														{value}
													</Text>
												</Grid>
											</Grid>
										);
									})}
								</Fieldset>
							</Grid>
						);
					}

					return <React.Fragment key={index}>{children}</React.Fragment>;
				})}
			</Grid>
		</div>
	);
};

export default OverviewDetails;
