import React from 'react';

import { Skeleton } from '@material-ui/lab';

import { Text } from 'sfc-kit';
import { useLoanNickname } from 'hooks';

export default function LoanNickname({ loanNumber }: { loanNumber?: string }): JSX.Element {
	const { isLoading, nickname } = useLoanNickname(loanNumber);

	return (
		<Text inline component="strong" weight="medium">
			{isLoading ? <Skeleton animation="wave" /> : nickname}
		</Text>
	);
}
