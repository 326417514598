import React, { useState, useEffect, FunctionComponent } from 'react';

import { makeStyles, createStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';

import { Button, Icon } from '..';

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			flexShrink: 0,
			marginLeft: theme.spacing(2.5),
		},
		button: {
			border: `2px solid ${theme.palette.divider}`,
			boxShadow: theme.shadows[0],
			height: 24,
			margin: theme.spacing(0, 0.5),
			minWidth: 'unset',
			padding: theme.spacing(0, 1),
			width: 24,
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	})
);

const TablePaginationActions: FunctionComponent<TablePaginationActionsProps> = ({ count, page: currentPage, rowsPerPage, onPageChange }) => {
	const classes = useStyles({} as any);

	const [pages, setPages] = useState<number[]>([]);

	useEffect(() => {
		let numPages = 0;
		const newPages = [];
		let totalRows = count;

		while (totalRows > 0) {
			numPages++;
			totalRows -= rowsPerPage;
		}

		for (let i = 0; i < numPages; i++) {
			newPages.push(i);
		}

		setPages(newPages);
	}, [count, rowsPerPage]);

	//We only need to show the pagination if it will actually do something
	if (pages.length < 2) {
		return null;
	}

	const previousIsDisabled = currentPage === 0;
	const nextIsDisabled = currentPage >= Math.ceil(count / rowsPerPage) - 1;

	const pageIsNearCurrentPage = (page: number): boolean => page >= currentPage - 2 && page <= currentPage + 2;

	return (
		<div className={classes.root}>
			<Button
				size="small"
				color="background.paper"
				aria-label="Previous Page"
				disabled={previousIsDisabled}
				tabIndex={previousIsDisabled ? -1 : 1}
				onClick={(event): void => onPageChange(event, currentPage - 1)}
				className={classes.button}>
				<Icon icon={KeyboardArrowLeftIcon} color="action" />
			</Button>
			{pages.map(page =>
				pageIsNearCurrentPage(page) ? (
					<Button
						key={page}
						size="small"
						tabIndex={1}
						variant="contained"
						className={classes.button}
						onClick={(event): void => onPageChange(event, page)}
						color={page === currentPage ? 'action' : 'background.paper'}
						aria-label={`Page ${page + 1}`}>
						{page + 1}
					</Button>
				) : null
			)}
			<Button
				size="small"
				aria-label="Next Page"
				tabIndex={nextIsDisabled ? -1 : 1}
				color="background.paper"
				disabled={nextIsDisabled}
				onClick={(event): void => onPageChange(event, currentPage + 1)}
				className={classes.button}>
				<Icon icon={KeyboardArrowRightIcon} color={nextIsDisabled ? 'text.disabled' : 'action'} />
			</Button>
		</div>
	);
};

export default TablePaginationActions;
