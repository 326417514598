import Environment from './Environment';
import { svcFinDevUrl, svcFinTestUrl, svcFinRCUrl, svcFinProdUrl } from '../constants/ExternalUrl';

const GetSvcFinUrlForEnvironment = (): string => {
	if (Environment.IsTest()) {
		return svcFinTestUrl;
	}

	if (Environment.IsRC()) {
		return svcFinRCUrl;
	}

	if (Environment.IsProduction() && Environment.isProductionURL()) {
		return svcFinProdUrl;
	}

	if (Environment.IsProduction() && Environment.isProductionURL()) {
		return svcFinProdUrl;
	}

	return svcFinDevUrl;
};

export default GetSvcFinUrlForEnvironment;
