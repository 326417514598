import React, { ReactNode, ReactElement, CSSProperties } from 'react';
import classnames from 'classnames';
import { type FontWeightProperty } from 'csstype';

//#region Material-UI Imports
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core';
import { Messages, SfcKitMessages, useSfcKitMessages, LangText, MessagesProps, Casing, Text } from '../..';
import generateCasing from '../../utils/GenerateCasing';
//#endregion Material-UI Imports

interface IStyleProps {
	positioned: boolean;
	titleFontWeight: CSSProperties['fontWeight'];
}

export interface ISfcAlertProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	open?: boolean;
	id?: string;
	title?: LangText<TMessages>;
	type?: AlertProps['severity'];
	onClose?: AlertProps['onClose'];
	variant?: AlertProps['variant'];
	position?: SnackbarProps['anchorOrigin'];
	autoHideAfter?: SnackbarProps['autoHideDuration'];
	message?: LangText<TMessages>;
	titleCasing?: Casing;
	bodyCasing?: Casing;
	alertDescribedBy?: string;
	className?: string;

	/**
	 * The `positioned` property tells the SfcAlert whether it should allow itself to be positioned by it's parent
	 */
	positioned?: boolean;

	children?: ReactNode;
}

export function GetIconMapping(variant: ISfcAlertProps['variant']): AlertProps['iconMapping'] {
	switch (variant) {
		case 'filled':
			return {
				success: <CheckCircleOutlineIcon />,
				error: <HighlightOffIcon />,
				warning: <ErrorOutlineIcon />,
				info: <InfoOutlinedIcon />,
			};

		case 'outlined':
		case 'standard':
		default:
			return {
				success: <CheckCircleIcon />,
				error: <CancelIcon />,
				warning: <ErrorIcon />,
				info: <InfoIcon />,
			};
	}
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: ({ positioned }: IStyleProps) => ({
			boxShadow: theme.shadows[2],
			position: positioned ? 'static' : 'fixed',
			width: positioned ? '100%' : 'inherit',
		}),
		alert: ({ positioned }: IStyleProps) => ({
			borderRadius: 0,
			position: 'relative',
			width: positioned ? '100%' : 'inherit',
		}),
		action: {
			alignItems: 'flex-start',
		},
		filledSuccess: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		standardSuccess: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.getContrastText(theme.palette.background.default),

			'& .MuiAlert-icon': {
				color: theme.palette.primary.light,
			},
		},
		filledError: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.getContrastText(theme.palette.error.main),
		},
		standardError: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.getContrastText(theme.palette.background.default),

			'& .MuiAlert-icon': {
				color: theme.palette.error.main,
			},
		},
		filledWarning: {
			backgroundColor: theme.palette.warning.main,
			color: theme.palette.getContrastText(theme.palette.warning.main),
		},
		standardWarning: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.getContrastText(theme.palette.background.default),

			'& .MuiAlert-icon': {
				color: theme.palette.warning.main,
			},
		},
		filledInfo: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.getContrastText(theme.palette.info.main),
		},
		standardInfo: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.getContrastText(theme.palette.background.default),

			'& .MuiAlert-icon': {
				color: theme.palette.info.main,
			},
		},
		title: ({ titleCasing }: IStyleProps & Partial<ISfcAlertProps>) => ({
			flexBasis: '100%',
			...generateCasing(titleCasing),
		}),
		icon: {
			alignItems: 'center',

			'& svg': {
				fontSize: 35,
			},
		},
		message: ({ bodyCasing }: Partial<ISfcAlertProps>) => ({
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			...generateCasing(bodyCasing),
		}),
		bar: {
			backgroundColor: theme.palette.primary.light,
			borderTopRightRadius: 16,
			borderBottomRightRadius: 16,
			height: '100%',
			left: 0,
			position: 'absolute',
			top: 0,
			width: theme.spacing(1),
		},
		barSuccess: {
			backgroundColor: theme.palette.primary.light,
		},
		barError: {
			backgroundColor: theme.palette.error.main,
		},
		barWarning: {
			backgroundColor: theme.palette.warning.main,
		},
		barInfo: {
			backgroundColor: theme.palette.info.main,
		},
	})
);

export default function SfcAlert<TMessages extends Messages = SfcKitMessages>({
	open = false,
	id = '',
	title,
	type = 'success',
	onClose,

	variant = 'filled',
	position = { vertical: 'bottom', horizontal: 'right' },
	autoHideAfter,
	messages,
	message,
	titleCasing,
	bodyCasing,
	alertDescribedBy,

	className,
	positioned = false,

	children,
}: ISfcAlertProps<TMessages>): ReactElement | null {
	const sfcKitMessages = useSfcKitMessages();
	const theme = useTheme();

	const classes = useStyles({
		positioned,
		titleFontWeight: (variant === 'filled' ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium) as FontWeightProperty,
		titleCasing,
		bodyCasing,
	});

	const iconMapping: AlertProps['iconMapping'] = GetIconMapping(variant);

	const alertClasses: AlertProps['classes'] = {
		root: classes.alert,
		icon: classes.icon,
		action: classes.action,
		message: classes.message,

		standardSuccess: classes.standardSuccess,
		filledSuccess: classes.filledSuccess,

		standardError: classes.standardError,
		filledError: classes.filledError,

		standardWarning: classes.standardWarning,
		filledWarning: classes.filledWarning,

		standardInfo: classes.standardInfo,
		filledInfo: classes.filledInfo,
	};

	if (!open) {
		return null;
	}

	const messageExists = (message && messages?.[message]) ?? sfcKitMessages[message as keyof SfcKitMessages];
	const titleExists = (title && messages?.[title]) ?? sfcKitMessages[title as keyof SfcKitMessages];
	const titleWeight = variant === 'filled' ? 'heavy' : 'medium';
	const textColor = variant === 'filled' ? 'misc.white' : undefined;

	return (
		<Snackbar
			open={open}
			anchorOrigin={position}
			autoHideDuration={autoHideAfter}
			className={classnames(classes.root, className)}
			id={id}
			aria-describedby={alertDescribedBy}>
			<Alert variant={variant} severity={type} onClose={onClose} iconMapping={iconMapping} classes={alertClasses}>
				{variant === 'standard' && (
					<div
						className={classnames(classes.bar, {
							[classes.barSuccess]: type === 'success',
							[classes.barError]: type === 'error',
							[classes.barWarning]: type === 'warning',
							[classes.barInfo]: type === 'info',
						})}
					/>
				)}
				{titleExists ? (
					<Text
						className={classes.title}
						id={alertDescribedBy}
						weight={titleWeight}
						color={textColor}
						message={title}
						messages={messages}
					/>
				) : (
					title !== undefined && (
						<AlertTitle id={alertDescribedBy} className={classes.title}>
							{title}
						</AlertTitle>
					)
				)}
				{messageExists ? (
					<Text color={textColor} id={!titleExists ? alertDescribedBy : undefined} messages={messages} message={message} />
				) : (
					children
				)}
			</Alert>
		</Snackbar>
	);
}
