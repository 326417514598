import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type FindDealerResult = MutationResultPair<string, AxiosError<string>, FindDealerRequestParameters, unknown>;

export type FindDealerConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<string, AxiosError<string>, FindDealerRequestParameters>;
};

export default function useFindDealerMutation(config: FindDealerConfig = { axiosConfig: {} }): FindDealerResult {
	async function FindDealer(request: FindDealerRequestParameters): Promise<string> {
		const client = ApiCSGService();

		const path = '/api/Loans/FindDealer';

		const response = await client.post<string>(path, request.body);

		return response.data;
	}

	return useMutation(FindDealer, config.mutationConfig);
}
