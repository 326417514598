import React from 'react';
import classnames from 'classnames';

import { Typography, TypographyProps, makeStyles, createStyles } from '@material-ui/core';

import {
	useSfcKitMessages,
	//
	ExtractColorFromKeyString,
	GenerateCasing,
	//
	Constants,
	//
	Color,
	Casing,
	FontWeight,
	Messages,
	SfcKitMessages,
	MessagesProps,
	LangText,
} from '../../..';

export interface LabelProps<TMessages extends Messages = SfcKitMessages> extends MessagesProps<TMessages> {
	for: string;
	message?: LangText<TMessages>;

	color?: Color;
	casing?: Casing;
	weight?: FontWeight;
	id?: string;

	className?: string;
	gutterBottom?: boolean;
	variant?: TypographyProps['variant'];
}

const useStyles = makeStyles(() =>
	createStyles({
		label: ({ casing, weight, color }: { casing?: Casing; weight: FontWeight; color: Color }) => {
			const extractedColor = ExtractColorFromKeyString(color);

			return { color: extractedColor, fontWeight: Constants.FontWeights[weight], ...GenerateCasing(casing) };
		},
	})
);

export default function Label<TMessages extends Messages = SfcKitMessages>({
	for: htmlFor,
	message,

	casing,
	weight = 'light',
	color = 'text.primary',
	id,

	className,
	gutterBottom = false,
	variant,

	messages,

	children,
}: React.PropsWithChildren<LabelProps<TMessages>>): React.ReactElement | null {
	const styles = useStyles({ casing, weight, color });

	const sfcKitMessages = useSfcKitMessages();
	// ToDo add htmlFor to Text
	return (
		<Typography
			component="label"
			id={id}
			htmlFor={htmlFor}
			className={classnames(styles.label, className)}
			gutterBottom={gutterBottom}
			variant={variant}>
			{message ? messages?.[message] ?? sfcKitMessages[message as keyof SfcKitMessages] : children}
		</Typography>
	);
}
