import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type IsUserResult = QueryResult<boolean, AxiosError<boolean>>;
export type IsUserConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<boolean, AxiosError<boolean>>;
};

interface IsUserQuery {
	(request: IsUserRequestParameters, config?: IsUserConfig): IsUserResult;
	key: 'IsUser';
}

const useIsUserQuery: IsUserQuery = (request, config = { axiosConfig: {} }) => {
	async function IsUser(): Promise<boolean> {
		const client = ApiCSGService();

		const path = '/api/Users/IsUser';

		const response = await client.get<boolean>(path, {
			email: request?.email,
		});

		return response.data;
	}

	return useQuery(['IsUser', ...Object.values(request)], IsUser, config.queryConfig);
};

useIsUserQuery.key = 'IsUser';

export default useIsUserQuery;
