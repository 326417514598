import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type SendContactUsEmailResult = MutationResultPair<boolean, AxiosError<boolean>, SendContactUsEmailRequestParameters, unknown>;

export type SendContactUsEmailConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<boolean, AxiosError<boolean>, SendContactUsEmailRequestParameters>;
};

export default function useSendContactUsEmailMutation(config: SendContactUsEmailConfig = { axiosConfig: {} }): SendContactUsEmailResult {
	async function SendContactUsEmail(request: SendContactUsEmailRequestParameters): Promise<boolean> {
		const client = ApiCSGService();

		const path = '/api/Email/SendContactUsEmail';

		const response = await client.post<boolean>(path, request.body);

		return response.data;
	}

	return useMutation(SendContactUsEmail, config.mutationConfig);
}
