import React, { FunctionComponent, ElementType } from 'react';

import { ListItemProps } from '@material-ui/core/ListItem';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Box, ListItem } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		root: {
			position: 'relative',
		},
		selected: {
			backgroundColor: `${theme.palette.background.menuDark} !important`,
			borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
		},
		box: {
			backgroundColor: theme.palette.primary.main,
			height: theme.spacing(1.5),
			left: -theme.spacing(0.75),
			position: 'absolute',
			transform: 'rotate(45deg)',
			width: theme.spacing(1.5),
		},
	});
});

export default function SelectableListItem<C extends ElementType>({
	selected,
	classes,
	children,
	...props
}: ListItemProps<C, { component?: C }>): ReturnType<FunctionComponent> {
	const styles = useStyles({ selected });

	return (
		<ListItem selected={selected} classes={{ root: styles.root, selected: styles.selected, ...classes }} {...props}>
			{selected && <Box className={styles.box} />}
			{children}
		</ListItem>
	);
}
