import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation, MutationConfig, MutationResultPair } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type CompleteEmailVerificationResult = MutationResultPair<void, AxiosError<void>, CompleteEmailVerificationRequestParameters, unknown>;

export type CompleteEmailVerificationConfig = {
	axiosConfig?: AxiosRequestConfig;
	mutationConfig?: MutationConfig<void, AxiosError<void>, CompleteEmailVerificationRequestParameters>;
};

export default function useCompleteEmailVerificationMutation(
	config: CompleteEmailVerificationConfig = { axiosConfig: {} }
): CompleteEmailVerificationResult {
	async function CompleteEmailVerification(request: CompleteEmailVerificationRequestParameters): Promise<void> {
		const client = ApiCSGService();

		const path = '/api/Authentication/CompleteEmailVerification';

		await client.post<void>(path, request.body);
	}

	return useMutation(CompleteEmailVerification, config.mutationConfig);
}
