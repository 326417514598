import { useState, useCallback } from 'react';

import FieldValidation from '../types/FieldValidation';
import GetPasswordStrength from '../utils/GetPasswordStrength';
import Validator from '../types/Validator';

import { Strengths } from '../components/StrengthMeter';

export interface IUseRatedAndValidatedPasswordOptions {
	initialValue?: string;
	initialStrength?: Strengths;

	validator?: Validator<string>;
	getRating?: (password: string) => Strengths;

	validateOnChange?: boolean;
}

export type UseRatedAndValidatedPasswordResult = [string, Strengths, (updatedValue: string) => void, FieldValidation<string, Validator<string>>];

export default function useRatedAndValidatedPassword({
	initialValue = '',
	initialStrength = Strengths.Unknown,

	getRating = GetPasswordStrength,
	validator = Validator.AlwaysValid<string>(),

	validateOnChange = true,
}: IUseRatedAndValidatedPasswordOptions): UseRatedAndValidatedPasswordResult {
	const [value, setValue] = useState(initialValue);
	const [strength, setStrength] = useState(initialStrength);

	const [invalid, setInvalid] = useState<boolean>(false);
	const [reasonForInvalidity, setReasonForInvalidity] = useState<string>();

	const validate = useCallback(async () => {
		const [valid, errorMessage] = await validator.Validate(value);

		setInvalid(!valid);
		setReasonForInvalidity(errorMessage);

		return valid;
	}, [value, validator]);

	const validateSync = useCallback(() => {
		const [valid, errorMessage] = validator.ValidateSync(value);

		setInvalid(!valid);
		setReasonForInvalidity(errorMessage);

		return valid;
	}, [value, validator]);

	const onChange = (updatedValue: string): void => {
		const password = updatedValue.trim().substr(0, 20);

		setValue(password);
		setStrength(getRating(password));

		if (validateOnChange) {
			validateSync();
		}
	};

	return [value, strength, onChange, new FieldValidation(invalid, reasonForInvalidity, validate, validateSync)];
}
