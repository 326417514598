import React, { Dispatch, createContext, PropsWithChildren, useReducer } from 'react';
import { ActionMap } from 'types/Context';
import reducer from './OnboardingReducer';

export enum OnboardingActionTypes {
	Reset = 'ONBOARDING_RESET',
	VerifyEmail = 'ONBOARDING_VERIFY_EMAIL',
	PaperlessOptInLoanNumbers = 'PAPERLESS_OPT_IN_LOAN_NUMBERS',
	PaperlessOptOutLoanNumbers = 'PAPERLESS_OPT_OUT_LOAN_NUMBERS',
}

export type OnboardingInitialStateType = {
	loanNumber: string;
	ssn: string;
	email: string;
	paperlessOptInLoanNumbers: Array<string>;
	paperlessOptOutLoanNumbers: Array<string>;
};

export const OnboardingInitialState: OnboardingInitialStateType = {
	loanNumber: '',
	ssn: '',
	email: '',
	paperlessOptInLoanNumbers: [],
	paperlessOptOutLoanNumbers: [],
};

interface VerifyEmail {
	loanNumber: OnboardingInitialStateType['loanNumber'];
	ssn: OnboardingInitialStateType['ssn'];
	email: OnboardingInitialStateType['email'];
}

type OnboardingPayload = {
	[OnboardingActionTypes.Reset]: undefined;
	[OnboardingActionTypes.VerifyEmail]: VerifyEmail;
	[OnboardingActionTypes.PaperlessOptInLoanNumbers]: OnboardingInitialStateType['paperlessOptInLoanNumbers'];
	[OnboardingActionTypes.PaperlessOptOutLoanNumbers]: OnboardingInitialStateType['paperlessOptOutLoanNumbers'];
};

export type OnboardingActions = ActionMap<OnboardingPayload>[keyof ActionMap<OnboardingPayload>];

export type OnboardingDispatchType = Dispatch<OnboardingActions>;

export const OnboardingContext = createContext<{
	onboardingState: OnboardingInitialStateType;
	dispatchForOnboarding: OnboardingDispatchType;
}>({
	onboardingState: OnboardingInitialState,
	dispatchForOnboarding: () => null,
});

export function OnboardingProvider({
	initialState = OnboardingInitialState,
	children,
}: PropsWithChildren<{ initialState?: OnboardingInitialStateType }>): React.ReactElement {
	const [state, dispatch] = useReducer(reducer, { ...OnboardingInitialState, ...initialState });

	return <OnboardingContext.Provider value={{ onboardingState: state, dispatchForOnboarding: dispatch }}>{children}</OnboardingContext.Provider>;
}

export default OnboardingContext;
