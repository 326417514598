import React, { ElementType } from 'react';
import classnames from 'classnames';
import { type FontWeightProperty } from 'csstype';

import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { Text, TextProps } from './Typography';

export interface MediumBoldProps extends TextProps {
	component?: ElementType<any>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
		},
	})
);

const MediumBold: React.FC<MediumBoldProps> = ({ children, className, component, ...props }) => {
	const classes = useStyles({} as any);

	return (
		<Text component={component ?? 'span'} {...props} className={classnames(classes.root, className)}>
			{children}
		</Text>
	);
};

export default MediumBold;
