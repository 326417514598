import classnames from 'classnames';
import React, { forwardRef, ReactNode, Ref } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles, createStyles, InputAdornment } from '@material-ui/core';
import Select, { SelectProps } from '@material-ui/core/Select';

import {
	//
	Constants,
	//
	InputControl,
	Icon,
	IconProps,
	//
	Messages,
	SfcKitMessages,
	MessagesProps,
	LangText,
	Casing,
	Variant,
} from '../../..';
import generateVariantPadding from '../../../utils/GenerateVariantPadding';

export interface SelectListProps<TMessages extends Messages = SfcKitMessages, TValue extends unknown = string> extends MessagesProps<TMessages> {
	id: string;
	value?: TValue;
	inputProps?: SelectProps['inputProps'];
	name?: string;

	title?: string;
	type?: string;
	className?: string;
	defaultValue?: string;

	/**
	 * Whether the input should display an error.
	 */
	error?: boolean;

	variant?: Variant;

	/**
	 * Whether the input is disabled.
	 */
	disabled?: boolean;

	/**
	 * Whether the label should be visible to the user. When false, the label will still be rendered but as srOnly.
	 */
	showLabel?: boolean;

	/**
	 * The icon to be displayed before the input.
	 */
	startIcon?: IconProps['icon'];

	/**
	 * The icon to be displayed as the arrow. Defaults to ArrowDropDownIcon.
	 */
	endIcon?: IconProps['icon'];

	/**
	 * The LangText key to be used as the label.
	 */
	label?: LangText<TMessages>;

	/**
	 * The casing to be used for the label.
	 */
	labelCasing?: Casing;

	/**
	 * The LangText key to be used as the helper text.
	 */
	helperText?: LangText<TMessages>;

	/**
	 * The casing to be used for the helper text.
	 */
	helperCasing?: Casing;

	/**
	 * The LangText key to be used as the error message.
	 */
	errorText?: LangText<TMessages>;

	/**
	 * The casing to be used for the error message.
	 */
	errorCasing?: Casing;

	/**
	 * The autoComplete for the select.
	 */
	autoComplete?: string;

	onBlur?: () => void;
	onClick?: () => void;
	onChange?: (value: TValue, event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;

	children: ReactNode;
}

const useStyles = makeStyles(theme =>
	createStyles({
		startAdornment: { marginRight: 0, paddingLeft: theme.spacing(1) },
		adornedStart: { paddingLeft: theme.spacing(Constants.Spacing.xSmall) },

		input: ({ variant = 'normal' }: { variant: Variant }) => ({ ...generateVariantPadding(variant) }),
	})
);

function SelectList<TMessages extends Messages = SfcKitMessages, TValue extends unknown = string>(
	{
		id,
		value,
		name,

		inputProps,

		type,
		className,
		defaultValue,

		error = false,
		disabled = false,
		showLabel = false,

		startIcon,
		endIcon = ArrowDropDownIcon,

		label,
		labelCasing,

		helperText,
		helperCasing,

		errorText,
		errorCasing,
		variant = 'normal',
		autoComplete,
		title,

		onBlur = (): void => undefined,
		onClick = (): void => undefined,
		onChange = (): void => undefined,

		messages,

		children,
	}: SelectListProps<TMessages, TValue>,
	ref?: Ref<HTMLInputElement>
): React.ReactElement | null {
	const styles = useStyles({ variant });

	return (
		<InputControl
			htmlFor={id}
			messages={messages}
			label={label}
			showLabel={showLabel}
			helperText={helperText}
			error={error && !disabled}
			errorText={errorText}
			casings={{ label: labelCasing, helper: helperCasing, error: errorCasing }}>
			<Select
				autoComplete={autoComplete ?? 'off'}
				name={name}
				inputRef={ref}
				id={id}
				inputProps={inputProps}
				type={type}
				value={value}
				error={error}
				title={title}
				labelWidth={0}
				onBlur={onBlur}
				onClick={onClick}
				defaultValue={defaultValue}
				disabled={disabled}
				role="combobox"
				IconComponent={endIcon}
				classes={{ select: classnames(styles.input, className) }}
				onChange={(event): void => onChange(event.target.value as TValue, event)}
				className={classnames({ [styles.adornedStart]: startIcon !== undefined })}
				startAdornment={
					startIcon && (
						<InputAdornment position="start" className={styles.startAdornment}>
							<Icon color="primary" icon={startIcon} />
						</InputAdornment>
					)
				}>
				{children}
			</Select>
		</InputControl>
	);
}

export default forwardRef(SelectList);
