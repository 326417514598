import React, { useContext } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import CopyrightInfo from './CopyrightInfo';

import { List, Drawer, Toolbar } from '@material-ui/core';

import { LoanContext } from 'context';
import { FlexBox, FlexItem, useScrollToTop } from 'sfc-kit';

import SecondaryDrawerContent from './SecondaryDrawerContent';

export interface ISecondaryDrawerProps {
	open?: boolean;
	onClose: (closePrimary: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			backgroundColor: theme.palette.background.menu,
			color: theme.palette.common.white,

			[theme.breakpoints.up('md')]: {
				width: 0,
				flexShrink: 0,
			},
		},
		drawerPaper: {
			backgroundColor: 'inherit',
			borderRight: 0,
			color: 'inherit',

			width: theme.appDrawer.width,
		},
	})
);

const SecondaryDrawer: React.FC<ISecondaryDrawerProps> = ({ open, onClose }) => {
	// const theme = useTheme();
	useScrollToTop();
	const classes = useStyles({} as any);
	// const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));

	const { hasLoanSelected } = useContext(LoanContext);

	return (
		<Drawer anchor="left" open={open && hasLoanSelected} className={classes.drawer} variant="persistent" classes={{ paper: classes.drawerPaper }}>
			<Toolbar />
			<FlexBox wrap="nowrap" fullHeight>
				<FlexBox justifyContent="space-between">
					<FlexItem xs={12}>
						{hasLoanSelected && (
							<List>
								<SecondaryDrawerContent onClose={onClose} open={open} />
							</List>
						)}
					</FlexItem>
					<CopyrightInfo isSidebarOpen={open} />
				</FlexBox>
			</FlexBox>
		</Drawer>
	);
};

export default SecondaryDrawer;
