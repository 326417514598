import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanPromotionResult = QueryResult<LoanPromotion, AxiosError<LoanPromotion>>;
export type LoanPromotionConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<LoanPromotion, AxiosError<LoanPromotion>>;
};

interface LoanPromotionQuery {
	(request: LoanPromotionRequestParameters, config?: LoanPromotionConfig): LoanPromotionResult;
	key: 'LoanPromotion';
}

const useLoanPromotionQuery: LoanPromotionQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanPromotion(): Promise<LoanPromotion> {
		const client = ApiCSGService();

		const path = `/api/Loans/${request.loanNumber}/LoanPromotion`;

		const response = await client.get<LoanPromotion>(path);

		return response.data;
	}

	return useQuery(['LoanPromotion', ...Object.values(request)], LoanPromotion, config.queryConfig);
};

useLoanPromotionQuery.key = 'LoanPromotion';

export default useLoanPromotionQuery;
