import React, { createContext, useReducer, Dispatch, PropsWithChildren } from 'react';
import { ActionMap } from 'types/Context';
import globalReducer from './GlobalReducer';

export interface GlobalStateType {
	isLoading: boolean;

	loadingSnackbar: {
		open: boolean;
		message: string;
	};
}

export const globalInitialState: GlobalStateType = {
	isLoading: false,

	loadingSnackbar: {
		open: false,
		message: '',
	},
};

export enum GlobalActionTypes {
	UpdateLoading = 'UPDATE_LOADING',
	UpdateLoadingSnackbar = 'UPDATE_LOADING_SNACKBAR',
}

export type UpdateLoadingType = boolean;

export interface UpdateLoadingSnackbarType {
	open: boolean;
	message?: string;
}

type GlobalPayload = {
	[GlobalActionTypes.UpdateLoading]: UpdateLoadingType;
	[GlobalActionTypes.UpdateLoadingSnackbar]: UpdateLoadingSnackbarType;
};

export type GlobalActions = ActionMap<GlobalPayload>[keyof ActionMap<GlobalPayload>];

export type GlobalDispatchType = Dispatch<GlobalActions>;

export const GlobalContext = createContext<{
	globalState: GlobalStateType;
	dispatchForGlobal: GlobalDispatchType;
}>({
	globalState: globalInitialState,
	dispatchForGlobal: () => null,
});

export function GlobalProvider({
	initialState = globalInitialState,
	children,
}: PropsWithChildren<{ initialState?: Partial<GlobalStateType> }>): React.ReactElement {
	const [state, dispatch] = useReducer(globalReducer, { ...globalInitialState, ...initialState });

	return <GlobalContext.Provider value={{ globalState: state, dispatchForGlobal: dispatch }}>{children}</GlobalContext.Provider>;
}

export default GlobalContext;
