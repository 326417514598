import { useGetLeadProductsQuery } from '../sfc-kit';

export default function useLoanLeadProducts(): Array<{ name: string; productId?: string }> {
	const leadProducts = useGetLeadProductsQuery();

	const getSortedTypes = () => {
		const sfcLoanProducts: Array<{ name: string; productId?: string }> = [];

		if (leadProducts.isSuccess) {
			const { data } = leadProducts;

			data?.forEach(({ otherInfo, productId }) => {
				if (otherInfo) {
					const leads = otherInfo.replace(`"LeadProduct":`, '');
					const products = leads.replace(/[[\]\\{\\}:",']+/g, ' ');
					const names = products.split('Name');

					names.shift();

					names.forEach(name => {
						const uniqueId = `${name.trim()}_${productId}`;
						if (name !== '') {
							if (name.trim() === 'Windows' && productId === 33) {
								sfcLoanProducts.push({ name: 'Windows/Doors', productId: uniqueId });
							} else {
								sfcLoanProducts.push({ name: name.trim(), productId: uniqueId });
							}
						}
					});
				}
			});
		}

		const typeOfImprovement = sfcLoanProducts.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});

		return typeOfImprovement;
	};

	return getSortedTypes();
}
