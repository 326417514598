import { useMessages } from 'hooks';
import React, { useState } from 'react';
import { FlexBox, FlexItem, Spacing, Button, Text } from 'sfc-kit';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { createStyles, makeStyles, Theme, Checkbox, Link } from '@material-ui/core';
import SquareButton from 'sfc-kit/src/components/SquareButton';

interface TOSContentProps {
	checked: boolean;
	printRef: React.RefObject<HTMLDivElement>;
	onCloseClick: () => void;
	handleCheckboxClick: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		checkboxPadding: { padding: theme.spacing(0, 0, 0, 1) },
		tosText: { overflowY: 'scroll', maxHeight: '70vh' },
		welcomeText: { maxWidth: '399px', margin: '0 auto', '@media print': { maxWidth: '100%' } },
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		checkboxFocus: {
			color: 'blue',
		},
	});
});

const TOSContent: React.FC<TOSContentProps> = ({ handleCheckboxClick, checked, onCloseClick, printRef }) => {
	const messages = useMessages();
	const classes = useStyles({} as any);
	const [focused, setFocused] = useState<boolean>(false);

	const [checkBoxActive, setCheckBoxActive] = useState<boolean>(checked);

	return (
		<FlexBox>
			<FlexItem className={classes.tosText}>
				<div ref={printRef}>
					<FlexBox className={classes.welcomeText} direction="column">
						<Spacing mb="large">
							<Text variant="body1" weight="medium" align="center" messages={messages} message="termsOfService.welcomeToASafe" />
						</Spacing>
						<Spacing mb="large">
							<Text variant="body1" weight="medium" align="center" messages={messages} message="termsOfService.federalESign" />
						</Spacing>
					</FlexBox>

					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.pleaseReadFederal" />
					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.followingDiscolsures" />

					<Text variant="body1" weight="medium" messages={messages} message="termsOfService.electronicDeliveryTitle" />
					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.electronicDeliveryBody" />

					<Text variant="body1" weight="medium" gutterBottom messages={messages} message="termsOfService.pageDeliveryTitle" />
					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.pageDeliveryBody" />

					<Text
						variant="body1"
						weight="medium"
						gutterBottom
						messages={messages}
						message="termsOfService.systemRequirementsAccessInfoTitle"
					/>

					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.systemRequirementsAccessInfoList" />

					<ul>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.systemRequirementsItemOne" />
						</li>
						<li>
							<Text variant="body2" inline messages={messages} message="termsOfService.systemRequirementsItemTwoP1" />
							<Link tabIndex={1} href="https://www.microsoft.com/en-us/edge">
								https://www.microsoft.com/en-us/edge
							</Link>
							<Text variant="body2" inline messages={messages} message="termsOfService.systemRequirementsItemTwoP2" />
						</li>
						<li>
							<Text variant="body2" inline messages={messages} message="termsOfService.systemRequirementsItemThreeP1" />
							<Link href="http://www.adobe.com/products/acrobat/readstep2.html" tabIndex={1}>
								http://www.adobe.com/products/acrobat/readstep2.html
							</Link>
							<Text variant="body2" inline>
								).
							</Text>
							<Spacing mt="small">
								<Text variant="body2" messages={messages} message="termsOfService.systemRequirementsItemThreeP2" />
							</Spacing>
						</li>
						<li>
							<Text variant="body2" inline messages={messages} message="termsOfService.systemRequirementsItemFour" />
							<Link tabIndex={1} href="https://esign.svcfin.com">
								https://esign.svcfin.com
							</Link>
							<Text variant="body2" inline>
								.
							</Text>
						</li>
					</ul>

					<Text
						variant="body1"
						weight="medium"
						gutterBottom
						messages={messages}
						message="termsOfService.systemRequirementsRetainInfoTitle"
					/>

					<Text variant="body2" inline messages={messages} message="termsOfService.systemRequirementsRetainInfoBodyP1" />
					<Link tabIndex={1} href="https://esign.svcfin.com">
						https://esign.svcfin.com/
					</Link>
					<Text variant="body2" inline messages={messages} message="termsOfService.systemRequirementsRetainInfoBodyP2" gutterBottom />

					<Text variant="body1" weight="medium" gutterBottom messages={messages} message="termsOfService.withdrawalElectronicTitle" />
					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.withdrawalElectronicBody" />

					<Text variant="body1" weight="medium" gutterBottom messages={messages} message="termsOfService.changingEmailTitle" />
					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.changingEmailBody" />

					<Text variant="body1" weight="medium" gutterBottom messages={messages} message="termsOfService.rightToDiscontinueTitle" />
					<Text gutterBottom variant="body2" messages={messages} message="termsOfService.rightToDiscontinueBody" />

					<Text variant="body1" weight="medium" gutterBottom messages={messages} message="termsOfService.systemEnhacementsTitle" />
					<Text variant="body2" messages={messages} message="termsOfService.systemEnhacementsBody" />

					<Spacing mt="xLarge">
						<Text inline variant="body2" messages={messages} message="termsOfService.byClicking" />
						<Text inline variant="body2" weight="medium" messages={messages} message="termsOfService.yesIConsent" />
						<Text inline variant="body2" gutterBottom messages={messages} message="termsOfService.belowYou" />
					</Spacing>

					<ul>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.consentItemOne" />
						</li>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.consentItemTwo" />
						</li>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.consentItemThree" />
						</li>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.consentItemFour" />
						</li>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.consentItemFive" />
						</li>
						<li>
							<Text variant="body2" messages={messages} message="termsOfService.consentItemSix" />
						</li>
					</ul>

					<Text inline variant="body2" messages={messages} message="termsOfService.ifYouClick" />
					<Text inline variant="body2" weight="medium" messages={messages} message="termsOfService.noDontConsent" />
					<Text inline variant="body2" gutterBottom messages={messages} message="termsOfService.notConsenting" />

					<Spacing my="large">
						<Text variant="caption" messages={messages} message="termsOfService.revision" />
					</Spacing>
				</div>
				<FlexBox alignItems="center">
					<Checkbox
						icon={
							<CheckBoxOutlineBlankIcon
								aria-label="select to agree to the Terms of Service"
								className={focused ? classes.checkboxFocus : ''}
								color="primary"
								style={{ fontSize: 22 }}
							/>
						}
						checkedIcon={<CheckBoxIcon className={focused ? classes.checkboxFocus : ''} color="primary" style={{ fontSize: 22 }} />}
						disableRipple
						id="paperless-terms-of-service-checkbox"
						data-testId="paperlessTermsCheckbox"
						color="primary"
						onFocus={(): void => setFocused(true)}
						onBlur={(): void => setFocused(false)}
						inputProps={{ 'aria-label': 'select to agree to the Terms of Service', tabIndex: 1 }}
						checked={checkBoxActive}
						onChange={(): void => setCheckBoxActive(!checkBoxActive)}
					/>
					<Text variant="caption" messages={messages} message="paperless.yesConsent" inline />
					<Text variant="caption" inline>
						{'\u00A0'}
					</Text>
					<Text variant="caption" messages={messages} message="paperless.termsOfService" inline />
				</FlexBox>
			</FlexItem>
			<FlexItem xs={12}>
				<FlexBox justifyContent="center">
					<FlexItem xs={6}>
						<Spacing mt="medium" mr="small">
							{/* <Button
								disabled={!checkBoxActive}
								casing="capitalizeEachWord"
								fullWidth
								tabIndex={1}
								className={classes.focus}
								onClick={(): void => {
									handleCheckboxClick(checkBoxActive);
									onCloseClick();
								}}
								message={'buttons.continue'}
								messages={messages}
							/> */}
							<SquareButton
								className={classes.focus}
								fullWidth
								tabIndex={1}
								color="primary"
								variant="contained"
								onClick={(): void => {
									handleCheckboxClick(checkBoxActive);
									onCloseClick();
								}}>
								Continue
							</SquareButton>
						</Spacing>
					</FlexItem>
					<FlexItem xs={6}>
						<Spacing mt="medium" ml="small">
							<Button
								fullWidth
								casing="capitalizeEachWord"
								onClick={onCloseClick}
								tabIndex={1}
								className={classes.focus}
								color="button.secondary"
								textColor="cancelButton"
								message={'buttons.cancel'}
								messages={messages}
							/>
						</Spacing>
					</FlexItem>
				</FlexBox>
			</FlexItem>
		</FlexBox>
	);
};

export default TOSContent;
