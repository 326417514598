import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { type FontWeightProperty } from 'csstype';

import Paths from 'constants/Paths';

import { Link, makeStyles, createStyles } from '@material-ui/core';
import { Text } from 'sfc-kit';

export interface AlreadyHaveAnAccountPromptProps {
	classes?: {
		typography?: string;
		link?: string;
	};
}

const useStyles = makeStyles(theme =>
	createStyles({
		link: {
			cursor: 'pointer',
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
			textDecoration: 'none',
			color: theme.palette.action.main,

			'&:hover': {
				textDecoration: 'none',
			},
		},
	})
);

const AlreadyHaveAnAccountPrompt: FunctionComponent<AlreadyHaveAnAccountPromptProps> = ({ classes = {} }) => {
	const styles = useStyles({} as any);

	return (
		<Text align="center" className={classes.typography}>
			Already have an account?{' '}
			<Link className={classnames(styles.link, classes.link)} component={RouterLink} to={Paths.Login}>
				Sign-In
			</Link>
		</Text>
	);
};


export default AlreadyHaveAnAccountPrompt;
