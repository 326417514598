import classnames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import { Paper, Popper, PopperProps, makeStyles, createStyles } from '@material-ui/core';

import { CSSProperties, Direction } from '../..';

export interface TooltipProps extends Omit<PopperProps, 'className' | 'classes'> {
	arrowDirection?: Direction;
	maxWidth?: number;
	ariaId?: string;
	className?: string;
	classes?: {
		root?: string;
		box?: string;
		paper?: string;
	};
}

export const ARROW_SIZE = 15;

export const generateMargin = (arrowDirection: Direction): CSSProperties => {
	switch (arrowDirection) {
		case 'right':
			return { margin: '0 1.5em' };

		case 'down':
			return { margin: '1.5em 0' };

		case 'up':
			return { margin: '1.5em 0' };

		default:
		case 'left':
			return { margin: '0 1.5em' };
	}
};

export const generatePlacement = (arrowDirection: Direction): PopperProps['placement'] => {
	switch (arrowDirection) {
		case 'up':
			return 'bottom';

		case 'down':
			return 'top';

		case 'left':
			return 'right';

		case 'right':
			return 'left';
	}
};

const useStyles = makeStyles(theme =>
	createStyles({
		popper: ({ arrowDirection, maxWidth }: { arrowDirection: Direction; maxWidth: number }) => ({
			boxShadow: theme.shadows[10],
			zIndex: 1,
			maxWidth,
			'&[x-placement*="bottom"] $arrow': {
				left: 0,
				top: 0,
				marginTop: '-1em',
				width: '3em',
				height: '1em',
				'&::before': {
					borderWidth: '0 1em 1em 1em',
					borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
				},
			},
			'&[x-placement*="top"] $arrow': {
				bottom: 0,
				left: 0,
				marginBottom: '-1em',
				width: '3em',
				height: '1em',
				'&::before': {
					borderWidth: '1em 1em 0 1em',
					borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
				},
			},
			'&[x-placement*="right"] $arrow': {
				left: 0,
				marginLeft: '-1em',
				height: '2em',
				width: '1em',
				'&::before': {
					borderWidth: '1em 1em 1em 0',
					borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
				},
			},
			'&[x-placement*="left"] $arrow': {
				right: 0,
				marginRight: '-1em',
				height: '2em',
				width: '1em',
				'&::before': {
					borderWidth: '1em 0 1em 1em',
					borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
				},
			},
			...generateMargin(arrowDirection),
		}),

		arrow: () => ({
			position: 'absolute',
			fontSize: ARROW_SIZE,
			width: '1em',
			height: '2em',
			'&::before': {
				content: '""',
				margin: 'auto',
				display: 'block',
				width: 0,
				height: 0,
				borderStyle: 'solid',
			},
		}),

		paper: {
			position: 'relative',
			zIndex: 2,
		},
	})
);

const Tooltip: FunctionComponent<TooltipProps> = ({
	arrowDirection = 'left',
	maxWidth = 250,
	children,
	className,
	ariaId,
	classes = {},
	...props
}) => {
	const styles = useStyles({ arrowDirection, maxWidth });
	const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

	return (
		<Popper
			placement={generatePlacement(arrowDirection)}
			{...props}
			id={ariaId}
			disablePortal
			className={classnames(styles.popper, className, classes.root)}
			modifiers={{
				arrow: {
					enabled: true,
					element: arrowRef,
				},
				preventOverflow: { enabled: true, boundariesElement: 'window' },
				computeStyle: { enabled: true, y: 'left' },
			}}>
			{arrowDirection !== undefined && <span ref={setArrowRef} className={styles.arrow} />}
			<Paper className={classnames(styles.paper, classes.paper)} elevation={0}>
				{children}
			</Paper>
		</Popper>
	);
};

export default Tooltip;
