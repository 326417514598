import React, { ElementType, FieldsetHTMLAttributes, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Text } from '../../';
import { Color, SfcKitMessages, Messages, LangText, MessagesProps, Casing } from '../../types';
import extractColorFromKeyString from '../../utils/ExtractColorFromKeyString';

export interface FieldsetProps<TMessages extends Messages = SfcKitMessages>
	extends MessagesProps<TMessages>,
		FieldsetHTMLAttributes<HTMLFieldSetElement> {
	label?: LangText<TMessages>;
	labelCasing?: Casing;
	labelColor?: Color;
	borderColor?: Color;
	background?: string;
	round?: boolean;
	component?: ElementType<any>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: ({
			borderColor = 'primary.main',
			labelColor,
			background,
			round,
		}: Pick<FieldsetProps, 'borderColor' | 'labelColor' | 'background' | 'round'>) => {
			const extractedBorderColor = extractColorFromKeyString(borderColor);
			const extractedLabelColor = labelColor ? extractColorFromKeyString(labelColor) : extractedBorderColor;

			return {
				position: 'relative',
				padding: theme.spacing(2),
				width: '100%',
				border: `4px solid ${extractedBorderColor}`,
				background,
				borderRadius: round ? '4px' : undefined,
				'& legend > p': {
					color: extractedLabelColor,
				},
			};
		},
		legend: {
			padding: '0 10px 0 6px',
		},
	})
);

export default function Fieldset<TMessages extends Messages = SfcKitMessages>({
	label,
	labelCasing,
	labelColor,
	messages,
	borderColor,
	background,
	round,
	component,
	children,
}: FieldsetProps<TMessages>): ReactElement {
	const classes = useStyles({ borderColor, labelColor, background, round });

	return (
		<fieldset className={classes.root}>
			{label && (
				<legend className={classes.legend}>
					<Text color="primary" variant="subtitle1" casing={labelCasing} component={component}>
						<strong>{messages?.[label]}</strong>
					</Text>
				</legend>
			)}

			{children}
		</fieldset>
	);
}
