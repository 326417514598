import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { FlexBox, FlexItem, Spacing } from '../..';

import IsString from '../../../guards/IsString';

import SquareButton from '../../SquareButton';
import SquareButtonLink from '../../SquareButtonLink';
import SfcAlert, { ISfcAlertProps } from '../../SfcAlert';
import { Text } from '../../Typography';
import { VoidClickHandler } from '../../../types/VoidClickHandler';
import { UseFormMethods } from 'react-hook-form';
import FormAlert from '../../form/FormAlert/FormAlert';

export interface WizardCardContentProps {
	/**
	 * The text to be displayed as the title of the content area.
	 */
	title: string;

	/**
	 * A callback function to be called when the continue button is clicked.
	 */
	onContinueClick?: VoidClickHandler;

	/**
	 * A callback function to be called if/when the back button is clicked.
	 */
	onGoBackClick?: VoidClickHandler;

	children?: ReactNode;

	/**
	 * ID from the Form for Continue button outside of form.
	 */
	formID?: string;

	/**
	 * The text to be displayed as the title of the alert.
	 */
	alertTitle?: string;

	/**
	 * The type of alert to be shown. i.e. Success, Error, Warning, or Info.
	 */
	alertType?: ISfcAlertProps['type'];

	/**
	 * Whether the alert should be open (visible).
	 */
	alertOpen?: boolean;

	/**
	 * The content to be displayed in the alert. If it is a string it will be rendered inside of a Text component.
	 */
	alertContent?: ReactNode;

	/**
	 * Errors from react hook form for the Form Alert component to display
	 */
	errors?: UseFormMethods['errors'];

	/**
	 * The text to be used in the continue button.
	 */
	continueText?: string;

	/**
	 * Whether the continue button should be disabled.
	 */
	continueDisabled?: boolean;

	/**
	 * Whether the back button should be rendered.
	 */
	showBackButton?: boolean;

	/**
	 * The location to redirect to when the back button is clicked.
	 */
	goBackLink?: string;

	/**
	 * Content to be displayed instead of the back button.
	 */
	backButtonReplacement?: ReactNode;

	isCaptcha?: boolean;
}

const useStyles = makeStyles(theme =>
	createStyles({
		backButton: {
			color: theme.palette.primary.dark,

			'&:hover': {
				color: theme.palette.primary.main,
			},
			'&:focus-visible': {
				outline: '2px solid #1B1B',
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		continueButton: {
			'&:focus-visible': {
				outline: '2px solid #1B1B',
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
		flex: {
			flex: 1,
		},
		error: {
			minHeight: '90px',
		},
		buttonGrid: {
			[theme.breakpoints.down('sm')]: {
				display: 'block',
				transform: 'scale(0.6)',
				webkitTransform: 'scale(0.6)',
				transformOrigin: '0 0',
				webkitTransformOrigin: '0 0',
			},
		},
	})
);

const WizardCardContent: FunctionComponent<WizardCardContentProps> = ({
	title,
	onContinueClick,
	onGoBackClick,
	formID,

	children,

	alertTitle,
	alertType,
	alertOpen,
	alertContent,
	errors,
	isCaptcha,

	continueText = 'Continue',
	continueDisabled = false,
	showBackButton = false,
	goBackLink,
	backButtonReplacement,
}) => {
	const styles = useStyles({ alertOpen });

	useEffect(() => {
		if (alertOpen) {
			document.getElementById('wizard-card-alert')?.scrollIntoView();
		}
		if (errors) {
			document.getElementById('wizard-card-form-alert')?.scrollIntoView();
		}
	}, [alertOpen, errors]);

	//ToDo Add Lang
	return (
		<FlexBox fullHeight direction="column" spacing="medium" justifyContent="space-between">
			<FlexItem className={styles.error}>
				<FormAlert errors={errors} id="wizard-card-form-alert" />
				<SfcAlert
					positioned
					type={alertType}
					id="wizard-card-alert"
					variant="standard"
					title={alertTitle}
					open={alertOpen}
					aria-invalid="true">
					{IsString(alertContent) ? <Text>{alertContent}</Text> : alertContent}
				</SfcAlert>
			</FlexItem>
			<FlexBox direction="column" justifyContent="space-between" className={styles.flex}>
				<FlexBox direction="column" justifyContent="center" className={styles.flex}>
					<Text gutterBottom variant="h6" component="h2">
						{title}
					</Text>
					<FlexItem>{children}</FlexItem>
				</FlexBox>

				<FlexBox className={isCaptcha ? styles.buttonGrid : undefined}>
					<FlexItem xs={6}>
						<Spacing my="medium" pr="small">
							{backButtonReplacement !== undefined && backButtonReplacement}
							{showBackButton && backButtonReplacement === undefined && (
								<SquareButtonLink
									fullWidth
									variant="contained"
									className={styles.backButton}
									to={goBackLink ?? ''}
									onClick={onGoBackClick}>
									Back
								</SquareButtonLink>
							)}
						</Spacing>
					</FlexItem>
					<FlexItem xs={6}>
						<Spacing mt="medium" pl="small">
							<SquareButton
								fullWidth
								className={styles.continueButton}
								form={formID}
								type="submit"
								color="primary"
								variant="contained"
								disabled={continueDisabled}
								onClick={onContinueClick}>
								{continueText}
							</SquareButton>
						</Spacing>
					</FlexItem>
				</FlexBox>
			</FlexBox>
		</FlexBox>
	);
};

export default WizardCardContent;
