import { Link as RouterLink } from 'react-router-dom';
import React, { FunctionComponent, useContext } from 'react';
import { type FontWeightProperty } from 'csstype';

import { Skeleton } from '@material-ui/lab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, ListItemText, LinearProgress } from '@material-ui/core';

import { usePayoffProgressQuery, NumberUtility, Text } from 'sfc-kit';

import Paths from 'constants/Paths';
import { LoanContext } from 'context';
import { useLoanNickname } from 'hooks';
import SelectableListItem from '../SelectableListItem';

interface IPrimaryDrawerLoanListItemProps {
	loan: Loan;
	isSelectable?: boolean;
	onClick: (loanNumber: string) => void;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		nickname: ({ selected }: { selected: boolean }) => ({
			color: selected ? '#0ce386' : 'inherit',
			fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
		}),
		progressBar: {
			margin: theme.spacing(0, 1),
			backgroundColor: '#eeeeee',
			color: '#0ce386',
			height: '7px',
			border: `1px solid ${theme.palette.common.white}`,
		},
		progressBarColorPrimary: {
			backgroundColor: theme.palette.common.white,
		},
		progressBarBarColorPrimary: {
			backgroundColor: '#0ce386',
		},
		box: {
			backgroundColor: theme.palette.primary.main,
			height: theme.spacing(1.5),
			left: -theme.spacing(0.75),
			position: 'absolute',
			transform: 'rotate(45deg)',
			width: theme.spacing(1.5),
		},
		focus: {
			'&:focus-visible': {
				outline: `2px solid ${theme.palette.primary.main}`,
				outlineOffset: '4px',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none',
			},
		},
	});
});

const PrimaryDrawerLoanListItem: FunctionComponent<IPrimaryDrawerLoanListItemProps> = ({ isSelectable, loan, onClick }) => {
	const { selectedLoanNumber } = useContext(LoanContext);

	const selected = loan.loanNumber === selectedLoanNumber;

	const { nickname } = useLoanNickname(loan.loanNumber);
	const { isLoading, data: payoffProgress } = usePayoffProgressQuery({ loanNumber: loan.loanNumber });

	const classes = useStyles({ selected });

	return (
		<>
			<SelectableListItem
				button
				className={classes.focus}
				selected={selected}
				to={Paths.Dashboard}
				component={RouterLink}
				onClick={(): void => onClick(loan.loanNumber)}
				tabIndex={isSelectable ? 2 : -1}>
				<Grid container direction="column">
					<ListItemText primary={nickname} primaryTypographyProps={{ className: classes.nickname, gutterBottom: true }} />
					<Grid container justifyContent="space-around" alignItems="center">
						<Grid item xs={6}>
							<Text variant="caption" color="misc.white">
								Initial Balance
							</Text>
							<Text color="misc.white">
								{isLoading ? <Skeleton animation="wave" /> : `$${NumberUtility.formatCurrency(payoffProgress?.loanAmount)}`}
							</Text>
						</Grid>
						<Grid item xs={6}>
							<Text align="center" variant="caption" color="misc.white">
								{isLoading ? <Skeleton animation="wave" /> : `${payoffProgress?.percentagePaid ?? 0}%`}
							</Text>
							<LinearProgress
								className={classes.progressBar}
								classes={{ colorPrimary: classes.progressBarColorPrimary, barColorPrimary: classes.progressBarBarColorPrimary }}
								variant="determinate"
								value={payoffProgress?.percentagePaid ?? 0}
							/>
						</Grid>
					</Grid>
				</Grid>
				<ChevronRightIcon fontSize="large" />
			</SelectableListItem>
			<Divider aria-hidden="true" />
		</>
	);
};


export default PrimaryDrawerLoanListItem;
