import React, { FunctionComponent } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import MuiSaveIcon from '@material-ui/icons/Save';
import MuiCreateIcon from '@material-ui/icons/Create';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { Text } from '../Typography';

export interface IEditToggleTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'variant' | 'classes'> {
	editing?: boolean;
	saveOnEnterPress?: boolean;

	onSave: () => void;
	onEdit: () => void;
	onChange: (value: string) => void | Promise<void>;

	SaveIcon?: typeof SvgIcon;
	EditIcon?: typeof SvgIcon;

	classes?: {
		icon?: string;
		iconButton?: string;
		endAdornment?: string;
	};
}

const EditToggleTextField: FunctionComponent<IEditToggleTextFieldProps> = ({
	editing = false,
	saveOnEnterPress = false,

	onSave,
	onEdit,
	onChange,

	SaveIcon = MuiSaveIcon,
	EditIcon = MuiCreateIcon,

	classes = {},

	InputProps,

	...props
}) => {
	const onClick = editing ? onSave : onEdit;
	const Icon = editing ? SaveIcon : EditIcon;
	const iconSrOnlyText = editing ? 'Save' : 'Edit';

	const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (editing && saveOnEnterPress && event.key === 'Enter') {
			onSave();
		}
	};

	return (
		<TextField
			{...props}
			onChange={async (event): Promise<void> => await onChange(event.target.value)}
			InputProps={{
				onKeyPress,
				disabled: !editing,
				disableUnderline: !editing,
				tabIndex: 2,
				endAdornment: (
					<InputAdornment position="end" className={classes.endAdornment}>
						<IconButton onClick={onClick} className={classes.iconButton} tabIndex={2}>
							<Icon color="primary" fontSize="small" className={classes.icon} />
							<Text color="misc.white" variant="srOnly">
								{iconSrOnlyText}
							</Text>
						</IconButton>
					</InputAdornment>
				),
				...InputProps,
			}}
		/>
	);
};

export default EditToggleTextField;
