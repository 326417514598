import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';

import { ZoomProps } from '@material-ui/core/Zoom';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';

import Image from 'sfc-kit/src/components/Image';
import SfcAlert from 'sfc-kit/src/components/SfcAlert';
import { Dialog, WizardCard, Text } from 'sfc-kit';
import MediumBold from 'sfc-kit/src/components/MediumBold';
import SquareButton from 'sfc-kit/src/components/SquareButton';

import { ContactUsSubject } from 'constants/ContactUs';
import { ContactUsLink } from 'components';

import EmailCheck from 'assets/Email-Check.png';
import { Grid, Hidden, IconButton, Zoom, useMediaQuery } from '@material-ui/core';

export interface IVerifyEmailDialogProps {
	open: boolean;
	attemptsExceeded: boolean;

	sidebarTitle?: string;
	sidebarContent?: ReactNode;

	onClose: () => void | Promise<void>;
	onResendEmail: () => void | Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '100%',
			minHeight: 0,
			overflowX: 'hidden',
			[theme.breakpoints.down('xs')]: {
				maxWidth: '100%',
				width: '100%',
			},
		},
		icon: {
			[theme.breakpoints.down('xs')]: {
				fontSize: theme.typography.h3.fontSize,
				margin: theme.spacing(0),
			},
		},
		minHeightZero: {
			minHeight: 0,
		},
		contentGrid: {
			height: '100%',
		},
		closeIconButton: {
			padding: theme.spacing(0.5),
		},
		title: ({ alertOpen }: { alertOpen: boolean | null }) => ({
			marginTop: theme.spacing(alertOpen === true ? 1 : 1),

			[theme.breakpoints.down('xs')]: {
				textAlign: 'center',
			},
		}),
		image: {
			display: 'block',
			margin: 'auto',
			width: 150,
		},
		buttonGrid: {
			[theme.breakpoints.up('sm')]: {
				marginTop: theme.spacing(4),
			},
		},
	})
);

const ZoomTransition = React.forwardRef<unknown, ZoomProps>(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

const VerifyEmailDialog: FunctionComponent<IVerifyEmailDialogProps> = ({
	open,
	attemptsExceeded,
	onClose,
	onResendEmail,
	sidebarTitle,
	sidebarContent,
}) => {
	const theme = useTheme();
	const styles = useStyles({ alertOpen: attemptsExceeded });
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
	const [isMobileView, setIsMobileView] = useState<boolean>(false);

	useEffect(() => {
		if (sessionStorage.getItem('ShowMobileView')) {
			const mobileViewSession = sessionStorage.getItem('ShowMobileView');
			if (mobileViewSession === 'true') {
				setIsMobileView(true);
			}
		}
	}, [setIsMobileView]);

	return (
		<Dialog open={open} maxWidth="xl" onClose={onClose} TransitionComponent={ZoomTransition}>
			<WizardCard
				icon={LockIcon}
				title={sidebarTitle}
				sidebarContent={sidebarContent}
				classes={{
					root: styles.root,
					icon: styles.icon,
					sidebar: styles.minHeightZero,
					content: styles.minHeightZero,
				}}>
				<Grid container direction="column" className={styles.contentGrid}>
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item xs>
							<SfcAlert
								positioned
								open={attemptsExceeded}
								title={isMobile ? undefined : 'Maximum Attempts Exceeded'}
								type="error"
								variant="standard">
								<Text variant="body2">
									If you still do not receive the email, the email address may not be correct, please contact the{' '}
									{!isMobileView ? (
										<ContactUsLink subject={ContactUsSubject.WebsiteAccess}>support team.</ContactUsLink>
									) : (
										<Text variant="body2" inline>
											support team.
										</Text>
									)}
								</Text>
							</SfcAlert>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose} className={styles.closeIconButton}>
								<CloseIcon aria-label="Close" />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs>
						<Text gutterBottom variant="h6" className={styles.title}>
							Verify Email Address
						</Text>
						<Grid container justifyContent={isMobile ? 'center' : 'space-around'} direction={isMobile ? 'column-reverse' : 'row'}>
							<Grid item xs={12} sm={6}>
								<Text variant="body2" gutterBottom>
									A verification link has been sent to the email you provided. If you do not receive the email try clicking the{' '}
									<MediumBold inline>Resend Email</MediumBold> button. If this does not work, make sure the email you provided was
									entered correctly.
								</Text>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Image src={EmailCheck} alt="Email Sent" className={styles.image} />
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={2} className={styles.buttonGrid}>
						<Hidden xsDown>
							<Grid item xs={6} />
						</Hidden>
						<Grid item xs={12} sm={6}>
							<SquareButton fullWidth color="primary" variant="contained" onClick={onResendEmail} disabled={attemptsExceeded}>
								Resend Email
							</SquareButton>
						</Grid>
					</Grid>
				</Grid>
			</WizardCard>
		</Dialog>
	);
};

export default VerifyEmailDialog;
