import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, QueryConfig, QueryResult } from 'react-query';
import { ApiCSGService } from '../ApiService';

export type LoanDetailOneUdfResult = QueryResult<string, AxiosError<string>>;
export type LoanDetailOneUdfConfig = {
	axiosConfig?: AxiosRequestConfig;
	queryConfig?: QueryConfig<string, AxiosError<string>>;
};

interface LoanDetailOneUdfQuery {
	(request: LoanDetailOneUdfRequestParameters, config?: LoanDetailOneUdfConfig): LoanDetailOneUdfResult;
	key: 'LoanDetailOneUdf';
}

const useLoanDetailOneUdfQuery: LoanDetailOneUdfQuery = (request, config = { axiosConfig: {} }) => {
	async function LoanDetailOneUdf(): Promise<string> {
		const client = ApiCSGService();
		
		const { loanNumber, udfNumber } = request;

		const path = `/api/Loans/${loanNumber}/LoanDetailOneUdf/${udfNumber}`;

		const response = await client.get<string>(path);

		return response.data;
	}

	return useQuery(['LoanDetailOneUdf', ...Object.values(request)], LoanDetailOneUdf, config.queryConfig);
};

useLoanDetailOneUdfQuery.key = 'LoanDetailOneUdf';

export default useLoanDetailOneUdfQuery;
